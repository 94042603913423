import React, {useEffect, useState} from 'react';
import Header from '../../containers/Header';
import Footer from '../../components/Footer';
import {useFormik} from "formik";
import * as Yup from "yup";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import {NavLink, Redirect} from "react-router-dom";
import './CreateAdvertisement.scss';
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {SimpleDialog} from '../SimpleDialog/simpleDialog';
import {getAllCategories} from '../../services/category'
import ButtonBase from "@material-ui/core/ButtonBase";
import {citiesToDialog} from "../../services/normalizers";
import {useDispatch, useSelector} from 'react-redux';
import ThirdPart from "./ThirdPart";
import {SecondPart2} from "./SecondPart2";
import {setMessage, setOpen, setBackColor} from "../../reducers/snackbarReducer";
import {setIsEditing, setEditObject} from "../../reducers/advertisementReducer";
import {createAdvert, getBrands, updateAdvert} from "../../services/advertisement";
import {getAllAdvertTypes} from "../../services/advertType";

import CircularProgress from "@material-ui/core/CircularProgress";
import {getAllColors} from "../../services/colors";
import Popover from "@material-ui/core/Popover";
import InfoIcon from '@material-ui/icons/Info';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import List from "@material-ui/core/List";
import {getAllPricingTypes} from "../../services/pricingType";
import {MdClose} from "react-icons/md";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import {staticApiUrl} from "../../utils/axios";
import ReactQuill from 'react-quill';

const EditAdvertisement = ({currentUser, isEditing, editObject}) => {

    const [openCategory, setOpenCategory] = useState(false);
    const [elementCategory, setElementCategory] = useState(null);
    const [elementCategoryParent, setElementCategoryParent] = useState(null);
    const [isChild, setIsChild] = useState(false);
    const [openCity, setOpenCity] = useState(false);
    const [elementCity, setElementCity] = useState(null);
    const currentCountry = useSelector(state => state.country.currentCountry);
    const cities = citiesToDialog(currentCountry.cities);
    const [models, setModels] = useState("");
    const [brands, setBrands] = useState([]);
    const [colors, setColors] = useState([]);
    const [advertTypes, setAdvertsTypes] = useState([]);
    const [pricingTypes, setPricingTypes] = useState([]);
    const [newPost, setNewPost] = useState(null);
    const [updatePost, setUpdatePost] = useState(null);
    const [publish, setPublish] = useState(true);
    const [errorMessage, setErrorMessage] = useState(false);
    const [categories, setCategories] = useState([]);
    const [description, setDescription] = useState(editObject?.description);

    const handleClickOpenCategory = () => {
        setOpenCategory(true);
    };

    /*const handleOpenErrorMessage = () => {
        setErrorMessage(true);
    };*/

    const handleCloseErrorMessage = () => {
        setErrorMessage(false);
    };

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            user_id: currentUser.id,
            category_id: editObject && editObject.category_id,
            type_advert: editObject && editObject.type_advert,
            title: editObject && editObject.title,
            description: editObject && editObject.description,
            price: editObject && editObject.price,
            type_price: editObject && editObject.type_price,
            negociable: editObject && editObject.negociable === "1" ? true : false,
            published: true,
            hidden_phone: false,
            country_id: currentCountry.id,
            city_id: editObject && editObject.city_id,
            zone_id: editObject && editObject.zone_id,
            images: editObject && editObject.images,
            old_images: [],
            brand_id: '',
            model_id: '',
            year: editObject && editObject.year,
            mileage: editObject && editObject.mileage,
            nb_door: editObject && editObject.nb_door,
            nb_seat: editObject && editObject.nb_seat,
            fuel: editObject && editObject.fuel,
            gear_box: editObject && editObject.gear_box,
            color: '',
            nb_rooms: editObject && editObject.nb_rooms,
            area: editObject && editObject.area,
            type_immo: editObject && editObject.type_immo,
            cgu: true,
            state: editObject && editObject.state,
            storage: editObject && editObject.storage,
            memory: editObject && editObject.memory,
            advert_id: editObject && editObject.advert_id,

        },
        validationSchema: Yup.object({
            category_id: Yup.string().required('Selectionnez une categorie'),
            // type_advert:  publish ? Yup.string().required('Selectionnez un type d\'annonce') : null,
            brand_id: publish ? Yup.lazy(() => {
                return ["69", "70", "71", "72", "74", "203", "79", "80", "81", "82"].includes(formik.values.category_id)? Yup.string().required('Ce champ est requis') : Yup.string()
            }) : null,
            cgu: Yup.bool().oneOf([true], 'Accepter les conditions d\'utilisation avant de continuer'),
            title: Yup.string().required('Le titre est requis'),
            city_id: Yup.string().required('Selectionner une ville ou un quartier')
        }),
        onSubmit: (values, actions) => {
            let advert = {...values};
            delete advert.cgu;
            delete advert.category;
            delete advert.city;
            advert.published = advert.published === false ? 0 : 1;
            advert.negociable = advert.negociable === false ? 0 : 1;
            advert.hidden_phone = advert.hidden_phone === false ? 0 : 1;
            advert.model_id = advert.model_id === '' ? null : advert.model_id;
            advert.brand_id = advert.brand_id === '' ? null : advert.brand_id;
            advert.city_id = advert.city_id === '' ? null : advert.city_id;
            advert.zone_id = advert.zone_id === '' ? null : advert.zone_id;
            advert.type_price = advert.type_price === '0' ? null : advert.type_price;
            advert.year = advert.year === '0' ? null : advert.year;
            advert.mileage = advert.mileage === '0' ? null : advert.mileage;
            advert.nb_door = advert.nb_door === '0' ? null : advert.nb_door;
            advert.nb_seat = advert.nb_seat === '0' ? null : advert.nb_seat;
            advert.nb_rooms = advert.nb_rooms === '0' ? null : advert.nb_rooms;
            // advert.color = advert.color === '0' ? null : advert.color;
            advert.area = advert.area === '0' ? null : advert.area;
            advert.storage = advert.storage === '0' ? null : advert.storage;
            advert.memory = advert.memory === '0' ? null : advert.memory;
            advert.description = description;

            if (advert.color.length !== 0) {
                for (let i = 0; i < advert.color.length; i++) {
                    advert.color[i] = +advert.color[i];
                }
            }

            if (!openErrorMessage()) {
                advert.color = "[" + advert.color + "]";
                updateAdvert(advert).then(({data})=> {
                    if (data.RESPONSECODE === 200) {
                        dispatch(setIsEditing({isEditing : false}));
                        dispatch(setEditObject({editObject : null}));
                        dispatch(setMessage({message :  data.RESPONSEMESSAGE}));
                        dispatch(setOpen({open : true}));
                        dispatch(setBackColor({background: "green"}));
                        formik.resetForm();
                        // setUpdatePost("/dashboard/adverts");
                        setNewPost("/confirmation-page");
                    } else {
                        dispatch(setMessage({message : data.RESPONSEMESSAGE}));
                        dispatch(setOpen({open : true}));
                    }
                }).catch((e)=>{
                    dispatch(setMessage({message : "Erreur lors de la modification"}));
                    dispatch(setOpen({open : true}));
                    console.error(e);
                }).finally((e)=> {
                    actions.setSubmitting(false);
                });
            } else {
                actions.setSubmitting(false);
            }
        },
    });

    const openErrorMessage = () => {
        if (
            formik.values.category_id === '' ||
            formik.values.title === '' ||
            formik.values.city_id === '' ||
            formik.values.cgu === false ||
            (elementCategoryParent &&
                ["69", "70", "71", "72", "74", "203", "79", "80", "81", "82"].includes(formik.values.category_id) &&
            formik.values.brand_id === '')) {
            setErrorMessage(true);
            return true;
        } else {
            setErrorMessage(false);
            return false;
        }
    }

    const handleCloseCategory = (element, category, isChild) => {
        if (isChild) {
            handleBrandsChange(element.id, 2);
            setElementCategory(element ? element : category);
            setElementCategoryParent(category);
            setIsChild(isChild);
            formik.setFieldValue('category_id', element.id, true);
        } else {
            dispatch(setMessage({message : "Vous devez choisir une sous catégorie pour continuer"}));
            dispatch(setOpen({open : true}));
            dispatch(setBackColor({background: "red"}));
        }
        setOpenCategory(false);
    };

    useEffect(() => {
        console.log(editObject);
        loadCategories(editObject.category_id);
        handleBrandsChange(editObject.category_id, 1);
        getAllColors().then(({data})=> {
            let res = data;
            setColors(res);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
        });
        getAllAdvertTypes().then(({data})=> {
            let res = data;
            setAdvertsTypes(res);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
        })
        getAllPricingTypes().then(({data})=> {
            let res = data;
            setPricingTypes(res);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
        });
        cities.map(element => {
            if (element.id == editObject.city_id) {
                setElementCity(element);
            } else {
                element.items.map(elt => {
                    if(elt.id === editObject.zone_id) {
                        setElementCity(elt);
                    }
                })
            }
        })
    }, [])

    let years = [];
    for (let start = 1980; start <= new Date().getFullYear(); start += 1) {
        years = [...years, start];
    }

    let kms = [];
    for (let start = 0; start < 100000; start += 5000) {
        kms = [...kms, `${start} - ${start + 5000}`];
    }
    for (let start = 100000; start < 200000; start += 10000) {
        kms = [...kms, `${start} - ${start + 10000}`];
    }
    for (let start = 200000; start < 500000; start += 50000) {
        kms = [...kms, `${start} - ${start + 50000}`];
    }

    let areas = [];
    for (let start = 1; start <= 1000; start += 50) {
        areas = [...areas, start];
    }

    let memories = [];
    for (let start = 1; start <= 512; start += 1) {
        memories = [...memories, start];
    }

    const handleClickOpenCity = () => {
        setOpenCity(true);
    };

    const loadCategories = () => {
        getAllCategories().then(({ data }) => {
            let { RESPONSEDATA } = data;
            let tab = [];
            for (let i = 0; i < RESPONSEDATA.length; i++) {
                var item = RESPONSEDATA[i];
                var obj = {};
                obj.id = item.id;
                let url = `${staticApiUrl}/iconimages/`;
                let filename = "no-image";
                if (item.icon[0] !== undefined) {
                    filename = url + item.icon[0].filename;
                }
                obj.image = filename;
                obj.title = item.title_fr;
                let tab2 = [];
                item.sub.map(elt2 => {
                    tab2.push({
                        id: elt2.id,
                        title: elt2.title_fr,
                        parentId: item.id
                    })
                })
                obj.items = tab2;
                tab.push(obj);
            }
            setCategories(tab);
                tab && tab.map(element => {
                if(element.id === (editObject && editObject.category_id)) {
                    setElementCategory(element);
                } else {
                    element.items.map(elt => {
                        if(elt.id === (editObject && editObject.category_id)) {
                            setElementCategory(elt);
                        }
                    })
                }
            });
        })
    }

    const handleCloseCity = (element, city) => {
        setOpenCity(false);
        if (city) {
            setElementCity(element ? element : city);
            formik.setFieldValue('city_id', city.id, true);
            formik.setFieldValue('zone_id', element ? element.id : '2', true);
        }
    };

    const handleMarqueChange = (event, brand_id, data) => {
        let filterModels = {};
        if (data) {
            filterModels = data.find((elm) => {
                if (event) {
                    formik.setFieldValue('brand_id', event.target.value);
                    return  (elm.id == event.target.value);
                } else {
                    return  (elm.id == brand_id);
                }
            });
        } else {
            filterModels = brands.find((elm) => {
                if (event) {
                    formik.setFieldValue('brand_id', event.target.value);
                    return  (elm.id == event.target.value);
                } else {
                    return  (elm.id == brand_id);
                }
            });
        }
        filterModels && setModels(filterModels.models);
        if (filterModels && editObject.model_id) {
            formik.setFieldValue('model_id', editObject.model_id);
        }
    };

    /* color popover */

    const [anchorElColor, setAnchorElColor] = React.useState(null);

    const handleClickColor = (event) => {
        setAnchorElColor(event.currentTarget);
        //document.body.style.position = "fixed";
    };

    const handleCloseColor = () => {
        setAnchorElColor(null);
        //document.body.style.position = "relative";
    };

    const handleBrandsChange = (type, method) => {
        var brandType = 1;
        if (type == '79' || type == '80') {
            brandType = 2;
        } else if (type == '81' || type == '82')  {
            brandType = 4;
        } else if (type == '71' || type == '69' || type == '72')  {
            brandType = 1;
        } else if (type == '70' || type == '203')  {
            brandType = 3;
        } else if (type == '74') {
            brandType = 5;
        }
        getBrands({'type': brandType})
            .then(({data}) => {
                let { RESPONSEDATA } = data;
                setBrands(RESPONSEDATA);
                if (method == 1){
                    handleMarqueChange(null, editObject.brand_id, RESPONSEDATA)
                    formik.setFieldValue('brand_id', editObject.brand_id ? editObject.brand_id : '' , true);
                } else {
                    formik.setFieldValue('brand_id', '' , true);
                }
            })
            .catch(e=> {
                console.error(e);
                // setBrands([]);
            }).finally(()=>{
        });
    };

    const handlePublish = () => {
        setPublish(!publish);
        formik.setFieldValue('published', !publish);
    };

    const openColor = Boolean(anchorElColor);
    const idColor = openColor ? 'simple-popover' : undefined;

    /* Color select */
    const [colorButtonText, setColorButtonText] = useState("Selectionner une ou plusieurs couleurs");
    const [checkedColor, setCheckedColor] = useState([]);
    const [checkedColorName, setCheckedColorName] = useState([]);
    const handleToggleColor = (value, name) => () => {
        const currentIndex = checkedColor.indexOf(value);
        const currentName = checkedColorName.indexOf(name);
        const newChecked = [...checkedColor];
        const newName = [...checkedColorName];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        if (currentName === -1) {
            newName.push(name);
        } else {
            newName.splice(currentName, 1);
        }
        setCheckedColor(newChecked);
        setCheckedColorName(newName);
        formik.setFieldValue("color", newChecked);
        setColorButtonText(newName + "");
    };

    const cancelToggleColor = () => {
        setAnchorElColor(null);
    }

    const resetToggleColor = () => {
        setCheckedColor([]);
        setCheckedColorName([]);
        setColorButtonText("Selectionner une ou plusieurs couleurs");
        cancelToggleColor();
    }

    const modules ={
        toolbar: [
          [{ 'font': [] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline'],
          [{'list': 'ordered'}, {'list': 'bullet'}],
          [{ 'align': [] }],
        ]
      }
    
      const formats =[
        "header",
        "font",
        "align",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "color",
        "background",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "image",
        "video",
        "code-block"
      ];

    const descriptionChange = (content, delta, source, editor) => {
        setDescription(editor.getHTML());
    }
    if(newPost)
        return <Redirect to={newPost} />

    if(updatePost)
        return <Redirect to={updatePost} />
    return (
        <div>
            <Header />
            <main id="createadvertisement" className="createadvertisement">
                <form onSubmit={formik.handleSubmit} className="createadvertisement-form">
                    <div className="createadvertisement-form-inner">
                        <h2 className="createadvertisement-form-title">Modifier une annonce</h2>
                        <div className="createadvertisement-form-firstpart">
                            <h3 className="createadvertisement-form-section-title">
                                <span className="createadvertisement-form-section-title-bubble">1</span>
                                Votre annonce
                            </h3>
                            {/* First part display */}
                            <div className="createadvertisement-form-block add-first-part">
                                <div className="createadvertisement-form-section category">
                                    <h3 className="createadvertisement-form-block-title">Catégorie</h3>
                                    <ButtonBase className={"custom-arrow-button" + (formik.errors.category_id ? ' is-invalid' : '')} variant="outlined" color="primary" onClick={handleClickOpenCategory}>{elementCategory ? elementCategory.title : "Toutes les categories"} <ArrowDropDownIcon /></ButtonBase>
                                    <TextField
                                        style={{ visibility: 'hidden' }}
                                        name="category_id"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        defaultValue={formik.values.category_id}
                                    />
                                    {(formik.errors.category_id) &&
                                    <p className="errorMessage">
                                        {formik.errors.category_id}
                                    </p>}
                                    <SimpleDialog prompt={"Choisir une categorie"} data={categories} defaultSelection={"Toutes les categories"} open={openCategory} hasImage={true} onClose={handleCloseCategory} />
                                </div>
                                <div className="createadvertisement-form-section add-type">
                                    <h3 className="createadvertisement-form-block-title">Type d'annonce</h3>
                                    <FormControl component="fieldset" className="addTypeWrapper">
                                        <RadioGroup
                                            name="type_advert"
                                            aria-label="type"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            defaultValue={formik.values.type_advert}
                                            className={(formik.errors.type_advert ? ' is-invalid' : '')}>
                                            {
                                                advertTypes && advertTypes.map(type => {
                                                    return <div className="radio-wrapper" key={type.id}>
                                                        <FormControlLabel value={type.id} control={<Radio />} label={type.name} />
                                                        <p>{type.description}</p>
                                                    </div>
                                                })
                                            }
                                        </RadioGroup>
                                    </FormControl>
                                    {(formik.errors.type_advert) &&
                                    <p className="errorMessage">
                                        {formik.errors.type_advert}
                                    </p>}
                                </div>
                            </div>

                            <div className="createadvertisement-form-block specs">
                                {/* AUto moto category selected */}
                                {
                                    (editObject && ["69", "70", "71", "72", "74", "203"].includes(editObject.category_id)) ||
                                        (["69", "70", "71", "72", "74", "203"].includes(elementCategory && elementCategory.id)) ? (
                                        <div className="createadvertisement-form-block-auto">
                                            <div className="createadvertisement-form-section">
                                                <div className="createadvertisement-form-section-wrapper">
                                                    <FormControl variant="outlined" className={"select-brand" + (formik.errors.brand_id ? ' is-invalid' : '')}>
                                                        <InputLabel id="brand-label">Marque</InputLabel>
                                                        <Select
                                                            name="brand_id"
                                                            labelId="brand-label"
                                                            id="brand-select"
                                                            label="Marque"
                                                            onChange={(e) => handleMarqueChange(e, null, null)}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.brand_id || ""}>
                                                            {
                                                                brands && brands.map(element => {
                                                                    return <MenuItem key={element.id} value={element.id}>{element.name}</MenuItem>
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                    {formik.errors.brand_id ? (
                                                        <p className="errorMessage">{formik.errors.brand_id}</p>
                                                    ) : null}
                                                </div>
                                                <div className="createadvertisement-form-section-wrapper">
                                                    <FormControl variant="outlined" className={"select-model" + (formik.errors.model_id ? ' is-invalid' : '')}>
                                                        <InputLabel id="model-label">Modèle</InputLabel>
                                                        <Select
                                                            name="model_id"
                                                            labelId="model-label"
                                                            id="model-select"
                                                            size="small"
                                                            label="Modèle"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.model_id || ""}>
                                                            {
                                                                models && models.map(element => {
                                                                    return <MenuItem key={element.id} value={element.id}>{element.name}</MenuItem>
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                    {formik.errors.model_id ? (
                                                        <p className="errorMessage">{formik.errors.model_id}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="createadvertisement-form-section">
                                                <div className="createadvertisement-form-section-wrapper">
                                                    <FormControl variant="outlined" className={"select-year" + (formik.errors.year ? ' is-invalid' : '')}>
                                                        <InputLabel id="year-label">Année</InputLabel>
                                                        <Select
                                                            name="year"
                                                            labelId="year-label"
                                                            id="year-select"
                                                            size="small"
                                                            label="Année"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            defaultValue={formik.values.year}
                                                            >
                                                            <MenuItem value="0">Aucune sélection</MenuItem>
                                                            {years.map((year) => (<MenuItem value={year} key={year}>{year}</MenuItem>))}
                                                        </Select>
                                                    </FormControl>
                                                    {formik.errors.year ? (
                                                        <p className="errorMessage">{formik.errors.year}</p>
                                                    ) : null}
                                                </div>
                                                <div className="createadvertisement-form-section-wrapper">
                                                    <FormControl variant="outlined" className={"select-km" + (formik.errors.mileage ? ' is-invalid' : '')}>
                                                        <InputLabel id="km-label">Kilométrage</InputLabel>
                                                        <Select
                                                            name="mileage"
                                                            labelId="km-label"
                                                            id="km-select"
                                                            size="small"
                                                            label="Kilométrage"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            defaultValue={formik.values.mileage}
                                                            >
                                                            <MenuItem value="0">Aucune sélection</MenuItem>
                                                            {kms.map((km) => (<MenuItem value={km} key={km}>{km}</MenuItem>))}
                                                        </Select>
                                                    </FormControl>
                                                    {formik.errors.mileage ? (
                                                        <p className="errorMessage">{formik.errors.mileage}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="createadvertisement-form-section">
                                                <div className="createadvertisement-form-section-wrapper">
                                                    <FormControl variant="outlined" className={"select-door" + (formik.errors.nb_door ? ' is-invalid' : '')}>
                                                        <InputLabel id="door-label">Nombre de porte</InputLabel>
                                                        <Select
                                                            name="nb_door"
                                                            labelId="door-label"
                                                            id="door-select"
                                                            size="small"
                                                            label="Nombre de porte"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            defaultValue={formik.values.nb_door}
                                                            >
                                                            <MenuItem value="0">Aucune sélection</MenuItem>
                                                            <MenuItem value="3">3 portes</MenuItem>
                                                            <MenuItem value="4">4 portes</MenuItem>
                                                            <MenuItem value="5">5 portes</MenuItem>
                                                            <MenuItem value="6">6 portes</MenuItem>
                                                            <MenuItem value="7">7 portes</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {formik.errors.nb_door ? (
                                                        <p className="errorMessage">{formik.errors.nb_door}</p>
                                                    ) : null}
                                                </div>
                                                <div className="createadvertisement-form-section-wrapper">
                                                    <FormControl variant="outlined" className={"select-seat" + (formik.errors.nb_seat ? ' is-invalid' : '')}>
                                                        <InputLabel id="seat-label">Nombre de place</InputLabel>
                                                        <Select
                                                            name="nb_seat"
                                                            labelId="seat-label"
                                                            id="seat-select"
                                                            variant="outlined"
                                                            size="small"
                                                            label="Nombre de place"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            defaultValue={formik.values.nb_seat}
                                                            >
                                                            <MenuItem value="0">Aucune sélection</MenuItem>
                                                            <MenuItem value={2}>2 places</MenuItem>
                                                            <MenuItem value={3}>3 places</MenuItem>
                                                            <MenuItem value={4}>4 places</MenuItem>
                                                            <MenuItem value={5}>5 places</MenuItem>
                                                            <MenuItem value={6}>6 places</MenuItem>
                                                            <MenuItem value={7}>7 places</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {formik.errors.nb_seat ? (
                                                        <p className="errorMessage">{formik.errors.nb_seat}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="createadvertisement-form-section column">
                                                <h3 className="createadvertisement-form-block-title">Carburant</h3>
                                                <div className="createadvertisement-form-section-wrapper fuel-container">
                                                    <div className="createadvertisement-form-section-row fuel-choice">
                                                        <FormControl component="fieldset" className="addFuelWrapper">
                                                            <RadioGroup
                                                                name="fuel"
                                                                aria-label="type"
                                                                onBlur={formik.handleBlur}
                                                                onChange={formik.handleChange}
                                                                defaultValue={formik.values.fuel}
                                                                className={(formik.errors.fuel ? ' is-invalid' : '')}>
                                                                <FormControlLabel value="1" control={<Radio />} label="Essence" />
                                                                <FormControlLabel value="2" control={<Radio />} label="Diesel" />
                                                                <FormControlLabel value="3" control={<Radio />} label="Hybride" />
                                                                <FormControlLabel value="4" control={<Radio />} label="Éléctrique" />
                                                                <FormControlLabel value="5" control={<Radio />} label="LPG" />
                                                                <FormControlLabel value="6" control={<Radio />} label="Autre" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                        {formik.errors.fuel ? (
                                                            <p className="errorMessage">{formik.errors.fuel}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="createadvertisement-form-section column gearbox">
                                                <h3 className="createadvertisement-form-block-title">Boîte de vitesse</h3>
                                                <div className="createadvertisement-form-section-wrapper">
                                                    <FormControl component="fieldset" className="addTypeWrapper">
                                                        <RadioGroup
                                                            aria-label="type"
                                                            name="gear_box"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            defaultValue={formik.values.gear_box}
                                                            className={(formik.errors.gear_box ? ' is-invalid' : '')}>
                                                            <div className="radio-wrapper">
                                                                <FormControlLabel value="1" control={<Radio />} label="Manuelle" />
                                                            </div>
                                                            <div className="radio-wrapper">
                                                                <FormControlLabel value="2" control={<Radio />} label="Automatique" />
                                                            </div>
                                                        </RadioGroup>
                                                    </FormControl>
                                                    {formik.errors.gear_box ? (
                                                        <p className="errorMessage">{formik.errors.gear_box}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    ) : ('')
                                }
                                {/* Color */}
                                {
                                    (editObject && ['55', '75', '76', '78', '152', '191'].includes(editObject.category_id)) || (elementCategory && elementCategory.id === "55") || (elementCategoryParent && elementCategoryParent.id === "55") || (elementCategory && elementCategory.id === "75") || (elementCategory && elementCategory.id === "76") || (elementCategory && elementCategory.id === "78") || (elementCategory && elementCategory.id === "152") || (elementCategory && elementCategory.id === "191") ? (
                                        <div className="createadvertisement-form-block-color">
                                            <div className="createadvertisement-form-section color">
                                                <div className="createadvertisement-form-section-wrapper">
                                                    <Button
                                                        aria-describedby={idColor}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleClickColor}>
                                                        <ArrowDropDownIcon/>
                                                        {
                                                            colorButtonText
                                                        }
                                                    </Button>
                                                    {formik.errors.color ? (
                                                        <p className="errorMessage">{formik.errors.color}</p>
                                                    ) : null}
                                                    <Popover
                                                        id={idColor}
                                                        open={openColor}
                                                        anchorEl={anchorElColor}
                                                        onClose={handleCloseColor}
                                                        className="color-popper"
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}>
                                                        <List className="color-list">
                                                            {
                                                                colors.map((color) => {
                                                                    const labelId = `checkbox-list-label-${color.id}`;
                                                                    return (
                                                                        <ListItem key={color.id} button>
                                                                            <ListItemIcon>
                                                                                <span style={{ backgroundColor: color.code }}></span>
                                                                            </ListItemIcon>
                                                                            <ListItemText id={labelId} primary={color.name} />
                                                                            <ListItemSecondaryAction>
                                                                                <Checkbox
                                                                                    edge="end"
                                                                                    onClick={handleToggleColor(color.id, color.name)}
                                                                                    checked={checkedColor.indexOf(color.id) !== -1 || checkedColorName.indexOf(color.name) !== -1 }
                                                                                    tabIndex={-1}
                                                                                    disableRipple
                                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                                    name="color"
                                                                                />
                                                                            </ListItemSecondaryAction>
                                                                        </ListItem>
                                                                    );
                                                                })
                                                            }
                                                            <div className="color-list-footer">
                                                                <Button  onClick={resetToggleColor} className="reset">Effacer</Button>
                                                                <Button onClick={cancelToggleColor} className="validate">Valider</Button>
                                                            </div>
                                                        </List>
                                                    </Popover>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ('')
                                }

                                {/* Informatique && Multimedia */}
                                {
                                        (editObject && ["57", "79", "80", "81", "82"].includes(editObject.category_id)) ||
                                        (["57", "79", "80", "81", "82"].includes(elementCategory && elementCategory.id))
                                        ? (
                                        <div className="createadvertisement-form-block-multimedia">
                                            <div className="createadvertisement-form-section">
                                                <div className="createadvertisement-form-section-wrapper">
                                                    <FormControl variant="outlined" className={"select-brand" + (formik.errors.brand_id ? ' is-invalid' : '')}>
                                                        <InputLabel id="brand-label">Marque</InputLabel>
                                                        <Select
                                                            name="brand_id"
                                                            labelId="brand-label"
                                                            id="brand-select"
                                                            label="Marque"
                                                            onChange={(e) => handleMarqueChange(e, null, null)}
                                                            onBlur={formik.handleBlur}
                                                            // defaultValue={isEditing ? editObject.brand_id : formik.values.brand_id}
                                                            value={formik.values.brand_id || ""}>
                                                            <MenuItem value="0">Aucune sélection</MenuItem>
                                                            {
                                                                brands && brands.map(element => {
                                                                    return <MenuItem key={element.id} value={element.id}>{element.name}</MenuItem>
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                    {formik.errors.brand_id ? (
                                                        <p className="errorMessage">{formik.errors.brand_id}</p>
                                                    ) : null}
                                                </div>
                                                <div className="createadvertisement-form-section-wrapper">
                                                    <FormControl variant="outlined" className={"select-model" + (formik.errors.model_id ? ' is-invalid' : '')}>
                                                        <InputLabel id="model-label">Modèle</InputLabel>
                                                        <Select
                                                            name="model_id"
                                                            labelId="model-label"
                                                            id="model-select"
                                                            size="small"
                                                            label="Modèle"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.model_id || ""}
                                                            >
                                                            <MenuItem value="0">Aucune sélection</MenuItem>
                                                            {
                                                                models && models.map(element => {
                                                                    return <MenuItem value={element.id}>{element.name}</MenuItem>
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                    {formik.errors.model_id ? (
                                                        <p className="errorMessage">{formik.errors.model_id}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* Phones and laptops category selected */}
                                            {
                                                (editObject && ["57", "79", "80", "81", "82"].includes(editObject.category_id)) || (elementCategory && elementCategory.id === "79") || (elementCategory && elementCategory.id === "80") || (elementCategory && elementCategory.id === "81") || (elementCategory && elementCategory.id === "82") ? (
                                                    <div className="createadvertisement-form-section extra-multimedia">
                                                        <div className="createadvertisement-form-section-wrapper">
                                                            <FormControl variant="outlined" className={"select-storage" + (formik.errors.storage ? ' is-invalid' : '')}>
                                                                <InputLabel id="storage-label">Capacité de stockage</InputLabel>
                                                                <Select
                                                                    name="storage"
                                                                    labelId="storage-label"
                                                                    id="storage-select"
                                                                    label="Capacité de stockage"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    defaultValue={formik.values.storage}
                                                                    >
                                                                    <MenuItem value="0">Aucune sélection</MenuItem>
                                                                    <MenuItem value="2">2 Go</MenuItem>
                                                                    <MenuItem value="4">4 Go</MenuItem>
                                                                    <MenuItem value="8">8 Go</MenuItem>
                                                                    <MenuItem value="16">16 Go</MenuItem>
                                                                    <MenuItem value="32">32 Go</MenuItem>
                                                                    <MenuItem value="64">64 Go</MenuItem>
                                                                    <MenuItem value="128">128 Go</MenuItem>
                                                                    <MenuItem value="256">256 Go</MenuItem>
                                                                    <MenuItem value="512">512 Go</MenuItem>
                                                                    <MenuItem value="1024">1 To</MenuItem>
                                                                    <MenuItem value="2048">2 To</MenuItem>
                                                                    <MenuItem value="2048+">2 To +</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                            {formik.errors.storage ? (
                                                                <p className="errorMessage">{formik.errors.storage}</p>
                                                            ) : null}
                                                        </div>
                                                        <div className="createadvertisement-form-section-wrapper">
                                                            <FormControl variant="outlined" className={"select-memory" + (formik.errors.memory ? ' is-invalid' : '')}>
                                                                <InputLabel id="memory-label">Mémoire RAM</InputLabel>
                                                                <Select
                                                                    name="memory"
                                                                    labelId="memory-label"
                                                                    id="memory-select"
                                                                    label="Mémoire RAM"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    defaultValue={formik.values.memory}
                                                                    >
                                                                    <MenuItem value="0">Aucune sélection</MenuItem>
                                                                    {
                                                                        memories.map((memory) => (
                                                                            <MenuItem value={memory} key={memory}>{memory} Go</MenuItem>)
                                                                        )
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                            {formik.errors.memory ? (
                                                                <p className="errorMessage">{formik.errors.memory}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                ) : ('')
                                            }
                                        </div>
                                    ) : ('')
                                }
                                {/* Estate category selected */}
                                {
                                    (editObject && ["13", "65", "66", "67", "68", "148", "149", "150", "151"].includes(editObject.category_id)) ||
                                    (elementCategory && elementCategory.id === "13") ||
                                    (elementCategoryParent && elementCategoryParent.id === "13") ? (
                                        <div className="createadvertisement-form-block-estate">
                                            <div className="createadvertisement-form-section">
                                                <div className="createadvertisement-form-section-wrapper">
                                                    <FormControl variant="outlined" className={"select-rooms" + (formik.errors.nb_rooms ? ' is-invalid' : '')}>
                                                        <InputLabel id="rooms-label">Nombre de pièces</InputLabel>
                                                        <Select
                                                            name="nb_rooms"
                                                            labelId="rooms-label"
                                                            id="rooms-select"
                                                            label="Pièces"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            defaultValue={formik.values.nb_rooms}
                                                            >
                                                            <MenuItem value="0">Aucune sélection</MenuItem>
                                                            <MenuItem value={1}>1</MenuItem>
                                                            <MenuItem value={2}>2</MenuItem>
                                                            <MenuItem value={3}>3</MenuItem>
                                                            <MenuItem value={4}>4</MenuItem>
                                                            <MenuItem value={5}>5</MenuItem>
                                                            <MenuItem value={6}>6</MenuItem>
                                                            <MenuItem value={7}>7</MenuItem>
                                                            <MenuItem value={8}>8</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {
                                                        formik.errors.nb_rooms ? (
                                                        <p className="errorMessage">{formik.errors.nb_rooms}</p>
                                                    ) : null}
                                                </div>
                                                <div className="createadvertisement-form-section-wrapper">
                                                    <FormControl variant="outlined" className={"select-area" + (formik.errors.area ? ' is-invalid' : '')}>
                                                        <InputLabel id="rooms-label">Surface (m&#178;)</InputLabel>
                                                        <Select
                                                            name="area"
                                                            labelId="brand-label"
                                                            id="brand-select"
                                                            label="Surface (m&#178;)"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            defaultValue={formik.values.area}
                                                            // value={formik.values.area || ""}
                                                            >
                                                            <MenuItem value="0">Aucune sélection</MenuItem>
                                                            {areas.map((area) => (<MenuItem value={area} key={area}>{area}</MenuItem>))}
                                                            <MenuItem value="+300">+1000</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {formik.errors.area ? (
                                                        <p className="errorMessage">{formik.errors.area}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    ) : ('')
                                }
                                {/* State */}
                                {
                                    /*Immobilier*/
                                    (editObject && ["13", "55", "56", "57", "58", "61", "79", "80", "81", "82", "141", "142", "143", "144", "96",
                                                    "187", "97", "188", "98", "189", "190", "191", "192", "193", "75", "76", "77", "78", "152",
                                                    "153", "156", "160", "83", "84", "86", "87", "88", "89", "90", "69", "70", "71", "72", "73",
                                                    "74", "146", "147", "156", "65", "66", "67", "68", "148", "149", "150", "151"].includes(editObject.category_id)) || (elementCategory && elementCategory.id === "13") || (elementCategoryParent && elementCategoryParent.id === "13") ||
                                    /*Autos motos */
                                    (elementCategory && elementCategory.id === "55") || (elementCategoryParent && elementCategoryParent.id === "55") ||
                                    /*Habillement*/
                                    (elementCategory && elementCategory.id === "56") || (elementCategoryParent && elementCategoryParent.id === "56") ||
                                    /*Informatique*/
                                    (elementCategory && elementCategory.id === "57") || (elementCategoryParent && elementCategoryParent.id === "57") ||
                                    /*Loisirs*/
                                    (elementCategory && elementCategory.id === "58") || (elementCategoryParent && elementCategoryParent.id === "58") ||
                                    /*Maison*/
                                    (elementCategory && elementCategory.id === "61") || (elementCategoryParent && elementCategoryParent.id === "61") ? (
                                        <div className="createadvertisement-form-block-state">
                                            <div className="createadvertisement-form-section">
                                                <div className="createadvertisement-form-section-wrapper">
                                                    <FormControl variant="outlined" className={"select-state" + (formik.errors.state ? ' is-invalid' : '')}>
                                                        <InputLabel id="state-select">Etat</InputLabel>
                                                        {
                                                            (editObject && ["57", "79", "80", "81", "82", "141", "142", "143", "144"].includes(editObject.category_id)) || (elementCategory && elementCategory.id === "57") || (elementCategoryParent && elementCategoryParent.id === "57") ? (
                                                                <Select
                                                                    name="state"
                                                                    labelId="state-label"
                                                                    id="state-select"
                                                                    label="Etat"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    defaultValue={formik.values.state}
                                                                    >
                                                                    <MenuItem value="0">Aucune sélection</MenuItem>
                                                                    <MenuItem value="neuf et sous blister">Neuf et sous blister</MenuItem>
                                                                    <MenuItem value="neuf sans  blister">Neuf sans  blister</MenuItem>
                                                                    <MenuItem value="très bon état">Très bon état</MenuItem>
                                                                    <MenuItem value="bon état">Bon état</MenuItem>
                                                                    <MenuItem value="etat satisfaisant">État satisfaisant</MenuItem>
                                                                </Select>
                                                            ) : ('')
                                                        }
                                                        {
                                                            (editObject && ["61", "96", "187", "97", "188", "98", "189", "190", "191", "192", "193"].includes(editObject.category_id)) || (editObject && editObject.category_id === "61") || (elementCategory && elementCategory.id === "61") || (elementCategoryParent && elementCategoryParent.id === "61") ? (
                                                                <Select
                                                                    name="state"
                                                                    labelId="state-label"
                                                                    id="state-select"
                                                                    label="Etat"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    defaultValue={formik.values.state}
                                                                    >
                                                                    <MenuItem value="0">Aucune sélection</MenuItem>
                                                                    <MenuItem value="neuf et jamais utilisé">Neuf et jamais utilisé</MenuItem>
                                                                    <MenuItem value="neuf et peu servis">Neuf et peu servis</MenuItem>
                                                                    <MenuItem value="très bon état">Très bon état</MenuItem>
                                                                    <MenuItem value="bon état">Bon état</MenuItem>
                                                                    <MenuItem value="etat satisfaisant">État satisfaisant</MenuItem>
                                                                </Select>
                                                            ) : ('')
                                                        }
                                                        {
                                                            (editObject && ["56", "75", "76", "77", "78", "152", "153", "156", "160"].includes(editObject.category_id)) || (elementCategory && elementCategory.id === "56") || (elementCategoryParent && elementCategoryParent.id === "56") ? (
                                                                <Select
                                                                    name="state"
                                                                    labelId="state-label"
                                                                    id="state-select"
                                                                    label="Etat"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    defaultValue={formik.values.state}
                                                                    >
                                                                    <MenuItem value="0">Aucune sélection</MenuItem>
                                                                    <MenuItem value="neuf avec étiquette">Neuf avec étiquette</MenuItem>
                                                                    <MenuItem value="neuf sans étiquette">Neuf sans étiquette</MenuItem>
                                                                    <MenuItem value="très bon état">Très bon état</MenuItem>
                                                                    <MenuItem value="bon état">Bon état</MenuItem>
                                                                    <MenuItem value="etat satisfaisant">État satisfaisant</MenuItem>
                                                                </Select>
                                                            ) : ('')
                                                        }
                                                        {
                                                            (editObject && ["58", "83", "84", "86", "87", "88", "89", "90"].includes(editObject.category_id)) || (elementCategory && elementCategory.id === "58") || (elementCategoryParent && elementCategoryParent.id === "58") ? (
                                                                <Select
                                                                    name="state"
                                                                    labelId="state-label"
                                                                    id="state-select"
                                                                    label="Etat"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    defaultValue={formik.values.state}
                                                                    >
                                                                    <MenuItem value="0">Aucune sélection</MenuItem>
                                                                    <MenuItem value="neuf">Neuf</MenuItem>
                                                                    <MenuItem value="très bon état">Très bon état</MenuItem>
                                                                    <MenuItem value="bon état">Bon état</MenuItem>
                                                                    <MenuItem value="etat satisfaisant">État satisfaisant</MenuItem>
                                                                </Select>
                                                            ) : ('')
                                                        }
                                                        {
                                                            (editObject && ["55", "69", "70", "71", "72", "74", "146", "147", "156"].includes(editObject.category_id)) || (elementCategory && elementCategory.id === "55") || (elementCategoryParent && elementCategoryParent.id === "55") ? (
                                                                <Select
                                                                    name="state"
                                                                    labelId="state-label"
                                                                    id="state-select"
                                                                    label="Etat"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    defaultValue={formik.values.state}
                                                                    >
                                                                    <MenuItem value="0">Aucune sélection</MenuItem>
                                                                    <MenuItem value="neuf">Neuf</MenuItem>
                                                                    <MenuItem value="occasion">Occasion</MenuItem>
                                                                </Select>
                                                            ) : ('')
                                                        }
                                                        {
                                                            (editObject && ["13", "65", "66", "67", "68", "148", "149", "150", "151"].includes(editObject.category_id)) || (elementCategory && elementCategory.id === "13") || (elementCategoryParent && elementCategoryParent.id === "13") ? (
                                                                <Select
                                                                    name="state"
                                                                    labelId="state-label"
                                                                    id="state-select"
                                                                    label="Etat"
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    defaultValue={formik.values.state}
                                                                    >
                                                                    <MenuItem value="0">Aucune sélection</MenuItem>
                                                                    <MenuItem value="jamais habité">Jamais habité</MenuItem>
                                                                    <MenuItem value="déja habité">Déja habité</MenuItem>
                                                                </Select>
                                                            ) : ('')
                                                        }
                                                    </FormControl>
                                                    {formik.errors.state ? (
                                                        <p className="errorMessage">{formik.errors.state}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    ): ('')
                                }
                            </div>

                            <div className="advert-infos">
                                <div className={"add-title" + (formik.errors.title ? ' is-invalid' : '')}>
                                    <TextField
                                        label="Titre"
                                        name="title"
                                        variant="outlined"
                                        size="small"
                                        onBlur={formik.handleBlur}
                                        inputProps={{ maxLength: 50 }}
                                        onChange={formik.handleChange}
                                        defaultValue={formik.values.title}
                                    />
                                    <small className="title-counter">{editObject && formik.values.title.length}/50</small>
                                    {formik.errors.title ? (
                                        <p className="errorMessage">
                                            {formik.errors.title}
                                        </p>
                                    ) : null}
                                </div>
                                {/* <div className={"add-description" + (formik.errors.description ? ' is-invalid' : '')}>
                                    <TextField
                                        label="Description"
                                        name="description"
                                        multiline
                                        rows={7}
                                        variant="outlined"
                                        size="small"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        defaultValue={formik.values.description}
                                    />
                                    {formik.errors.description ? (
                                        <p className="errorMessage">
                                            {formik.errors.description}
                                        </p>
                                    ) : null}
                                </div> */}
                                <ReactQuill
                                    className='quill-style'
                                    modules={modules}
                                    formats={formats}
                                    value={description}
                                    onChange={descriptionChange} 
                                />
                                {
                                    (editObject && ["13", "63", "65", "66", "67", "68", "148", "149", "150", "151",
                                                    "105", "106", "107", "108", "109", "110", "111", "112", "113", "114", "115", "116", "162",
                                                    "163", "164", "165", "165", "167", "168", "169", "170", "171", "172", "173", "174", "175",].includes(editObject.category_id)) || (elementCategory && elementCategory.id === "13") || (elementCategoryParent && elementCategoryParent.id === "13") || (elementCategory && elementCategory.id === "63") || (elementCategoryParent && elementCategoryParent.id === "63") ? (
                                        <div className={"add-pricing" + (formik.errors.type_price ? ' is-invalid' : '')}>
                                            <FormControl variant="outlined" className="pricing">
                                                <InputLabel id="add-pricing">Type de tarification</InputLabel>
                                                <Select
                                                    name="type_price"
                                                    labelId="add-pricing"
                                                    id="add-pricing"
                                                    variant="outlined"
                                                    size="small"
                                                    label="Type de tarification"
                                                    onBlur={formik.handleBlur}
                                                    defaultValue={formik.values.type_price}
                                                    onChange={formik.handleChange}>
                                                    <MenuItem value="0">Aucune sélection</MenuItem>
                                                    {
                                                        pricingTypes.map((pricing) => {
                                                            return (
                                                                <MenuItem key={pricing.id} value={pricing.id}>{pricing.name}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                            {formik.errors.type_price ? (
                                                <p className="errorMessage">
                                                    {formik.errors.type_price}
                                                </p>
                                            ) : null}
                                        </div>
                                    ): ('')
                                }
                                {
                                    (formik.values.type_advert == "3") || (formik.values.type_advert == "4") ?
                                        ('') : (
                                            <div className={"add-price" + (formik.errors.price ? ' is-invalid' : '')}>
                                                <TextField
                                                    label="Prix"
                                                    name="price"
                                                    type="number"
                                                    variant="outlined"
                                                    size="small"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    defaultValue={formik.values.price}
                                                    />
                                                {formik.errors.price ? (
                                                    <p className="errorMessage">
                                                        {formik.errors.price}
                                                    </p>
                                                ) : null}
                                                <FormControlLabel
                                                    control={
                                                        <div>
                                                            <Checkbox
                                                                name="negociable"
                                                                color="primary"
                                                                id="negociable"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.negociable}
                                                                checked={formik.values.negociable}
                                                            />
                                                        </div>
                                                    }
                                                    label={<label htmlFor="negociable">Négociable</label>} />
                                            </div>
                                        )
                                }
                                <div className="add-town">
                                    <ButtonBase className={"custom-arrow-button" + (formik.errors.city_id ? ' is-invalid' : '')} variant="outlined" color="primary" onClick={handleClickOpenCity}>{elementCity ? elementCity.title : "Ville ou quartier"} <ArrowDropDownIcon /></ButtonBase>
                                    <TextField
                                        style={{ visibility: 'hidden' }}
                                        name="city_id"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        defaultValue={formik.values.city_id}
                                        />
                                    {(formik.errors.city_id) &&
                                    <p className="errorMessage">
                                        {formik.errors.city_id}
                                    </p>}
                                    <SimpleDialog prompt={"Choisir une ville ou un quartier"} data={cities} defaultSelection={"Toutes les villes"} open={openCity} onClose={handleCloseCity} />
                                </div>
                            </div>
                        </div>

                        <SecondPart2 values={formik.values} isEditing={isEditing} />

                        <ThirdPart values={formik.values} handleChange={formik.handleChange} />

                        <div className="published">
                            <FormControlLabel
                                control={
                                    <div>
                                        <Checkbox
                                            name="published"
                                            color="primary"
                                            id="published"
                                            defaultChecked={true}
                                            onChange={handlePublish}
                                            defaultValue={formik.values.published}
                                        />
                                    </div>
                                }
                                label={<label htmlFor="pulished">Publier</label>} />
                            <Tooltip title="Message" arrow placement="right">
                                <IconButton aria-label="Message">
                                    <InfoIcon/>
                                </IconButton>
                            </Tooltip>
                        </div>

                        {/* Submit block display */}
                        <div className={"name-input-submit-item" + (formik.errors.cgu ? ' is-invalid' : '')}>
                            <FormControlLabel
                                control={
                                    <div>
                                        <Checkbox
                                            name="cgu"
                                            color="primary"
                                            id="cgu"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            defaultValue={formik.values.cgu}/>
                                    </div>
                                }
                                label={<label htmlFor="cgu">J'accepte les <NavLink to="/conditions-generales" target="_blank">Conditions Générales d'Utilisation</NavLink></label>} />
                            {formik.errors.cgu ? (
                                <p className="errorMessage">{formik.errors.cgu}</p>
                            ) : null}
                            {
                                formik.values.published == 0 ? (
                                    <Button type="submit" variant="contained" disabled={formik.isSubmitting}>
                                        Sauver en tant que brouillion
                                        {
                                            formik.isSubmitting ?
                                                (<CircularProgress />) : ('')
                                        }
                                    </Button>
                                ) : (
                                    <Button type="submit" variant="contained" disabled={formik.isSubmitting}>
                                        Modifier
                                        {
                                            formik.isSubmitting ?
                                                (<CircularProgress />) : ('')
                                        }
                                    </Button>
                                )
                            }
                            <Dialog
                                className="dialog"
                                disableBackdropClick
                                disableEscapeKeyDown
                                onClose={handleCloseErrorMessage}
                                aria-labelledby="simple-dialog-title"
                                open={errorMessage}>
                                <IconButton aria-label="close" className="closeButton" onClick={handleCloseErrorMessage}>
                                    <MdClose />
                                </IconButton>
                                <div className="change-container error-message">
                                    <DialogTitle id="simple-dialog-title"><InfoIcon /> Message d'erreur</DialogTitle>
                                    <DialogContent>
                                        <p>Veuillez remplir tous les champs obligatoires avant de continuer</p>
                                    </DialogContent>
                                </div>
                            </Dialog>
                        </div>
                    </div>
                </form>
            </main>
            <Footer />
        </div>
    );
};

export default EditAdvertisement;
