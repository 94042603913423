import React, {useState} from 'react';
import './Help.scss';
import Footer from '../../components/Footer';
import Header from '../../containers/Header';
import { MdClose, MdSearch } from 'react-icons/md';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import help from '../../imgs/help.svg';
import faqBg from '../../imgs/faq_bg.png';
import faq from '../../imgs/faq.svg';
import security from '../../imgs/security.svg';
import tutorial from '../../imgs/tutorials.svg';
import { NavLink } from 'react-router-dom';
import RadioGroup from '@material-ui/core/RadioGroup';
import { FormControlLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Help = () => {

    const [noticeValue, setNoticeValue] = useState('');
    const [open, setOpen] = useState(false);

    const handleNotice = e => {
        const { value } = e.target;
        setNoticeValue(value);
        console.log(noticeValue)
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Header />
            <div className="help-content">
                <div className="help-content-header">
                    <img className="help-content-header-bg" src={faqBg} alt=""/>
                    <h3>Comment pouvons-nous vous aider ?</h3>
                    <div className="help-content-header-form">
                        <div className="help-content-header-form-inner">
                            <MdSearch />
                            <input name="help-search" placeholder="Recherche..." />
                        </div>
                    </div>
                </div>
                <div className="help-content-body">
                    <div className="footer-breadcrumb">
                        <ul>
                            <li>
                                <NavLink to="/">Accueil</NavLink>
                            </li>
                            <li className="separator">/</li>
                            <li>Aide et support</li>
                        </ul>
                    </div>
                    <div className="help-content-body-header">
                        <img src={help} alt=""/>
                        <h1>Aide et support</h1>
                    </div>
                    <div className="help-content-body-links">
                        <div className="help-content-body-links-item">
                            <div className="help-content-body-links-item-image">
                                <img src={faq} alt="faq"/>
                            </div>
                            <h4>Foire aux questions</h4>
                            <p>
                                ici vous trouverez toutes les réponses aux questions le plus fréquemment posées par nos utilisateurs
                            </p>
                            <NavLink to="/faq">
                                <span>Voir plus</span>
                                <ArrowForwardIosIcon />
                            </NavLink>
                        </div>
                        <div className="help-content-body-links-item">
                            <div className="help-content-body-links-item-image">
                                <img src={security} alt="faq"/>
                            </div>
                            <h4>Conseils de sécurité</h4>
                            <p>
                                D'importants conseils pour l'utilisation sécurisée de Taari
                            </p>
                            <NavLink to="/conseils-securite">
                                <span>Voir plus</span>
                                <ArrowForwardIosIcon />
                            </NavLink>
                        </div>
                        <div className="help-content-body-links-item">
                            <div className="help-content-body-links-item-image">
                                <img src={tutorial} alt="faq"/>
                            </div>
                            <h4>Tutoriels et vidéos</h4>
                            <p>
                                Ici vous trouverez des tutoriels et vidéos qui vous aideront dans votre parcours d'intégration à ma communauté
                            </p>
                            <NavLink to="/tutoriels">
                                <span>Voir plus</span>
                                <ArrowForwardIosIcon />
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="help-content-satisfy">
                    <div className="container">
                        <hr className="medium" />
                        <h2 className="help-content-satisfy-title">Cette documentation vous-a-t-elle été utile ?</h2>
                        <div className="help-content-satisfy-buttons">
                            <RadioGroup onChange={handleNotice}>
                                <FormControlLabel value="1" className="yes" control={<Radio />} label="Oui" />
                                <FormControlLabel value="0" className="no" control={<Radio />} label="Non" />
                            </RadioGroup>
                        </div>
                        <p className="help-content-satisfy-choice-results">
                            Utilisateurs qui ont trouvé cela utile : <span>865</span> sur <span>9000</span>
                        </p>
                        <strong className="help-content-satisfy-subtitle">
                            Vous avez d'autres questions ?
                            <NavLink to="/envoyer-une-demande">Envoyer une demande</NavLink>
                        </strong>
                        <div className="help-content-satisfy-suggest">
                            <p className="help-content-satisfy-suggest-left">
                                <strong className="help-satisfy-suggest-left-title">
                                    Aidez-nous à nous améliorer !
                                </strong>
                                <br />
                                Images, contenu, structure... ou une idée, n'hésitez pas à nous faire part de vos suggestions !
                            </p>
                            <Button className="help-content-satisfy-suggest-right" onClick={handleClickOpen}>Envoyer une suggestion</Button>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={Transition}
                                keepMounted
                                className="dialog suggestion"
                                disableBackdropClick
                                disableEscapeKeyDown
                                aria-labelledby="simple-dialog-title">
                                <IconButton aria-label="close" className="closeButton" onClick={handleClose}>
                                    <MdClose />
                                </IconButton>
                                <div className="change-container">
                                    <DialogTitle id="simple-dialog-title">Envoyer une suggestion</DialogTitle>
                                    <DialogContent>
                                        <div className="suggestion-container">
                                            <div className="suggestion-container-item">
                                                <TextField id="outlined-basic" label="Titre de votre suggestion" variant="outlined" />
                                            </div>
                                            <div className="suggestion-container-item">
                                                <TextField
                                                    id="standard-multiline-static"
                                                    label="Votre suggestion"
                                                    multiline
                                                    rows={5}
                                                    variant="outlined"
                                                />
                                            </div>
                                        </div>
                                    </DialogContent>
                                    <DialogActions className="suggestion-footer">
                                        <Button onClick={handleClose}>Annuler</Button>
                                        <Button>Envoyer</Button>
                                    </DialogActions>
                                </div>
                            </Dialog>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Help;
