import React from 'react';
import Carousel from 'react-elastic-carousel';
import { uuid } from 'uuidv4';

import Testimonial from './Testimonial';
import background from '../../imgs/homepage_bg_2.png';
import testimonial1 from '../../imgs/testimonial1.jpg';
import testimonial2 from '../../imgs/testimonial2.jpg';
import testimonial3 from '../../imgs/testimonial3.jpg';
import testimonial4 from '../../imgs/testimonial4.jpg';
import testimonial5 from '../../imgs/testimonial5.jpg';
import testimonial6 from '../../imgs/testimonial6.jpg';

const Testimonials = () => {
  const testimonials = [
    {
      review: 'J\'ai acheté mon smartphone en une heure grace à Taari et j\'en suis fier !',
      name: 'Solange Sedjro',
      country: 'Togo',
      image: testimonial4,
    },
    {
      review: 'Avec ma boutique sur Taari, mes clients arrivent à trouver les voitures qu’ils recherchent sans se déplacer au port.',
      name: 'Patrice Emeka',
      country: 'Vendeur de voiture d’occasion à Cotonou',
      image: testimonial5,
    },
    {
      review: 'J\'ai vendu les anciens meubles de mon appartement pour un très bon prix.',
      name: 'Fatou Diakité',
      country: 'Bamako',
      image: testimonial2,
    },
    {
      review: 'Avec Taari j’ai eu beaucoup plus de clients pour mon activité de location Immobilière',
      name: 'Bara Solei',
      country: 'Niamey',
      image: testimonial1,
    },
    {
      review: 'Grace à Taari ma boutique a plus de visibilité et j\'ai multiplié ma clientèle par dix. Merci à toute l\'équipe de Taari.',
      name: 'Diabi Mbengue',
      country: 'Dakar',
      image: testimonial3,
    },
    {
      review: 'J’ai vendu ma maison en très peu de temps sur Taari. C’est incroyable.',
      name: 'Noel Seka',
      country: 'Côte d’Ivoire',
      image: testimonial6,
    },
  ];

  return (
    <section className="homepage-testimonials" style={{
      backgroundImage: `url('${background}')`,
    }}>
      {/* <img className="homepage-bg" src={background} alt="" /> */}
      <h2 className="homepage-testimonials-title bold">Que disent nos utilisateurs ?</h2>
      <Carousel
        itemsToShow={3}
        showArrows={false}
        className="testimonials"
        itemsToScroll={3}
        style={null}
        breakPoints={
          [
            {
              width: 1,
              itemsToShow: 1,
              itemsToScroll: 1,
            },
            {
              width: 550,
              itemsToShow: 2,
              itemsToScroll: 2,
            },
            {
              width: 861,
              itemsToShow: 3,
              itemsToScroll: 3,
            },
          ]
        }
      >
        {testimonials.map((testimonial) => <Testimonial key={uuid()} testimonial={testimonial} />)}
      </Carousel>
    </section>
  );
};

export default Testimonials;
