import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  shop: {},
  shops: [],
  shopResult: [],
  isSearching: 1,
  searchObject: {},
  searchResultNumber: null,
  shopsLoaded: false,
  shopLoaded: false,
  searchShopNumber: null,
};

const shopSlice = createSlice({
  name : 'shop',
  initialState : initialState,
  reducers : {
    resetShops : (state, action) => {
      state.shops = []
    },

    shopsLoading : (state, action) => {
      state.shopsLoaded = action.payload.status
    },

    shopLoading : (state, action) => {
      state.shopLoaded = action.payload.status
    },

    saveShops : (state, action) => {
      state.shops =  [ ...action.payload.shops ];
    },

    setShop : (state, action) => {
      state.shop = action.payload.shop
    },

    setShopResult : (state, action) => {
      state.shopResult = action.payload.shopResult
    },

    setIsSearching : (state, action) => {
      state.isSearching = action.payload.isSearching
    },

    setSearchObject : (state, action) => {
      state.searchObject = action.payload.searchObject
    },

    setSearchShopNumber : (state, action) => {
      state.searchShopNumber = action.payload.searchShopNumber
    },
  }
});

export const { resetShops, shopsLoading, shopLoading, saveShops, setShop, setShopResult, setIsSearching, setSearchObject, setSearchShopNumber } = shopSlice.actions;

export default shopSlice.reducer;
