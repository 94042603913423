import React from "react";
import '../conseils-securite/conseils-securite.scss';
import Header from "../../../containers/Header";
import { NavLink } from "react-router-dom";
import security from "../../../imgs/security-colored.svg";
import Footer from "../../Footer";

const AdviceSecurity = () => {
    return (
        <div>
            <Header/>
            <div className="security-container">
                <div className="security-container-breadcrumb">
                    <ul>
                        <li>
                            <NavLink to="/">Accueil</NavLink>
                        </li>
                        <li className="separator">/</li>
                        <li>
                            <NavLink to="/aide-et-support">Aide et support</NavLink>
                        </li>
                        <li className="separator">/</li>
                        <li>Conseils et sécurité</li>
                    </ul>
                </div>
                <div className="security-container-header">
                    <img src={security} alt=""/>
                    <h1>Conseils et sécurité</h1>
                </div>
                <div className="security-container-body">
                    <p><strong>Chers utilisateurs,</strong></p>
                    <p>
                        Soucieux de vous offrir la meilleure expérience sur notre site, nous contrôlons toutes les annonces avant leur mise en ligne, et surveillons régulièrement les annonces diffusées. Cependant, nous sommes conscients que des personnes mal intentionnées utilisent notre site pour mettre en ligne des annonces fictives et tenter d'extorquer de l'argent. Nous vous recommandons la plus grande prudence vis-à-vis des pratiques suivantes, il s'agit très certainement de <strong>tentatives d'escroquerie</strong> :
                    </p>
                    <p>
                        <strong>Vous êtes acheteur ou cherchez une location ?</strong>
                    </p>
                    <ul>
                        <li>Le vendeur vous demande d'effectuer un paiement par <strong>Mobile Money</strong> ou <strong>Western Union</strong> ? Ces modes de paiement <strong>ne sont pas sécurisés</strong> et risquent bien d'être factices. Nous vous conseillons d'utiliser des moyens de paiement plus sécurisés.</li>
                        <li>Nous vous rappelons que Taari refuse toute annonce portant sur des biens situés à l'étranger. Si <strong>le prétendu vendeur/loueur vous indique se situer à l'étranger</strong> et qu'il vous demande le <strong>versement d'un acompte</strong>, méfiez-vous, l'annonce est probablement fictive.</li>
                        <li>De manière générale, méfiez-vous des propositions trop alléchantes et des prix trop bas. Ne payez jamais à l'avance un vendeur que vous ne connaissez pas (ne versez pas d'acompte). Dans le cas où vous cherchez à louer un bien, ne versez jamais en avance la totalité du prix de la location.</li>
                        <li>Nous n'intervenons en aucun cas comme intermédiaire ou tiers de confiance dans les transactions entre acheteurs et vendeurs. <strong>Refusez systématiquement tout versement d'argent qui vous serait demandé au nom de notre site</strong>. Exemple de cas identifié : un vendeur se fait passer pour le Service Consommateur du Taari et certifie que le site a contrôlé la qualité du bien à vendre ou à louer, la véracité des informations et des documents communiqués par le vendeur.</li>
                    </ul>
                    <p>
                        <strong>Vous cherchez à vendre ou louer votre bien ?</strong>
                    </p>
                    <ul>
                        <li>Un acheteur veut vous payer avec PayPal et vous demande de créer un compte ? Après avoir créé votre compte PayPal, vous avez reçu une confirmation de paiement par email. Avant d'envoyer l'objet de la vente, <strong>connectez-vous à votre compte PayPal et vérifiez que vous avez bien reçu l'argent sur votre solde PayPal</strong>. Méfiez-vous des emails. <strong>Certains fraudeurs envoient de faux emails PayPal pour vous pousser à expédier un colis</strong> alors que vous n'avez pas reçu le paiement. <strong>Le solde de votre compte PayPal est toujours la meilleure preuve que la vente se passe bien.</strong></li>
                        <li>Vous venez de recevoir un mail vous demandant de renseigner vos coordonnées bancaires suite à l'achat de l'option Logo Urgent. Cet email ne provient en aucun cas de nos services. Il s'agit d'une tentative de phishing, soyez vigilant et ne transmettez jamais vos coordonnées bancaires.</li>
                        <li>Vous venez de recevoir un mail vous signalant que <strong>votre compte va être suspendu</strong>. Cet email ne provient en aucun cas de nos services. Il s'agit d'une tentative de phishing, soyez vigilant et ne transmettez jamais vos coordonnées bancaires.</li>
                        <li>Ne déposez jamais d'annonce à la place de quelqu'un d'autre. Si vous avez déjà validé l'annonce, merci de nous transmettre les coordonnées avec lesquelles l'annonce a été déposée.</li>
                        <li>Vous venez de recevoir un mail ou un SMS vous demandant de rappeler une personne intéressée par votre annonce <strgon>au 888...</strgon> Il s'agit d'une <strong>tentative d'escroquerie via un numéro surtaxé</strong>. Supprimez le mail ou le SMS sans hésitation.</li>
                    </ul>
                    <p>
                        Nous mettons tout en œuvre pour limiter ces pratiques frauduleuses. Nous vous recommandons cependant de faire preuve de vigilance. Si vous avez un doute sur la qualité de votre interlocuteur, n'hésitez pas à <a href="mailto:@contact.taari.co">envoyer un email à notre service clientèle</a> et à signaler les annonces qui vous semblent suspectes en cliquant sur le lien « Signaler l'annonce » situé en bas de page de l'annonce.
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AdviceSecurity;
