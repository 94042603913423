import { connect } from 'react-redux';
import Country from '../components/Homepage/Country';
import HomepageCountryDropdown from '../components/Homepage/HomepageCountryDropdown';

import { closeCountryPopup, getAllCountries, countrySelected } from '../reducers/countryReducer';

const mapStateToProps = state => ({
  countries: state.country.countries,
  selected: state.country.currentCountry,
  countriesLoaded: state.country.countriesLoaded,
});

const mapDispatchToProps = dispatch => ({
  closeCountryPopup: () => {
    dispatch(closeCountryPopup());
  },
  getAllCountries: () => {
    dispatch(getAllCountries());
  },
  countrySelected: (country) => {
    dispatch(countrySelected({country : country}));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HomepageCountryDropdown);