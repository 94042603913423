import React from "react";
import illustration from '../../imgs/home-illustration.svg';
import Link from "@material-ui/core/Link";

export default function HomeIllustration() {
    return (
        <div className="Home-illustration">
            <div className="Home-illustration-text">
                <h3>Taari c'est aussi une communauté d'entraide et d'échange</h3>
                <p>Troquer un objet, échanger un bien ou un service... <br/>ou tout simplement... en faire don...</p>
                <p>Envie de les rejoindre ?</p>
                <Link href="#">Rejoindre la communauté</Link>
            </div>
            <div className="Home-illustration-image">
                <img src={illustration} alt="community"/>
            </div>
        </div>
    )
}