import React from 'react';
import {
  Switch,
  Redirect,
  Route,
  useLocation,
  useParams
} from 'react-router-dom';

import Header from '../../containers/HomepageHeader';
import Splash from '../../containers/Splash';
import Countries from '../../containers/HomepageCountries';
import CountriesPopup from '../../containers/HomepageCountryPopup';
import Testimonials from './Testimonials';
import Prefooter from './Prefooter';
import Footer from '../Footer';
import './Homepage.scss';

const Homepage = ({
  countryPopupOpened,
  // needToRedirect,
  currentCountry,
}) => {
  let params = useParams();
  
  console.warn('useParams: ', params);

  if (currentCountry) {
    return (
      <Switch>
        <Route>
          <Redirect to={`/${currentCountry.slug}/search`} />
        </Route>
      </Switch>
    );
  }

  return (
    <main id="homepage" className="homepage">
      <Header />
      <Splash />
      { (countryPopupOpened) && (<CountriesPopup />) }
      <Countries />
      <Testimonials />
      <Prefooter />
      {/* <div className="spacer no-mobile" /> */}
      <Footer />
    </main>
  );
};

export default Homepage;
