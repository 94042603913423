import React from "react";
import './cookies.scss';
import cookies from '../../imgs/cookies.svg';
import Button from "@material-ui/core/Button";

export class CookiesPopup  extends React.Component {

    constructor(props) {
        super(props);
        if (localStorage.getItem("cookiespopupstate") != undefined) {
            this.state = JSON.parse(localStorage.getItem("cookiespopupstate"));

        } else {
            this.state = {accepted: false, hide : false};
        }
        this.accept = this.accept.bind(this);
        this.show = this.show.bind(this);
    }

    componentDidMount() {
        setTimeout(this.show, 20000);
    }

    accept() {
        this.setState((state) => ({ accepted : true }));
        localStorage.setItem("cookiespopupstate", JSON.stringify({ accepted : true, hide : false }));
    }

    show() {
        this.setState((state) => ({ hide : true }));
    }

    render() {
        return (
            (!this.state.accepted && this.state.hide)  &&  <div className={"cookies-popup " + ( this.state.hide ? "show" : "")}>
                <div className="cookies-popup-header">
                    <div className="cookies-popup-header-text">
                        <ul>
                            <li>Salut c'est nous...</li>
                            <li>les cookies !</li>
                        </ul>
                    </div>
                    <div className="cookies-popup-header-image">
                        <img src={cookies} alt="cookies-icon"/>
                    </div>
                </div>
                <div className="cookies-popup-text">
                    <p>On a attendu d'être sûrs que le <br/>contenu de ce site vous intéresse avant de <br/>vous déranger, mais on aimerait bien vous accompagner <br/>pendant votre visite... C'est OK pour vous ?</p>
                </div>
                <div className="cookies-popup-buttons">
                    <Button onClick={this.accept}>OK pour moi</Button>
                    <a href={`/donnees-privees`}>En savoir plus</a>
                </div>
            </div>
        );
    }
}
