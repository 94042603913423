import React, { useState } from 'react';
import cameraPlusOutline from '@iconify/icons-mdi/camera-plus-outline';
import Icon from "@iconify/react";
import { PictureInput } from "./PictureInput";

export const SecondPart = ({ values }) => {


    const maxInitInputs = 3;
    const maxInputs = 9;
    let ipts = [];
    for (let i = 0; i < maxInputs; i++) {
        ipts.push({ key: (i + 1) });
    };

    const [inputs, setInputs] = useState(ipts);
    const [unfolded, setUnfolded] = useState(false);
    const [deleteCount, setDeleteCount] = useState(maxInputs + 1);
   // const [pictures, setPictures] = useState(values.pictures);

    const updateInput = (index, url) => {
        let input = inputs[index];
        let ipts = inputs.slice();
        if (input !== undefined) {
            input.file = url;
            ipts[index] = input;
            setInputs(ipts);
            console.warn(input);
            values.images.push(input.file);
        }
    }

    const enabledInput = (index) => {
        let previous = 0;
        if (index > 0) {
            previous = index - 1;
            if (inputs[previous] && ((inputs[previous]).file !== undefined))
                return false;
            else
                return true;
        }
        return false;
    }

    const unfoldable = () => {
        let reducer = (accumulator, currentValue) => {
            return accumulator + (currentValue.file != undefined ? 1 : 0);
        }

        let total = inputs.reduce(reducer, 0);
        return total == maxInitInputs;
    }

    const unfoldInputs = () => {
        if (unfoldable()) {
            setUnfolded(true);
        }
    }

    const deleteInput = (index) => {
        let position = 2;
        if (unfolded)
            position = 8;
        let dc = deleteCount + 1;
        let ipts = inputs.slice();
        if (ipts[index] != undefined) {
            ipts.splice(index, 1);
            ipts.splice(position, 0, { key: (dc + 1) });
            setInputs(ipts);
            setDeleteCount(dc);
            values.images.splice(index,1);
        }
    }

    return (
        <div className="createadvertisement-form-secondpart">
            <h3 className="createadvertisement-form-section-title">
                <span className="createadvertisement-form-section-title-bubble">2</span>
                    Photos
                </h3>
            <div className="createadvertisement-form-secondpart-container">
                <div className="createadvertisement-form-secondpart-container-inner">
                    {inputs.slice(0, (unfolded ? maxInputs : maxInitInputs)).map((element, index) => {
                        return <PictureInput key={element.key} index={index}
                            objet={element} updateInput={updateInput}
                            deleteInput={deleteInput} enabledInput={enabledInput} />
                    })}

                    {!unfolded && <div className="image-picker-item rest-images" onClick={unfoldInputs}>
                        <div className="image-picker-item-inner">
                            <Icon icon={cameraPlusOutline} />
                            <span>{maxInputs - maxInitInputs} photos Additionnelles</span>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}
