import React from 'react';
import { TextField, Button, Menu, MenuItem } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { useFormik } from 'formik';
import fr from 'react-phone-input-2/lang/fr.json';
import * as Yup from 'yup';
import {authenticateUser} from '../../services/users';
import {useDispatch, useSelector} from 'react-redux';
import { Redirect, } from 'react-router-dom';
import {MdClose} from "react-icons/md";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PhoneInput from "react-phone-input-2";
import {setBackColor, setMessage, setOpen} from "../../reducers/snackbarReducer";
import CircularProgress from "@material-ui/core/CircularProgress";
import {saveUser, changeOperation} from "../../reducers/userReducer";
import {subscribeToOneSignal} from '../../services/chat';
import './Login.scss';
import Header from "../../containers/Header";

const LoginForm = ({currentCountry}) => {

    const dispatch = useDispatch();

    /*useEffect(() => {
        // window.OneSignal = window.OneSignal || [];
        // const OneSignal = window.OneSignal;
        // OneSignal.push(function() {
        //     OneSignal.on('notificationDisplay', function(event) {
        //     console.warn('OneSignal notification displayed:', event);
        //     });
        // });
        // OneSignal.push(function() {
        //     OneSignal.getUserId(function(userId) {
        //         console.warn('userId: ', userId);
        //     });
        // });
    }, []);*/
    const [anchorEl, setAnchorEl] = React.useState(null);
    let isAuthenticated = useSelector(state => state.user.isAuthenticated);
    let countries = useSelector(state => state.country.countries);
    let defaultCountrySlug = currentCountry.slug;
    const [changePassword, setChangePassword] = React.useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOpenForgotPassword = () => {
        dispatch(changeOperation({ operation : 1}));
        setChangePassword(true);
    };

    const handleCloseForgotPassword = () => {
        setChangePassword(false);
    };

    const handleClose = (type) => {
        setAnchorEl(null);
    };

    const togglePassword = ({ currentTarget }) => {
        currentTarget.classList.toggle('active');
        const passwordInput = document.getElementById('password');

        if (currentTarget.classList.contains('active')) {
            passwordInput.type = 'text';
        }
        else {
            passwordInput.type = 'password';
        }
    }

    const formik = useFormik({
        initialValues: {
            login: '',
            password: '',
        },
        validationSchema: Yup.object({
            login: Yup.string()
                .min(8, "Au min 8 caractères").required("Ce champ est requis"),
            password: Yup.string()
                .required('Champ requis'),
        }),
        onSubmit: (values, actions) => {
            let user = {...values};
            user.login = "+" + user.login;
            window.OneSignal = window.OneSignal || [];
            const OneSignal = window.OneSignal;
            authenticateUser(user.login, user.password).then(({data})=> {
                if (data.RESPONSECODE == 200) {
                    console.warn('user: ', data);
                    OneSignal.push(function() {
                        OneSignal.getUserId(function(userId) {
                            console.warn('userId: ', userId);
                            var obj = {
                                user_id : data.RESPONSEDATA.user_data.id,
                                player_id : userId
                            };
                            subscribeToOneSignal(obj).then(({data}) => {
                                console.warn(data.RESPONSEMESSAGE);
                            }).catch(error => {
                                console.warn(error);
                            });
                        });
                    });
                    dispatch(saveUser({ user : data.RESPONSEDATA.user_data }));
                    dispatch(setMessage({message : data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                    dispatch(setBackColor({background: 'green'}));
                    formik.resetForm();
                    actions.setSubmitting(false);
                } else {
                    dispatch(setMessage({message : data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                    dispatch(setBackColor({background: 'red'}));
                    actions.setSubmitting(false);
                }
            }).catch((e)=>{
                dispatch(setMessage({message : "Erreur lors de la création"}));
                dispatch(setOpen({open : true}));
                console.error(e);
            }).finally((e)=> {
            })
        },
    });

    if(isAuthenticated === true)
        return <Redirect to="/dashboard/adverts" />

    if(changePassword === true)
        return <Redirect to="/number-confirmation" />

    return (
        <div>
            <Header/>
            <div className="login">
                <form className="login-form" onSubmit={formik.handleSubmit}>
                    <div className="logo-font">
                        <h3>Taari</h3>
                    </div>
                    <strong className="login-form-title">Bienvenue</strong>
                    <strong className="login-form-subtitle">Accéder à votre compte</strong>
                    <div className="full-form-wrapper">
                        <div className="full-form-wrapper tel-input">
                            <PhoneInput
                                inputProps={{id : "phoneNumber", name : "login"}}
                                onChange={(phone) => {
                                    formik.setFieldValue('login', phone);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.login}
                                localization={fr}
                                country={defaultCountrySlug}
                                searchPlaceholder={'Rechercher'}
                                countryCodeEditable={false}
                                containerClass={'phone-input' + (formik.touched.login && formik.errors.login ? ' is-invalid' : '')}
                                placeholder={'Numéro de téléphone'}
                                defaultMask={'.. .. .. .. .. ..'}
                                preferredCountries = {
                                    countries.map((country) => (
                                        country.slug
                                    ))
                                }
                                /*onlyCountries={['tg', 'cd', 'ml', 'sn', 'bj', 'ne', 'bf', 'ci']}*/
                                enableSearch={true}
                            />
                            {formik.touched.login && formik.errors.login ? (
                                <p className="errorMessage">{formik.errors.login}</p>
                            ) : null}
                        </div>
                    </div>
                    <div className="full-form-wrapper password-input-item">
                        <TextField
                            label="Saisissez votre mot de passe"
                            type="password"
                            variant="outlined"
                            className={"login-form-field" + (formik.errors.password && formik.touched.password ? ' is-invalid' : '')}
                            name="password"
                            id="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <p className="errorMessage">{formik.errors.password}</p>
                        ) : null}
                        <span className="show-password" onClick={togglePassword}><VisibilityIcon /></span>
                    </div>
                    <div className="full-form-wrapper forgot-password">
                        <Button onClick={handleOpenForgotPassword}>Mot de passe oublié ?</Button>
                        <Dialog
                            className="dialog"
                            // open={changePassword}
                            onClose={handleCloseForgotPassword}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description">
                            <IconButton aria-label="close" className="closeButton" onClick={handleCloseForgotPassword}>
                                <MdClose />
                            </IconButton>
                            <div className="change-container">
                                <DialogTitle id="dialog-title">{"Récupérer mon mot de passe"}</DialogTitle>
                                <DialogContent>
                                    <div className="dialog-container">
                                        <div className="dialog-container-item">
                                            <TextField
                                                name="email"
                                                id="oldPassword"
                                                label="Votre courriel"
                                                type="email"
                                                variant="outlined"
                                                className="email"
                                                size="small"
                                            />
                                        </div>
                                    </div>
                                </DialogContent>
                                <DialogActions className="confirmation-button">
                                    <Button onClick={handleCloseForgotPassword} color="primary">
                                        Annuler
                                    </Button>
                                    <Button onClick={handleCloseForgotPassword} color="primary">
                                        Envoyer
                                    </Button>
                                </DialogActions>
                            </div>
                        </Dialog>
                    </div>
                    <div className="login-form-line">
                        <div className="half-form-wrapper">
                            <Button className="login-menu" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                Créer un compte
                            </Button>
                            <Menu
                                getContentAnchorEl={null}
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <NavLink to="/signup/individual">
                                    <MenuItem onClick={handleClose}>Pour moi</MenuItem>
                                </NavLink>
                                <NavLink to="/signup/professionnal">
                                    <MenuItem onClick={handleClose}>Pour entreprise</MenuItem>
                                </NavLink>
                            </Menu>
                        </div>
                        <div className="half-form-wrapper">
                            <Button type="submit" className="login-form-button" disabled={formik.isSubmitting} variant="contained" color="primary">
                                Connexion
                                {
                                    formik.isSubmitting ?
                                        (<CircularProgress />) : ('')
                                }
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    )
};

export default LoginForm;
