import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  active: '',
  categories: [],
  currentCategory: null,
};

const countrySlice = createSlice({
  name : "category",
  initialState : initialState,
  reducers : {
    categorySelected : (state, action) => {
      state.currentCategory = action.payload.category;
    },

    saveCategories : (state, action) => {
      state.categories = [ ...action.payload.categories ]
    },

    saveAllCategories : (state, action) => {
      state.categories = action.payload.categories
    },

    getAllCategories : (state, action) => {
      
    }
  }
});

export const {saveAllCategories, saveCategories, categorySelected, getAllCategories} = countrySlice.actions;
export default countrySlice.reducer;
