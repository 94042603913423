import React from "react";
import './AdvertsDetails.scss';
import {adverts} from "../../../data/advert";
import defaultImage from "../../../imgs/default-image.png";
import {TextField} from "@material-ui/core";
import disabled from "proptypes/disabled";
import Button from "@material-ui/core/Button";

export class AdvertsDetails extends React.Component {
    constructor(props) {
        super(props);
        console.log(props);
        let advertId = props.match.params.advertId;
        let advertisement = adverts.find(element => element.id == advertId);
        this.state = {
            advertisement : advertisement
        }
    }
    render() {

        return (
            <div className="dashboard-ad-details">
                <h2>{this.props.location.state.title}</h2>
                <div className="dashboard-ad-details-left">
                    { (this.props.location.state.images.length > 0) ?
                        (
                            this.props.location.state.images.map((image)=>{
                                return (
                                    <div className="dashboard-ad-details-left-item">
                                        <img key={image.id} src={image.thumbnailUrl} alt={image.title} />
                                    </div>
                                )
                            })
                        ) : (
                            <div className="dashboard-ad-details-left-item">
                                <img src={defaultImage} alt="" />
                            </div>
                        )
                    }
                </div>
                <div className="dashboard-ad-details-right">
                    <div className="dashboard-ad-details-right-item full-width">
                        <label htmlFor="ad-title">Titre</label>
                        <input
                            name="ad-title"
                            id="ad-title"
                            type="text"
                            defaultValue={this.props.location.state.title}
                        />
                    </div>
                    <div className="dashboard-ad-details-right-item">
                        <label htmlFor="ad-price">Prix</label>
                        <input
                            name="ad-price"
                            id="ad-price"
                            type="number"
                            size="small"
                            defaultValue={
                                (this.props.location.state.price == '' || undefined) ?
                                    ('') : (this.props.location.state.price)
                            }
                        />
                    </div>
                    {/* <div className="dashboard-ad-details-right-item">
                        <label htmlFor="ad-type">Type</label>
                        <input
                            name="ad-type"
                            id="ad-type"
                            type="text"
                            size="small"
                            defaultValue={this.props.location.state.advertType}
                        />
                    </div> */}
                    <div className="dashboard-ad-details-right-item">
                        <label htmlFor="ad-category">Catégorie</label>
                        <input
                            name="ad-category"
                            id="ad-category"
                            type="text"
                            size="small"
                            defaultValue={this.props.location.state.category}
                        />
                    </div>
                    <div className="dashboard-ad-details-right-item">
                        <label htmlFor="ad-town">Ville ou quartier</label>
                        <input
                            name="ad-town"
                            id="ad-town"
                            type="text"
                            size="small"
                            disabled={disabled}
                            defaultValue={this.props.location.state.city}
                        />
                    </div>
                    <div className="dashboard-ad-details-right-item">
                        <label htmlFor="ad-town">Date de publication</label>
                        <input
                            name="ad-town"
                            id="ad-town"
                            type="text"
                            size="small"
                            disabled={disabled}
                            defaultValue={this.props.location.state.created_at}
                        />
                    </div>
                    {
                        (this.props.location.state.nb_rooms !== undefined || '') ? (
                            <div className="dashboard-ad-details-right-item">
                                <label htmlFor="ad-nbrroom">Nombre de pièces</label>
                                <input
                                    name="ad-nbrroom"
                                    id="ad-nbrroom"
                                    type="text"
                                    size="small"
                                    defaultValue={this.props.location.state.nb_rooms}
                                />
                            </div>
                        ) : ('')
                    }
                    {
                        (this.props.location.state.nbrBathroom !== undefined || '') ? (
                            <div className="dashboard-ad-details-right-item">
                                <label htmlFor="ad-nbrbathroom">Nombre de salles de bain</label>
                                <input
                                    name="ad-nbrbathroom"
                                    id="ad-nbrbathroom"
                                    type="text"
                                    size="small"
                                    defaultValue={this.props.location.state.nbrBathroom}
                                />
                            </div>
                        ) : ('')
                    }
                    {
                        (this.props.location.state.area !== undefined || '') ? (
                            <div className="dashboard-ad-details-right-item">
                                <label htmlFor="ad-area">Surface</label>
                                <input
                                    name="ad-area"
                                    id="ad-area"
                                    type="text"
                                    size="small"
                                    defaultValue={this.props.location.state.area}
                                />
                            </div>
                        ) : ('')
                    }
                    {
                        (this.props.location.state.brand_name !== undefined || '') ? (
                            <div className="dashboard-ad-details-right-item">
                                <label htmlFor="ad-brand">Marque</label>
                                <input
                                    name="ad-brand"
                                    id="ad-brand"
                                    type="text"
                                    size="small"
                                    defaultValue={this.props.location.state.brand_name.name}
                                />
                            </div>
                        ) : ('')
                    }
                    {
                        (this.props.location.state.model_name !== undefined || '') ? (
                            <div className="dashboard-ad-details-right-item">
                                <label htmlFor="ad-model">Modèle</label>
                                <input
                                    name="ad-model"
                                    id="ad-model"
                                    type="text"
                                    size="small"
                                    defaultValue={this.props.location.state.model_name.name}
                                />
                            </div>
                        ) : ('')
                    }
                    {
                        (this.props.location.state.description !== undefined || '') ? (
                            <div className="dashboard-ad-details-right-item">
                                <label htmlFor="ad-mcondition">Etat du produit</label>
                                <input
                                    name="ad-condition"
                                    id="ad-condition"
                                    type="text"
                                    size="small"
                                    defaultValue={this.props.location.state.description}
                                />
                            </div>
                        ) : ('')
                    }
                    {
                        (this.props.location.state.year !== undefined || '') ? (
                            <div className="dashboard-ad-details-right-item">
                                <label htmlFor="ad-build-year">Année de sortie</label>
                                <input
                                    name="ad-build-year"
                                    id="ad-build-year"
                                    type="text"
                                    size="small"
                                    defaultValue={this.props.location.state.year}
                                />
                            </div>
                        ) : ('')
                    }
                    {
                        (this.props.location.state.mileage !== undefined || '') ? (
                            <div className="dashboard-ad-details-right-item">
                                <label htmlFor="ad-kilometers">Kilomètrage</label>
                                <input
                                    name="ad-kilometers"
                                    id="ad-kilometers"
                                    type="text"
                                    size="small"
                                    defaultValue={this.props.location.state.mileage}
                                />
                            </div>
                        ) : ('')
                    }
                    {
                        (this.props.location.state.fuel !== undefined || '') ? (
                            <div className="dashboard-ad-details-right-item">
                                <label htmlFor="ad-fuel">Essence</label>
                                <input
                                    name="ad-fuel"
                                    id="ad-fuel"
                                    type="text"
                                    size="small"
                                    defaultValue={this.props.location.state.fuel}
                                />
                            </div>
                        ) : ('')
                    }
                    <div className="submit-validation">
                        <Button type="submit">Modifier</Button>
                    </div>
                </div>
            </div>
        );
    }
}