import React, { useEffect, useState } from "react";
import './searchResults.scss';
import Header from "../../containers/Header";
import Footer from "../Footer";
import Advert from "../Advert/advert";
import {useSelector} from "react-redux";

const SearchResults = () => {
    const searchResult = useSelector(state => state.advertisement.searchResult);
    useEffect(()=>{
    }, []);

    return (
        <div className="filters-results">
            <Header/>
            <div className="filter-results-content">
                <h1>Résultats de votre recherche <span>"..."</span></h1>
                <div className="filter-results-content-container">
                {
                    searchResult && searchResult.map((advert)=> {
                            return (
                                <Advert key={advert.advert_id} object={advert} />
                            )
                        })
                    }
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default SearchResults;