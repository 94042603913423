import React from "react";
import './MySavedAdverts.scss';
import DashboardAdView from "../Dashboard Ad View/DashboardAdView";
import {connect} from "react-redux";
import {getUserSavedAdvert} from '../../../services/user_adverts';

class MySavedAdverts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 15,
            offset: 0,
            adverts: [],
        }
    }

    componentDidMount(){
        this.loadAdverts();
    }

    loadAdverts = () => {
        // this.setState((state)=> ({ shopLoading : true }));
        var body = {
            'user_id': this.props.currentUser.id,
            'offset': 0,
            'limit': 15,
            'country_id': this.props.currentCountry.id,
        };
        getUserSavedAdvert(body).then(({data}) => {
            const {RESPONSECODE, RESPONSEMESSAGE, RESPONSEDATA} = data
            if (RESPONSECODE == 200) {
                this.setState({adverts: RESPONSEDATA});
            }
        }).catch(error => {
            console.warn(error);
        }).finally(()=>{
            // this.setState((state)=> ({ shopLoading : false }));
        });
    }

    render() {
        const data = this.state.adverts;
        return (
            <div className="saved-adverts">
                <h1>Annonces sauvegardées</h1>
                <div className="saved-adverts-content">
                    {
                        data.length !== 0 ? (
                            <div className="saved-adverts-content-inner">
                                {
                                    data.map((advert)=> {
                                        return (
                                            <DashboardAdView key={advert.advert_id} object={advert} loadAdverts={this.loadAdverts} footer={true} edited={false} saved={true} />
                                        )
                                    })
                                }
                            </div>
                        ) : (
                            <span className="content">
                                Aucune annonce sauvegardée!
                            </span>
                        )
                    }
                </div>
                {/* <div className="content-more-load">
                    <Button>Plus d'annonces</Button>
                </div> */}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentCountry: state.country.currentCountry,
        currentUser: state.user.currentUser,
    }};

const mapDispatchToProps = dispatch => {
    return {

    }};

export default connect(mapStateToProps, mapDispatchToProps)(MySavedAdverts);
