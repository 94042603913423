import React from 'react';
import Categories from '../../containers/FooterCategories';
import FooterBanner from '../../containers/FooterBanner';
import './footer.scss';

const Footer = () => {

    return (
        <footer className="footer">
            <div className="container-wrapper">
                <div className="container-wrapper-inner">
                        <Categories />
                </div>
            </div>
            <FooterBanner />
        </footer>
        )
    }

export default Footer;
