import React, {useEffect, useState} from "react";
import Header from "../../containers/Header";
import Footer from "../Footer";
import './ConditionOfUse.scss';
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import paper from "../../imgs/paper.svg";
import {NavLink} from "react-router-dom";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const CGUTabs = () => {
    const size = useWindowSize();
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div>
            <Header />
            <div className="cgu-container">
                <div className="footer-breadcrumb">
                    <ul>
                        <li>
                            <NavLink to="/">Accueil</NavLink>
                        </li>
                        <li className="separator">/</li>
                        <li>Conditions générales d'utilisation</li>
                    </ul>
                </div>
                <div className="cgu-container-inner">
                    <div className="cgu-container-inner-tabs-wrapper">
                        <div className="cgu-container-inner-tabs-wrapper-header">
                            <div className="cgu-container-inner-tabs-wrapper-header-image">
                                <img src={paper} alt="paper"/>
                            </div>
                            <div className="cgu-container-inner-tabs-wrapper-header-text">
                                <h1>CGU TAARI.CO</h1>
                                <p>Mis à jour septembre 2021</p>
                            </div>
                        </div>
                        <Tabs
                            /*orientation="vertical"*/
                            orientation={size.width <= 768 ? 'horizontal' : 'vertical'}
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="simple tabs example"
                            className="cgu-container-tabs"
                        >
                            <Tab label="CONDITIONS GÉNÉRALES D’UTILISATION" {...a11yProps(0)} />
                            <Tab label="CONDITIONS GÉNÉRALES DE VENTE DES OPTIONS DE VISIBILITÉ PAYANTES" {...a11yProps(1)} />
                            <Tab label="CONDITIONS GÉNÉRALES DE VENTE DES BOUTIQUES TAARI" {...a11yProps(2)} />
                        </Tabs>
                    </div>
                    <div className="cgu-container-inner-tab-panel-wrapper">
                        <TabPanel className="cgu-container-tab-panel" value={value} index={0}>
                            <div className="cgu-container-tab-panel-header">
                                <h2>CONDITIONS GÉNÉRALES D’UTILISATION</h2>
                            </div>
                            <div className="cgu-container-tab-panel-body">
                                <div className="cgu-container-tab-panel-body-item">
                                    <p className="except">
                                        L'accès au Site Internet, sa consultation et son utilisation sont subordonnés à l'acceptation sans réserve des présentes Conditions Générales d'Utilisation (ci-après dénommées les " CGU ").
                                    </p>
                                    <p className="except">
                                        Chacun des termes mentionnés ci-dessous a, dans les présentes CGU, la signification suivante :
                                    </p>
                                    <p className="except">
                                        Annonce : désigne l'ensemble des éléments et données (audio, photo, vidéo, texte, etc…), déposés par un Annonceur, sous sa responsabilité éditoriale, en vue de proposer, rechercher ou échanger un bien ou un service et diffusés sur le Site Internet.
                                        Annonceur : désigne toute personne physique de plus de 16 ans ou toute personne morale ayant déposé et mis en ligne une Annonce via le site Taari.co. Le terme "Annonceur" regroupe dans les CGU les deux types d'Annonceur déposant des annonces via le service Taari, à savoir :
                                        L'Annonceur Particulier : qui s'entend de toute personne physique de plus de 16 ans, agissant exclusivement à des fins privées. L’Annonceur "Pro" : qui s'entend de toute personne morale, société, association ou auto-entrepreneur agissant exclusivement à des fins professionnelles. Hébergeur : désigne le Groupe Techno'Sens (voir la rubrique mentions légales) Service(s) Taari : désigne-le(s) service(s) que Taari.co met à la disposition des Utilisateurs et des Annonceurs sur le Site Internet tel que décrit à l'article les présentes CGU. Site Internet : désigne le site Internet accessible principalement depuis l'URL https//www.taari.co et permettant aux Utilisateurs et aux Annonceurs d'accéder via internet au Service Taari décrit dans les présentes CGU. Utilisateur : désigne tout visiteur, ayant accès au Service Taari via le Site Internet.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>1</span>Acceptation :</h3>
                                    <p>
                                        L'accès au Site Internet, sa consultation et son utilisation sont subordonnés à l'acceptation sans réserve des présentes Conditions Générales d'Utilisation (ci-après dénommées les " CGU ").
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>2</span>Déclarations :</h3>
                                    <p>Les annonceurs/utilisateurs reconnaissent que le contenu publicitaire, y compris les textes, images, graphique, vidéos ("Contenu") publié sur Taari.co est exact et conforme aux lois en vigueur.</p>
                                    <p>Taari.co et Techno'Sens (La " Société ") n'assume aucune responsabilité quant à toute illicéité ou toute erreur relative aux biens, services ou offres d’emplois annoncés sur le site.</p>
                                    <p>L'annonceur/utilisateur convient que son Contenu ne viole aucun droit d'auteur, droits de propriété intellectuelle ou d'autres droits de toute personne ou entité.</p>
                                    <p>Les annonceurs acceptent que leur contenu puisse être présenté à travers les sites partenaires de Taari.co conformément aux mêmes modalités et conditions que Taari.co</p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>3</span>Responsabilités :</h3>
                                    <p>
                                        L'annonceur/utilisateur du site est responsable de tous dommages causés à tout tiers y compris à Taari.co / Techno'Sens du fait de l'utilisation ou de l'exploitation du site et/ou de l'un de ses éléments, et dégage Taari.co / Techno'Sens des conséquences, des réclamations ou actions dont il pourrait, de ce fait, faire l'objet. L'utilisateur du site renonce également à exercer tout recours contre Taari.co / Techno ‘Sens dans le cas de poursuites diligentées par un tiers à son encontre du fait de l'utilisation et/ou de l'exploitation du site.
                                    </p>
                                    <p>
                                        Taari.co / Techno'Sens ne saurait être tenu responsable de l'utilisation par un tiers du site Taari.co et ne pourra encourir aucune responsabilité du fait de toute perte, dommage, réclamation, dette de toute nature résultant :
                                    </p>
                                    <p>
                                        (a) des erreurs ou omissions afférentes au contenu, y compris aux inexactitudes techniques ou aux erreurs typographiques ;
                                    </p>
                                    <p>
                                        (b) de l’accès aux sites web de tiers ou de contenu directement ou indirectement accessible via les liens présents sur Taari.co ;
                                    </p>
                                    <p>
                                        (c) de l'indisponibilité du site Taari ou de tout élément de ce dernier ;
                                    </p>
                                    <p>
                                        (d) de l’indisponibilité du service e-paiement occasionné par le réseau Internet ou par tout autre intervenant ;
                                    </p>
                                    <p>
                                        (e) de votre usage, licite ou illicite, du site Taari.co ;
                                    </p>
                                    <p>
                                        (f) de votre usage de tout équipement ou application sur Taari.co ;
                                    </p>
                                    <p>
                                        (g) en cas de force majeure ou de faits indépendants de la volonté de la Société.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>4</span>Liens vers d’autres sites :</h3>
                                    <p>
                                        Taari.co / Techno'Sens n'édite pas et ne contrôle pas les sites dont les liens sont proposés sur son site et dès lors, ne saurait être responsable de leurs contenus, produits, publicités ou de tous éléments ou services qu'ils présentent.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>5</span>Copyright :</h3>
                                    <p>Le contenu des annonces déposées appartient aux annonceurs, néanmoins, en déposant des annonces sur le site, l’annonceur concède à Taari.co / Techno'Sens le droit d’exploitation non exclusif, transférable, sous licence, à titre gracieux, pour le monde entier sur (i) l’ensemble du contenu des annonces et notamment sur les photographies, textes, vidéos, illustrations, marques, logos, titres, documents en pièce jointe (ci-après le « Contenu »), au fur et à mesure de leur publication sur le site Taari.co ainsi (ii) qu’une licence sur l’ensemble des droits de propriété intellectuelle incombant au contenu et notamment sur les droits d’auteurs sur les éléments utilisés dans son annonce, tels que les photographies, textes, vidéos, dessins, illustrations, et ce pour toute la durée légale de ses droits de propriété intellectuelle et pour le monde entier.</p>
                                    <p>Les annonceurs accordent à Taari.co / Techno'Sens le droit non-exclusif et sans redevance d’utiliser, reproduire, modifier, adapter, publier, traduire, créer à partir du contenu d’autres œuvres et de distribuer le contenu ou l’incorporer à n'importe quelle forme, moyen ou technologie connue ou développée plus tard, dans le cadre du service Taari.co ou en relation avec ses activités, et ce à des fins commerciales ou non et notamment publicitaires, ainsi que dans le cadre d’une diffusion sur les réseaux sociaux sur lesquels Taari.co / Techno'Sens sont présents.</p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>6</span>Filigranes :</h3>
                                    <p>
                                        Toutes les images sont en filigranes afin d’empêcher qu'elles ne soient utilisées à d'autres fins sans le consentement préalable de l'annonceur.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>7</span>Sécurité et images :</h3>
                                    <p>
                                        Taari.co se réserve le droit de changer le titre du Contenu à des fins éditoriales. Taari.co se réserve en outre le droit de ne pas publier des images qui n'ont aucun rapport avec le contenu, ou des images ne respectant pas les règles de Taari.co ou qui contiennent le logo d’un site concurrent.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>8</span>Accès au service :</h3>
                                    <p>
                                        Le service d’utilisation du site Taari.co est accessible gratuitement à tout utilisateur disposant d'un accès à internet. Les coûts afférents à l'accès à ce service, notamment les coûts du matériel, des logiciels ou de l’accès à internet sont exclusivement à la charge de l'utilisateur. Il est seul responsable du bon fonctionnement de son équipement informatique ainsi que de son accès à internet.
                                    </p>
                                    <p>
                                        Certaines sections du site sont réservées aux membres inscrits sur le site Taari après qu’ils aient procédé à leur identification à l'aide de leur identifiant et de leur mot de passe.
                                    </p>
                                    <p>
                                        La Société se réserve le droit de refuser l'accès aux services disponibles sur le site Taari.co, unilatéralement et sans notification préalable, à tout utilisateur ne respectant pas les présentes conditions d'utilisation.
                                    </p>
                                    <p>
                                        La Société met en œuvre tous les moyens raisonnables à sa disposition pour assurer un accès de qualité aux services, mais n'est tenu à aucune obligation d'y parvenir.
                                    </p>
                                    <p>
                                        La Société ne peut, en outre, être tenue responsable de tout dysfonctionnement du réseau ou des serveurs ou de tout autre événement échappant au contrôle raisonnable, qui empêcherait ou dégraderait l'accès aux services.
                                    </p>
                                    <p>
                                        La Société se réserve la possibilité d'interrompre, de suspendre momentanément ou de modifier sans préavis l'accès à tout ou partie des services, afin d'en assurer la maintenance, ou pour toute autre raison, sans que l'interruption n'ouvre droit à aucune obligation ni indemnisation.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>9</span>Identifiant et mot de passe :</h3>
                                    <p>
                                        Identifiant : Le terme « Identifiant » recouvre les informations nécessaires à l'identification d'un utilisateur sur le site pour accéder aux zones réservées aux membres. Mot de passe : Le « Mot de passe » est une information confidentielle, dont l'utilisateur doit garder le secret, lui permettant, utilisé conjointement avec son Identifiant, de prouver son identité. L'accès à certaines sections du site Taari et notamment du service « mon compte » ou ma « boutique en ligne » nécessite l'utilisation d'un Identifiant et d'un Mot de passe. Le Mot de passe, choisi par l'utilisateur, est personnel et confidentiel. L'utilisateur s'engage à conserver son mot de passe secret et à ne pas le divulguer sous quelque forme que ce soit. L'utilisation de son Identifiant et de son Mot de passe à travers internet se fait aux risques et périls de l'utilisateur. Il appartient à l'utilisateur de prendre toutes les dispositions nécessaires permettant de protéger ses données contre toute atteinte.
                                    </p>
                                    <p> Taari.co s'engage néanmoins à mettre en place tous les moyens nécessaires pour garantir la sécurité et la confidentialité des données transmises. L'utilisateur est informé qu'un ou plusieurs cookies, ne contenant aucune information personnelle, pourront être placés sur son disque dur afin d'assurer son identification.
                                    </p>
                                    <p>
                                        L'utilisateur admet connaître les limitations et contraintes propres au réseau internet et, à ce titre, reconnaît notamment l'impossibilité d'une garantie totale de la sécurisation des échanges de données. La Société ne pourra pas être tenue responsable des préjudices découlant de la transmission de toute information, y compris de celle de l’Identifiant et/ou du Mot de passe, via les services disponibles sur le site.
                                    </p>
                                    <p>
                                        L'Utilisateur s'engage, d'une manière générale, à respecter l'ensemble de la réglementation en vigueur dans le pays où il utilise le service Taari.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>10</span>Respect de la vie privée :</h3>
                                    <p>
                                        Taari.co utilise vos informations uniquement dans le cadre prévu par la loi du pays où est utilisé le service Taari et dans le respect absolu de la vie privée.
                                    </p>
                                    <p>
                                        Pour cela nous, le respect de la vie privée est un principe essentiel de la communauté.
                                    </p>
                                    <p>
                                        La Société stocke et traite vos informations sur des serveurs situés en France et qui sont protégés par des méthodes de sécurité aussi bien physiques que technologiques.
                                    </p>
                                    <p>
                                        Vous pouvez consulter et modifier les informations que vous nous fournissez, et choisir de ne pas recevoir certains types de communications lors de l'ouverture de votre compte ou à tout autre moment. Nous utilisons des tiers pour vérifier et certifier nos principes de respect de la vie privée.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>11</span>Le traitement des données :</h3>
                                    <p>
                                        Lorsque vous utilisez les services proposés par la Société, vous acceptez que vos données personnelles soient transmises à l’entité suivante :
                                    </p>
                                    <p>
                                        Le Groupe <strong>Techno'Sens</strong> SARL au droit Togolais immatriculée au Registre du Commerce de Lomé sous le numéro TG-LOM 2017 B 1192 et dont le siège social est situé au 35 rue de la patience Cassablanca Lomé Togo.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>12</span>Collecte des données personnelles :</h3>
                                    <p>
                                        Vous pouvez naviguer sur Taari.co sans avoir à décliner votre identité ou nous fournir une quelconque information. Lorsque vous nous communiquez vos informations personnelles, vous perdez votre anonymat. Si vous choisissez de nous les fournir, vous acceptez le transfert et le stockage de ces informations sur nos serveurs situés au Togo ou en France et en tout autre lieu dans le monde où la Société possède des locaux.
                                    </p>
                                    <p>
                                        Nous pouvons recueillir et enregistrer les données personnelles suivantes :
                                    </p>
                                    <p>
                                        Le nom, l'adresse e-mail, la date d’inscription, la ville ainsi que le quartier correspondant, le(s) numéro(s) de téléphone, l’URL de votre site Web,
                                        les coordonnées physiques, la date de naissance et (selon le service utilisé) des informations financières, telles que les numéros de carte de crédit ou les numéros de compte bancaire ;
                                        les informations relatives à vos activités sur le site (telles que les achats, les ventes, les objets et le contenu que vous générez ou qui sont liés à votre compte tel que le logo, description de votre magasin, photos de vos produits…) ;
                                        les conversations en messagerie, la résolution de litiges, la correspondance sur nos sites et la correspondance qui nous est envoyée ;
                                        les informations résultant de votre interaction avec nos sites, services, contenus et publicités, y compris les informations relatives à votre ordinateur et votre connexion, des statistiques sur les pages affichées, les données de navigation, les données publicitaires, votre adresse IP et des informations de blog standard ;
                                        les informations supplémentaires que nous vous demandons d'envoyer pour vous authentifier ou si nous pensons que vous enfreignez le règlement du site (par exemple, nous pouvons vous demander de nous envoyer une pièce d'identité ou une facture pour vérifier votre adresse, ou pour répondre à des questions supplémentaires afin de vérifier votre identité ou la propriété d'un objet que vous mettez en vente) ; ou toute autre façon justifiée de recueillir des informations permettant de vérifier votre identité.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>13</span>Marketing :</h3>
                                    <p>
                                        La Société ne vend ni ne loue vos données personnelles à des tiers à des fins de marketing sans votre consentement formel.
                                    </p>
                                    <p>
                                        La Société peut combiner vos données avec d’autres données ainsi que recueillir des données auprès d'autres sociétés et les utiliser pour améliorer et personnaliser ses services, contenus et publicités.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>14</span>Utilisation des données personnelles :</h3>
                                    <p>
                                        L'objectif principal de la collecte de données personnelles est de vous offrir une expérience sûre, optimale, efficace et personnalisée. Vous acceptez que nous puissions utiliser vos données personnelles et notamment afin de :
                                    </p>
                                    <p>
                                        fournir nos services et notamment le service client que vous demandez ;
                                        résoudre les litiges, percevoir des frais et remédier à des problèmes ;
                                        empêcher, détecter et enquêter sur toutes activités potentiellement interdites ou illégales, et faire appliquer nos conditions générales d'utilisation ;
                                        personnaliser, évaluer et améliorer nos services, contenus et publicités ;
                                        vous informer sur nos services et ceux de nos sociétés liées, par du marketing ciblé, des mises à jour de service et des offres promotionnelles basées sur vos préférences de communication ;
                                        comparer les informations pour s'assurer de leur exactitude, et les vérifier auprès de tiers.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>15</span>Partage de vos données :</h3>
                                    <p>
                                        La Société peut divulguer vos données personnelles, telles que votre identité, votre adresse IP, adresse email ou numéro de téléphone, pour satisfaire à des obligations légales, pour faire appliquer les présentes conditions générales, pour répondre à des réclamations liées à une annonce ou du contenu en violation avec les droits d'autres utilisateurs, ou pour préserver les droits, la propriété ou la sécurité de quiconque.
                                    </p>
                                    <p>
                                        Nous pouvons également communiquer vos données personnelles :
                                    </p>
                                    <p>
                                        à des sociétés liées à la Société telle que la maison mère, pour fournir du contenu et des services communs (comme l'inscription, les transactions et le service clients), pour détecter et empêcher des agissements potentiellement illégaux et pour orienter leurs décisions concernant leurs produits, services et communications. Les sociétés liées utiliseront ces données pour vous envoyer des communications marketing, uniquement dans la mesure où vous avez sollicité leurs services ;
                                        à des prestataires de services sous contrat qui nous assistent dans nos activités opérationnelles (par exemple, les investigations sur les fraudes, le recouvrement des créances, les programmes d'affiliation et de récompense, et assistance client) ;
                                        à d'autres tiers à qui vous nous avez expressément demandé d'envoyer vos données.
                                        aux autorités judiciaires, administratives et publiques, en réponse à une demande de renseignements relative à une enquête criminelle, des allégations d’activités illégales ou toute autre activité pouvant engager votre responsabilité civile, la nôtre, ou celle d’un autre utilisateur du site Taari.co. Pourront figurer parmi les informations personnelles que nous divulguerons votre Identifiant et son historique, votre nom, ville, région, numéro de téléphone, adresse e-mail, les plaintes pour fraude vous concernant, l’historique de vos enchères et articles proposés à la vente, ou toute autre information que nous pourrions estimer utile ;
                                        à d'autres entités commerciales, dans le cas où la Société ferait l'objet d'une fusion ou d'une acquisition par une autre société. (Dans une telle hypothèse, nous veillerons à ce que la nouvelle entité applique les stipulations des présentes notamment relativement au respect de la vie privée concernant vos données à caractère personnel.)
                                        Sans limiter ce qui précède, par souci d'assurer le respect de votre vie privée et de maintenir les personnes malveillantes à l'écart de vos données personnelles, nous ne communiquerons pas vos données personnelles à des autorités judiciaires, à des autorités publiques ou à des tiers, sans citation à comparaître, ordonnance de tribunal ou autre procédure légale similaire, sauf si nous estimons de bonne foi que la transmission des données est nécessaire pour prévenir un dommage matériel ou un préjudice financier imminent, ou encore une suspicion d’activités illégales.
                                    </p>
                                    <p>
                                        Pour les annonceurs situés à l'étranger, Taari.co / Techno'Sens se réserve le droit d'ajouter les mentions dans votre Contenu "Avertissement : l'annonce a été placée à l’étranger".
                                    </p>
                                    <p>
                                        Taari.co / Techno'Sens se réserve le droit de rechercher toutes les voies de recours légalement disponibles en cas de violation des conditions d’utilisation du site et notamment le droit de bloquer l'accès au site et à ses fonctionnalités.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>16</span>Informations que vous partagez sur Taari :</h3>
                                    <p>
                                        Votre Identifiant et le nom de votre « boutique en ligne » apparaissent sur l’ensemble du site Taari.co et sont donc visibles par tout le monde.
                                    </p>
                                    <p>
                                        Par conséquent, si vous associez votre nom à votre pseudo, les personnes à qui vous avez révélé votre nom seront en mesure d'identifier personnellement vos activités sur le site Taari.co. Si vous êtes un vendeur professionnel, vous devez communiquer votre identité et vos coordonnées (par exemple le nom et l'adresse géographique) lorsque vous mettez des objets en vente.
                                    </p>
                                    <p>
                                        Si vous accédez au site Taari.co à partir d'un ordinateur partagé ou d'un ordinateur dans un cybercafé, certaines de vos données personnelles peuvent être également visibles par d'autres personnes utilisant l'ordinateur après vous. Sur un ordinateur partagé, nous vous recommandons de vous déconnecter du site Taari.co et de supprimer vos cookies.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>17</span>Utilisation d'informations Taari :</h3>
                                    <p>
                                        La Société vous permet de communiquer des informations personnelles et financières pour effectuer des transactions. Nous vous encourageons à communiquer vos pratiques en matière de respect de la vie privée et à respecter la vie privée des autres utilisateurs.
                                    </p>
                                    <p>
                                        Étant donné que nous ne pouvons pas garantir la confidentialité ou la sécurité de vos données, nous vous encourageons à évaluer la politique de confidentialité et de sécurité de votre partenaire commercial avant d'entamer une transaction et de choisir de communiquer vos données.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>18</span>Cookies :</h3>
                                    <p>
                                        Nous utilisons des « cookies » (petits fichiers placés sur votre disque dur) sur certaines de nos pages pour mieux analyser le flux généré par notre page internet, personnaliser nos services, notre contenu et nos publicités, mesurer l’efficacité promotionnelle, et promouvoir la confiance et la sécurité.
                                    </p>
                                    <p>
                                        Voici quelques éléments à savoir sur les cookies :
                                    </p>
                                    <p>
                                        Nous proposons des fonctionnalités qui ne sont disponibles que grâce aux cookies.
                                        Nous utilisons des cookies pour vous identifier et vous permettre de rester connecté.
                                        La plupart des cookies sont des « cookies de session », ce qui signifie qu'ils sont automatiquement supprimés de votre disque dur à la fin d'une session.
                                        Vous avez toujours la possibilité de refuser nos cookies si votre navigateur le permet, mais dans ce cas, certains de nos sites et services risquent de ne plus vous êtes accessibles.
                                        Vous rencontrerez peut-être des cookies de tiers sur certaines pages de sites sur lesquels nous n'avons pas de contrôle. (Par exemple, si vous consultez une page Web créée par un autre utilisateur, un cookie vous sera peut-être envoyé par cette page Web.)
                                    </p>
                                    <p>
                                        Vous avez la possibilité de paramétrer votre navigateur de façon à refuser les cookies de publicité ciblée émis par nos partenaires. Nous vous conseillons de consulter les pages suivantes relatives aux procédures d’opposition à ces cookies prévues par ces derniers :
                                    </p>
                                    <ul>
                                        <li>Google (Adsense, DoubleClick Adexchange)</li>
                                        <li>Critéo</li>
                                        <li>Real Media</li>
                                        <li>Taboola</li>
                                        <li>Quantum</li>
                                        <li>Weborama</li>
                                        <li>Rubicon</li>
                                        <li>Outbrain</li>
                                        <li>Appnexus</li>
                                        <li>Tealium</li>
                                        <li>Adyoulike</li>
                                        <li>Twitter</li>
                                        <li>Facebook</li>
                                        <li>Sublimskinz</li>
                                        <li>AB Tasty</li>
                                        <li>ADventori</li>
                                    </ul>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>19</span>Protection de compte :</h3>
                                    <p>
                                        Votre Mot de passe est la clé d'accès à votre compte. Utilisez des chiffres, des lettres et des caractères spéciaux uniques, et ne communiquez votre Mot de passe à personne. Si vous communiquez votre Mot de passe ou vos informations personnelles à d'autres personnes, gardez à l'esprit que vous êtes responsable de toutes les actions effectuées depuis votre compte. Si vous perdez le contrôle de votre mot de passe, vous risquez de perdre le contrôle d'une importante partie de vos données personnelles et votre responsabilité légale est engagée pour toute action effectuée en votre nom. Par conséquent, si la confidentialité de votre Mot de passe a été compromise pour une raison quelconque, vous devez immédiatement prévenir la Société et modifier votre Mot de passe.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>20</span>Droit d'accès et de rectification de vos données personnelles :</h3>
                                    <p>
                                        Conformément à la loi relative à la protection des personnes physiques à l'égard du traitement des données à caractère personnel vous pouvez consulter et modifier la plupart des informations que nous stockons à tout moment sur le site Taari.co.
                                    </p>
                                    <p>
                                        Généralement, nous ne modifierons pas manuellement vos données personnelles parce qu'il est très difficile de vérifier votre identité à distance. Si vos données personnelles changent ou sont inexactes vous devez les modifier sans délai. Sur votre demande à notre service clients, nous fermerons votre compte et retirerons vos données personnelles de la partie publique du site aussi vite que possible, en fonction des activités de votre compte et conformément aux dispositions applicables. Toutefois, nous conservons dans nos systèmes les données personnelles des comptes fermés afin de nous conformer aux dispositions légales en vigueur, de prévenir les fraudes, de recouvrir les montants dus, de résoudre tout litige, de régler tout problème, de collaborer à toute enquête, de faire appliquer nos conditions générales d'utilisation ou de prendre toute autre action permise par la loi.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>21</span>Sécurité :</h3>
                                    <p>
                                        Vos données sont enregistrées sur nos serveurs situés en France. Nous considérons vos données comme un actif qui doit être protégé et utilisons de nombreux outils (cryptage, mots de passe, sécurité physique, etc.) pour les préserver de tout accès ou divulgation non autorisée. Toutefois, comme vous le savez probablement, des tiers sont susceptibles d'intercepter des envois ou des communications privées ou d'y accéder de façon illégale, et d'autres utilisateurs peuvent faire une utilisation abusive ou inappropriée de vos données personnelles recueillies sur le site. En conséquence, bien que nous mettions tout en œuvre pour assurer le respect de votre vie privée, nous ne pouvons pas garantir que vos données personnelles ou communications privées resteront toujours anonymes.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>22</span>Tiers :</h3>
                                    <p>
                                        Sauf stipulation contraire aux présentes dispositions, tout ce qui précède ne s'applique qu'à l'utilisation et la divulgation des données que nous recueillons de votre part. Si vous communiquez vos données à d'autres personnes, qu'il s'agisse d'acheteurs ou de vendeurs sur nos sites ou d'autres sites sur Internet, différents règlements peuvent être applicables en ce qui concerne l'utilisation ou la divulgation des informations que vous leur fournissez. La Société n'a aucun contrôle sur les politiques des tiers en matière de respect de la vie privée, et vous êtes soumis aux règlements de ces tiers. Nous vous encourageons à poser des questions avant de communiquer vos données personnelles à d'autres personnes.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>23</span>Indemnisation :</h3>
                                    <p>
                                        Les annonceurs/utilisateurs s'engagent à indemniser Taari.co / Techno'Sens, ses dirigeants, administrateurs, employés et agents contre toutes les pertes, dépenses, dommages et coûts, y compris les honoraires d'avocats, résultant de toute violation des conditions générales d’utilisation du site Taari.co.
                                    </p>
                                    <p>
                                        Les annonceurs/utilisateurs s'engagent à indemniser Taari.co / Techno'Sens, ses dirigeants, administrateurs, employés et agents contre tout comportement fautif ou négligent de la part des annonceurs/utilisateurs.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>24</span>Modification :</h3>
                                    <p>
                                        Taari.co / Techno'Sens se réserve le droit de modifier les conditions générales d’utilisation du site à n'importe quel moment. Toute modification entrera en vigueur immédiatement après sa publication sur Taari.co. Vous êtes tenus de consulter régulièrement le site et votre accès régulier ou usage du site Taari.co induisent votre approbation des modalités et conditions modifiées.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>25</span>Références légales :</h3>
                                    <p>
                                        L’annonceur et la Société sont soumis au droit du pays où est utilisé le service Taari.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>26</span>Mentions légales relatives à la société :</h3>
                                    <p>
                                        Société à responsabilité limitée de droit Togolais au capital social de 2.000.000 FCFA Siège social est situé à Lomé au 35 rue de la patience quartier Cassablanca. Immatriculée au registre du commerce de Lomé sous le numéro TG-LOM 2017 B 1192
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>27</span>Tribunaux compétents :</h3>
                                    <p>
                                        Tout litige ou réclamation afférents à l'utilisation du site Taari.co sera de la compétence exclusive du tribunal de commerce de Lomé.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>28</span>Loi applicable et tribunaux compétents :</h3>
                                    <p>
                                        Les présentes conditions générales d’utilisation du site sont soumises au droit Togolais.
                                    </p>
                                    <p>
                                        Tout litige ou réclamation afférents à l'utilisation du site Taari.co sera de la compétence exclusive des tribunaux Togolais.
                                    </p>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel className="cgu-container-tab-panel" value={value} index={1}>
                            <div className="cgu-container-tab-panel-header">
                                <h2>CONDITIONS GÉNÉRALES DE VENTE DES OPTIONS DE VISIBILITÉ PAYANTES</h2>
                            </div>
                            <div className="cgu-container-tab-panel-body">
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>1</span>Objet :</h3>
                                    <p>
                                        Les présentes conditions générales ont pour objet de définir les modalités de mise à disposition du service « boutique en ligne » de Taari.co, ci-après nommé « Boutique en Ligne » et les conditions d'utilisation de ce service par l'utilisateur ci-après nommé « l’Annonceur ».
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>2</span>Évolution des présentes conditions :</h3>
                                    <p>
                                        La Société se réserve le droit de modifier les termes, conditions et mentions à tout moment.
                                    </p>
                                    <p>
                                        Il est ainsi conseillé à l’Annonceur de consulter régulièrement la dernière version des conditions générales disponibles sur le site https://www.taari.co/.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>3</span>Acceptation :</h3>
                                    <p>
                                        Nos ventes sont soumises aux présentes conditions générales qui prévalent sur toutes autres.
                                    </p>
                                    <p>
                                        Elles ont pour objet d'informer tout éventuel Annonceur sur la teneur de ses engagements lors de la souscription à un service payant tel que la Boutique en Ligne. Le simple fait de cliquer sur une icône permettra de confirmer la souscription de l’Annonceur et vaudra consentement irrévocable et définitif sur toutes les dispositions de ces conditions générales de vente (CGV).
                                    </p>
                                    <p>
                                        Dans le cas où l'Annonceur ne souhaite pas accepter tout ou partie des présentes conditions générales, il lui est demandé de renoncer à tout usage du service.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>4</span>Moyen de souscription au service payant boutique en ligne :</h3>
                                    <p>
                                        La Boutique en Ligne peut être souscrite par tout Annonceur par carte bancaire, par PayPal, par Mobil Money ou par crédits. Si vous souhaitez connaître le tarif de souscription, veuillez-vous reporter au tableau des tarifs accessible ici
                                    </p>
                                    <p>
                                        Pour commencer l’ouverture d’une boutique en ligne il faudrait au préalable remplir le formulaire d’ouverture de boutique accessible sur la page https://www.taari.co/connexion/ ;
                                    </p>
                                    <p>
                                        D’autres moyens de payements/souscriptions peuvent être ajoutés à la seule discrétion de la Société.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>5</span>Durée de validité du service payant boutique en ligne :</h3>
                                    <p>
                                        Les durées de validité de la Boutique en Ligne disponibles sont de 12 Mois.
                                    </p>
                                    <p>
                                        Les souscripteurs au service payant Boutique en Ligne sont soumis aux mêmes conditions générales de vente des options de visibilité payantes cité au chapitre II.
                                    </p>
                                    <p>
                                        La suppression d'une Boutique en Ligne entraine la suppression de toute(s) annonce(s) attachée(s) à ce compte (même adresse mail) et, en conséquence, de toute(s) option(s) payante(s) souscrite(s) pour ces annonces et ne donne aucun droit à remboursement, ni indemnité, ce que l'annonceur reconnait et accepte.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>6</span>Description du service payant boutique en ligne :</h3>
                                    <p>
                                        En optant pour la Boutique en Ligne, vous vous positionnez en véritable utilisateur professionnel sur le site d'annonces n°1 de votre pays. En effet, la Boutique en Ligne vous permet d’avoir un espace dédié sur Taari.co, de mettre en avant votre logo, votre adresse physique ainsi qu'une description détaillée de votre activité, ainsi que le lien de votre propre site Web.
                                    </p>
                                    <p>
                                        En faisant l'acquisition d'une Boutique en Ligne, vous bénéficiez de véritables privilèges tels que la promotion simultanée de cinq autres produits sur vos propres annonces. Multipliant ainsi de manière considérable vos probabilités de réaliser des ventes.
                                    </p>
                                    <p>
                                        L’acquisition d'une Boutique en Ligne vous permet de bénéficier de l'assistance et l'accompagnement d'un conseiller commercial dédié qui vous orientera et vous conseillera au niveau de votre activité sur Taari.co.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>7</span>Prix d’acquisition du service payant boutique en ligne :</h3>
                                    <p>
                                        Le prix d’acquisition du service payant Boutique en Ligne dépend de la durée de validité, les options de visibilités payantes incluses dans le pack ainsi que d’autres critères liés au profil de l’utilisateur laissé à la seule discrétion de Taari.co (exemple : Secteur d’activité, ville…)
                                    </p>
                                    <p>
                                        Les tarifs appliqués sont ceux en vigueur au jour de l'achat d'une boutique. Taari.co se réserve la possibilité de modifier ses prix à tout moment.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>8</span>Modes de paiements compatibles avec les boutiques en ligne :</h3>
                                    <p>
                                        Parce qu'une solution e-commerce ne doit pas vous conditionner dans le choix de l'établissement bancaire qui traitera vos flux monétiques, elle est compatible avec tous les modes de paiement off line (chèque, virement, versement déplacé, espèce,)
                                    </p>
                                    <p>
                                        Le payement se fait carte bancaire, par PayPal, par Mobil Money ou par crédits
                                    </p>
                                    <p>
                                        L’Annonceur garantit la Société qu’il dispose des autorisations nécessaires pour utiliser le mode de paiement choisi par lui, lors de la souscription au service « Boutique en Ligne ».
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>9</span>Remboursement :</h3>
                                    <p>
                                        L'Annonceur est informé que toute annonce déposée ou modifiée en cours de diffusion dans sa boutique fait l'objet, avant sa mise en ligne sur le site Taari.co, d'une validation par le service modération.
                                    </p>
                                    <p>
                                        Si la Société était contrainte de supprimer l'annonce en cours de diffusion ou la Boutique en Ligne en raison de sa non-conformité aux règles de diffusion du service de modération et notamment parce que son contenu a été, légitimement, signalé comme abusif, la Société ne remboursera pas à l'Annonceur le montant de versé pour l’acquisition de sa Boutique en Ligne, ni en partie ni totalement.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>10</span>Rétractation :</h3>
                                    <p>
                                        En application aux mesures de protection du consommateur, l’Annonceur ayant souscrit une boutique en ligne dispose d'un délai 7 jours pour se rétracter. Toutefois, en validant la commande, ledit Annonceur accepte que Taari.co commence à exécuter la prestation et renonce expressément à son droit de rétractation.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>11</span>Responsabilité et force majeure :</h3>
                                    <p>
                                        La responsabilité de Taari.co / Techno'Sens ne peut être engagée en cas d'inexécution ou de mauvaise exécution de la commande due, soit du fait de l'annonceur, soit d'un cas de force majeure.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>12</span>Modification :</h3>
                                    <p>
                                        Taari.co / Techno'Sens se réserve le droit de modifier les conditions générales de vente à n'importe quel moment. Toute modification entrera en vigueur immédiatement après sa publication sur le site Taari.co. Vous êtes tenus de consulter régulièrement le site et votre accès régulier ou usage du site Taari.co induisent votre approbation des conditions modifiées.
                                    </p>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel className="cgu-container-tab-panel" value={value} index={2}>
                            <div className="cgu-container-tab-panel-header">
                                <h2>CONDITIONS GÉNÉRALES DE VENTE DES BOUTIQUES TAARIS</h2>
                            </div>
                            <div className="cgu-container-tab-panel-body">
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>1</span>Objet :</h3>
                                    <p>
                                        Les présentes conditions générales ont pour objet de définir les modalités de mise à disposition du service « boutique en ligne » de Taari.co, ci-après nommé « Boutique en Ligne » et les conditions d'utilisation de ce service par l'utilisateur ci-après nommé « l’Annonceur ».
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>2</span>Évolution des présentes conditions :</h3>
                                    <p>
                                        La Société se réserve le droit de modifier les termes, conditions et mentions à tout moment.
                                    </p>
                                    <p>
                                        Il est ainsi conseillé à l’Annonceur de consulter régulièrement la dernière version des conditions générales disponibles sur le site https://www.taari.co/.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>3</span>Acceptation :</h3>
                                    <p>
                                        Nos ventes sont soumises aux présentes conditions générales qui prévalent sur toutes autres.
                                    </p>
                                    <p>
                                        Elles ont pour objet d'informer tout éventuel Annonceur sur la teneur de ses engagements lors de la souscription à un service payant tel que la Boutique en Ligne. Le simple fait de cliquer sur une icône permettra de confirmer la souscription de l’Annonceur et vaudra consentement irrévocable et définitif sur toutes les dispositions de ces conditions générales de vente (CGV).
                                    </p>
                                    <p>
                                        Dans le cas où l'Annonceur ne souhaite pas accepter tout ou partie des présentes conditions générales, il lui est demandé de renoncer à tout usage du service.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>4</span>Moyen de souscription au service payant boutique en ligne :</h3>
                                    <p>
                                        La Boutique en Ligne peut être souscrite par tout Annonceur par carte bancaire, par PayPal, par Mobil Money ou par crédits. Si vous souhaitez connaître le tarif de souscription, veuillez-vous reporter au tableau des tarifs accessible ici
                                    </p>
                                    <p>
                                        Pour commencer l’ouverture d’une boutique en ligne il faudrait au préalable remplir le formulaire d’ouverture de boutique accessible sur la page https://www.taari.co/connexion/ ;
                                    </p>
                                    <p>
                                        D’autres moyens de payements/souscriptions peuvent être ajoutés à la seule discrétion de la Société.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>5</span>Durée de validité du service payant boutique en ligne :</h3>
                                    <p>
                                        Les durées de validité de la Boutique en Ligne disponibles sont de 12 Mois.
                                    </p>
                                    <p>
                                        Les souscripteurs au service payant Boutique en Ligne sont soumis aux mêmes conditions générales de vente des options de visibilité payantes cité au chapitre II.
                                    </p>
                                    <p>
                                        La suppression d'une Boutique en Ligne entraine la suppression de toute(s) annonce(s) attachée(s) à ce compte (même adresse mail) et, en conséquence, de toute(s) option(s) payante(s) souscrite(s) pour ces annonces et ne donne aucun droit à remboursement, ni indemnité, ce que l'annonceur reconnait et accepte.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>6</span>Description du service payant boutique en ligne :</h3>
                                    <p>
                                        En optant pour la Boutique en Ligne, vous vous positionnez en véritable utilisateur professionnel sur le site d'annonces n°1 de votre pays. En effet, la Boutique en Ligne vous permet d’avoir un espace dédié sur Taari.co, de mettre en avant votre logo, votre adresse physique ainsi qu'une description détaillée de votre activité, ainsi que le lien de votre propre site Web.
                                    </p>
                                    <p>
                                        En faisant l'acquisition d'une Boutique en Ligne, vous bénéficiez de véritables privilèges tels que la promotion simultanée de cinq autres produits sur vos propres annonces. Multipliant ainsi de manière considérable vos probabilités de réaliser des ventes.
                                    </p>
                                    <p>
                                        L’acquisition d'une Boutique en Ligne vous permet de bénéficier de l'assistance et l'accompagnement d'un conseiller commercial dédié qui vous orientera et vous conseillera au niveau de votre activité sur Taari.co.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>7</span>Prix d’acquisition du service payant boutique en ligne :</h3>
                                    <p>
                                        Le prix d’acquisition du service payant Boutique en Ligne dépend de la durée de validité, les options de visibilités payantes incluses dans le pack ainsi que d’autres critères liés au profil de l’utilisateur laissé à la seule discrétion de Taari.co (exemple : Secteur d’activité, ville…)
                                    </p>
                                    <p>
                                        Les tarifs appliqués sont ceux en vigueur au jour de l'achat d'une boutique. Taari.co se réserve la possibilité de modifier ses prix à tout moment.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>8</span>Modes de paiements compatibles avec les boutiques en ligne :</h3>
                                    <p>
                                        Parce qu'une solution e-commerce ne doit pas vous conditionner dans le choix de l'établissement bancaire qui traitera vos flux monétiques, elle est compatible avec tous les modes de paiement off line (chèque, virement, versement déplacé, espèce,)
                                    </p>
                                    <p>
                                        Le payement se fait carte bancaire, par PayPal, par Mobil Money ou par crédits
                                    </p>
                                    <p>
                                        L’Annonceur garantit la Société qu’il dispose des autorisations nécessaires pour utiliser le mode de paiement choisi par lui, lors de la souscription au service « Boutique en Ligne ».
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>9</span>Remboursement :</h3>
                                    <p>
                                        L'Annonceur est informé que toute annonce déposée ou modifiée en cours de diffusion dans sa boutique fait l'objet, avant sa mise en ligne sur le site Taari.co, d'une validation par le service modération.
                                    </p>
                                    <p>
                                        Si la Société était contrainte de supprimer l'annonce en cours de diffusion ou la Boutique en Ligne en raison de sa non-conformité aux règles de diffusion du service de modération et notamment parce que son contenu a été, légitimement, signalé comme abusif, la Société ne remboursera pas à l'Annonceur le montant de versé pour l’acquisition de sa Boutique en Ligne, ni en partie ni totalement.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>10</span>Rétractation :</h3>
                                    <p>
                                        En application aux mesures de protection du consommateur, l’Annonceur ayant souscrit une boutique en ligne dispose d'un délai 7 jours pour se rétracter. Toutefois, en validant la commande, ledit Annonceur accepte que Taari.co commence à exécuter la prestation et renonce expressément à son droit de rétractation.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>11</span>Modification des données et des contenus :</h3>
                                    <p>
                                        Le profil utilisateur sur Taari.co met à disposition des utilisateurs les champs nécessaires pour la modification de toutes les données personnelles concernant les Boutiques en Ligne. Tout est simple et ergonomique. Pas besoin de savoir coder.
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>12</span>Données personnelles :</h3>
                                    <p>
                                        Dans une logique de respect de la vie privée de ses utilisateurs, Taari s'engage à ce que la collecte et le traitement d'informations personnelles, effectués au sein du présent site, soient effectués conformément aux stipulations régissant le traitement des données personnelles figurant dans le chapitre « Conditions générales de vente des options de visibilité payantes ».
                                    </p>
                                </div>
                                <div className="cgu-container-tab-panel-body-item">
                                    <h3><span>13</span>Loi applicable et tribunaux compétents :</h3>
                                    <p>
                                        Les présentes conditions générales d’utilisation du site sont soumises au droit togolais.
                                    </p>
                                    <p>
                                        Tout litige ou réclamation afférents à l'utilisation du site Taari.co sera de la compétence exclusive des tribunaux togolais.
                                    </p>
                                    <p>
                                        Pour toute réclamation liée à la facturation : <a href="mailto:boutiques@taari.co">boutiques@taari.co</a> <br/>
                                        Pour tout besoin d'informations lié aux Boutiques en Ligne : <a href="mailto:boutiques@taari.co">boutiques@taari.co</a> <br/>
                                        Pour toute réclamation liée au fonctionnement du site et de votre Boutique en Ligne : <a href="mailto:contact@taari.co">contact@taari.co</a>
                                    </p>
                                </div>
                            </div>
                        </TabPanel>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

// Hook
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

export default CGUTabs;
