import React from 'react';

const Item = ({ item, callback }) => {
  const {
    title,
    link,
  } = item;

  return (
    <li>
      <a className="hover-underline semi" onClick={callback} href="#">{title}</a>
    </li>
  );
};

export default Item;
