import React from 'react';
import { uuid } from 'uuidv4';
import { NavLink } from 'react-router-dom';

import './Breadcrumbs.scss';

const Breadcrumbs = ({
  past,
  actual,
}) => {
  return (
    <ul className="breadcrumbs">
      { past.map((item) => (
        <li
          key={uuid()}
          className="breadcrumbs-item"
        >
          <NavLink to={item.url}>
            {item.name}
          </NavLink>
        </li>
      )) }
      <li className="breadcrumbs-item breadcrumbs-item--current">
        {actual}
      </li>
    </ul>
  );
};

export default Breadcrumbs;
