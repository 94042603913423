import { connect } from 'react-redux';
import Header from '../components/Header';
import {
  setSearchResult,
  setIsSearching,
  setSearchObject,
  setSearchResultNumber,
  setIsEditing,
  setEditObject,
  setShowSearchDialog
} from '../reducers/advertisementReducer';
import { closeHeader,toggleHeader } from '../reducers/headerReducer';
import { openCountryPopup, resetCountry, citySelected } from '../reducers/countryReducer';

const mapStateToProps = state => ({
  header: state.header,
  country: state.country.currentCountry,
  isAuthenticated : state.user.isAuthenticated,
  currentUser : state.user.currentUser,
  currentCountry : state.country.currentCountry,
  showSearchDialog: state.advertisement.showSearchDialog,
  countMessages: state.message.countMessages,
});

const mapDispatchToProps = dispatch => ({
  openCountryPopup: () => {
    dispatch(openCountryPopup());
  },
  toggleHeader: () => {
    dispatch(toggleHeader());
  },
  closeHeader: () => {
    dispatch(closeHeader());
  },
  resetCountry: () => {
    dispatch(resetCountry());
  },
  setSearchResult: (data) => {
    dispatch(setSearchResult({ searchResult : data}));
  },
  setIsSearching: (data) => {
    dispatch(setIsSearching({ isSearching : data}));
  },
  setSearchObject: (data) => {
    dispatch(setSearchObject({ searchObject : data}));
  },
  setSearchResultNumber: (data) => {
    dispatch(setSearchResultNumber({ searchResultNumber : data}));
  },
  citySelected: (data) => {
    dispatch(citySelected({ city : data}));
  },
  setIsEditing: (isEditing) => {
    dispatch(setIsEditing({ isEditing : isEditing}));
  },
  setEditObject: (editObject) => {
    dispatch(setEditObject({ editObject : editObject}));
  },
  setShowSearchDialog: (data) => {
    dispatch(setShowSearchDialog({ showSearchDialog: data}));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);