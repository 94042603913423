import React from 'react';
import HomeIllustration from "./home-illustration";

const Prefooter = () => (
    <section className="homepage-prefooter">
        <HomeIllustration />
        <p>
            Avec Taari vous pouvez consulter des annonces <a className="hover-underline" href="/search">immobilières</a>, des <a className="hover-underline" href="/search">voitures d'occasion</a>, des <a className="hover-underline" href="/search">offres d'emploi</a>, des <a className="hover-underline" href="/search">meubles</a>, du <a className="hover-underline" href="/search">matériel multimédia</a>, ou tout autre types de biens ou services.
            <br />
            Sur Taari.co, faites de bonnes affaires tout en ayant une démarche éco-responsable.
        </p>
    </section>
);

export default Prefooter;
