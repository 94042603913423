import React from 'react';
import { AiOutlineLoading3Quarters as LoadingIcon } from 'react-icons/ai';
import { uuid } from 'uuidv4';

import './Loading.scss';

const Loading = ({
  word = 'Chargement',
}) => {
  const letters = word.split('');

  return (
    <section className="loading">  
      <div className="loading-letters">
        { letters.map((letter) => (
          <span key={uuid()} className="loading-letters-letter">{letter}</span>
        )) }
      </div>
  
      <div className="loading-loader">
        <LoadingIcon />
        <LoadingIcon />
        <LoadingIcon />
      </div>
    </section>
  );
}

export default Loading;
