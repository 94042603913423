import { connect } from 'react-redux';
import {Advertisement} from '../components/Advertisement';
import { getAdvertisementById, setSavedAdvertisements } from '../reducers/advertisementReducer';
import {setMessage, setOpen} from "../reducers/snackbarReducer";
import {setAdvertToChat} from '../reducers/messageReducer';

const mapStateToProps = state => ({
  advertisement: state.advertisement.advertisement,
  advertisements: state.advertisement.advertisements,
  currentCountry: state.country.currentCountry,
  selectedAdvert: state.advertisement.selectedAdvert,
  advertisementLoaded: state.advertisement.advertisementLoaded,
  advert: state.advertisement.advert,
  currentUser: state.user.currentUser,
  savedAdverts: state.advertisement.savedAdverts,
});

const mapDispatchToProps = dispatch => ({
  getAdvertisementById: (id) => {
    dispatch(getAdvertisementById({ id : id}));
  },
  setMessage: (message) => {
    dispatch(setMessage({ message : message}));
  },
  setAdvertToChat: (advert) => {
    dispatch(setAdvertToChat({ advertToChat : advert}));
  },
  setSavedAdvertisements: (data) => {
    dispatch(setSavedAdvertisements({ savedAdverts : data}));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Advertisement);
