/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './Results.scss';
import Advert from "../Advert/advert";
import { useSelector, useDispatch } from 'react-redux';
import { Button, CircularProgress } from "@material-ui/core";
import { Icon } from '@iconify/react';
import IconButton from "@material-ui/core/IconButton";
import {MdClose} from "react-icons/md";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {FormControl, InputLabel, TextField} from "@material-ui/core";
import {Formik} from "formik";
import * as Yup from "yup";
import bxArrowToTop from '@iconify/icons-bx/bx-arrow-to-top';
import { Redirect, } from 'react-router-dom';
import { AdvertSkeleton } from "../skeletons/AdvertSkeleton";
// import {saveAdvertisements} from "../../reducers/advertisementReducer";
import {
    countAdverts, 
    searchAdvertisements, 
    getAdvertsCat, 
    filterAdvertisements
} from "../../services/advertisement";
import {
    setSavedSearch, 
    getUserSavedAdvert
} from '../../services/user_adverts';

const Results = ({ 
    advertisementLoaded, 
    saveAdvertisements, 
    searchResult, 
    isSearching, 
    searchObject, 
    setIsSearching, 
    setSearchResult, 
    setSearchObject, 
    searchResultNumber, 
    setMessage, 
    searchObjectName, 
    setSavedAdvertisements
}) => {
    
    //const dispatch = useDispatch();
    const currentCountry = useSelector((state) => state.country.currentCountry);
    const currentUser = useSelector(state => state.user.currentUser);
    //  const advertisementsSearchFilter = useSelector((state) => state.advertisement.advertisementsSearchFilter);

    const [ adverts, setAdverts ] = useState(null);
    const [ cursor, setCursor ] = useState({limit : 15, offset : 0, currentCount : 0});

    const [showScroll, setShowScroll] = useState(false)
    const [advertsInitializing, setAdvertsInitializing] = useState(true)
    const [advertsLoading, setAdvertsLoading] = useState(false)
    const [counts, setCounts] = React.useState(null);
    const [openSaveDialog, setOpenSaveDialog] = React.useState(false);
    const [saveProgress, setSaveProgress] = React.useState(false);
    const [isAuthenticated, setIsAuthenticated] = React.useState(null);
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

    const formSchema = Yup.object().shape({
        title: Yup.string().required('Ce champ est requis')
    });

    React.useEffect(() => {
        window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
    }, []);

    //console.log(width);

    const checkScrollTop = () => {
        if (screenWidth >= 992) {
            //console.log('Grande taille' + screenWidth);
            if (!showScroll && window.pageYOffset > 950) {
                setShowScroll(true)
            } else if (showScroll && window.pageYOffset <= 950) {
                setShowScroll(false)
            }
        } else if(screenWidth <= 991) {
            //console.log('Petite taille' + screenWidth);
            if (!showScroll && window.pageYOffset > 500) {
                setShowScroll(true)
            } else if (showScroll && window.pageYOffset <= 500) {
                setShowScroll(false)
            }
        }
    };

    const getAllCount = () => {
        var data = {
            "country_id": currentCountry.id,
        }
        countAdverts(data).then(({data}) => {
            setCounts(data);
        }).catch(error => {
            console.warn(error);
        });
    }

    window.addEventListener('scroll', checkScrollTop)

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior : "smooth"
        });
    };

    const search1 = () => {
        // if (searchObject?.city_id) {
        //     getAdvertsCat(searchObject).then(({data}) => {
        //         let { RESPONSEDATA, STATUS } = data;
        //         if (STATUS) {
        //             let myBody = Object.assign({}, searchObject);
        //             setIsSearching(2);
        //             let res = [...searchResult, ...RESPONSEDATA];
        //             setSearchResult(res);
        //             let currentCount = cursor.currentCount + RESPONSEDATA.length;
        //             setCursor({ ...cursor, currentCount : currentCount});
        //             myBody.offset = myBody.offset + 15;
        //             setSearchObject(myBody)
        //         } else {
        //             setIsSearching(2);
        //             // window.location.reload();
        //         }
        //     })
        //         .catch(e=> {
        //             console.error(e);
        //             // window.location.reload();
        //         }).finally(()=>{
        //         setAdvertsLoading(false);
        //     });
        // } else {
        //     searchAdvertisements(searchObject).then(({data}) => {
        //         let { RESPONSEDATA, STATUS } = data;
        //         if (STATUS) {
        //             let myBody = Object.assign({}, searchObject);
        //             setIsSearching(2);
        //             let res = [...searchResult, ...RESPONSEDATA];
        //             setSearchResult(res);
        //             let currentCount = cursor.currentCount + RESPONSEDATA.length;
        //             setCursor({ ...cursor, currentCount : currentCount});
        //             myBody.offset = myBody.offset + 15;
        //             setSearchObject(myBody)
        //         } else {
        //             setIsSearching(2);
        //             // window.location.reload();
        //         }
        //     })
        //         .catch(e=> {
        //             console.error(e);
        //             // window.location.reload();
        //         }).finally(()=>{
        //         setAdvertsLoading(false);
        //     });
        // };
        searchAdvertisements(searchObject).then(({data}) => {
            let { RESPONSEDATA, STATUS } = data;
            if (STATUS) {
                let myBody = Object.assign({}, searchObject);
                setIsSearching(2);
                let res = [...searchResult, ...RESPONSEDATA];
                setSearchResult(res);
                let currentCount = cursor.currentCount + RESPONSEDATA.length;
                setCursor({ ...cursor, currentCount : currentCount});
                myBody.offset = myBody.offset + 15;
                setSearchObject(myBody)
            } else {
                setIsSearching(2);
            }
        })
            .catch(e=> {
                console.error(e);
            }).finally(()=>{
            setAdvertsLoading(false);
            setAdvertsInitializing(false);
        });
    };

    const search2 = () => {
        filterAdvertisements({ country_id: currentCountry.id, limit: 15, offset:  cursor.offset})
            .then(({data}) => {
                let { RESPONSEDATA, STATUS } = data;
                if (STATUS) {
                    let res = [...adverts, ...RESPONSEDATA];
                    setSearchResult(res);
                    saveAdvertisements({advertisements : res});
                    let currentCount = cursor.currentCount + RESPONSEDATA.length;
                    setCursor({ ...cursor, currentCount : currentCount, offset : cursor.offset + 15});
                    setAdverts(res);
                }
            })
            .catch(e=> {
                console.error(e);
            }).finally(()=>{
            setAdvertsLoading(false);
        });
    };

    const loadMore = ()=>{
        setAdvertsLoading(true);
        isSearching == 2 ? search1() : search2();
    }

    const fetchAdvertisements = (adverts)=>{
        if(!adverts){
            setAdvertsInitializing(true);
            filterAdvertisements({ country_id: currentCountry.id, limit: 15, offset: 0 })
                .then(({data}) => {
                    let { RESPONSEDATA } = data;
                    setSearchResult(RESPONSEDATA);
                    saveAdvertisements({advertisements : RESPONSEDATA});
                    setAdverts(RESPONSEDATA);
                    let currentCount = RESPONSEDATA.length;
                    setCursor({...cursor, currentCount : currentCount, offset : cursor.offset + 15});
                })
                .catch(e=> {
                    console.error(e);
                    setAdverts([]);
                }).finally(()=>{
                setAdvertsInitializing(false);
            });
        }
    }

    const sortFunction = (type, array) => {
        var tab = [];
        if (type == 1) {
            tab = array.sort(function(a,b){
                return new Date(a.created_at) - new Date(b.created_at);
            });
        } else if (type == 2) {
            tab = array.sort(function(a,b){
                return new Date(b.created_at) - new Date(a.created_at);
            });
        } else if (type == 3) {
            tab = array.sort(function(a,b){
                return parseInt(a.price) - parseInt(b.price);
            });
        } else if (type == 4) {
            tab = array.sort(function(a,b){
                return parseInt(b.price) - parseInt(a.price);
            });
        }
    };

    const handleOtherAdvert = () => {
        // window.location.reload();
    };

    const saveSearch = (actions, title) => {
        var myBody = Object.assign({}, searchObject);
        var myBody2 = Object.assign({}, searchObjectName);
        var myBody3 = Object.assign(myBody, myBody2);
        var arr = [];
        arr.push(myBody3);
        var body = {
            'user_id': currentUser.id,
            'country_id': currentCountry.id,
            'title': title,
            'criteria': arr,
        };
        setSavedSearch(body).then(({data}) => {
            const {RESPONSECODE, RESPONSEMESSAGE} = data
            if (RESPONSECODE == 200) {
                setMessage(RESPONSEMESSAGE);
                actions.setSubmitting(false);
                handleCloseSave();
            } else if (RESPONSECODE == 204) {
                setMessage(RESPONSEMESSAGE);
            }
        }).catch(error => {
            console.warn(error);
        });
    };

    const handleCloseSave = () => {
        setOpenSaveDialog(false);
    };

    const handleOpenSave = () => {
        if (currentUser) {
            setOpenSaveDialog(true);
        } else {
            setIsAuthenticated(false);
        }
    };

    const loadSavedAdverts = () => {
        if(currentUser) {
            var body = {
                'user_id': currentUser.id,
                'offset': 0,
                'limit': 15,
                'country_id': currentCountry.id,
            };
            getUserSavedAdvert(body).then(({data}) => {
                const {RESPONSECODE, RESPONSEDATA} = data
                if (RESPONSECODE == 200) {
                    setSavedAdvertisements(RESPONSEDATA);
                } else if (RESPONSECODE == 204) {
                    setSavedAdvertisements([]);
                }
            }).catch(error => {
                console.warn(error);
            }).finally(()=>{
            });
        }
    };

    useEffect(()=>{
        getAllCount();
        isSearching == 2 ? search1() : fetchAdvertisements(adverts);
        setCursor({ ...cursor, currentCount : searchResultNumber});
        loadSavedAdverts();
    }, []);

    if(isAuthenticated === false)
        return <Redirect to="/login" />

    return (

        <div className="search-results">
            <div className={"search-save-wrapper " + (showScroll === true ? 'show' : '')}>
                <div className="search-save-wrapper-content">
                    <div className="search-save-wrapper-content-left">
                        <p>({isSearching == 2 ? (searchResultNumber ? searchResultNumber : 0) : (counts ? counts.total_adverts : 0)}), annonces, tout "{currentCountry.name}"</p>
                        <Button onClick={handleOpenSave}>Sauver la recherche</Button>
                    </div>
                    <div className="search-save-wrapper-content-right">
                        <Button onClick={scrollTop} >Retour en haut <Icon icon={bxArrowToTop} /></Button>
                    </div>
                </div>
            </div>
            <span className="advertisements-list-popup" />
            { advertsInitializing ?
                <AdvertSkeleton count={9} /> :
                <div className="advertisements-list">
                    {
                        isSearching == 2 ?  searchResult.map((advert)=> {
                            return (
                                <Advert key={advert.advert_id} object={advert} />
                            )
                        }) : isSearching == 3 ? searchResult.map((advert)=> {
                            return (
                                <Advert key={advert.advert_id} object={advert} />
                            )
                        }) :adverts?.map((advert)=> {
                            return (
                                <Advert key={advert.advert_id} object={advert} />
                            )
                        })
                    }
                </div> }
            { !advertsInitializing &&
            <div className="load-more-content">
               {/* <p>Vous avez vu {isSearching == 2 ? searchResult.length : cursor.currentCount} sur {isSearching == 2 ? searchResultNumber : counts && counts.total_adverts} annonces</p>*/}
                <p>Vous avez vu {isSearching == 2 ? searchResult.length : cursor.currentCount} sur {isSearching == 2 ? counts && counts.total_adverts : counts && counts.total_adverts} annonces</p>

                {
                    isSearching == 2 ? (
                        <Button onClick={()=>loadMore()} disabled={(searchResult.length < searchResultNumber && !advertsLoading) ? false : true}>
                            Voir plus d'annonces
                            {advertsLoading && <CircularProgress /> }
                        </Button>
                    ) :  isSearching == 3 ?   (
                        <Button onClick={()=>loadMore()} disabled={(searchResult.length) ? false : true}>
                            Voir plus d'annonces
                            {advertsLoading && <CircularProgress /> }
                        </Button>
                    )
                    
                    :counts && (
                        <Button onClick={()=>loadMore()} disabled={(cursor.currentCount < counts.total_adverts && !advertsLoading) ? false : true}>
                            Voir plus d'annonces
                            {advertsLoading && <CircularProgress /> }
                        </Button>
                    )
                }

{/*
                    isSearching == 2 ? (
                        <Button onClick={()=>loadMore()} disabled={(searchResult.length < searchResultNumber && !advertsLoading) ? false : true}>
                            Voir plus d'annonces
                            {advertsLoading && <CircularProgress /> }
                        </Button>
                    ) :  counts && (
                        <Button onClick={()=>loadMore()} disabled={(cursor.currentCount < counts.total_adverts && !advertsLoading) ? false : true}>
                            Voir plus d'annonces
                            {advertsLoading && <CircularProgress /> }
                        </Button>
                    )
                */}


            </div> }
            <Dialog
                className="dialog"
                open={openSaveDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <IconButton aria-label="close" className="closeButton"
                            onClick={handleCloseSave}>
                    <MdClose/>
                </IconButton>
                <div className="change-container ad-delete">
                    <DialogTitle id="alert-dialog-title">{"Sauvegarder la recherche"}</DialogTitle>
                    <Formik
                        initialValues={{title: ""}}
                        validationSchema={formSchema}
                        onSubmit={(values, actions) => {
                            saveSearch(actions, values.title);
                        }}>
                        {({
                              values, errors, touched, handleChange,
                              handleBlur, handleSubmit, isSubmitting
                          }) => (
                            <form onSubmit={handleSubmit}>
                                <DialogContent>
                                    <div className="dialog-container">
                                        {
                                            saveProgress === false ? (
                                                <div>
                                                    <div className="dialog-container-item">
                                                        <p>Voulez-vous vraiment sauvegarder cette recherche ?</p>
                                                        <p>Si oui donner un titre a cette sauvegarde</p>
                                                    </div>
                                                    {
                                                        <div className="dialog-container-item">
                                                            <FormControl
                                                                className={"select-category" + (errors.title && touched.title ? ' is-invalid' : '')}>
                                                                <TextField
                                                                    name="title"
                                                                    id="title-label"
                                                                    type="text"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    placeholder="Titre de la sauvegarde"
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    value={values.title}
                                                                />
                                                            </FormControl>
                                                            {errors.title && touched.title &&
                                                            <p className="errorMessage">
                                                                {errors.title}
                                                            </p>}
                                                        </div>
                                                    }
                                                </div>
                                            ) : ('')
                                        }
                                    </div>
                                </DialogContent>
                                <DialogActions className="confirmation-button">
                                    {
                                        saveProgress === false ? (
                                            <div className="initial-buttons">
                                                <Button onClick={handleCloseSave}
                                                        color="primary">
                                                    Annuler
                                                </Button>
                                                <Button type="submit" disabled={isSubmitting}
                                                        color="primary">
                                                    Oui
                                                    {
                                                        isSubmitting ?
                                                            (<CircularProgress/>) : ('')
                                                    }
                                                </Button>
                                            </div>
                                        ) : ('')
                                    }
                                </DialogActions>
                            </form>
                        )}
                    </Formik>
                </div>
            </Dialog>
        </div>
    );
};

export default Results;
