import React, {useState} from "react";
import './CreationSuccesMessage.scss';
import Header from "../../../containers/Header";
import CheckIcon from '@material-ui/icons/Check';
import Button from "@material-ui/core/Button";
import {Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import {setMessage, setOpen} from "../../../reducers/snackbarReducer";
import {saveUser} from "../../../reducers/userReducer";

class NumberValidation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
        }
    }

    goToSearch = () => {
        this.setState({redirect: true})
    }

    render() {
    return (
        <div>
            <Header />
            {
                this.state.redirect && <Redirect to='/login' />
            }
            <div className="creation-success-message">
                <div className="creation-success-message-inner">
                    <h1>Bienvenu dans la communauté Taari</h1>
                    <div className="creation-success-message-inner-icon">
                        <ul>
                            <li>
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                    <circle className="path circle" fill="none" stroke="#5cc171" stroke-width="6"
                                            stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                                    <polyline className="path check" fill="none" stroke="#5cc171" stroke-width="6"
                                              stroke-linecap="round" stroke-miterlimit="10"
                                              points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                                </svg>
                            </li>
                            <li>Opération réussie</li>
                        </ul>
                    </div>
                    <div className="creation-success-message-inner-wrapper">
                        <p>
                            Vous pouvez désormais; via vos PC, tablette et Smartphone, très facilement et rapidement: <br/>
                            Trouver ou vendre des biens <br/>
                            Trouver ou proposer des services <br/>
                            Gérer votre page personnelle <br/>
                            Discuter avec d'autres utilisateurs via le chat interne sécurisé. </p>
                        <p>Nous vous souhaitons d'exellentes ventes et de supers achats ! </p>
                        <Button onClick={this.goToSearch}>Continuer</Button>
                    </div>
                </div>
            </div>
        </div>
    )
    }
}

const mapStateToProps = state => ({
    currentCountry: state.country.currentCountry,
  });
  
  const mapDispatchToProps = dispatch => ({
    setOpen: (open) => {
        dispatch(setOpen({ open : open}));
    },
    setMessage: (message) => {
        dispatch(setMessage({ message : message}));
    },
    saveUser: (user) => {
        dispatch(saveUser({ user : user}));
    },
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(NumberValidation);