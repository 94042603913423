import axios from 'axios';

import {
  apiUrl,
} from '../utils/axios';

import {
    saveUser, disconnectUser, updateUserLoadState
  } from "../reducers/userReducer";
import stores from '../store/index';

/* Login user */

export function authenticateUser(login, password) {
    return axios.post(`${apiUrl}/user_authen`, {login : login, password : password});
}

/* Logout user */

export function logUserOut() {

    axios.post(`${apiUrl}/logout`)
      .then(({ data }) => {
            stores.store.dispatch(disconnectUser());
      })
      .catch((e) => console.error(e));
}

export function removelogUserOutData() {

      stores.store.dispatch(disconnectUser());
  
}


/* Create user */

export function createUser(userData){
    return axios.post(`${apiUrl}/user_registration`, userData);
}

export function numberValidation(userData){
  return axios.post(`${apiUrl}/confirm_code`, userData);
}

export function changePassword(userData){
  return axios.post(`${apiUrl}/change_password`, userData);
}

export function newPassword(userData){
  return axios.post(`${apiUrl}/new_password`, userData);
}

export function passwordInit(userData){
  return axios.post(`${apiUrl}/init_password`, userData);
}

export function numberInit(userData){
  return axios.post(`${apiUrl}/change_number_init`, userData);
}

export function codeGeneration(userData){
  return axios.post(`${apiUrl}/code_generation`, userData);
}

export function userUpdate(userData){
  return axios.post(`${apiUrl}/user_update`, userData);
}

export function getUser(userData){
  return axios.post(`${apiUrl}/get_user_infos`, userData);
}

export function deleteUserAdvert(advertData){
  return axios.post(`${apiUrl}/user_advert_deletion`, advertData);
}

export function deleteUserAccount(data){
  return axios.post(`${apiUrl}/user_acount_deletion`, data);
}