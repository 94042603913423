import React, {useEffect, useState} from 'react';
import {
  MdLocationOn
} from 'react-icons/md';
import Button from "@material-ui/core/Button";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { SimpleDialog } from "../SimpleDialog/simpleDialog";
import {useDispatch, useSelector} from "react-redux";
import {citiesToDialog} from "../../services/normalizers";
import {updateAdvertisementsSearchFilter} from "../../reducers/advertisementReducer";
import Carousel from 'react-elastic-carousel';
import {getAllCategories} from "../../services/category";
import {staticApiUrl} from "../../utils/axios";

const TopCategories = ({counts, getAdverts, searchResult, currentCategory, isSearching, searchResultNumber, sortFunction}) => {
  const [state, setState] = useState({
    tri: '',
    name: 'filter',
  });

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [typeVendeur, setTypeVendeur] = useState(0);
  const [tri, setTri] = useState('');

  // const handleChange = (event) => {
  //   const name = event.target.name;
  //   setState({
  //     ...state,
  //     [name]: event.target.value,
  //   });
  // };

  const handleChange = (event) => {
    setTri(event.target.value);
    sortFunction(event.target.value, searchResult);
  };

  const handleChange2 = (event) => {
    //console.log('sort ')
    //sortFunction(event.target.value, searchResult);
  };

  // const handleCh = (event) => {
  //   setTypeVendeur(event.target.value);
  //   // sortFunction(event.target.value, searchResult);
  // };

  const currentCountry = useSelector(state => state.country.currentCountry);
  const cities = citiesToDialog(currentCountry.cities);
  const [categories, setCategories] = useState([]);

  const handleClickOpen = ()=>{
    setOpen(true);
  }

  const handleClose = (element) => {
    setOpen(false);
    setSelected(element);
    if(element){
      filterAdvertisements1();
    }
  };

  const filterAdvertisements1 = () => {
    if(selected.type == 'CITY'){
      dispatch(updateAdvertisementsSearchFilter({ cityId : selected.id}));
    }else if (selected.type == 'DISTRICT'){
      dispatch(updateAdvertisementsSearchFilter({ districtId : selected.id}))
    }
  }

  const loadCategories = () => {
    getAllCategories().then(({ data }) => {
      let { RESPONSEDATA } = data;
      let tab = [];
      for (let i = 0; i < RESPONSEDATA.length; i++) {
        var item = RESPONSEDATA[i];
        var obj = {};
        obj.id = item.id;
        let url = `${staticApiUrl}/iconimages/`;
        let filename = "no-image";
        if (item.icon[0] !== undefined) {
          filename = url + item.icon[0].filename;
        }
        obj.image = filename;
        tab.push(obj);
      }
      setCategories(tab);
    })
  }

  useEffect(() => {
    loadCategories();
  }, [])

  const findImg = (id) => {
    let obj = categories.find((cat) => cat.id == id);
    return (obj ? obj.image : "");
  }

  /*useEffect(() => {
    filterAdvertisements({
      country_id: currentCountry.id,
      limit: 15,
      offset: 0
    }).then((data)=> {
      console.log(data);
    }).catch((e)=>{
      console.error(e);
    }).finally((e)=> {
      console.log(e);
    });
  }, []);*/

  return (
      <div className="advertisements-topcategories">
        <h3 className="advertisements-topcategories-title">Toutes les catégories</h3>
        <div className="advertisements-topcategories-list">
          <Carousel
              itemsToShow={6}
              showArrows={true}
              className="top-categories"
              itemsToScroll={1}
              style={null}
              breakPoints={
                [
                  {
                    width: 1,
                    itemsToShow: 2
                  },
                  {
                    width: 370,
                    itemsToShow: 3
                  },
                  {
                    width: 480,
                    itemsToShow: 4
                  },
                  {
                    width: 752,
                    itemsToShow: 6
                  }
                ]
              }
          >
            {
              counts && counts.total_categories_adverts.map(counter => {
                return (
                    <li key={counter.id} className="advertisements-topcategories-list-item" onClick={() => getAdverts(counter)} >
                      <span className="advertisements-topcategories-list-item-inner">
                        {
                          categories && <img src={findImg(counter.id)} alt={counter.title_fr}/>
                              /*<Skeleton animation="wave"  variant="rect" width="100%" height={102} />*/
                        }
                        <span className="advertisements-topcategories-list-item-inner-name">{counter.title_fr}</span>
                        <span className="advertisements-topcategories-list-item-inner-count">{counter.total_adverts} annonces</span>
                      </span>
                    </li>
                );
              })
            }
          </Carousel>
        </div>

        {/* <li className="advertisements-topcategories-list-item">
            <img src={Multimedia} alt="Multimédia"/>
            <span className="advertisements-topcategories-list-item-name">Multimédia</span>
            <span className="advertisements-topcategories-list-item-count">7849 annonces</span>
          </li>
          <li className="advertisements-topcategories-list-item">
            <img src={Immobilier} alt="Immobilier"/>
            <span className="advertisements-topcategories-list-item-name">Immobilier</span>
            <span className="advertisements-topcategories-list-item-count">842 annonces</span>
          </li>
          <li className="advertisements-topcategories-list-item">
            <img src={Services} alt="Services"/>
            <span className="advertisements-topcategories-list-item-name">Services</span>
            <span className="advertisements-topcategories-list-item-count">0 annonce</span>
          </li>
          <li className="advertisements-topcategories-list-item">
            <img src={Meubles} alt="Meubles-et-Décorations"/>
            <span className="advertisements-topcategories-list-item-name">Meubles et décorations</span>
            <span className="advertisements-topcategories-list-item-count">427 annonces</span>
          </li>
          <li className="advertisements-topcategories-list-item">
            <img src={Emplois} alt="Emplois"/>
            <span className="advertisements-topcategories-list-item-name">Emplois</span>
            <span className="advertisements-topcategories-list-item-count">4 annonces</span>
          </li> */}
        <div className="mobile-top-list">
          <div className="mobile-top-list-container">
            <div className="mobile-top-list-container-horizontal-scroll">
              <ul>
                <li className="allCategories">
                  <Button onClick={handleClickOpen}><MdLocationOn />{selected ? selected.title : "Tout le "+currentCountry.name}</Button>
                  <SimpleDialog prompt={"Choisir une ville ou un quartier"} data={cities} defaultSelection={"Toutes les villes"} open={open} onClose={handleClose} />
                </li>
                <li className="tri">
                  <span>Tri :</span>
                  <NativeSelect
                      name="tri"
                      onChange={handleChange}
                      inputProps={{
                        name: 'tri',
                      }}
                      value={tri}
                  >
                    <option value="2">Plus récentes</option>
                    <option value="1">Moins récentes</option>
                    <option value="3">Prix croissants</option>
                    <option value="4">Prix décroissants</option>
                  </NativeSelect>
                </li>
              </ul>
              {/*<CustomRadio
                  id="essence"
                  group="topCategories"
                  label="Autos-motos"
                  size="small"
                  className="createadvertisement-custom-radio radio-button"
              />
              <CustomRadio
                  id="diesel"
                  group="topCategories"
                  label="Meubles et décorations"
                  size="small"
                  className="createadvertisement-custom-radio radio-button"
              />
              <CustomRadio
                  id="hybride"
                  group="topCategories"
                  label="Emplois"
                  size="small"
                  className="createadvertisement-custom-radio radio-button"
              />
              <CustomRadio
                  id="electrique"
                  group="topCategories"
                  label="Multimédia"
                  size="small"
                  className="createadvertisement-custom-radio radio-button"
              />
              <CustomRadio
                  id="gpl"
                  group="topCategories"
                  label="Immobilier"
                  size="small"
                  className="createadvertisement-custom-radio radio-button"
              />
              <CustomRadio
                  id="autre"
                  group="topCategories"
                  label="Services"
                  size="small"
                  className="createadvertisement-custom-radio radio-button"
              />*/}
            </div>
            <div className="adType">
              <FormControlLabel control={<Checkbox checked={true} value={5} name="particuliers" />} label="Particuliers" />
              <FormControlLabel control={<Checkbox checked={true} value={6} name="professionels" />} label="Professsionels" />
            </div>
            <div className="top-categories">
              <small>Toutes les catégories, tout "{currentCountry.name}" ({counts ? counts.total_adverts : 0})</small>
            </div>
          </div>
        </div>
      </div>
  );
};

export default TopCategories;
