import React from 'react'

export default function getPathname() {
    let pathname;
    if (typeof window === 'undefined') {
      pathname = global.location.pathname
    } else {
      pathname = window.location.pathname
    }
    if (pathname === '/') {
      pathname = 'dashboard'
    } else {
      pathname = pathname.split('/')[4]
    }
   return pathname
  }