import React, { useState, useEffect } from 'react';
import cameraPlusOutline from '@iconify/icons-mdi/camera-plus-outline';
import Icon from "@iconify/react";
import { PictureInput } from "./PictureInput";
import {staticApiUrl} from "../../utils/axios";

const url = `${staticApiUrl}/adimages/`;

export const SecondPart2 = ({ values, isEditing }) => {


    useEffect(() => {
        console.log(values.images);
        if (values.images && values.images.length > 3) {
            setUnfolded(true);
        }
        var old_images = [];
        values.images && values.images.map((elt, index) => {
            old_images.push({
                id: elt.id,
                filename: elt.filename
            });
        });
        values.old_images = old_images;
        setOldImages(values.images);
        values.images && values.images.map((elt, index) => {
            initInput(index, `${url}${elt.filename}`);
        });
        values.images = [];
    }, [])

    const maxInitInputs = 3;
    const maxInputs = 9;
    let ipts = [];
    for (let i = 0; i < maxInputs; i++) {
        ipts.push({ key: i });
    };

    const [inputs, setInputs] = useState(ipts);
    const [unfolded, setUnfolded] = useState(false);
    const [deleteCount, setDeleteCount] = useState(maxInputs + 1);
    const [oldImages, setOldImages] = useState([]);

   // const [pictures, setPictures] = useState(values.pictures);

    const  toDataUrl = (url, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    };

    const updateInput = (index, url) => {
        let input = inputs[index];
        let ipts = inputs.slice();
        if (input !== undefined) {
            input.file = url;
            ipts[index] = input;
            setInputs(ipts);
            values.images.push(input.file);
            values.old_images = oldImages;
            console.warn('inputs: ', values);
        }
    }

    const initInput = (index, url) => {
        let input = inputs[index];
        let ipts = inputs.slice();
        if (input !== undefined) {
            input.file = url;
            ipts[index] = input;
            setInputs(ipts);
        }
    }

    const enabledInput = (index) => {
        let previous = 0;
        if (index > 0) {
            previous = index - 1;
            if (inputs[previous] && ((inputs[previous]).file !== undefined))
                return false;
            else
                return true;
        }
        return false;
    }

    const unfoldable = () => {
        let reducer = (accumulator, currentValue) => {
            return accumulator + (currentValue.file != undefined ? 1 : 0);
        }

        let total = inputs.reduce(reducer, 0);
        return total == maxInitInputs;
    }

    const unfoldInputs = () => {
        if (unfoldable()) {
            setUnfolded(true);
        }
    }

    const deleteInput = (index) => {
        let position = 2;
        if (unfolded)
            position = 8;
        let dc = deleteCount + 1;
        let ipts = inputs.slice();
        if (ipts[index] != undefined) {
            ipts.splice(index, 1, { key: (index) });
            // ipts.splice(position, 0, { key: (dc + 1) });
            setInputs(ipts);
            setDeleteCount(dc);
            var oldTab = ipts.filter(elt => {
                return elt.file && (elt.file.includes('.png') || elt.file.includes('.jpg') || elt.file.includes('.jpeg'));
            });
            var tab2 = [];
            oldImages.map(element => {
                oldTab.filter(elt => {
                    if (elt.file.includes(element.filename)) {
                        tab2.push(element)
                    }
                });
            })
            setOldImages(tab2);
            values.old_images = tab2;
            var newTab = ipts.filter(elt => {
                return elt.file && elt.file.includes('data:image/');
            });
            values.images = newTab;
            console.warn(values);
        }
    }

    return (
        <div className="createadvertisement-form-secondpart">
            <h3 className="createadvertisement-form-section-title">
                <span className="createadvertisement-form-section-title-bubble">2</span>
                    Photos
                </h3>
            <div className="createadvertisement-form-secondpart-container">
                <div className="createadvertisement-form-secondpart-container-inner">
                    {inputs.slice(0, (unfolded ? maxInputs : maxInitInputs)).map((element, index) => {
                        return <PictureInput key={element.key} index={element.key}
                            objet={element} updateInput={updateInput}
                            deleteInput={deleteInput} enabledInput={enabledInput} />
                    })}

                    {!unfolded && <div className="image-picker-item rest-images" onClick={unfoldInputs}>
                        <div className="image-picker-item-inner">
                            <Icon icon={cameraPlusOutline} />
                            <span>{maxInputs - maxInitInputs} photos Additionnelles</span>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}
