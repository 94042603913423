import { connect } from 'react-redux';
import FooterCategories from '../components/Footer/Categories';

import { getAllCategories } from '../reducers/categoryReducer';
import { openCountryPopup } from '../reducers/countryReducer';

const mapStateToProps = state => ({
  categories: state.category.categories,
});

const mapDispatchToProps = dispatch => ({
  openCountryPopup: () => {
    dispatch(openCountryPopup());
  },
  getAllCategories: () => {
    dispatch(getAllCategories());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FooterCategories);