import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {FaChevronDown} from 'react-icons/fa';
import {MdClose, MdMenu} from 'react-icons/md';

import CountryDropdown from '../../containers/HomepageCountryDropdown';
import IconButton from "@material-ui/core/IconButton";

const Header = ({
                  header,
                  toggleHeader,
                  openCountryPopup,
                  closeHeader,
                  country,
                  resetCountry,
                }) => {
  const pageScrolled = () => {
    const { scrollY } = window;
    const header = document.querySelector('.homepage-header');

    if (!header) return;

    const { offsetHeight } = header;

    if (scrollY > offsetHeight) {
      header.classList.add('scrolled');
    }
    else {
      header.classList.remove('scrolled');
    }
  };

  const location = useLocation();

  window.addEventListener('scroll', pageScrolled);
  pageScrolled();

  const isMenuOpened = (baseClass) => {
    let additionnalClass = '';

    if (header.opened) {
      document.body.classList.add('no-scroll');
      additionnalClass = ' opened';
    }
    else {
      document.body.classList.remove('no-scroll');
    }

    return baseClass + additionnalClass;
  }

  const manageCountryDropdown = (e) => {
    e.preventDefault();
    if (window.innerWidth > 650) {
      e.currentTarget.classList.toggle('closed');
    }
    else {
      closeHeader();
      openCountryPopup();
    }
  };

  const closeMenuMobile = () => {
    closeHeader();
  }

  const handleReturnToHomepage = () => {
    resetCountry();
  };

  const manageMenuContainer = (e) => {
    const {
      target,
      currentTarget,
    } = e;

    const menu = document.querySelector('.main-header-nav');
    if (!menu) {
      return;
    }

    if (menu.classList.contains('opened') && target === currentTarget) {
      closeHeader();
    }
  };

  return (
      <header className="homepage-header">
        <h1 className="homepage-header-logo">taari</h1>
        <nav className={isMenuOpened('homepage-header-nav')}>
          <ul>
            <li>
              <NavLink onClick={closeHeader} to="/decouvrir-taari">Découvrir Taari</NavLink>
            </li>
            <li>
              <NavLink onClick={closeHeader} to="/not-found">Rejoignez-nous</NavLink>
            </li>
            <li>
              <NavLink onClick={closeHeader} to="/aide-et-support">Aide</NavLink>
            </li>
            <li
                onClick={manageCountryDropdown}
                className="homepage-header-dropdown closed"
            >
              <div className="first-line">
                { (country) && (
                    <>
                      <img src={country.image} alt={country.name} />
                      {country.name}
                    </>
                ) }
                { (!country) && (<span>Choisir un pays</span>) }
                <FaChevronDown />
              </div>
              <CountryDropdown />
            </li>
          </ul>
        </nav>
        <div className="main-header-mobile-opener">
          <IconButton onClick={toggleHeader} aria-label="menu">
            <MdMenu />
          </IconButton>
        </div>
        <div className={isMenuOpened('main-header-nav-container')} onClick={manageMenuContainer}>
          <div className="mobile-close" onClick={closeMenuMobile}>
            <IconButton aria-label="search">
              <MdClose />
            </IconButton>
          </div>
          <nav className={isMenuOpened('main-header-nav')}>
            <h1 className="mobile-logo">
              <NavLink to="/" onClick={handleReturnToHomepage}>taari</NavLink>
            </h1>
            <ul>
              <li>
                <NavLink onClick={closeHeader} to="/about">Découvrir Taari</NavLink>
              </li>
              <li>
                <NavLink onClick={closeHeader} to="/join-us">Rejoignez-nous</NavLink>
              </li>
              <li>
                <NavLink onClick={closeHeader} to="/aide-et-support">Aide</NavLink>
              </li>
              <li
                  onClick={manageCountryDropdown}
                  className="homepage-header-dropdown closed"
              >
                <div className="first-line">
                  { (country) && (
                      <>
                        <img src={country.image} alt={country.name} />
                        {country.name}
                      </>
                  ) }
                  { (!country) && (<span>Choisir un pays</span>) }
                  <FaChevronDown />
                </div>
                <CountryDropdown />
              </li>
            </ul>
            {
              location.pathname == "/help" ? (
                  <div className="request-send">
                    <NavLink to={`/help/ask-us`}>Envoyer une demande</NavLink>
                  </div>
              ) : ('')
            }
          </nav>
        </div>
      </header>
  );
}

export default Header;
