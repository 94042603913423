import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  FaChevronDown, FaUserCircle,
} from 'react-icons/fa';
import {
  MdMenu,
  MdSearch,
  MdClose,
} from 'react-icons/md';
import {
  BsPencilSquare,
} from 'react-icons/bs';
import CountryDropdown from '../../containers/HomepageCountryDropdown';
import { disconnectUser } from "../../reducers/userReducer";
import stores from '../../store/index';
import './Header.scss';
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { Icon } from '@iconify/react';
import caretDown from '@iconify/icons-carbon/caret-down';
import bxsUser from '@iconify/icons-bx/bx-user';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import Link from "@material-ui/core/Link";
import ButtonBase from "@material-ui/core/ButtonBase";
import { getAllCategories } from '../../services/category';
import { setPreLoginData } from "../../reducers/userReducer";
import { useDispatch } from "react-redux";
import {staticApiUrl} from "../../utils/axios";
import Badge from "@material-ui/core/Badge";

const Header = ({
                  header,
                  toggleHeader,
                  isAuthenticated,
                  currentUser,
                  currentCountry,
                  openCountryPopup,
                  closeHeader,
                  country,
                  resetCountry,
                  setSearchResult,
                  setIsSearching,
                  setSearchObject,
                  setSearchResultNumber,
                  citySelected,
                  setIsEditing,
                  setEditObject,
                  showSearchDialog,
                  setShowSearchDialog,
                  countMessages
                }) => {

  let url = `${staticApiUrl}/countryimages/`;
  const userUrl = `${staticApiUrl}/userimages/`;

  //console.log(currentUser);

  const dispatch = useDispatch();

  const location = useLocation();

  const locationPath = window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1);

  let filename = "no-image";

  if (
      location.pathname === "/not-found" ||
      location.pathname === "/cookies" ||
      location.pathname === "/aide-et-support" ||
      location.pathname === "/decouvrir-taari" ||
      location.pathname === "/regles-de-diffusion" ||
      location.pathname === "/conditions-generales" ||
      location.pathname === "/donnees-privees" ||
      location.pathname === "/faq" ||
      location.pathname === "/conseils-securite" ||
      location.pathname === "/tutoriels" ||
      location.pathname === "/envoyer-une-demande"
  ) {
  } else {
    if (country?.images[0] !== undefined) {
      filename = country?.images[0].filename;
    }
  }

  const isMenuOpened = (baseClass) => {
    let additionnalClass = '';
    if (header.opened) {
      document.body.classList.add('no-scroll');
      additionnalClass = ' opened';
    }
    else {
      document.body.classList.remove('no-scroll');
    }
    return baseClass + additionnalClass;
  }

  const manageCountryDropdown = (e) => {
    e.preventDefault();
    if (window.innerWidth > 1150) {
      e.currentTarget.classList.toggle('closed');
    }
    else {
      closeHeader();
      openCountryPopup();
    }
  };

  const manageMenuContainer = (e) => {
    const {
      target,
      currentTarget,
    } = e;

    const menu = document.querySelector('.main-header-nav');
    if (!menu) {
      return;
    }

    if (menu.classList.contains('opened') && target === currentTarget) {
      closeHeader();
    }
  };

  const closeMenuMobile = () => {
    closeHeader();
  }

  const handleReturnToHomepage = () => {
    resetCountry();
  };

  const first = {title : "Toutes les categories", items : [], icon : 'https://api.iconify.design/mdi:backburger.svg'};
  const [data, setData] = React.useState([first]);
  const [open, setOpen] = React.useState(false);


  const [openMenu, setOpenMenu] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggleMenu = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleCloseMenu = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenMenu(false);
  };

  const setEditToDefault = (e) => {
    setIsEditing(false);
    setEditObject(null);
    handleCloseMenu(e);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenMenu(false);
    }
  }

  function logout(){
    dispatch(setPreLoginData({ user : {} }));
    stores.store.dispatch(disconnectUser());
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(openMenu);
  React.useEffect(() => {
    if (prevOpen.current === true && openMenu === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = openMenu;

    getAllCategories().then(({ data }) => {
      let { categories } = data;
      /*categories.unshift(first);*/
      setData(categories);
    }).catch((e)=>{
      console.error(e);
    })
  }, [openMenu]);

  const handleGoToHome = () => {
    setIsSearching(1);
    setSearchResult([]);
    setSearchResultNumber(null);
    setSearchObject({});
    citySelected(null);
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };

  const reloadShops = () => {
    setIsEditing(false);
    setEditObject(null);
    citySelected(null);
    // window.location.reload();
  };

  return (
      <header className="main-header">
        {
          (
              locationPath === "not-found" ||
              locationPath === "cookies" ||
              locationPath === "aide-et-support" ||
              location === "decouvrir-taari" ||
              location === "regles-de-diffusion" ||
              locationPath === "condition-generales" ||
              locationPath === "donnees-privees" ||
              locationPath === "faq" ||
              locationPath === "conseils-securite" ||
              locationPath === "tutoriels" ||
              locationPath === "envoyer-une-demande"
          ) ? ('') : (
              <div className="main-header-mobile-opener">
                <IconButton onClick={toggleHeader} aria-label="menu">
                  <MdMenu />
                </IconButton>
              </div> )
        }
        <h1 className="main-header-logo">
          <NavLink to="/" onClick={handleGoToHome}>taari</NavLink>
        </h1>
        {
          (
              locationPath === "not-found" ||
              locationPath === "cookies" ||
              locationPath === "aide-et-support" ||
              location === "decouvrir-taari" ||
              location === "regles-de-diffusion" ||
              locationPath === "condition-generales" ||
              locationPath === "donnees-privees" ||
              locationPath === "faq" ||
              locationPath === "conseils-securite" ||
              locationPath === "tutoriels" ||
              locationPath === "envoyer-une-demande"
          ) ? ('')
              : (
                  <div className="main-header-mobile-search">
                    {
                      (locationPath !== "search") ? (
                          <NavLink to="/search">
                            <IconButton aria-label="search">
                              <MdSearch />
                            </IconButton>
                          </NavLink>
                      ) : (
                          <IconButton aria-label="search" onClick={() => setShowSearchDialog(true)}>
                            <MdSearch />
                          </IconButton>
                      )
                    }
                  </div>
              )
        }
        {
          (
              location.pathname === "/not-found" ||
              location.pathname === "/cookies" ||
              location.pathname === "/conditions-generales" ||
              location.pathname === "/regles-de-diffusion" ||
              location.pathname === "/decouvrir-taari" ||
              location.pathname === "/donnees-privees"  ||
              location.pathname === "/aide-et-support" ||
              location.pathname === "/conseils-securite" ||
              location.pathname === "/faq" ||
              location.pathname === "/tutoriels" ||
              location.pathname === "/envoyer-une-demande"
          ) ? ('') : (
              <div className="main-header-desktop-menu">
                <div className={isMenuOpened('main-header-nav-container')} onClick={manageMenuContainer}>
                  <div className="mobile-close" onClick={closeMenuMobile}>
                    <IconButton aria-label="search">
                      <MdClose />
                    </IconButton>
                  </div>
                  <nav className={isMenuOpened('main-header-nav')}>
                    <h1 className="mobile-logo">
                      <NavLink to="/" onClick={() =>{
                        closeHeader();
                        handleGoToHome()
                      }}>taari</NavLink>
                    </h1>
                    <ul>
                      <li>
                        <ButtonBase>
                          <NavLink className="btn btn--large" activeClassName="selected" onClick={closeHeader} to={`/advertisements/create`}><BsPencilSquare />
                            Dépôt d'annonce GRATUIT
                          </NavLink>
                        </ButtonBase>
                      </li>
                      {country &&
                      <li className="mobile-search">
                        <NavLink className="bigger" activeClassName="selected" onClick={handleGoToHome} to={`/${country.slug}/search`}><MdSearch />Rechercher</NavLink>
                      </li>}
                      <li className="spacer ml" />
                      <li className="ml">
                        <NavLink onClick={() => {setIsEditing(false); setEditObject(null); closeHeader()}} activeClassName="selected" to="/dashboard/saved-adverts">Favoris</NavLink>
                      </li>
                      <li className="ml">
                        <NavLink onClick={() => {setIsEditing(false); setEditObject(null); closeHeader()}} activeClassName="selected" to="/dashboard/messages">
                          <Badge className="chat-badge" badgeContent={countMessages} color="primary">
                            <span>Chat</span>
                          </Badge>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink activeClassName="selected" onClick={() => {reloadShops(); closeHeader()}} to="/shops">Boutiques <span className="badge">Pro</span></NavLink>
                      </li>
                      {!isAuthenticated && <li>
                        <NavLink className="login-link" onClick={() => {setIsEditing(false); setEditObject(null); closeHeader()}} activeClassName="selected" to="/login">
                          <Icon className="user-icon" icon={bxsUser} />
                          Se connecter</NavLink>
                      </li>}
                      {(isAuthenticated && currentUser) && <li className="user-profile">
                        <Button
                            ref={anchorRef}
                            aria-controls={open ? 'user-simple-menu' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggleMenu}>
                          <Icon className="user-icon" icon={bxsUser} /> {currentUser.first_name} <Icon className="user-caret" icon={caretDown} />
                        </Button>
                        <Popper className="user-popper" open={openMenu} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                          {({ TransitionProps, placement }) => (
                              <Grow
                                  {...TransitionProps}
                                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                              >
                                <Paper>
                                  <ClickAwayListener onClickAway={(e) => setEditToDefault(e)}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                      <div className="user-profile-inner">
                                        <div className="user-profile-inner-top">
                                          <div className="user-profile-inner-top-image">
                                            {
                                              (currentUser.image && currentUser.image[0]) ? (
                                                  <img src={`${userUrl}${currentUser.image[0].filename}`} alt={currentUser.first_name}/>
                                              ) : (
                                                <FaUserCircle />
                                              )
                                            }
                                          </div>
                                          <div className="user-profile-inner-top-username">
                                            <h3>{currentUser.first_name}</h3>
                                          </div>
                                          <div className="user-profile-inner-top-email">
                                            <ul>
                                              <li>{currentUser.email}</li>
                                              <li>/</li>
                                              <li>{currentUser.phone}</li>
                                            </ul>
                                          </div>
                                          <Link href="/dashboard/profile" onClick={(e) => setEditToDefault(e)}>Gérer votre compte taari</Link>
                                        </div>
                                        <div className="user-profile-inner-bottom">
                                          <Link href="/dashboard/adverts" onClick={(e) => setEditToDefault(e)}>
                                            Mes annonces
                                          </Link>
                                          <Link className="mobile-hide" href="/advertisements/create" onClick={(e) => setEditToDefault(e)}>
                                            Déposer une annonce
                                          </Link>
                                          <Link className="mobile-hide" href="/dashboard/saved-adverts" onClick={(e) => setEditToDefault(e)}>
                                            Annonces sauvegardées
                                          </Link>
                                          <Link className="mobile-hide" href="/dashboard/messages" onClick={(e) => setEditToDefault(e)}>
                                            <Badge badgeContent={countMessages} color="primary">
                                              <span>Mes messages</span>
                                            </Badge>
                                          </Link>
                                        </div>
                                        <div className="user-profile-inner-footer">
                                          <Button onClick={logout}>Déconnexion</Button>
                                        </div>
                                      </div>
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                          )}
                        </Popper>
                      </li>}
                      <li className="spacer" />
                      <li onClick={manageCountryDropdown} className="homepage-header-dropdown closed">
                        <div className="first-line">
                          { (country) && (
                              <>
                                <img src={url + filename} alt={country.name} />
                                {country.name}
                              </>
                          ) }
                          { (!country) && (<span>Choisir un pays</span>) }
                          <FaChevronDown />
                        </div>
                        <CountryDropdown />
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
          )
        }
        {
          location.pathname === "/aide-et-support" ?  (
              <div className="request-send">
                <NavLink to={`/envoyer-une-demande`}>Envoyer une demande</NavLink>
              </div>
          ) : ('')
        }
      </header>
  );
}

export default Header;
