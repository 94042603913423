import React from "react";
import "./DiffusionRules.scss";
import Header from "../../containers/Header";
import Footer from "../Footer";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import {MdClose} from "react-icons/md";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import {NavLink} from "react-router-dom";

const DiffusionRules = () => {

    const [openProhibitedDialog, setOpenProhibitedDialog] = React.useState(false);
    const [openIllegalDialog, setOpenIllegalDialog] = React.useState(false);

    const handleClickOpenProhibitedDialog = () => {
        setOpenProhibitedDialog(true);
    };

    const handleClickOpenIllegalDialog = () => {
        setOpenIllegalDialog(true);
    };

    const handleCloseProhibitedDialog = () => {
        setOpenProhibitedDialog(false);
    };

    const handleCloseIllegalDialog = () => {
        setOpenIllegalDialog(false);
    };

    return (
        <div>
            <Header/>
            <div className="diffusion-rules-container">
                <div className="footer-breadcrumb">
                    <ul>
                        <li>
                            <NavLink to="/">Accueil</NavLink>
                        </li>
                        <li className="separator">/</li>
                        <li>Règles de diffusion</li>
                    </ul>
                </div>
                <div className="diffusion-rules-container-inner">
                    <h1>PRESENTATION</h1>
                    <p>
                        Le site Internet <a href="https//www.taari.co">https//www.taari.co</a> ci-après dénommé le « Site Internet », est un site d’hébergement proposant un service de dépôt et de consultation de petites annonces de biens et services sur Internet destiné aux particuliers.
                    </p>
                    <h2>REGLEMENT DE PUBLICATION :</h2>
                    <h3>Règles de diffusion.</h3>
                    <h4>Doublons :</h4>
                    <p>
                        Il est interdit de publier plusieurs annonces pour un même article, service ou emploi. Supprimez l'ancienne annonce, patientez un délai de 15 minutes puis publiez votre nouvelle annonce. De même, il est interdit de publier des annonces pour un même article, service ou emploi sous différentes catégories. Si vous voulez remonter votre annonce en haut de la page, il suffit d'opter pour notre service Renouvellement.
                    </p>
                    <h4>Marketing :</h4>
                    <p>
                        Seules sont autorisées les annonces de vente, location, emploi ou service. L'utilisation d'une annonce à des fins de marketing pur est interdite.
                    </p>
                    <h4>Annonce de particulier :</h4>
                    <p>
                        Les annonces catégorisées comme "Particulier" publiées sur Taari.co sont réservées aux personnes non commerciales, autorisées à s'engager par contrat légal.
                    </p>
                    <h4>Annonce de professionnel :</h4>
                    <p>
                        Les annonces catégorisées comme "Professionnel" publiées sur Taari.co sont réservées aux entreprises. Taari.co se réserve le droit de juger du caractère professionnel d'une annonce.
                    </p>
                    <h4>Services :</h4>
                    <p>
                        Les services proposés ou recherchés doivent respecter les lois en vigueur du pays pour chaque profession.
                    </p>
                    <h4>Titre de l’annonce :</h4>
                    <p>
                        Le titre de l'annonce doit brièvement décrire l'article ou le service proposé. Il ne doit pas contenir des numéros de téléphones ou de termes tels que :
                    </p>
                    <ul>
                        <li>Urgent</li>
                        <li>Je vends, vente, à vendre, offre</li>
                        <li>Demande</li>
                        <li>Adresse web</li>
                        <li>Une date</li>
                        <li>Un nom d'entreprise</li>
                        <li>Une URL (adresse Web)</li>
                        <li>Un numéro de téléphone</li>
                        <li>Un lien ou un compte de réseaux sociaux</li>
                    </ul>
                    <p>
                        Taari.co se réserve le droit de modifier le titre de l'annonce pour qu'il soit conforme au règlement. <br/>
                        Aucun caractère inutile n'est autorisé dans le titre
                    </p>
                    <h4>Texte de l’annonce :</h4>
                    <p>
                        L'article ou le service doit être décrit dans le texte de l'annonce. Il est interdit de proposer simplement un lien vers une autre page. Le texte des annonces ne doit pas être copié sur une autre annonce : ces textes sont protégés par le droit d'auteur.
                    </p>
                    <h4>Langue :</h4>
                    <p>
                        Seules les annonces en Français sont autorisées.
                    </p>
                    <h4>Catégories :</h4>
                    <p>
                        L'annonce doit être placée dans la catégorie et sous-catégorie décrivant le mieux l'article ou le service. Le cas échéant, Taari.co peut la déplacer dans la catégorie correspondante. Les biens et services n'appartenant pas à la même catégorie doivent être proposés dans des annonces séparées.
                        Les annonces de vente doivent être classées sous « Offre », <br/>
                        Les annonces de recherche sous « Je recherche » <br/>
                        Les annonces de dons d’objet ou de services sous « Objets gratuit ».
                    </p>
                    <p>
                        Nombre d’annonces actives par utilisateur : <br/>
                        Le nombre d’annonces actives par utilisateur sur Taari.co est limité selon le type d’utilisateurs. <br/>
                        Le tableau ci-dessous reprend le niveau de limitation par type de compte (Particulier /Professionnel / Boutique) :
                    </p>
                    <div className="table-wrapper">
                        <table className="table">
                            <thead>
                            <tr>
                                <th></th>
                                <th colSpan="3">Compte</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Categories</td>
                                <td>Particuliers</td>
                                <td>Professionnels</td>
                                <td>Boutiques</td>
                            </tr>
                            <tr>
                                <td>Immobilier</td>
                                <td>5</td>
                                <td>5</td>
                                <td>Illimité</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <p>
                        Cette limitation est susceptible d’être mise à jour à tout moment.
                    </p>
                    <p>
                        Un seul véhicule ou une seule propriété par annonce : <br/>
                        Il est interdit de proposer plus d'un véhicule ou d'une propriété dans une même annonce, sauf en cas d'échange (par exemple 2 contre 1).
                    </p>

                    <h4>Liens :</h4>
                    <p>
                        Les liens inclus dans l'annonce doivent être directement liés à l'article ou au service proposé. Le règlement régissant les annonces s'applique également au lien. Il est interdit d'établir un lien vers un autre site d'annonces classées ou d'enchères.
                    </p>
                    <h4>Photos :</h4>
                    <p>
                        La vente d'animaux sur Taari.co doit se conformer à la législation en vigueur du Pays.
                    </p>
                    <h4>Biens piratés et contrefaçons :</h4>
                    <p>
                        Les annonces proposant des articles interdits à la vente de par la législation en vigueur du Pays sont interdites.
                    </p>
                    <h4>Règlement pour les animaux :</h4>
                    <p>
                        La vente d'animaux sur Taari.co doit se conformer à la législation en vigueur du Pays.
                    </p>
                    <h4>Articles illégaux :</h4>
                    <p>
                        Les annonces proposant des articles interdits à la vente de par la législation en vigueur du pays sont interdites. <span className="fake-link" onClick={handleClickOpenIllegalDialog}>Voici une liste d'exemples de produits illégaux</span>.
                    </p>
                    <Dialog
                        className="dialog diffusion"
                        disableBackdropClick
                        disableEscapeKeyDown
                        open={openIllegalDialog}>
                        onClose={handleClickOpenIllegalDialog}
                        aria-labelledby="simple-dialog-title"
                        <IconButton aria-label="close" className="closeButton" onClick={handleCloseIllegalDialog}>
                            <MdClose />
                        </IconButton>
                        <div className="change-container diffusion-dialog">
                            <DialogTitle id="simple-dialog-title">ARTICLES ILLEGAUX</DialogTitle>
                            <DialogContent>
                                <div className="prohibited-items">
                                    <ul>
                                        <li>Les monnaies, les factures de n'importe quel pays en cours de validité ou en circulation. Est inclus l'argent falsifié et tout produit de la fabrication ou de la reproduction d'instruments financiers.</li>
                                        <li>Tout instrument financier dont les comptes bancaires, les cartes de crédit, les fonds, les chèques, les prêts, les lignes de crédit. Tout article destiné à obtenir ou à stocker des données de cartes de crédit ou de débit. Sont incluses également les bases de données relatives aux numéros de cartes de débit ou de crédit ou toute autre information de nature privée.</li>
                                        <li>Bases de données des données personnelles ou de spam et tout produit ou service qui pourrait contribuer à une distribution non autorisée.</li>
                                        <li>Biens et marchandises volés ou de contrebande.</li>
                                        <li>Les substances illégales et les produits destinés à produire, modifier, consommer des substances illégales. Les anabolisants et les stéroïdes. Les drogues. Les médicaments et tout article pharmaceutique sous prescription.</li>
                                        <li>Les plantes et les animaux menacés d'extinction. La fourrure, les organes, les résidus ou les parties d'animaux.</li>
                                        <li>Les chiens dangereux : Est entendu par chien dangereux, tous les chiens qui se distinguent, au regard de leur race ou de leurs caractéristiques morphologiques, par une agressivité présentant un danger pour l'Homme.</li>
                                        <li>Toute matière explosive.</li>
                                        <li>Les feux d'artifice, la poudre à canon ou les matières explosives y compris les manuels qui enseignent ou détaillent comment fabriquer des bombes ou des explosifs.</li>
                                        <li>Armes et articles connexes tels que les munitions, les balles, les silencieux, les chargeurs, les kits, les lance-flammes, etc.</li>
                                        <li>Les articles considérés comme faisant partie du patrimoine historique.</li>
                                        <li>Tout article en rapport avec le hacking et le cracking.</li>
                                        <li>Satellite et câble décodeurs TV en dehors d'une distribution autorisée. Antennes des fournisseurs de TV par satellite. Logiciel permettant le décodage des signaux de transmission par satellite ou de câble TV.</li>
                                        <li>Tout type d'objets qui viole le droit d'auteur, les brevets, les marques, les dessins ou modèles industriels et les secrets commerciaux.</li>
                                        <li>Les pièces d'identité tels que certificats de naissance, le permis de conduire, les passeports, les visas, etc. Ou tout article de production de tels documents.</li>
                                        <li>Les organes et déchets humains, tels que des corps humains, corps, organes, membres du corps humain ou déchets humains.</li>
                                        <li>Tous les articles relatifs à la pédophilie, de pornographie mettant en scène des enfants, des enfants nus ou de quelconque façon impliquant des mineurs.</li>
                                        <li>Thérapies.</li>
                                        <li>Combines malhonnêtes rapides pour devenir riche.</li>
                                    </ul>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseIllegalDialog} color="primary">
                                    Ok
                                </Button>
                            </DialogActions>
                        </div>
                    </Dialog>
                    <h4>Contenu injurieux :</h4>
                    <p>
                        Les annonces ou photos pouvant être perçues comme une insulte envers des individus, des groupes ethniques ou des personnalités sont interdites.
                    </p>
                    <h4>Biens et services interdits :</h4>
                    <p>
                        Taari.co a établi des restrictions sur certains biens ou services susceptibles d'être proposés. <span className="fake-link" onClick={handleClickOpenProhibitedDialog}>Voici une liste de biens ou services non autorisés</span>.
                    </p>
                    <Dialog
                        className="dialog diffusion"
                        disableBackdropClick
                        disableEscapeKeyDown
                        open={openProhibitedDialog}>
                        onClose={handleClickOpenProhibitedDialog}
                        aria-labelledby="simple-dialog-title"
                        <IconButton aria-label="close" className="closeButton" onClick={handleCloseProhibitedDialog}>
                            <MdClose />
                        </IconButton>
                        <div className="change-container diffusion-dialog">
                            <DialogTitle id="simple-dialog-title">ARTICLES INTERDITS ET SERVICES NON AUTORISES</DialogTitle>
                            <DialogContent>
                                <div className="prohibited-items">
                                    <ul>
                                        <li>Produits pour la santé et la beauté hors parfums</li>
                                        <li>Sous-vêtements usagés</li>
                                        <li>Documents d'immatriculation de véhicules</li>
                                        <li>Lentilles de contact (personnelles)</li>
                                        <li>Adresses e-mail</li>
                                        <li>Propagande offensive</li>
                                        <li>Produits contenant le logo d’une marque de tabac</li>
                                        <li>Messages/Vœux</li>
                                        <li>Stratégies de marketing à niveau unique</li>
                                        <li>Phytothérapie</li>
                                        <li>Médecine ayurvédique et homéopathie</li>
                                        <li>Amulettes</li>
                                        <li>Services d’escorte.</li>
                                        <li>Services de magie noire</li>
                                        <li>Service d’enrichissement rapide.</li>
                                        <li>Programmes d'assurance et carte médicale</li>
                                        <li>Tire-lait, biberons, tasses, serviettes ou sucettes usagés</li>
                                        <li>Médicaments et compléments alimentaires pour animaux domestiques</li>
                                        <li>Services SMS</li>
                                        <li>Programmes d'enrichissement rapide</li>
                                    </ul>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseProhibitedDialog} color="primary">
                                    Ok
                                </Button>
                            </DialogActions>
                        </div>
                    </Dialog>
                    <h4>Offres non réalistes :</h4>
                    <p>
                        Les offres non réalistes ne sont pas autorisées. Taari.co se réserve le droit de juger de ce qui n'est pas réaliste.
                    </p>
                    <h4>Utilisation d'un service de dépôt d'annonces :</h4>
                    <p>
                        L’utilisation d’un service de dépôt d’annonces, au titre duquel des personnes sont payées pour déposer de très grandes quantités d'annonces sur les sites Web, ne sont pas autorisés sur Taari.co. Ces méthodes inondent le site de spams et rendent difficile la recherche d'autres contenus par les utilisateurs.
                    </p>
                    <h4>Recevoir la newsletter Taari.co et être informé de nos promotions et actualités :</h4>
                    <p>
                        En déposant une annonce ou en répondant à une annonce vous nous autorisez à vous envoyer des emails ou des sms pour vous informer et vous aider à mieux vendre et acheter sur Taari.co. Vous disposez à tout moment du droit de vous désinscrire de ce service via les newsletters.
                    </p>
                    <p>
                        Si une annonce est refusée pour non-respect du règlement, l'annonceur pourra la modifier. Taari.co se réserve le droit de juger de la conformité d'une annonce au règlement et à l'esprit du site. Le présent règlement est susceptible d'être modifié et peut être réactualisé en permanence
                    </p>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default DiffusionRules;
