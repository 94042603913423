import axios from 'axios';
import { advertisementLoading } from '../reducers/advertisementReducer';

import {
  apiUrl,
} from '../utils/axios';

export function filterAdvertisements(data){
  return axios.post(`${apiUrl}/get_adverts`, data);
}

export function othersAdvertisements(data){
  return axios.post(`${apiUrl}/get_adverts_by_same_cat`, data);
}

export function createAdvert(advertData){
    return axios.post(`${apiUrl}/set_advert`, advertData);
}

export function updateAdvert(advertData){
  return axios.post(`${apiUrl}/update_advert`, advertData);
}

export function getBrands(type){
  return axios.post(`${apiUrl}/get_auto_brands`, type);
}

export function searchAdvertisements(data){
  return axios.post(`${apiUrl}/general_filter_search`, data);
}

export function searchAdvertisementsNumber(data){
  return axios.post(`${apiUrl}/general_filter_search_number`, data);
}

export function countAdverts(data){
  return axios.post(`${apiUrl}/count_adverts`, data);
}

export function advertViewIncrement(data){
  return axios.post(`${apiUrl}/inc_advert_view`, data);
}


export function getAdvertsCat(data){
  return axios.post(`${apiUrl}/get_adverts_by_parent_cat`, data);
}

export function signalAdvert(data){
  return axios.post(`${apiUrl}/send_signalement`, data);
}

export function getAdvertById(data){
  return axios.post(`${apiUrl}/get_advert_by_id`, data);
}