import React from "react";
import Header from "../../containers/Header";
import Footer from "../Footer";
import './DiscoverTaari.scss';
import logo from "../../imgs/favicon.svg";
import {NavLink} from "react-router-dom";

const DiscoverTaari = () => {
    return (
        <div>
            <Header />
            <div className="discover-taari-container">
                <div className="footer-breadcrumb">
                    <ul>
                        <li>
                            <NavLink to="/">Accueil</NavLink>
                        </li>
                        <li className="separator">/</li>
                        <li>Découvrir taari</li>
                    </ul>
                </div>
                <div className="discover-taari-container-inner">
                    <div className="discover-taari-container-inner-header">
                        <div className="discover-taari-container-inner-header-image">
                            <img src={logo} alt="logo"/>
                        </div>
                        <div className="discover-taari-container-inner-header-text">
                            <h1>NOUS DECOUVRIR</h1>
                        </div>
                    </div>
                    <div className="discover-taari-container-inner-body">
                        <p className="except">
                            Que vous souhaitiez vendre ou acheter un article, chercher ou proposer un service, ou simplement regarder les annonces à la recherche d'une bonne affaire : sur Taari.co vous êtes au bon endroit. Peut-être que la bonne affaire se trouve juste au coin de la rue.
                        </p>
                        <p className="except">
                            Découvrez ci-dessous Taari et apprenez ainsi à mieux utiliser le site.
                        </p>
                        <h2>9 BONNES RAISONS D’UTILISER LES SERVICES TAARI :</h2>
                        <div className="discover-taari-container-inner-body-item">
                            <h3><span>01</span>PLUS UTILISÉ MAIS TOUJOURS PRISÉ</h3>
                            <p>
                                Ne laissez pas les objets que vous n'utilisez plus, prendre la poussière à la cave ! Ce dont vous n'avez plus besoin, peut servir à quelqu'un près de chez vous. En plus vous gagnez un peu d'argent, dont vous pouvez profiter pour vous faire plaisir !
                            </p>
                        </div>
                        <div className="discover-taari-container-inner-body-item">
                            <h3><span>02</span>ABSOLUMENT GRATUIT</h3>
                            <p>
                                Mettre une annonce sur Taari ne coûte rien. Lorsque vous vendez votre article, vous gardez 100% du prix de vente. Une bonne affaire sur Taari est toujours gratuite pour le vendeur autant que pour l'acheteur.
                            </p>
                        </div>
                        <div className="discover-taari-container-inner-body-item">
                            <h3><span>03</span>RAPIDE ET FACILE</h3>
                            <p>
                                Aussitôt dit, aussitôt fait : choisissez une catégorie, un titre et une description. Ajoutez vos images et confirmez votre annonce. C'est fait ! Votre annonce sera contrôlée puis publiée sur Taari. <a href="/advertisements/create">Déjà essayé?</a>
                            </p>
                        </div>
                        <div className="discover-taari-container-inner-body-item">
                            <h3><span>04</span>CHERCHER ET TROUVER</h3>
                            <p>
                                Avec plusieurs dépôts d’annonce par jour, il y'en a pour tous les goûts. Voici comment vous trouverez l'objet que vous cherchez : servez-vous de notre moteur de recherche, utilisez des filtres ou naviguez simplement à travers les différentes <a href="/:countrySlug/search">catégories</a>.
                            </p>
                        </div>
                        <div className="discover-taari-container-inner-body-item">
                            <h3><span>05</span>VOTRE SÉCURITÉ</h3>
                            <p>
                                La protection des utilisateurs face aux cas de fraude est une priorité de Taari. Pour que vous puissiez utiliser le site en toute tranquillité, nous contrôlons chaque annonce avant sa publication. Souhaitez-vous en savoir plus ? Lisez nos <a href="#">conseils sur la sécurité</a>.
                            </p>
                        </div>
                        <div className="discover-taari-container-inner-body-item">
                            <h3><span>06</span>AU COIN DE LA RUE</h3>
                            <p>
                                Chaque jour, des millions de raisons de visiter Taari. - le credo de Taari. Visitez les pages de votre pays, ville, quartier et trouvez de super articles dans près de chez vous. Si ce que vous recherchez ne se trouve pas tout près de chez vous ? Vous pouvez aussi étendre votre recherche aux régions voisines ou à tout le pays.
                            </p>
                        </div>
                        <div className="discover-taari-container-inner-body-item">
                            <h3><span>07</span>TOUJOURS AVEC SOI</h3>
                            <p>
                                Ne ratez jamais une bonne affaire et insérez votre annonce, même lorsque vous êtes dehors. Où que vous soyez, avec votre téléphone ou votre tablette, Taari est là pour vous. Visiter Taari.co sur votre Téléphone portable ou téléchargez no appli.
                            </p>
                        </div>
                        <div className="discover-taari-container-inner-body-item">
                            <h3><span>08</span>INFORMATION & DIVERTISSEMENT</h3>
                            <p>
                                Taari est plus qu'une plateforme de petites annonces c’est une communauté. Sur <a href="https://www.facebook.com/taaricommunity/" target="_blank" rel="noopener noreferrer">Facebook</a>, <a href="https://twitter.com/taaricommunity" target="_blank" rel="noopener noreferrer">Twitter</a>, <a href="https://www.instagram.com/taaricommunity/" target="_blank" rel="noopener noreferrer">Instagram</a>, <a href="https://www.youtube.com/channel/UCPHkGD-KE1iyssKznVkwWPw/featured?view_as=subscriber" target="_blank" rel="noopener noreferrer">YouTube</a> vous trouvez de nombreuses infos intéressantes et amusantes.
                            </p>
                        </div>
                        <div className="discover-taari-container-inner-body-item">
                            <h3><span>09</span>NOUS SOMMES LÀ POUR VOUS</h3>
                            <p>
                                Avez-vous une question ? Ecrivez-nous un e-mail à <a href="mailto:support@taari.co">support@taari.co</a> ou utilisez le formulaire de contact sur notre site/appli. Nous sommes à votre service.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default DiscoverTaari;
