/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
// import { Parallax, Background } from 'react-parallax';

import background from '../../imgs/home-banner-illustration.svg';

const Splash = ({
  openCountryPopup,
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    openCountryPopup();
  };

  return (
    <section className="homepage-splash">
      <div className="homepage-splash-inner">
        {/* <Parallax strength={200}> */}
        {/* <Background className="parallax"> */}
        <img className="homepage-splash-inner-bg" src={background} alt="" />
        {/* </Background> */}
        {/* </Parallax> */}
        <div className="homepage-splash-inner-container">
          <h2 className="homepage-splash-inner-container-title">
            Bienvenue
            <br className="breaker" />
            Dans la communauté <br className="breaker" />Taari
          </h2>
          <h2 className="homepage-splash-inner-container-title-mobile">
            Bienvenue dans la communauté Taari
          </h2>
          <p className="homepage-splash-inner-container-desc">
            Offrez une nouvelle vie à vos bien en déposant des petites annonces gratuites et sans commissions.
          </p>
          <a className="homepage-splash-inner-container-button" href="#" onClick={handleClick} data-text="Commencer">Rejoindre la communauté</a>
        </div>
      </div>
    </section>
  );
};

export default Splash;
