import React from "react";
import './chat.scss';

import Header from "../../containers/Header";
import Footer from "../../components/Footer";

export class Chat extends React.Component {
    render() {
        return (
            <div>
                <Header />
                <div className="page-content">
                    <div className="page-container">
                        <h1>Chat</h1>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
