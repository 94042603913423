import React from "react";
import './advert.scss';
import {IoMdChatboxes, IoMdHeart, IoMdHeartEmpty} from "react-icons/io";
import {TiCamera} from "react-icons/ti";
import {Link} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {setMessage, setOpen} from "../../reducers/snackbarReducer";
import {setAdvert, setSavedAdvertisements} from "../../reducers/advertisementReducer";
import {setAdvertToChat} from '../../reducers/messageReducer';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import {MdClose, MdPhone} from "react-icons/md";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import ReactWhatsapp from 'react-whatsapp';
import { Redirect } from 'react-router-dom';
import {setSavedAdvert, deleteUserSavedAdvert, getUserSavedAdvert} from '../../services/user_adverts';
import Tooltip from "@material-ui/core/Tooltip";
import {staticApiUrl} from "../../utils/axios";
import moment from 'moment';
moment.locale('fr');

export class Advert extends React.Component {
    constructor(props) {
        super(props);
        this.currentCountry = props.currentCountry;
        //console.log(this.currentCountry.slug);
        this.state = {
            add : props.object,
            openSnackBar: false,
            contact: false,
            showSellerNumber: false,
            showChat: false,
            like: false,
            saved: []
        };
        this.setOpen = props.setOpen;
        this.setMessage = props.setMessage;
        this.setAdvert = props.setAdvert;
        this.setAdvertToChat = props.setAdvertToChat;
        this.openContact = this.openContact.bind(this);
        this.closeContact = this.closeContact.bind(this);
        /*this.showNumber = this.showNumber.bind(this);*/
    }

    handleOtherAdvertChange = () => {
        this.setAdvert(this.props.object);
        // window.location.reload();
    };

    /* stopPropagation = (e) => {
         e.stopPropagation();
     }*/

    componentDidMount() {
        // document.addEventListener('click', this.handlePageClick);
        this.loadSavedAdverts();
        window.addEventListener('click', this.stopPropagation);
    }

    openContact() {
        this.setState((state) => ({  contact: true }));
    }

    closeContact() {
        this.setState((state) => ({  contact: false }));
    }

    showNumber () {
        this.setState((state) => ({ showSellerNumber : true }));
    }

    handleFavoriteChange = (e) => {
        const { currentTarget } = e;
        const { checked } = currentTarget;
        if(this.props.currentUser) {
            if (checked) {
                this.setState({like: true});
                var body = {
                    'user_id': this.props.currentUser.id,
                    'advert_id': this.props.object.advert_id,
                    'country_id': this.currentCountry.id,
                };
                setSavedAdvert(body).then(({data}) => {
                    const {RESPONSECODE, RESPONSEMESSAGE} = data
                    if (RESPONSECODE == 200) {
                        this.setMessage(RESPONSEMESSAGE);
                        this.loadSavedAdverts();
                    }
                }).catch(error => {
                    console.warn(error);
                });
            } else {
                console.log('else');
                this.setState({like: false});
                let savedAdvert = null;
                if (this.state.saved.length > 0) {
                    savedAdvert = this.state.saved.find((elm) => {
                        return  (elm.advert_id == this.props.object.advert_id);
                    });
                } else {
                    savedAdvert = this.props.savedAdverts.find((elm) => {
                        return  (elm.advert_id == this.props.object.advert_id);
                    });
                }
                if (savedAdvert) {
                    var body = {
                        'saved_advert_id': savedAdvert.saved_id,
                    };
                    deleteUserSavedAdvert(body).then(({data}) => {
                        const {RESPONSECODE, RESPONSEMESSAGE} = data
                        if (RESPONSECODE == 200) {
                            this.setMessage(RESPONSEMESSAGE);
                            this.loadSavedAdverts();
                        }
                    }).catch(error => {
                        console.warn(error);
                    });
                }
            }
        } else {
            this.setState({like: false});
            this.setMessage('Connecter vous pour sauvegarder cette annonce');
        }
        // this.setOpen(true);
    };

    goToChat = () => {
        this.setAdvertToChat(this.props.object);
        this.setState({showChat: true});
    };

    loadSavedAdverts = () => {
        if(this.props.currentUser) {
            var body = {
                'user_id': this.props.currentUser.id,
                'offset': 0,
                'limit': 15,
                'country_id': this.props.currentCountry.id,
            };
            getUserSavedAdvert(body).then(({data}) => {
                const {RESPONSECODE, RESPONSEDATA} = data
                console.log(data);
                if (RESPONSECODE == 200) {
                    let savedAdvert = RESPONSEDATA.find((elm) => {
                        return  (elm.advert_id == this.props.object.advert_id);
                    });
                    if (savedAdvert) {
                        console.log('savedAdvert');
                        this.setState({like: true});
                    }
                    this.setState({saved: RESPONSEDATA})
                    setSavedAdvertisements(RESPONSEDATA);
                } else if (RESPONSECODE == 204) {
                    this.setState({like: false, saved: []})
                    setSavedAdvertisements([]);
                }
            }).catch(error => {
                console.warn(error);
            }).finally(()=>{
            });
        }
    };

    render() {

        const slugify = () => {
            const regexSpaces = /(\s+|-+|_+)/gm;
            const regexDashes = /-+/gm;
            let name = this.props.object.title.toLowerCase();
            name = name.replace(regexSpaces, '-');
            name = name.replace(regexDashes, '-');
        
            return `${name}`;
        };

        let url = `${staticApiUrl}/adimages/`;

        let filename = "no-image";
        if (this.props.object.images !== undefined && this.props.object.images[0] !== undefined) {
            filename = this.props.object.images[0].filename;
        }

        const REGEX_GROUPS = /(\d)(?=(\d\d\d)+(?!\d))/g
        function formatting(int, delimiter = '\u202f') {
            return int.toString().replace(REGEX_GROUPS, `$1${delimiter}`)
        }

       
        return (
            <div className="add-item">
                {this.state.showChat && <Redirect to="/dashboard/messages" />}
                <span className="advertisements-list-popup" />
                <div className="add-item-inner">
                    {
                        this.props.object.usertype == 1 ?
                            ('') :
                            (
                                <div className="add-item-inner-notice">
                                    <p>Pro</p>
                                </div>
                            )
                    }
                    <div className="add-item-inner-image">
                        <Link to={{
                            pathname: `advertisements/${slugify()}/${this.props.object.advert_id}`,
                            state: this.props.object,
                        }}
                              onClick={() => this.handleOtherAdvertChange()}>

                            { this.props.object.images !== undefined && this.props.object.title !== undefined ?
                                (
                                    <img src={url + filename} alt={this.props.object.title} />
                                ) : (
                                    <img src={url + 'default_image.png'} alt="" />
                                )
                            }
                            { (this.props.object.images !== undefined && this.props.object.images.length > 1) && (
                                <div className="add-item-inner-image-count">
                                    <ul>
                                        <li><TiCamera /></li>
                                        <li>{this.props.object.images.length}</li>
                                    </ul>
                                </div>
                            ) }
                            {
                                this.props.featured == true ? (
                                    <div className="advertisements-separator-ads-inner-item-wrapper-image-une">
                                        <p>A la une</p>
                                    </div>
                                ) : ('')
                            }

                            <div className="add-item-inner-image-description">
                                <p dangerouslySetInnerHTML={{__html: this.props.object.description}}/>
                            </div>
                        </Link>
                    </div>
                    <div className="add-item-inner-text">
                        <div className="add-item-inner-text-call">
                            {
                                (this.props.currentUser && this.props.currentUser.id) !== this.props.object.user_id && (
                                    <Button onClick={this.openContact}>Contacter</Button>
                                )
                            }
                            <Dialog
                                className="dialog"
                                disableBackdropClick
                                disableEscapeKeyDown
                                onClose={this.closeContact}
                                aria-labelledby="simple-dialog-title"
                                open={this.state.contact}>
                                <IconButton aria-label="close" className="closeButton" onClick={this.closeContact}>
                                    <MdClose />
                                </IconButton>
                                <div className="change-container contact">
                                    <DialogTitle id="simple-dialog-title">Contacter {this.props.object.first_name}</DialogTitle>
                                    <DialogContent>
                                        <div className="buttons">
                                            {
                                                (this.props.object.hidden_phone === "0")  ?
                                                    (
                                                        <a href={`tel:` + '+' + this.props.object.phone_indicatif + this.props.object.phone} className="buttons-button orange">
                                                            <MdPhone />
                                                            {"+" + this.props.object.phone_indicatif + this.props.object.phone}
                                                        </a>
                                                    ) : (
                                                        <Tooltip className="hidden-message" title="Ce utilisateur ne souhaite pas afficher son numéro" arrow placement="top">
                                                            <Button className="hidden-phone advertisement-secondary-seller-buttons-inner-button upper orange">
                                                                <MdPhone /> Afficher le numéro
                                                            </Button>
                                                        </Tooltip>
                                                    )
                                            }
                                            <Button className="buttons-button outline" onClick={() => this.goToChat()}>
                                                <IoMdChatboxes />Chat
                                            </Button>
                                            <ReactWhatsapp className="buttons-button green" number={"+" + this.props.object.phone_indicatif + this.props.object.phone} message={`Bonjour ${this.props.advert?.usertype == 1 ? this.props.advert?.first_name : this.props.advert?.pro_rs}, l'article ${this.props.advert?.title} est-il toujours disponible?`}>
                                                Message Whatsapp
                                            </ReactWhatsapp>
                                        </div>
                                    </DialogContent>
                                </div>
                            </Dialog>
                        </div>
                        <div className="add-item-inner-text-title">
                            <h3>
                                <Link to={{
                                    pathname: `advertisements/${slugify()}/${this.props.object.advert_id}`,
                                    state: this.props.object,
                                }}
                                      onClick={() => this.handleOtherAdvertChange()}>
                                    {this.props.object.title}
                                </Link>
                            </h3>
                        </div>
                        <div className="add-item-inner-text-price">
                            {
                                (this.props.object.price == null || this.props.object.price == "0") ?
                                    (
                                        <p>
                                            Me contacter
                                        </p>
                                    ) : (
                                        <p>
                                            <span>{formatting(this.props.object.price)} {this.currentCountry && this.currentCountry.currency[0].slug}</span>
                                            {
                                                this.props.object.category_id == "13" ||
                                                this.props.object.category_id == "65" ||
                                                this.props.object.category_id == "66"||
                                                this.props.object.category_id == "67" ||
                                                this.props.object.category_id == "148" ||
                                                this.props.object.category_id == "149" ||
                                                this.props.object.category_id == "150" ||
                                                this.props.object.category_id == "151" ||
                                                this.props.object.category_id == "105" ||
                                                this.props.object.category_id == "106" ||
                                                this.props.object.category_id == "107" ||
                                                this.props.object.category_id == "108" ||
                                                this.props.object.category_id == "109" ||
                                                this.props.object.category_id == "174" ||
                                                this.props.object.category_id == "110" ||
                                                this.props.object.category_id == "111" ||
                                                this.props.object.category_id == "112" ||
                                                this.props.object.category_id == "113" ||
                                                this.props.object.category_id == "114" ||
                                                this.props.object.category_id == "115" ||
                                                this.props.object.category_id == "116" ||
                                                this.props.object.category_id == "162" ||
                                                this.props.object.category_id == "163" ||
                                                this.props.object.category_id == "164" ||
                                                this.props.object.category_id == "165" ||
                                                this.props.object.category_id == "166" ||
                                                this.props.object.category_id == "167" ||
                                                this.props.object.category_id == "168" ||
                                                this.props.object.category_id == "169" ||
                                                this.props.object.category_id == "170" ||
                                                this.props.object.category_id == "172" ||
                                                this.props.object.category_id == "173" ||
                                                this.props.object.category_id == "175" ||
                                                this.props.object.category_id == "176" ? (
                                                    (this.props.object.pricing_type !== null) ? (
                                                        <span className="pricing">/ {this.props.object.pricing_type[0].name}</span>
                                                    ) : ('')
                                                ) : ('')
                                            }
                                        </p>
                                    )
                            }
                            {
                                (this.props.object.type_advert === "3" ||  this.props.object.type_advert === "4") ? (
                                    <span className="free-object">{this.props.object.type_advert_name}</span>
                                ) : ('')
                            }
                            {
                                ((this.props.object.price !== null || this.props.object.price !== "0") && (this.props.object.negotiable === "1")) ? (
                                    <p className="negociable">Négociable</p>
                                ) : ('')
                            }
                        </div>
                        <div className="add-item-inner-text-location">
                            <div className="add-item-link-text-location-left">
                                <ul>
                                    <li>
                                        {this.props.object.city_name} { this.props.object.zone_name.name !== '' ? (' / ' + this.props.object.zone_name.name) : ''}
                                    </li>
                                </ul>
                            </div>
                            <div className="add-item-inner-text-location-right">
                                <ul>
                                    <li>{moment.utc(this.props.object.created_at).format('DD-MM-YYYY HH:MM')} </li>
                                </ul>
                            </div>
                        </div>
                        <div className="add-item-inner-text-favorite">
                            <input type="checkbox" checked={this.state.like} id={`advertisements--${this.props.object.advert_id}`} onChange={this.handleFavoriteChange} />
                            <label htmlFor={`advertisements--${this.props.object.advert_id}`}>
                                <IoMdHeartEmpty className="switch-off" />
                                <IoMdHeart className="switch-on" />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        open: state.snackbar.open,
        message: state.snackbar.message,
        currentCountry: state.country.currentCountry,
        currentUser : state.user.currentUser,
        savedAdverts: state.advertisement.savedAdverts,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setOpen: (open) => {
            dispatch(setOpen({ open : open}));
        },
        setMessage: (message) => {
            dispatch(setMessage({ message : message}));
        },
        setAdvert: (advert) => {
            dispatch(setAdvert({ advert : advert}));
        },
        setAdvertToChat: (advert) => {
            dispatch(setAdvertToChat({ advertToChat : advert}));
        },
        setSavedAdvertisements: (data) => {
            dispatch(setSavedAdvertisements({ savedAdverts : data}));
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Advert);
