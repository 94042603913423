import React from "react";
import './shopSkeleton.scss';
import Skeleton from "@material-ui/lab/Skeleton";

export class ShopSkeleton extends React.Component {
    constructor(props) {
        super(props);
        this.count = props.count;
        this.components = [];
        for(let i = 0; i < this.count; i++){
            this.components.push(
                <div className="skeleton-shop-item">
                    <div className="skeleton-shop-item-image">
                        <Skeleton animation="wave"  variant="rect" width="100%" height={102} />
                    </div>
                    <div className="skeleton-shop-item-infos">
                        <div className="skeleton-shop-item-infos-title">
                            <Skeleton animation="wave"  width="100%" height={40} spacing={6} />
                        </div>
                        <div className="skeleton-shop-item-infos-description">
                            <Skeleton animation="wave"  width="100%" height={30} spacing={6} />
                        </div>
                        <div className="skeleton-shop-item-infos-list">
                            <Skeleton animation="wave"  width="30%" height={30} spacing={6} style={{marginRight: 20}} />
                            <Skeleton animation="wave"  width="30%" height={30} spacing={6} />
                        </div>
                    </div>
                </div>

            );
        }

    }


    render() {

        return (<div className="skeleton-shop-wrapper">
            {this.components.map((component, index)=>{
                return <div key={"composant-"+index}> { component } </div>
            })}
        </div>);
    }


}