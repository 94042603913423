import React from "react";
import './joinus.scss';
import Header from "../../containers/Header";
import Footer from "../Footer";

const JoinUs = () => {
    return (
        <div className="join-content">
            <Header/>
            <h1>Rejoignez-nous</h1>
            <Footer/>
        </div>
    )
}

export default JoinUs;
