import React, {useEffect, useState} from "react";
import {FormControl, MenuItem, Select} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import './simpleList.scss';
import {getAllCategories} from "../../services/category";
import {staticApiUrl} from "../../utils/axios";

const SimpleCategoriesList = (props) => {

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(props?.item);


    const loadCategories = () => {
        getAllCategories().then(({ data }) => {
            let { RESPONSEDATA } = data;
            let tab = [];
            for (let i = 0; i < RESPONSEDATA.length; i++) {
                var item = RESPONSEDATA[i];
                var obj = {};
                obj.id = item.id;
                let url = `${staticApiUrl}/iconimages/`;
                let filename = "no-image";
                if (item.icon[0] !== undefined) {
                    filename = url + item.icon[0].filename;
                }
                obj.image = filename;
                obj.title = item.title_fr;
                let tab2 = [];
                item.sub.map(elt2 => {
                    tab2.push({
                        id: elt2.id,
                        title: elt2.title_fr,
                        parentId: item.id
                    })
                })
                obj.items = tab2;
                tab.push(obj);
            }
            setCategories(tab);
        })
    }

    useEffect(() => {
        loadCategories();
    }, [])

    const handleClose = (e) => {
        setSelectedCategory(e.target.value)
        if(props.setItem){
            props.setItem(e.target.value) 
        }
        props.onClose(e.target.value);
    }

   


    //console.log("SIMPLE C", props.item, selectedCategory)

    return(
        <div className="simple-list">
            <FormControl variant="outlined" className="simple-list-category">
                <InputLabel id="category-label">Choisir une catégorie</InputLabel>
                <Select
                    onChange={handleClose}
                    value={props.parent?props.item:selectedCategory}
                    name="categories"
                    id="categories"
                    labelId="category-label"
                    variant="outlined"
                    size="small"
                    className="categries-list">
                    <MenuItem value="0">
                        Toute les catégories
                    </MenuItem>
                    { categories.map((element, index) => {
                        return (
                            <MenuItem value={element} key={element.title}>
                                <img src={element.image} alt=""/>
                                { element.title }
                            </MenuItem>)
                    })}
                </Select>
            </FormControl>
        </div>
    );
}

export default SimpleCategoriesList;