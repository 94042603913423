import {
  saveAllCategories,
} from "../reducers/categoryReducer";

import {
  getAllCategories,
} from '../services/category'

const logMiddleware = (store) => (next) => (action) => {

    switch (action.type) {
      case 'category/getAllCategories':
        getAllCategories().then(({ data }) => {
          let { RESPONSEDATA } = data;
          let tab = [];
            for (let i = 0; i < RESPONSEDATA.length; i++) {
                var item = RESPONSEDATA[i];
                var obj = {};
                obj.id = item.id;
                obj.title = item.title_fr;
                let tab2 = [];
                item.sub.map(elt2 => {
                    tab2.push({
                        id: elt2.id,
                        title: elt2.title_fr,
                        parentId: item.id
                    })
                })
                obj.items = tab2;   
                tab.push(obj);
            }
          store.dispatch(saveAllCategories({ categories : tab }));
        }).catch((e)=>{
          console.error(e);
        })
        break;

      default:
        break;
    }
  
  next(action);
};

export default logMiddleware;
