import React from 'react';

const Categories = ({counts}) => {
    const handleChangeCategory = (e, category) => {
        e.preventDefault();
    };

    return (
        <ul className="advertisements-categories">
            {
                counts && (
                    <li
                        className="advertisements-categories-item advertisements-categories-item-active"
                        onClick={(e) => handleChangeCategory(e, 'all')}
                    >
                        Toutes, {counts.total_adverts}
                    </li>
                )
            }
            {
                counts && (
                    <li
                        className="advertisements-categories-item"
                        onClick={(e) => handleChangeCategory(e, 'user')}
                    >
                        Particulier, {counts.total_individual_adverts}
                    </li>
                )
            }
            {
                counts && (
                    <li
                        className="advertisements-categories-item"
                        onClick={(e) => handleChangeCategory(e, 'pro')}
                    >
                        Professionnel, {counts.total_pro_adverts}
                    </li>
                )
            }
            {
                counts && (
                    <li
                        className="advertisements-categories-item"
                        onClick={(e) => handleChangeCategory(e, 'search')}
                    >
                        Ils recherchent, {counts.total_theysearch_adverts}
                    </li>
                )
            }
            {
                counts && (
                    <li
                        className="advertisements-categories-item"
                        onClick={(e) => handleChangeCategory(e, 'purpose')}
                    >
                        Trocs, {counts.total_troc_adverts}
                    </li>
                )
            }
            {
                counts && (
                    <li
                        className="advertisements-categories-item"
                        onClick={(e) => handleChangeCategory(e, 'free')}
                    >
                        Objets gratuits, {counts.total_free_adverts}
                    </li>
                )
            }
        </ul>
    );
};

export default Categories;
