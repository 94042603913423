import React, {useEffect, useState} from "react";
import './ShopDetailsFilter.scss';
import {useSelector} from "react-redux";
import {citiesToDialog} from "../../../services/normalizers";
import {Button, CircularProgress, FormControl, MenuItem, Select} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {SimpleDialog} from "../../SimpleDialog/simpleDialog";
import InputLabel from "@material-ui/core/InputLabel";
import {getCategorySubs} from "../../../services/category";

var city_id = "";
var zone_id = "";
var category_id = "";

const ShopDetailsFilter = (props) => {

    const currentCountry = useSelector(state => state.country.currentCountry);
    const cities = citiesToDialog(currentCountry.cities);
    const [openCity, setOpenCity] = useState(false);
    const [elementCity, setElementCity] = useState(null);
    const [categoryName, setCategoryname] = useState('');
    const [category, setCategory] = useState(null);


    const handleClickOpenCity = ()=>{
        setOpenCity(true);
    }
    const handleClickCloseCity = ()=>{
        setOpenCity(false);
      }

    const handleCloseCity = (element, city) => {
        if (city === undefined) {
            props.reload();
        } else {
            setOpenCity(false);
            setElementCity(element ? element : city);
            if (element) {
                zone_id = element.id;
                city_id = city.id
            } else {
                zone_id = null;
                city_id = city.id
            }
            goToSearch(false);
        }
    };

    const [sub, setSub] = useState('');
    const [subCategories, setSubCategories] = useState([]);

    const loadSubs = () => {
        getCategorySubs(props.categoryId).then(({data}) => {
            setCategoryname(data[0].title_fr);
            var tab = [{id: 0, title_fr: data[0].title_fr}, ...data[0].sub]
            console.warn(tab);
            setSubCategories(tab);
        }).catch((e) => {
            console.error(e);
        }).finally((e) => {});
    }

    useEffect(() => {
        loadSubs();
    }, [props.categoryId]);

    const handleChangeSub = (event) => {
        setSub(event.target.value);
        category_id = event.target.value;
        if (category_id == 0) {
            props.reload();
        } else {
            goToSearch(false);
        }
    };


    
  const resetFilter = () => {
    category_id = ""
    zone_id = "";
    city_id = "";
    setElementCity(null)
    setCategoryname('Categorie')
    setSub('')
    //props.citySelected(null)
    //setCategory(null)
    if(props.setSearchShopAdvertsNumber){
      props.setSearchShopAdvertsNumber(null)
    }
    if(props?.searchAd){
        props.searchAd(
          {
              'limit': 15,
              'offset': 0,
              'country_id': currentCountry.id,
              'city_id': '',
              'zone_id': '',
              'category_id': ''
            }
      )
      }
  

}


    const goToSearch = (type) => {
        var obj = {
          'limit': 15,
          'offset': 0,
          'country_id': currentCountry.id,
          'city_id': city_id,
          'zone_id': zone_id,
          'category_id': category_id
        }
        if (type !== false) {
          props.searchAd(obj);
        } else {
          props.filterAd(obj);
        }
      }

    return (
        <div className="shop-details-filter">
            <div className="shop-details-filter-sub-categories">
                <FormControl variant="outlined" className="simple-list-category">
                    <InputLabel id="category-label">
                        {
                            categoryName
                        }
                    </InputLabel>
                    <Select
                        name="categories"
                        id="categories"
                        labelId="category-label"
                        placeholder="Choisir une categorie"
                        variant="outlined"
                        size="small"
                        onChange={handleChangeSub}
                        value={sub}
                        className="categries-list">
                        {
                            subCategories ? subCategories.map(sub => {
                                return (
                                    <MenuItem key={sub.id} value={sub.id}>{sub.title_fr}</MenuItem>
                                )
                            }) : ('')
                        }
                    </Select>
                </FormControl>
            </div>
            <div className="shop-details-filter-cities">
                <Button onClick={handleClickOpenCity}>
                    <span>{elementCity ? elementCity.title : "Ville ou quartier"}</span>
                    <ArrowDropDownIcon/>
                </Button>
                <SimpleDialog prompt={"Choisir une ville ou un quartier"} data={cities} defaultSelection={"Toutes les villes"} open={openCity} onClose={handleCloseCity} onCancel={handleClickCloseCity} />
            </div>
            <div className="shop-details-filter-submit">
                <Button
                    onClick={goToSearch}
                    variant="contained"
                    className="search-filters-submit"
                    disableElevation>
                    {props.searchShopAdvertsNumber !== null ? `Afficher ${props.searchShopAdvertsNumber} résultats` :  'Recherche'}
                    {
                        props.searching ? (<CircularProgress />) : ('')
                    }
                </Button>
                { props.searchShopAdvertsNumber !== null ?

                <Button
                    onClick={resetFilter}
                    variant="contained"
                    className="search-filters-reset"
                    disableElevation>
                        {'Réinitialiser'}
                    {
                        props.searching ? (<CircularProgress />) : ('')
                    }
                </Button>
:null
}
            </div>
        </div>
    )
}

export default ShopDetailsFilter;