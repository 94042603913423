import React from "react";
import '../tutos/tutos.scss';
import Header from "../../../containers/Header";
import { NavLink } from "react-router-dom";
import tuto from "../../../imgs/tutorials-colored.svg";
import Footer from "../../Footer";

const Tutorials = () => {
    return (
        <div>
            <Header/>
            <div className="tuto-container">
                <div className="tuto-container-breadcrumb">
                    <ul>
                        <li>
                            <NavLink to="/">Accueil</NavLink>
                        </li>
                        <li className="separator">/</li>
                        <li>
                            <NavLink to="/aide-et-support">Aide et support</NavLink>
                        </li>
                        <li className="separator">/</li>
                        <li>Tutoriels et vidéos</li>
                    </ul>
                </div>
                <div className="tuto-container-header">
                    <img src={tuto} alt=""/>
                    <h1>Tutoriels et vidéos</h1>
                </div>
                <div className="tuto-container-body">
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Tutorials;
