import React from "react";
import fr from 'react-phone-input-2/lang/fr.json';
import {Checkbox, FormControlLabel, TextField} from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import {MdRemoveRedEye} from "react-icons/md";
import {useSelector} from "react-redux";
import {useFormik} from "formik";

const ThirdPart = ({values, handleChange}) => {


    //const [state, setState] = React.useState({});

    const currentUser = useSelector(state => state.user.currentUser);


    // On définit de manière dynamique le placeholder des inputs
    // des téléphones (la lib utilisée ne le permet pas...)
    setTimeout(() => {
        const phoneInputContainers = document.querySelectorAll(':scope .react-tel-input');
        phoneInputContainers.forEach((container) => {
            const phoneInput = container.querySelector('input');
            const { placeholder } = phoneInput;
            const inputLabel = container.querySelector('.flag-dropdown');
            inputLabel.dataset.placeholder = placeholder;
        });
    });

    const togglePassword = ({ currentTarget }) => {
        currentTarget.classList.toggle('active');
        const passwordInput = document.getElementById('password');

        if (currentTarget.classList.contains('active')) {
            passwordInput.type = 'text';
        }
        else {
            passwordInput.type = 'password';
        }
    }

    return (
        <div>
            <div className="createadvertisement-form-thirdpart">
                <h3 className="createadvertisement-form-section-title">
                    <span className="createadvertisement-form-section-title-bubble">3</span>
                    Vos informations
                </h3>
                <div className="createadvertisement-form-thirdpart-container">
                    <div className="createadvertisement-form-thirdpart-container-inner">
                        <div className="name-input-item">
                            <TextField
                                label="Prénom"
                                type="text"
                                variant="outlined"
                                className="firstname"
                                size="small"
                                disabled={true}
                                defaultValue={currentUser.first_name}
                            />
                        </div>
                        <div className="name-input-item">
                            <TextField
                                label="Email"
                                type="text"
                                variant="outlined"
                                className="email"
                                size="small"
                                disabled={true}
                                defaultValue={currentUser.email}
                            />
                        </div>
                        <div className="phone-input-item">
                            <PhoneInput
                                localization={fr}
                                country={'tg'}
                                containerClass={'phone-input'}
                                defaultMask={'.. .. .. .. ..'}
                                preferredCountries={['tg', 'cd', 'ml', 'sn', 'bj', 'ne', 'bf', 'ci']}
                                enableSearch={true}
                                placeholder="Numéro de téléphone"
                                disabled={true}
                                value={"+" + currentUser.phone_indicatif + currentUser.phone}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="hidden_phone"
                                        id="hidden_phone"
                                        color="primary"
                                        onChange={handleChange}
                                        value={values.hidden_phone}
                                    />
                                }
                                label={<label htmlFor="hidden_phone">Cacher ce numero sur l'annonce</label>}
                            />
                        </div>
                        <div className="phone-input-item">
                            <PhoneInput
                                localization={fr}
                                country={'tg'}
                                containerClass={'whatsapp-input'}
                                defaultMask={'.. .. .. .. ..'}
                                preferredCountries={['tg', 'cd', 'ml', 'sn', 'bj', 'ne', 'bf', 'ci']}
                                enableSearch={true}
                                placeholder="Numéro de téléphone Whatsapp"
                                disabled={true}
                                value={"+" + currentUser.whatsapp_indicatif + currentUser.whatsapp}
                            />
                        </div>

                        {/* <div className="password-input-item">
                            <TextField
                                label="Mot de passe"
                                type="password"
                                variant="outlined"
                                size="small"
                                id="password"
                                disabled="disabled"
                                defaultValue="password-validation"
                            />
                            <span className="show-password" onClick={togglePassword}>
                                <MdRemoveRedEye />
                            </span>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThirdPart;
