import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  opened: false,
};


export const headerSlice = createSlice({
  name: 'header',
  initialState : initialState,
  reducers :{
    toggleHeader : state => {
      state.opened = !state.opened
    },

    closeHeader : state => {
      state.opened = false
    },

    openHeader : state => {
      state.opened = true
    }

  }
});

export const {toggleHeader, closeHeader, openHeader} = headerSlice.actions;
export default headerSlice.reducer;
