import { connect } from 'react-redux';
import Search from '../components/Search';
import {
  saveBrands,
  setSearchResult,
  setIsSearching,
  setSearchObject,
  setSearchObjectName,
  setSearchResultNumber,
    setShowSearchDialog
} from '../reducers/advertisementReducer';
import {
  saveAllCategories,
  categorySelected
} from '../reducers/categoryReducer';
import {citySelected} from '../reducers/countryReducer';
const mapStateToProps = state => ({
  currentCountry: state.country.currentCountry,
  brands: state.advertisement.brands,
  searchResult: state.advertisement.searchResult,
  isSearching: state.advertisement.isSearching,
  searchObject: state.advertisement.searchObject,
  searchResultNumber: state.advertisement.searchResultNumber,
  showSearchDialog: state.advertisement.showSearchDialog,
});

const mapDispatchToProps = dispatch => ({
  saveBrands: (data) => {
    dispatch(saveBrands({ brands : data.brands}));
  },
  saveCategories: (data) => {
    dispatch(saveAllCategories({ categories : data}));
  },
  setSearchResult: (data) => {
    dispatch(setSearchResult({ searchResult : data}));
  },
  setIsSearching: (data) => {
    dispatch(setIsSearching({ isSearching : data}));
  },
  setSearchObject: (data) => {
    dispatch(setSearchObject({ searchObject : data}));
  },
  setSearchObjectName: (data) => {
    dispatch(setSearchObjectName({ searchObjectName : data}));
  },
  setSearchResultNumber: (data) => {
    dispatch(setSearchResultNumber({ searchResultNumber : data}));
  },
  categorySelected: (data) => {
    dispatch(categorySelected({ category : data}));
  },
  citySelected: (data) => {
    dispatch(citySelected({ city : data}));
  },
  setShowSearchDialog: (data) => {
    dispatch(setShowSearchDialog({ showSearchDialog: data}));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);