import React from 'react';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import Header from '../../containers/Header';
/*import Footer from '../Footer';*/
import SignupForm from '../../containers/SignupForm';
import SignupProForm from '../../containers/SignupProForm';

import './Signup.scss';

const Signup = () => {
  return (
    <>
      <Header />
      <main className="signup">
        <Switch>
          <Route path={`/signup/individual`} component={SignupForm} />
          <Route path={`/signup/professionnal`} component={SignupProForm} />
          <Route>
            <Redirect to="/not-found" />
          </Route>
        </Switch>
      </main>
      {/*<Footer />*/}
    </>
  );
};

export default Signup;
