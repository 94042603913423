import { connect } from 'react-redux';
import Header from '../components/Homepage/Header';
import { openCountryPopup } from '../reducers/countryReducer';
import { closeHeader, toggleHeader } from '../reducers/headerReducer';


const mapStateToProps = state => ({
  header: state.header,
  country: state.country.currentCountry,
});

const mapDispatchToProps = dispatch => ({
  openCountryPopup: () => {
    dispatch(openCountryPopup());
  },
  toggleHeader: () => {
    dispatch(toggleHeader());
  },
  closeHeader: () => {
    dispatch(closeHeader());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);