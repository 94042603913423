import { applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import logMiddleware from './logMiddleware';
import countryMiddleware from './countryMiddleware';
import categoryMiddleware from './categoryMiddleware';
import advertisementMiddleware from './advertisementMiddleware';

const middlewares = composeWithDevTools(
  applyMiddleware(
    logMiddleware,
    countryMiddleware,
    categoryMiddleware,
    advertisementMiddleware,
  ),
);

export default middlewares;
