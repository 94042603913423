const advertisements = [
  {
    id: 1,
    title: 'Vente Chrysler 300',
    content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aut quaerat a unde ullam? Adipisci accusantium nostrum corporis sed iste dolore quod similique! Blanditiis nesciunt reprehenderit eaque consequatur laborum mollitia, rerum, nostrum sint repudiandae sunt cupiditate facere accusantium maiores? Deserunt possimus impedit cum ipsum ea minus, asperiores totam adipisci odio quam!',
    price: 'Me contacter',
    free: false,
    districtId: 0,
    cityId: 0,
    userId: 0,
    categoryId: 0,
    adImages: [
      {
        lowqImage: 'https://picsum.photos/200?random=1',
        image: 'https://picsum.photos/200?random=1',
      },
      {
        lowqImage: 'https://picsum.photos/200?random=2',
        image: 'https://picsum.photos/200?random=2',
      },
    ],
    createdAt: '2020-06-17T12:12:09+02:00',
  },
  {
    id: 2,
    title: 'À louer villa pour courts et longs séjours',
    content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aut quaerat a unde ullam? Adipisci accusantium nostrum corporis sed iste dolore quod similique! Blanditiis nesciunt reprehenderit eaque consequatur laborum mollitia, rerum, nostrum sint repudiandae sunt cupiditate facere accusantium maiores? Deserunt possimus impedit cum ipsum ea minus, asperiores totam adipisci odio quam!',
    price: '300 000 FCFA',
    free: false,
    districtId: 0,
    cityId: 0,
    userId: 0,
    categoryId: 0,
    adImages: [
      {
        lowqImage: 'https://picsum.photos/200?random=3',
        image: 'https://picsum.photos/200?random=3',
      },
      {
        lowqImage: 'https://picsum.photos/200?random=4',
        image: 'https://picsum.photos/200?random=4',
      },
      {
        lowqImage: 'https://picsum.photos/200?random=5',
        image: 'https://picsum.photos/200?random=5',
      },
    ],
    createdAt: '2020-06-17T12:12:09+02:00',
  },
  {
    id: 3,
    title: 'Vente toyota highlander full options',
    content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aut quaerat a unde ullam? Adipisci accusantium nostrum corporis sed iste dolore quod similique! Blanditiis nesciunt reprehenderit eaque consequatur laborum mollitia, rerum, nostrum sint repudiandae sunt cupiditate facere accusantium maiores? Deserunt possimus impedit cum ipsum ea minus, asperiores totam adipisci odio quam!',
    price: '3 000 000 FCFA',
    free: false,
    districtId: 0,
    cityId: 0,
    userId: 0,
    categoryId: 0,
    adImages: [
      {
        lowqImage: 'https://picsum.photos/200?random=6',
        image: 'https://picsum.photos/200?random=6',
      },
    ],
    createdAt: '2020-06-17T12:12:09+02:00',
  },
];

export const getAllAdvertisements = () => advertisements;
export const getAdvertisementById = (advertisementId) => advertisements.find((advertisement) => advertisement.id === parseInt(advertisementId, 10));

export default {
  getAllAdvertisements,
  getAdvertisementById,
};
