import React from "react";
import './NewPassword.scss';
import Header from "../../../containers/Header";
import Button from "@material-ui/core/Button";
import {TextField} from "@material-ui/core";
import { connect } from 'react-redux';
import VisibilityIcon from "@material-ui/icons/Visibility";
import {newPassword} from "../../../services/users";
import {setBackColor, setMessage, setOpen} from "../../../reducers/snackbarReducer";

import {Redirect} from 'react-router-dom';

class NewPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password1: '',
            password2: '',
            confirm: false,
        }
        this.setOpen = props.setOpen;
        this.setMessage = props.setMessage;
        this.setBackColor = props.setBackColor;
        this.preLoginData = props.preLoginData;
        this.togglePassword = this.togglePassword.bind(this);
    }

    componentDidMount() {
    }

    handlePasswordChange = (e, type) => {
        this.setState({[type]: e.target.value});
    };

    changePassword = () => {
        const {password1, password2} = this.state;
        var userData = {
            user_id: this.preLoginData.user_id,
            new_password: password1,
        }
        // if (password1 === '' || password2 === '') {
        //     this.setMessage('Veuillez saisir un mot de passe');
        //     // this.setOpen(true);
        // } 
        if (password1 !== password2) {
            this.setMessage('Veuillez saisir des mot de passe identiques');
            this.setBackColor('red');
            // this.setOpen(true);
        } else {
            newPassword(userData).then(({data}) => {
                this.setMessage(data.RESPONSEMESSAGE);
                this.setOpen(true);
                this.setBackColor('green');
                this.setState((state) => ({ confirm : true }));
            }).catch(error => {
                // this.setMessage({message : data.RESPONSEMESSAGE});
                this.setOpen(true);
            });
        }
    }

    togglePassword = ({ currentTarget }) => {
        currentTarget.classList.toggle('active');
        const passwordInput1 = document.getElementById('newPassword1');

        const passwordInput2 = document.getElementById('newPassword2');

        if (currentTarget.classList.contains('active')) {
            passwordInput1.type = 'text';
            passwordInput2.type = 'text';
        }
        else {
            passwordInput1.type = 'password';
            passwordInput2.type = 'password';
        }
    }

    render() {
        return (
            <div>
                <Header />
                {
                    this.state.confirm && <Redirect to='/login' />
                }
                <div className="new-password">
                    <div className="new-password-form">
                        <h2>Saisissez votre nouveau mot de passe</h2>
                        <div className="new-password-form-item">
                            <TextField
                                name="newPassword1"
                                id="newPassword1"
                                label="Nouveau mot de passe"
                                type="password"
                                variant="outlined"
                                className="oldPassword"
                                size="small"
                                value={this.state.password1}
                                onChange={(e) => this.handlePasswordChange(e, 'password1')}
                            />
                            <span className="show-password" onClick={this.togglePassword}><VisibilityIcon /></span>
                        </div>
                        <div className="new-password-form-item">
                            <TextField
                                name="newPassword2"
                                id="newPassword2"
                                label="Confirmer le mot de passe"
                                type="password"
                                variant="outlined"
                                className="oldPassword"
                                size="small"
                                value={this.state.password2}
                                onChange={(e) => this.handlePasswordChange(e, 'password2')}
                            />
                            <span className="show-password" onClick={this.togglePassword}><VisibilityIcon /></span>
                        </div>
                        <Button className="new-password-form-button" onClick={this.changePassword}>Valider</Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    preLoginData: state.user.preLoginData,
});

const mapDispatchToProps = dispatch => ({
    setOpen: (open) => {
        dispatch(setOpen({ open : open}));
    },
    setMessage: (message) => {
        dispatch(setMessage({ message : message}));
    },
    setBackColor: (backColor) => {
        dispatch(setBackColor({backColor: backColor}))
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
