import { createSlice } from '@reduxjs/toolkit';

const initialState = {
      activePanel: 'country',
      countries: [],
      cities: [],
      districts: [],

      popups: {
        complete: {
          opened: false,
        },
        country: {
          opened: false,
        },
        dropdown: {
          opened: false,
        },
      },

      needToRedirect: false,

      currentCountry: null,
      currentCity: null,
      currentDistrict: null,

      countriesLoaded: false,
    };


export const countrySlice = createSlice({
  name : 'country',
  initialState : initialState,
  reducers : {
    countryLoading : (state, action) => {
        state.countriesLoaded = action.payload.status;
        state.countries = (action.payload.status) ? state.countries : [];
    },

    resetCountry : (state, action) => {
      state.currentCountry = null;
    },
    
    getAllCountries : (state, action) => {

    },

    getAllCities : (state, action) => {

    },

    changeCountryActivePanel : (state, action) => {
      state.activePanel = action.payload.activePanel;
      state.currentCountry = (action.payload.reset) ? null : state.currentCountry;
      state.currentCity = (action.payload.reset) ? null : state.currentCity;
    },

    countrySelected : (state, action) => {
      state.currentCountry = action.payload.country;
      state.currentCity = null;
      state.activePanel = 'city';
    },

    saveCities : (state, action) => {
      state.cities = [ ...action.payload.cities ]
    },

    saveCountries : (state, action) => {
      state.countries = [ ...action.payload.countries ];
    },

    openCompleteCountryPopup : (state, action) => {
      state.popups.complete.opened = true
    },

    closeCompleteCountryPopup : (state, action) => {
      state.popups.complete.opened = false
    },

    openCountryPopup : (state, action) => {
      state.popups.country.opened = true;
    },

    closeCountryPopup : (state, action) => {
      state.popups.country.opened = false
    },

    openCountryDropdown : (state, action) => {
      state.popups.dropdown.opened = true
    },

    closeCountryDropdown : (state, action) => {
      state.popups.country.opened = false
    },
    citySelected : (state, action) => {
      state.currentCity = action.payload.city;
    },
  }
});

export const { openCompleteCountryPopup, openCountryPopup, closeCompleteCountryPopup, closeCountryPopup, openCountryDropdown,
                closeCountryDropdown, changeCountryActivePanel, countrySelected, resetCountry, countryLoading, saveCountries,
                saveCities, getAllCountries, getAllCities, citySelected } = countrySlice.actions;
export default countrySlice.reducer;
