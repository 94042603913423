import React, {useState, useRef} from "react";
import '../ShopFilter/ShopFilter.scss';
import SimpleCategoriesList from "../../Search/simple-categories-list";
import SearchIcon from '@material-ui/icons/Search';
import {Button, CircularProgress} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {SimpleDialog} from "../../SimpleDialog/simpleDialog";
import {citiesToDialog} from "../../../services/normalizers";
import {useSelector} from "react-redux";

var city_id = "";
var zone_id = "";
var search_text = "";
var category_id = ""

const ShopFilter = (props) => {

  const currentCountry = useSelector(state => state.country.currentCountry);
  const cities = citiesToDialog(currentCountry.cities);
  const [openCity, setOpenCity] = useState(false);
  const [elementCity, setElementCity] = useState(null);
  const [queryString, setQueryString] = useState('');
  const [category, setCategory] = useState(null);



  const handleClickOpenCity = ()=>{
    setOpenCity(true);
  }

  const handleClickCloseCity = ()=>{
    setOpenCity(false);
  }

  const handleCloseCity = (element, city) => {
    if (city === undefined) {
      props.citySelected(null);
      props.reload();
      
    } else {
      setOpenCity(false);
      setElementCity(element ? element : city);
      if (element) {
        zone_id = element.id;
        city_id = city.id
      } else {
        zone_id = null;
        city_id = city.id
      }
      props.citySelected(element ? element : city);
      goToSearch(false);
    }
  };

  const handleTextChange = (e) => {
    search_text = e.target.value;
    setQueryString(search_text)
    setTimeout(() => {
      goToSearch(false);
    }, 500);
  };

  const resetFilter = () => {
        category_id = ""
        zone_id = "";
        search_text = "";
        city_id = "";
        setElementCity(null)
        props.citySelected(null)
        setCategory(null)
        setQueryString('')
        if(props.setSearchShopNumber){
          props.setSearchShopNumber(null)
        }
        if(props?.searchAd){
          props.searchAd(
            {
                'limit': 15,
                'offset': 0,
                'country_id': currentCountry.id,
                'city_id': '',
                'zone_id': '',
                'search_text': '',
                'category_id': ''
              }
        )
        }
  
  }


  const goToSearch = (type) => {
    var obj = {
      'limit': 15,
      'offset': 0,
      'country_id': currentCountry.id,
      'city_id': city_id,
      'zone_id': zone_id,
      'search_text': search_text,
      'category_id': category_id
    }
    //console.log('objet', obj, type)
    //console.log('type', type)
    if (type !== false) {
      //console.log('log 1')
      props.searchAd(obj);
    } else {
      //console.log('log 2')

      props.filterAd(obj);
    }
  }

  const handleCloseCategory = (element) => {
    if (element == 0) {
      props.citySelected(null);
      props.reload();
    } else {
      category_id = element.id;
      goToSearch(false);
    }
};

  return (
    <div className="shop-filters">
      <div className="shop-filters-container">
          <div className="shop-filters-container-row left">
              <SimpleCategoriesList parent={true}  item={category} setItem={setCategory}  onClose={handleCloseCategory} />
          </div>

          <div className="shop-filters-container-row center">
              <input value={queryString} id="item-name" className="" onChange={handleTextChange} />
              <label htmlFor="item-name">
                  <SearchIcon /> Que cherchez vous ?
              </label>
          </div>

          <div className="shop-filters-container-row right">
              <Button onClick={handleClickOpenCity}>
                  <span>{elementCity ? elementCity.title : "Ville ou quartier"}</span>
                  <ArrowDropDownIcon/>
              </Button>
              <SimpleDialog prompt={"Choisir une ville ou un quartier"} data={cities} defaultSelection={"Toutes les villes"} open={openCity} onClose={handleCloseCity} onCancel={handleClickCloseCity} />
          </div>
      </div>
      <div className="shop-filters-submit">
        <Button
          onClick={goToSearch}
          variant="contained"
          className="search-filters-submit"
          disableElevation>
          {props.searchShopNumber !== null ? `Afficher ${props.searchShopNumber} résultats` :  'Recherche'}
          {
            props.searching ? (<CircularProgress />) : ('')
          }
        </Button>
        { props.searchShopNumber !== null ?

       
        <Button
          onClick={resetFilter}
          variant="contained"
          className="search-filters-reset"
          disableElevation>
          {'Réinitialiser'}
          {
            props.searching ? (<CircularProgress />) : ('')
          }
        </Button>:null
         }
      </div>
    </div>
  )
}

export default ShopFilter;