import React, {useState} from "react";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import './shopDetails.scss';
import Header from "../../containers/Header";
import Footer from "../../components/Footer";
import {Button, CircularProgress} from "@material-ui/core";
import {MdForum, MdPhone} from "react-icons/md";
import {IoMdChatboxes} from "react-icons/io";
import {FormControl, MenuItem, Select} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import {getAdvertByShop, searchShopsAdvert, searchShopAdvertsNumber, countShopAdverts} from "../../services/shop";
import Advert from "../Advert/advert";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { getUser } from '../../services/users'
import Loading from "../Loading";
import { AdvertSkeleton } from "../skeletons/AdvertSkeleton";
import {connect} from 'react-redux';
import { getAllCategories } from '../../services/category';
import ReactWhatsapp from 'react-whatsapp';
import ShopDetailsFilter from "./ShopDetailsFilter/ShopDetailsFilter";
import shops from "../Shops/shops";
import {staticApiUrl} from "../../utils/axios";

var inf = require('../../imgs/Informatique_et_Multimedia.jpg');
var ser = require('../../imgs/service.png');
var aut = require('../../imgs/Auto_moto.jpg');
var loi = require('../../imgs/Loisir_et_diverstisseement.jpg');
var imm = require('../../imgs/Immobilier.jpg');
var ent = require('../../imgs/Entreprise.jpg');
var emp = require('../../imgs/emploi.png');
var hab = require('../../imgs/Habillement_et_bien_etre.jpg');
var mai = require('../../imgs/Maison_et_jardin.jpg');
var eve = require('../../imgs/Evenement_et_annonces_locales.jpg');
var ach = require('../../imgs/Achat_et_vente_de_fonds_de_commerces.jpg');
var off = require('../../imgs/Appel_offre_en_ligne.jpg');
var diver = require('../../imgs/Autres_et_divers.jpg');
let baseUrl = `${staticApiUrl}/userimages/`;

class ShopDetails extends  React.Component {
    constructor(props) {
        console.log(props);
        super(props);
        this.currentCountry = props.currentCountry;
        //console.log(this.currentCountry);
        this.state = {
            shop : props.location.state,
            shops: [],
            categories : [],
            category : {},
            shopLoading : true,
            categoryLoading : true,
            advertsInitializing : true,
            limit: 15,
            offset: 0,
            total: 0,
            loadedCount : 0,
            userData: {},
            openCity: false,
            sort: 'none',
            showSellerNumber : false,
            filterCount: null,
            searching: false,
            isSearching: 1,
            searchObject: {},
            city: this.props.location.state.city,
            website: '',
        };
        this.openCity = this.openCity.bind(this);
        this.closeCity = this.closeCity.bind(this);
        this.sorting = this.sorting.bind(this);
        this.showNumber = this.showNumber.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }

    componentDidMount(){
        countShopAdverts({country_id: this.currentCountry.id, store_id: this.props.location.state.user_id || this.props.location.state.id}).then(result => {
            this.setState({total: result.data});
        });
        this.loadShop();
        this.getUser();
    }

    getUser = async () => {
        let user = await getUser({ user_id: this.props.location.state.user_id || this.props.location.state.id, country_id: this.props.currentCountry.id});
        this.setState({userData: user.data.RESPONSEDATA[0]});
    }

    loadShop = async () => {
        const {limit, offset, shops} = this.state;
        this.setState((state)=> ({ shopLoading : true }));
        let tab = [];
        let res = await getAllCategories();
        let { RESPONSEDATA } = res.data;
        for (let i = 0; i < RESPONSEDATA.length; i++) {
            var item = RESPONSEDATA[i];
            var obj = {};
            obj.id = item.id;
            obj.title = item.title_fr;
            let tab2 = [];
            item.sub.map(elt2 => {
                tab2.push({
                    id: elt2.id,
                    title: elt2.title_fr,
                    parentId: item.id
                })
            })
            obj.items = tab2;
            tab.push(obj);
        }
        let cat = this.props.location.state.category_id ? this.props.location.state.category_id : '64';
        let tab2 = tab.filter(element => {
            return element.id == cat || (element.items.find(elt => {return elt.id == cat}));
        });
        this.setState({categories: tab2[0].items, category: tab2[0]});
        getAdvertByShop({ user_id: this.props.location.state.user_id || this.props.location.state.id, country_id: this.props.currentCountry.id, limit: limit, offset: offset })
            .then(({data}) => {
                let { RESPONSEDATA } = data;
                let res = shops.slice();
                if (RESPONSEDATA) {
                    RESPONSEDATA.forEach((shop) => res.push(shop));
                    this.setState((state)=> ({ shops : res, loadedCount: res.length, offset: offset + 15}));
                }
            })
            .catch(e=> {
                console.error(e);
                this.setState((state)=> ({ shopLoading : false, advertsInitializing : false }));
            }).finally(()=>{
            this.setState((state)=> ({ shopLoading : false, advertsInitializing : false }));
        });
    }

    filterAd = async (body) => {
        body.store_id = this.props.location.state.id || this.state.userData.id;
        var res = await searchShopAdvertsNumber(body);
        this.setState({filterCount: res.data});
    };

    searchAd = async (body) => {
        body.store_id = this.props.location.state.id || this.state.userData.id;
        this.setState({searching: true});
        searchShopsAdvert(body)
            .then(({data}) => {
                const { RESPONSEDATA, STATUS } = data;
                if (STATUS) {
                    body.offset = 15;
                    this.setState({isSearching: 2, searchObject: body, shops: RESPONSEDATA, loadedCount: shops.length});
                } else {
                    this.setState({isSearching: 2, searchObject: body, shops: [], loadedCount: 0});
                }
            })
            .catch(e=> {
                this.setState({isSearching: 2, searchObject: {}, shops: []});
                console.error(e);
            }).finally(()=>{
            this.setState({searching: false});
        });
    };

    reload = () => {
        window.location.reload();
    };

    loadMore(){
        const {limit, offset, shops, isSearching, loadedCount, searchObject} = this.state;
        // this.setState((state)=> ({ shopLoading: true }));
        if (isSearching == 1) {
            getAdvertByShop({ user_id: this.props.location.state.user_id || this.props.location.state.id, country_id: this.currentCountry.id, limit: limit, offset: offset })
                .then(({data}) => {
                    const { RESPONSEDATA, STATUS } = data;
                    if (STATUS) {
                        let res = [...shops, ...RESPONSEDATA];
                        let currentCount = loadedCount + RESPONSEDATA.length;
                        this.setState((state)=> ({ shops : res, loadedCount: currentCount, offset: offset + 15 }));
                    }
                })
                .catch(e=> {
                    console.error(e);
                }).finally(()=>{
                // this.setState((state)=> ({ shopLoading: false }));
            });
        } else if (isSearching == 2){
            searchShopsAdvert(searchObject)
                .then(({data}) => {
                    const { RESPONSEDATA, STATUS } = data;
                    if (STATUS) {
                        let res = [...shops, ...RESPONSEDATA];
                        let currentCount = loadedCount + RESPONSEDATA.length;
                        this.setState((state)=> ({ shops : res, loadedCount: currentCount, searchObject: {...searchObject, offset: offset + 15 }}));
                    }
                })
                .catch(e=> {
                    console.error(e);
                }).finally(()=>{
                // this.setState((state)=> ({ shopLoading: false }));
            });
        }
    }

    getCategorieImage = (cat) => {
        switch (cat) {
            case '57':
                return inf;
                break;
            case '63':
                return ser;
                break;
            case '55':
                return aut;
                break;
            case '58':
                return loi;
                break;
            case '13':
                return imm;
                break;
            case '132':
                return ent;
                break;
            case '62':
                return emp;
                break;
            case '56':
                return hab;
                break;
            case '61':
                return mai;
                break;
            case '206':
                return eve;
                break;
            case '209':
                return ach;
                break;
            case '212':
                return off;
                break;
            case '64':
                return diver;
                break;
            default:
                break;
        }
    };

    openCity() {
        this.setState((state) => ({ openCity: true }));
    }

    closeCity() {
        this.setState((state) => ({ openCity: false }));
    }

    sorting(event) {
        this.setState(() => ({ sort: event.target.value}));
        this.sortFunction(event.target.value);
    }

    sortFunction = (type) => {
        var tab = [];
        let arr = [...this.state.shops];

        if (type == 1) {
            tab = arr.sort(function(a,b){
                return new Date(a.created_at) - new Date(b.created_at);
            });
        } else if (type == 2) {
            tab = arr.sort(function(a,b){
                return new Date(b.created_at) - new Date(a.created_at);
            });
        }
        else if (type == 3) {
            tab = arr.sort(function(a,b){
                return parseInt(a.price) - parseInt(b.price);
            });
        } else if (type == 4) {
            tab = arr.sort(function(a,b){
                return parseInt(b.price) - parseInt(a.price);
            });
        }
        this.setState({shops: tab});
    };

    showNumber () {
        this.setState((state) => ({ showSellerNumber : true }));
    }

    render() {
        const {shops} = this.state;
        console.log(this.state.userData);
        console.log(this.state.userData.internet);
        if (this.state.userData.internet !== undefined) {
            if (this.state.userData.internet.includes('http')) {
                this.state.website = this.state.userData.internet.replace(/\s/g, "")
            } else {
                this.state.website = 'http://' + this.state.userData.internet.replace(/\s/g, "")
            }
        }
        return (
            <div className="shop-details-wrapper">
                <Header/>
                { this.state.shopLoading && <Loading />}
                { this.state.userData &&
                <div className="page-content shop-details">
                    <div className="shop-details-container">

                        <div className="shop-details-container-back">
                            {/* { this.state.category && this.state.category.image ?
                             ((this.state.category && this.state.category.image) &&  <img src={this.state.category.image} alt="image"/>)
                             : (<Skeleton animation="wave"  variant="rect" width="100%" height="100%" />)
                         } */}
                            <img src={this.getCategorieImage(this.state.category.id)} alt="image"/>
                            <div className="shop-details-container-back-shop-image">
                                {
                                    this.state.userData.image && this.state.userData.image.length !== 0 ? (
                                        <img src={baseUrl+this.state.userData.image[0].filename}  alt={this.state.userData.pro_rs}/>
                                    ) : (
                                        <img src={baseUrl + 'default_image.png'} alt={this.state.userData.pro_rs}/>
                                    )
                                }
                            </div>
                        </div>
                        <div className="shop-details-container-infos">
                            <div className="shop-details-container-infos-left">
                                <div className="shop-details-container-infos-left-title">
                                    <h3>{this.state.userData.pro_rs}</h3>
                                </div>
                                <div className="shop-details-container-infos-left-city">
                                    <ul>
                                        <li>Ville : {this.state.city}</li>
                                    </ul>
                                </div>
                                {
                                    this.state.userData.activity_description !== null ? (
                                        <div className="shop-details-container-infos-left-description">
                                            <ul>
                                                <li>Présentation</li>
                                                <li>
                                                    {this.state.userData.activity_description}
                                                </li>
                                            </ul>
                                        </div>
                                    ) : ('')
                                }
                                {
                                    this.state.userData.address !== null ? (
                                        <div className="shop-details-container-infos-left-address">
                                            <ul>
                                                <li>Adresse</li>
                                                <li>{this.state.userData.address}</li>
                                            </ul>
                                        </div>
                                    ) : ('')
                                }
                                {
                                    this.state.userData.horaires !== null ? (
                                        <div className="shop-details-container-infos-left-hours">
                                            <ul>
                                                <li>Horaires</li>
                                                <li>{this.state.userData.horaires}</li>
                                            </ul>
                                        </div>
                                    ) : ('')
                                }
                                {
                                    this.state.userData.pro_rc !== null ? (
                                        <div className="shop-details-container-infos-left-rccm">
                                            <ul>
                                                <li>NRCCM:</li>
                                                <li>{this.state.userData.pro_rc}</li>
                                            </ul>
                                        </div>
                                    ) : ('')
                                }

                                <div className="shop-details-container-infos-left-buttons">
                                    <a href={`tel:` + '+' + this.state.userData.phone_indicatif + this.state.userData.phone} className="shop-details-container-infos-left-buttons-button upper orange">
                                        <MdPhone/>
                                        {
                                            '+' + this.state.userData.phone_indicatif + this.state.userData.phone
                                        }
                                    </a>
                                   {/* <Button className="shop-details-container-infos-left-buttons-button upper outline">
                                        <IoMdChatboxes/>Chat
                                    </Button>*/}
                                    <ReactWhatsapp className="shop-details-container-infos-left-buttons-button upper green" number={"+" + this.state.userData.phone_indicatif + this.state.userData.phone} message={`Bonjour ${this.state.userData.pro_rc}`}>
                                        Message Whatsapp
                                    </ReactWhatsapp>
                                </div>
                                {
                                    (this.state.userData.internet !== " ") ? (
                                        <div className="shop-details-container-infos-left-website">
                                            <a href={this.state.website} rel="noopener" target="_blank">Visiter notre site web</a>
                                        </div>
                                    ) : ('')
                                }
                            </div>
                            <div className="shop-details-container-infos-right">
                                <div className="shop-details-container-infos-right-search">
                                    <ShopDetailsFilter
                                        categoryId={this.state.userData.category_id}
                                        searchAd={this.searchAd}
                                        filterAd={this.filterAd}
                                        searchShopAdvertsNumber={this.state.filterCount}
                                        setSearchShopAdvertsNumber={(num) => this.setState({filterCount:num})}
                                        searching={this.state.searching}
                                        reload={this.reload}
                                    />
                                </div>
                                <div className="shop-details-container-infos-right-items">
                                    <div className="shop-details-container-infos-right-items-title">
                                        <div className="shop-details-container-infos-right-items-title-left">
                                            <div className="shop-details-container-infos-right-items-title-left-breadcrumbs">
                                                <Breadcrumbs aria-label="breadcrumb">
                                                    <Link color="inherit" href="/">
                                                        Accueil
                                                    </Link>
                                                    <Link color="inherit" href="/shops">
                                                        Boutiques
                                                    </Link>
                                                    <Typography color="textPrimary">{this.state.userData.pro_rs}</Typography>
                                                </Breadcrumbs>
                                            </div>
                                            <div className="shop-details-container-infos-right-items-title-left-count">
                                                <p>({this.state.isSearching == 1 ? this.state.total : this.state.filterCount}) annonce(s) trouvée(s)</p>
                                            </div>
                                        </div>
                                        <div className="shop-details-container-infos-right-items-title-right">
                                            <div className="shop-details-container-infos-right-items-title-right-tri">
                                                <p>Tri :</p>
                                            </div>
                                            <FormControl>
                                                <Select
                                                    displayEmpty
                                                    inputProps={{'aria-label': 'Without label'}}
                                                    onChange={this.sorting}
                                                    value={this.state.sort}
                                                >
                                                    <MenuItem value="none">Aucune selection</MenuItem>
                                                    <MenuItem value="1">Moins récent</MenuItem>
                                                    <MenuItem value="2">Plus récent</MenuItem>
                                                    <MenuItem value="3">Moins cher</MenuItem>
                                                    <MenuItem value="4">Plus cher</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="shop-details-container-infos-right-items-content">
                                        { this.state.advertsInitializing ?
                                            <AdvertSkeleton count={8} /> :
                                            shops.map((advert) => {
                                                return (
                                                    <Advert key={advert.advert_id} object={advert} />
                                                )
                                            })
                                        }
                                    </div>
                                    { !this.state.advertsInitializing &&
                                    <div className="load-more-content">
                                        <p> Vous avez vu {this.state.loadedCount} sur {this.state.isSearching == 1 ? this.state.total : this.state.filterCount} annonce(s)</p>
                                        <Button onClick={this.loadMore} disabled={((this.state.loadedCount < (this.state.isSearching == 1 ? this.state.total : this.state.filterCount)) && !this.state.shopLoading) ? false : true }>
                                            Voir plus d'annonces
                                            {this.state.shopLoading && <CircularProgress /> }
                                        </Button>
                                    </div> }
                                </div>
                            </div>
                        </div>
                    </div>
                </div> }
                { this.state.userData &&
                <div className="mobile-fixed-buttons">
                    <div className="mobile-fixed-buttons-item">
                        <a href={`tel:` + '+' + this.state.userData.phone_indicatif + this.state.userData.phone} className="direct-link shop-details-container-infos-left-buttons-button upper orange">
                            <MdPhone/> Contacter
                        </a>
                    </div>
                    <div className="mobile-fixed-buttons-item">
                        <Button>
                            <MdForum/>
                            <span>Chatter</span>
                        </Button>
                    </div>
                    <div className="mobile-fixed-buttons-item">
                        <ReactWhatsapp className="whatsapp" number={"+" + this.state.userData.phone_indicatif + this.state.userData.phone} message={`Bonjour ${this.state.userData.pro_rc}`}>
                            <div className="MuiButton-label">
                                <WhatsAppIcon/>
                                <span>Whatsapp</span>
                            </div>
                        </ReactWhatsapp>
                    </div>
                </div> }
                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
    currentCountry: state.country.currentCountry,
    categories: state.category.categories,
});

const mapDispatchToProps = dispatch => ({
    // setOpen: (open) => {
    //     dispatch(setOpen({ open : open}));
    // },
    // setMessage: (message) => {
    //     dispatch(setMessage({ message : message}));
    // },
    // saveUser: (user) => {
    //     dispatch(saveUser({ user : user}));
    // },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopDetails);
