import { connect } from 'react-redux';
import Splash from '../components/Homepage/Splash';

import { openCountryPopup } from '../reducers/countryReducer';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  openCountryPopup: () => {
    dispatch(openCountryPopup());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Splash);