/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { uuid } from 'uuidv4';
import Category from '../../containers/FooterCategory';
import Masonry from "react-masonry-css";

const Categories = ({
                        categories,
                        getAllCategories,
                    }) => {
    useEffect(() => {
        getAllCategories();
    }, []);

    const breakpointColumnsObj = {
        default: 4,
        1100: 4,
        992: 3,
        768: 2
    };

    return (
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="footer-categories"
            columnClassName="footer-categories-item-wrapper">
            {categories.map((category) => <Category key={uuid()} category={category} />)}
        </Masonry>
    );
};
export default Categories;
