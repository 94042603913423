import { connect } from 'react-redux';
import Homepage from '../components/Homepage';

const mapStateToProps = state => ({
  countryPopupOpened: state.country.popups.country.opened,
  needToRedirect: state.country.needToRedirect,
  currentCountry: state.country.currentCountry,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);