import { createGlobalStyle } from 'styled-components';
import airstream from './Airstream.ttf';
import avenirThin from './AvenirLTStd-Roman.otf';
import avenirLight from './AvenirLTStd-Book.otf';
import avenirMedium from './AvenirLTStd-Medium.otf';
import SegoeUiBold from './Segoe-UI-Bold.woff';

export default createGlobalStyle`
  @font-face {
    font-family: 'airstream-webfont';
    src: local('airstream-webfont'), local('airstream-webfont'),
    url(${airstream}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  /* Avenir Thin */
  @font-face {
    font-family: 'avenirThin';
    src: local('avenirThin'), local('avenirThin'),
    url(${avenirThin}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  /* Avenir Light */
  @font-face {
    font-family: 'avenirLight';
    src: local('avenirLight'), local('avenirLight'),
    url(${avenirLight}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  /* Avenir Medium */
  @font-face {
    font-family: 'avenirMedium';
    src: local('avenirMedium'), local('avenirMedium'),
    url(${avenirMedium}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  /* Segoe Ui bold */
  @font-face {
    font-family: 'SegoeUiBold';
    src: local('SegoeUiBold'), local('SegoeUiBold'),
    url(${SegoeUiBold}) format('woff');
    font-weight: 600;
    font-style: normal;
  }
`;
