import axios from 'axios';

import {
  apiUrl,
} from '../utils/axios';

export function getShopById(data){
  return axios.post(`${apiUrl}/get_user_infos`, data);
}

export function getAdvertByShop(data){
  return axios.post(`${apiUrl}/get_user_adverts`, data);
}

export function filterShops(data){
  return axios.post(`${apiUrl}/get_stores`, data);
}

export function searchShops(data){
  return axios.post(`${apiUrl}/search_store_by_criteria`, data);
}

export function searchShopsNumber(data){
  return axios.post(`${apiUrl}/search_store_by_criteria_number`, data);
}

export function countShops(data){
  return axios.post(`${apiUrl}/get_store_number`, data);
}

export function searchShopsAdvert(data){
  return axios.post(`${apiUrl}/search_store_advert_by_criteria`, data);
}

export function searchShopAdvertsNumber(data){
  return axios.post(`${apiUrl}/search_store_advert_by_criteria_number`, data);
}

export function countShopAdverts(data){
  return axios.post(`${apiUrl}/get_store_advert_number`, data);
}