import { connect } from 'react-redux';
import { setOpen, setMessage, setBackColor } from '../reducers/snackbarReducer';
import SnackBarComponent from "../components/Snackbar/SnackBarComponent";

const mapStateToProps = state => ({
  open: state.snackbar.open,
  message: state.snackbar.message,
  background: state.snackbar.background
});

const mapDispatchToProps = dispatch => ({
  setOpen: (open) => {
    dispatch(setOpen({ open : open}));
  },
  setMessage: (message) => {
    dispatch(setMessage({ message : message}));
  },
  setBackColor: (background) => {
    dispatch(setBackColor({ background : background}));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SnackBarComponent);
