/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { uuid } from 'uuidv4';

import Loading from '../Loading';
import {staticApiUrl} from "../../utils/axios";

var data = null;
const CountryDropdown = ({
                           getAllCountries,
                           countries,
                           selected,
                           countrySelected,
                           countriesLoaded,
                         }) => {
  useEffect(() => {
    getAllCountries();
  }, []);

  let sortedCountries = countries.slice().sort((a, b) => a.order > b.order);
  if (selected) {
    sortedCountries = sortedCountries.filter((country) => country.id !== selected.id);
  }

  let url = `${staticApiUrl}/countryimages/`;

  const changeCountry = (country) => {
    countrySelected(country);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
      <ul className="homepage-header-dropdown-list">
      <span className="homepage-header-dropdown-list-inner">
      {sortedCountries.map((country) => (
          <li
              key={uuid()}
              className="homepage-header-dropdown-list-inner-item"
              onClick={() => changeCountry(country)}
          >
            { (!countriesLoaded) && (<Loading word="Chargement" />) }
            {
              country.images[0] !== undefined && (
                  <img src={url + country.images[0].filename} alt={country.name} />
              )
            }
            {country.name}
          </li>
      ))}
      </span>
      </ul>
  );
};

export default CountryDropdown;
