import { connect } from 'react-redux';
import HomepageCountryPopup from '../components/Homepage/HomepageCountryPopup';

import {
  closeCountryPopup,
  getAllCountries,
  countrySelected,
} from '../reducers/countryReducer';

const mapStateToProps = (state) => ({
  countries: state.country.countries,
  selected: state.country.activeCountry,
  countriesLoaded: state.country.countriesLoaded,
});

const mapDispatchToProps = dispatch => ({
  closeCountryPopup: () => {
    dispatch(closeCountryPopup());
  },
  getAllCountries: () => {
    dispatch(getAllCountries());
  },
  countrySelected: (country) => {
    dispatch(countrySelected({ country : country }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HomepageCountryPopup);