import React from 'react';
import { Route, Redirect } from "react-router-dom";

const RestrictedRoute = ({ component: Component, currentCountry, setSnackBarMessage, ...rest }) => {

    const message = "Veuillez sélectionner un pays pour continuer";
    if(!currentCountry)
        setSnackBarMessage(message);

 return   <Route {...rest} render={(props) => (
        currentCountry
            ? <Component {...props} />
            : <Redirect to='/' />
    )} />
}

export default RestrictedRoute;
