import React from 'react';
import './Homepage.scss';
import { NavLink } from 'react-router-dom';
import {staticApiUrl} from "../../utils/axios";

const Country = ({
                     country,
                     countrySelected,
                 }) => {
    const {
        slug,
        name
    } = country;

    let url = `${staticApiUrl}/countryimages/`;

    let filename = "no-image";
    if (country.images[0] !== undefined) {
        filename = country.images[0].filename;
    }


    const handleClick = (e) => {
        e.preventDefault();
        countrySelected(country);
    };

    return (
        country.images[0] !== undefined &&
        <li className="country-block">
            <NavLink to={`/${slug}/search`} onClick={handleClick} title={name}>
                <div className="image">
                    <img src={url + filename} alt={name} />
                </div>
                <span>{name}</span>
            </NavLink>
        </li>
    );
};

export default Country;
