import React from "react";
import './DashboardAdView.scss';
import {Link, NavLink, Redirect} from "react-router-dom";
import {TiCamera} from "react-icons/ti";
import Skeleton from "@material-ui/lab/Skeleton";
import Button from "@material-ui/core/Button";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import {BsPencilSquare} from "react-icons/bs";
import IconButton from "@material-ui/core/IconButton";
import {MdClose} from "react-icons/md";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {Formik} from "formik";
import * as Yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import {deleteUserAdvert} from "../../../services/users";
import {deleteUserSavedAdvert} from '../../../services/user_adverts';
import {setMessage, setOpen} from "../../../reducers/snackbarReducer";
import {connect} from "react-redux";
import {setIsEditing, setEditObject, setAdvert} from "../../../reducers/advertisementReducer";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {staticApiUrl} from "../../../utils/axios";

export class DashboardAdView extends React.Component {
    constructor(props) {
        super(props);
        this.currentCountry = props.currentCountry;
        this.state = {
            loaded : false,
            add : props.object,
            openDeleteDialog: false,
            deleteProgress: false
        };
        this.setOpen = props.setOpen;
        this.setMessage = props.setMessage;
        this.setAdvert = props.setAdvert;
        setTimeout(()=> {
            this.setState((state)=> ({ loaded : true }));
        }, 3000);

        this.handleOpenDelete = this.handleOpenDelete.bind(this);
        this.handleCloseDelete = this.handleCloseDelete.bind(this);
        this.deleteProgressing = this.deleteProgressing.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
    }

    handleOpenDelete = () => {
        this.setState((state) => ({ openDeleteDialog : true }));
    };

    handleCloseDelete () {
        this.setState((state) => ({ openDeleteDialog : false }));
    };

    deleteProgressing () {
        this.setState((state) => ({ deleteProgress : true }));
    }

    refreshPage() {
        window.location.reload();
    }

    handleOtherAdvertChange = () => {
        this.setAdvert(this.props.object);
    };

    handleEdit = () => {
        this.props.setIsEditing(true);
        this.props.setEditObject({
            user_id: this.props.object.user_id,
            advert_id: this.props.object.advert_id,
            category_id: this.props.object.category_id,
            type_advert: this.props.object.type_advert,
            type_advert_name: this.props.object.type_advert_name,
            title: this.props.object.title,
            description: this.props.object.description,
            price: this.props.object.price,
            type_price: this.props.object.type_price,
            negociable: this.props.object.negotiable,
            published: this.props.object.published,
            hidden_phone: false,
            city_id: this.props.object.city_id,
            zone_id: this.props.object.zone_id,
            images: this.props.object.images,
            brand_id: this.props.object.brand_id,
            model_id: this.props.object.model_id,
            year: this.props.object.year,
            mileage: this.props.object.mileage,
            nb_door: this.props.object.nb_door,
            nb_seat: this.props.object.nb_seat,
            fuel: this.props.object.fuel,
            gear_box: this.props.object.gear_box,
            color: this.props.object.color,
            nb_rooms: this.props.object.nb_rooms,
            area: this.props.object.area,
            type_immo: this.props.object.type_immo,
            state: this.props.object.state,
            storage: this.props.object.storage,
            memory: this.props.object.memory
        });
    };

    render() {
        const slugifyd = () => {
            const regexSpaces = /(\s+|-+|_+)/gm;
            const regexDashes = /-+/gm;
            let name = encodeURIComponent(this.props.object.title ? this.props.object.title.toLowerCase() : '');
            name = name.replace(regexSpaces, '-');
            name = name.replace(regexDashes, '-');
            return `${name}`;
        };

        const slugify = () => {
            const regexSpaces = /(\s+|-+|_+)/gm;
            const regexDashes = /-+/gm;
            let name = this.props.object.title.toLowerCase();
            name = name.replace(regexSpaces, '-');
            name = name.replace(regexDashes, '-');
        
            return `${name}`;
        };
        let url = `${staticApiUrl}/adimages/`;

        let filename = "no-image";
        if (this.props.object.images[0] !== undefined) {
            filename = this.props.object.images[0].filename;
        }
        /*const slugify = () => {
            const regexSpaces = /(\s+|-+|_+)/gm;
            const regexDashes = /-+/gm;
            let name = this.props.object.title.toLowerCase();
            name = name.replace(regexSpaces, '-');
            name = name.replace(regexDashes, '-');

            return `${name}`;
        };*/
        const REGEX_GROUPS = /(\d)(?=(\d\d\d)+(?!\d))/g
        function formatting(int, delimiter = '\u202f') {
            return int?.toString().replace(REGEX_GROUPS, `$1${delimiter}`)
        }
        const formSchema = Yup.object().shape({
            motif: !this.props.object.saved_id ? Yup.string()
                .required('Ce champ est requis') : null
        });
       // console.log('saved item', this.props.object)
        return (
            <div className="dashboard-ad-view">
                <div className="dashboard-ad-view-item">
                    {this.props.isEditing && <Redirect to="/advertisements/edit" />}
                    {/* <Link href={`/advertisements/${slugify()}/${this.props.object.advert_id}`} className="add-item-link"> */}
                    <div className="dashboard-ad-view-item-body">
                        { this.state.loaded ?
                            (
                                <div className="dashboard-ad-view-item-body-image">
                                    <Link to={{
                                        pathname: `/${this.currentCountry.slug}/advertisements/${slugify()}/${this.props.object.advert_id}`,

                                        state: this.props.object,
                                    }}
                                          onClick={() => this.handleOtherAdvertChange()}>
                                        { this.props.object.images !== undefined && this.props.object.title !== undefined ?
                                            (
                                                <img src={url + filename} alt={this.props.object.title} />
                                            ) : (
                                                <img src={url + 'default_image.png'} alt="" />
                                            )
                                        }

                                        { (this.props.object.images.length > 1) && (
                                            <div className="dashboard-ad-view-item-body-image-count">
                                                <ul>
                                                    <li><TiCamera /></li>
                                                    <li>{this.props.object.images.length}</li>
                                                </ul>
                                            </div>
                                        ) }
                                    </Link>
                                </div>
                            ) :
                            (<Skeleton animation="wave"  variant="rect" width={160} height={130} />)
                        }
                        <div className="dashboard-ad-view-item-body-text">
                            <div className="dashboard-ad-view-item-body-text-title">
                                {this.state.loaded ?
                                    (<h3>{this.props.object.title}</h3>) :
                                    (<Skeleton animation="wave"  width="100%" height={30} spacing={6} style={{marginTop: -10}} />)
                                }
                            </div>
                            <div className="dashboard-ad-view-item-body-text-price">
                                {this.state.loaded ?
                                    (
                                        this.props.object.price !== '' || undefined ?
                                            (<p>
                                                <span>{formatting(this.props.object.price)}</span> {this.currentCountry && this.currentCountry.currency[0].slug}
                                            </p>) : (<p>Me contacter</p>)
                                    ) :
                                    (
                                        <Skeleton animation="wave"  width="50%" height={26}  />
                                    )
                                }
                            </div>
                            <div className="dashboard-ad-view-item-body-text-icons">
                                {
                                    this.state.loaded ?
                                        (
                                            <div className="dashboard-ad-view-item-body-text-icons-left">
                                                <ul>
                                                    <li><VisibilityOutlinedIcon /></li>
                                                    <li><span>{this.props.object.views}</span></li>
                                                </ul>
                                            </div>
                                        ) :
                                        (<Skeleton animation="wave"  width="30%" height={26} spacing={6} style={{marginRight: 20, marginTop: -6}} />)
                                }
                                {
                                    this.state.loaded ? (
                                            <div className="dashboard-ad-view-item-body-text-icons-right">
                                                {
                                                    this.props.edited === true ? (
                                                        <ul>
                                                            <li><EmailOutlinedIcon /></li>
                                                            {/*<li><span>9</span></li>*/}
                                                        </ul>
                                                    ) : ('')
                                                }
                                            </div>
                                        ) :
                                        (<Skeleton animation="wave"  width="30%" height={26} spacing={6} style={{marginTop: -6}} />)
                                }
                            </div>
                        </div>
                    </div>
                    {/* </Link> */}
                    {
                        this.props.footer === true ? (
                            <div className="dashboard-ad-view-item-footer">
                                {
                                    this.props.edited === true && (
                                        <div className="dashboard-ad-view-item-footer-edited">
                                            <div className="dashboard-ad-view-item-footer-edited-left">
                                                <Button>
                                                    <span>Booster mon annonce</span>
                                                    <TrendingUpOutlinedIcon />
                                                </Button>
                                            </div>
                                            <div className="dashboard-ad-view-item-footer-edited-right">
                                                {
                                                    !this.props.object.saved_id && (
                                                        <Button onClick={() => this.handleEdit()}><BsPencilSquare/></Button>
                                                    )
                                                }
                                                <Button onClick={this.handleOpenDelete}><DeleteOutlineOutlinedIcon /></Button>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    this.props.saved === true && (
                                        <div className="dashboard-ad-view-item-footer-saved">
                                            <div className="dashboard-ad-view-item-footer-saved-left">
                                                {/* <NavLink to={`/advertisements/${slugify()}/${this.props.object.advert_id}`}>Afficher</NavLink> */}
                                                <Link to={{
                                                    //pathname: `/advertisements/${slugify()}/${this.props.object.advert_id}`,
                                                    pathname: `/${this.currentCountry.slug}/advertisements/${slugify()}/${this.props.object.advert_id}`,

                                                    state: this.props.object,
                                                }}>
                                                    Afficher
                                                </Link>
                                            </div>
                                            <div className="dashboard-ad-view-item-footer-saved-right">
                                                <Button onClick={this.handleOpenDelete}>Supprimer</Button>
                                            </div>
                                        </div>
                                    )
                                }
                                <Dialog
                                    className="dialog"
                                    open={this.state.openDeleteDialog}
                                    onClose={this.handleOpenDeleteChange}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description">
                                    <IconButton aria-label="close" className="closeButton"
                                                onClick={this.handleCloseDelete}>
                                        <MdClose/>
                                    </IconButton>
                                    <div className="change-container ad-delete">
                                        <DialogTitle id="alert-dialog-title">{"Suppression d'annonce"}</DialogTitle>
                                        <Formik
                                            initialValues={{motif: ""}}
                                            validationSchema={formSchema}
                                            onSubmit={(values, actions) => {
                                                if (this.props.object.saved_id) {
                                                    let advertData = {
                                                        'saved_advert_id': this.props.object.saved_id,
                                                    }
                                                    deleteUserSavedAdvert(advertData).then(({data}) => {
                                                        console.warn(data);
                                                        if (data.RESPONSECODE === 200) {
                                                            this.setMessage(data.RESPONSEMESSAGE);
                                                            this.setOpen(true);
                                                            actions.setSubmitting(false);
                                                            this.refreshPage();
                                                        } else {
                                                            this.setMessage(data.RESPONSEMESSAGE);
                                                            this.setOpen(true);
                                                            actions.setSubmitting(false);
                                                        }
                                                    }).catch((e) => {
                                                        console.error(e);
                                                        this.setMessage("Erreur lors de la création");
                                                        this.setOpen(true);
                                                    }).finally((e) => {
                                                        actions.setSubmitting(false);
                                                    })
                                                } else {
                                                    let advertData = {
                                                        'advert_id': this.props.object.advert_id,
                                                        'deletion_motive': values.motif
                                                    }
                                                    deleteUserAdvert(advertData).then(({data}) => {
                                                        if (data.RESPONSECODE === 200) {
                                                            this.setMessage(data.RESPONSEMESSAGE);
                                                            this.setOpen(true);
                                                            actions.setSubmitting(false);
                                                            this.deleteProgressing();
                                                        } else {
                                                            this.setMessage(data.RESPONSEMESSAGE);
                                                            this.setOpen(true);
                                                            actions.setSubmitting(false);
                                                        }
                                                    }).catch((e) => {
                                                        console.error(e);
                                                        this.setMessage("Erreur lors de la création");
                                                        this.setOpen(true);
                                                    }).finally((e) => {
                                                        actions.setSubmitting(false);
                                                    })
                                                }
                                            }}>
                                            {({
                                                  values, errors, touched, handleChange,
                                                  handleBlur, handleSubmit, isSubmitting
                                              }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <DialogContent>
                                                        <div className="dialog-container">
                                                            {
                                                                this.state.deleteProgress === false ? (
                                                                    <div>
                                                                        <div className="dialog-container-item">
                                                                            <p>Voulez-vous vraiment suppprimer
                                                                                l'annonce {this.props.object.title} ?</p>
                                                                        </div>
                                                                        {
                                                                            !this.props.object.saved_id && (
                                                                                <div className="dialog-container-item">
                                                                                    <FormControl
                                                                                        className={"select-category" + (errors.motif && touched.motif ? ' is-invalid' : '')}>
                                                                                        <InputLabel id="motif-label">Motif
                                                                                            de la
                                                                                            suppression</InputLabel>
                                                                                        <Select
                                                                                            name="motif"
                                                                                            id="motif-label"
                                                                                            labelId="category-label"
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                            onBlur={handleBlur}
                                                                                            onChange={handleChange}
                                                                                            value={values.motif}
                                                                                        >
                                                                                            <MenuItem value="">Veuillez
                                                                                                sélectionner une
                                                                                                raison</MenuItem>
                                                                                            <MenuItem
                                                                                                value="Je souhaite modifier le texte de mon annonce / ajouter des photos">Je
                                                                                                souhaite modifier le
                                                                                                texte de mon annonce /
                                                                                                ajouter des
                                                                                                photos</MenuItem>
                                                                                            <MenuItem
                                                                                                value="Je souhaite renouveler mon annonce pour la faire apparaitre en tête de liste">Je
                                                                                                souhaite renouveler mon
                                                                                                annonce pour la faire
                                                                                                apparaitre en tete de
                                                                                                liste</MenuItem>
                                                                                            <MenuItem
                                                                                                value="J'ai vendu / loué mon bien sur Taari">J'ai
                                                                                                vendu / loué mon bien
                                                                                                sur Taari</MenuItem>
                                                                                            <MenuItem
                                                                                                value="J'ai vendu / loué mon bien par un autre moyen">J'ai
                                                                                                vendu / loué mon bien
                                                                                                par un autre
                                                                                                moyen </MenuItem>
                                                                                        </Select>
                                                                                    </FormControl>
                                                                                    {errors.motif && touched.motif &&
                                                                                    <p className="errorMessage">
                                                                                        {errors.motif}
                                                                                    </p>}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                ) : ('')
                                                            }
                                                            {
                                                                this.state.deleteProgress === true ? (
                                                                    <div className="dialog-container-item success">
                                                                        <p><CheckCircleIcon/> Votre annonce a été
                                                                            supprimer avec succès</p>
                                                                    </div>
                                                                ) : ('')
                                                            }
                                                        </div>
                                                    </DialogContent>
                                                    <DialogActions className="confirmation-button">
                                                        {
                                                            this.state.deleteProgress === false ? (
                                                                <div className="initial-buttons">
                                                                    <Button onClick={this.handleCloseDelete}
                                                                            color="primary">
                                                                        Annuler
                                                                    </Button>
                                                                    <Button type="submit" disabled={isSubmitting}
                                                                            color="primary">
                                                                        Oui
                                                                        {
                                                                            isSubmitting ?
                                                                                (<CircularProgress/>) : ('')
                                                                        }
                                                                    </Button>
                                                                </div>
                                                            ) : ('')
                                                        }
                                                        {
                                                            this.state.deleteProgress === true ? (
                                                                <div className="succes-buttons">
                                                                    <div className="succes-buttons-item">
                                                                        <Link to={{pathname: `/dashboard/adverts`}}
                                                                              onClick={this.refreshPage}>Revenir à mes
                                                                            annonces</Link>
                                                                    </div>
                                                                    <div className="succes-buttons-item">
                                                                        <Link to={{pathname: `/advertisements/create`}}>Déposer
                                                                            une annonce</Link>
                                                                    </div>
                                                                </div>
                                                            ) : ('')
                                                        }
                                                    </DialogActions>
                                                </form>
                                            )}
                                        </Formik>
                                    </div>
                                </Dialog>
                            </div>
                        ) : ('')
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        open: state.snackbar.open,
        message: state.snackbar.message,
        currentCountry: state.country.currentCountry,
        isEditing: state.advertisement.isEditing,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setOpen: (open) => {
            dispatch(setOpen({ open : open}));
        },
        setMessage: (message) => {
            dispatch(setMessage({ message : message}));
        },
        setIsEditing: (isEditing) => {
            dispatch(setIsEditing({ isEditing : isEditing}));
        },
        setEditObject: (editObject) => {
            dispatch(setEditObject({ editObject : editObject}));
        },
        setAdvert: (advert) => {
            dispatch(setAdvert({ advert : advert}));
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardAdView);
