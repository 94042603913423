import { combineReducers } from 'redux';

import userReducer from './userReducer';
import headerReducer from './headerReducer';
import countryReducer from './countryReducer';
import categoryReducer from './categoryReducer';
import advertisementReducer from './advertisementReducer';
import snackBarReducer from './snackbarReducer';
import shopReducer from './shopReducer';
import messageReducer from './messageReducer';

const reducers = combineReducers({
  user: userReducer,
  snackbar: snackBarReducer,
  header: headerReducer,
  country: countryReducer,
  category: categoryReducer,
  advertisement: advertisementReducer,
  shop: shopReducer,
  message: messageReducer,
});

export default reducers;
