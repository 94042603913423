import {
  getAdvertisement,
  saveAdvertisements,
  advertisementsLoading,
  advertisementLoading,
  saveAdvertisement,
  resetAdvertisements,
  resetAdvertisement,
  getAdvertisementByCountryId
} from "../reducers/advertisementReducer";

import {
  getAllAdvertisements,
  getAdvertisementById,
} from '../data/advertisements';

import axios from 'axios';

import {
  apiUrl,
} from '../utils/axios';

const logMiddleware = (store) => (next) => (action) => {

    switch (action.type) {
      case getAdvertisement().type:
        store.dispatch(resetAdvertisement());
        store.dispatch(advertisementLoading({ status : true}));

        setTimeout(() => {
          const advertisement = getAdvertisementById(action.payload.id);
          store.dispatch(saveAdvertisement({ advertisement : advertisement}));
          store.dispatch(advertisementLoading({ status : false}));
        }, 342);
        break;

      case 'advertisement/getAllAdvertisements':
        store.dispatch(resetAdvertisements());
        store.dispatch(advertisementsLoading({ status : true}));

        setTimeout(() => {
          const advertisements = getAllAdvertisements();
          store.dispatch(saveAdvertisements({ advertisements : advertisements }));
          store.dispatch(advertisementsLoading({ status : false}));
        }, 217);
        break;
        
      default:
        break;
    }

  next(action);
};

export default logMiddleware;
