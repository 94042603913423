import React from "react";
import './MyMessages.scss';
import SendIcon from '@material-ui/icons/Send';
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import {getDiscussions, createDiscussion, createMessage, deleteDiscussion} from '../../../services/chat';
import { setMessagesCount } from '../../../reducers/messageReducer';
import {MdClose} from "react-icons/md";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {staticApiUrl} from "../../../utils/axios";

var adUrl = `${staticApiUrl}/adimages/`;
var userUrl = `${staticApiUrl}/userimages/`;

export class MyMessages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            currentDiscussion: null,
            discussions: [],
            content: '',
            messages: [],
            openDeleteDialog: false
        };
        this.messagesEndRef = React.createRef(null);
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        console.warn(this.props.advertToChat);
        window.OneSignal = window.OneSignal || [];
        const OneSignal = window.OneSignal;
        // if (this.props.currentUser) {
        //     OneSignal.push(function() {
        //         OneSignal.getUserId(function(userId) {
        //             var obj = {
        //                 user_id : this.props.currentUser.id,
        //                 player_id : userId
        //             };
        //             subscribeToOneSignal(obj).then(result => {
        //                 console.warn(result);
        //             }).catch(error => {
        //                 console.warn(error);
        //             })
        //         });
        //     });
        // }
        OneSignal.push(() => {
            OneSignal.on('notificationDisplay', this.myCallBack);
        });
        if (this.props.advertToChat) {
            this.newDiscussion();
        } else {
            this.getAllDiscussions();
        }
    }

    myCallBack = (event) => {
        console.warn(event);
        this.getAllDiscussions();
    };

    scrollToBottom = () => {
        if (this.messagesEndRef?.current?.scrollIntoView() ) {
            setTimeout(() => {
                this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
            }, 1000);
        }
    }

    getAllDiscussions = () => {
        var obj = {
            user_id: this.props.currentUser.id,
            country_id: this.props.currentCountry.id
        };
        getDiscussions(obj).then(({data}) => {
            const {RESPONSECODE, RESPONSEDATA} = data;
            //console.log('messages', RESPONSEDATA)
            if (RESPONSECODE == 200) {
                console.warn(RESPONSEDATA);
                if (RESPONSEDATA.length !== 0) {
                    let count = 0
                    if (this.state.currentDiscussion) {
                        var currentDiscussion = RESPONSEDATA.find(elt => {
                            return elt.id === this.state.currentDiscussion.id;
                        });
                        this.setState({discussions: RESPONSEDATA, currentDiscussion: currentDiscussion, messages: currentDiscussion.messages});
                    } else {
                        this.setState({discussions: RESPONSEDATA, currentDiscussion: RESPONSEDATA[0], messages: RESPONSEDATA[0].messages});
                    }
                    for (let index = 0; index < RESPONSEDATA.length; index++) {
                        count = count + parseInt(RESPONSEDATA[index]?.nb_sender_unread_messages)
                    }
                    this.props.setMessagesCount(count);
                } else {
                    this.setState({discussions: RESPONSEDATA})
                }
                this.scrollToBottom();
            };
        }).catch(error => {
            console.error(error);
        });
    };

    newDiscussion = () => {
        var obj = {
            sender_id : this.props.currentUser.id,
            receiver_id : this.props.advertToChat.user_id,
            advert_id : this.props.advertToChat.advert_id,
            country_id : this.props.currentCountry.id,
            title : this.props.advertToChat.title
        };
        createDiscussion(obj).then(({data}) => {
            this.getAllDiscussions();
        }).catch(error => {
            console.error(error);
        });
    };

    newMessage = () => {
        const {currentDiscussion, content, messages} = this.state;
        var obj;
        if (content !== '') {
            if (currentDiscussion.advert.user_id == this.props.currentUser.id) {
                obj = {
                    sender_id : this.props.currentUser.id,
                    receiver_id : currentDiscussion.sender.id,
                    discussion_id : currentDiscussion.id,
                    content : content,
                };
            } else {
                obj = {
                    sender_id : this.props.currentUser.id,
                    receiver_id : currentDiscussion.advert.user_id || this.props.advertToChat.user_id,
                    discussion_id : currentDiscussion.id,
                    content : content,
                };
            }
            createMessage(obj).then(({data}) => {
                this.setState({content: ''});
                this.getAllDiscussions();
                // this.scrollToBottom();
                // var tab = messages;
                // tab.push(content);
                // this.setState({messages: tab});
            }).catch(error => {
                console.error(error);
            });
        }
    };

    handleClick = (event) => {
        this.setState((state) => ({ anchorEl : event.currentTarget }));
    };

    handleClose = () => {
        this.setState((state) => ({ anchorEl : null }));
    };

    handleChangeDiscussion = (discussion) => {
        this.setState({currentDiscussion: discussion, messages: discussion.messages, content: ''});
        this.getAllDiscussions();
        this.scrollToBottom();
    };

    handleContentChange = (e) => {
        this.setState({content: e.target.value});
    };

    handleDeleteChange = () => {
        this.setState({openDeleteDialog: !this.state.openDeleteDialog})
    };

    deleteDiscussion = () => {
        const {currentDiscussion} = this.state;
        var obj = {
            user_id : this.props.currentUser.id,
            discussion_id : currentDiscussion.id,
        };
        deleteDiscussion(obj).then(({data}) => {
            const {RESPONSECODE, RESPONSEMESSAGE} = data;
            if (RESPONSECODE == 200) {
                console.warn(RESPONSEMESSAGE);
                this.getAllDiscussions();
                this.handleDeleteChange();
                window.location.reload();
            } else {
                this.handleDeleteChange();
                alert('Suppresion échouée, veuillez réessayer')
            }
        }).catch(error => {
            console.warn(error);
        });
    };

    render() {
        const {discussions, currentDiscussion, openDeleteDialog} = this.state;
        return (
            <div className="chat-wrapper">
                <h3>Mes messages {/*<Badge badgeContent={2} color="primary"></Badge>*/}</h3>
                <div className="chat-wrapper-inner">
                    {
                        discussions.length > 0 ? (
                            <div className="chat-wrapper-inner-container">
                                <div className="chat-wrapper-inner-container-menu">
                                    <div className="chat-wrapper-inner-container-menu-search">
                                        <div className="chat-wrapper-inner-container-menu-search-form">
                                            <form action="">
                                                <input type="text" placeholder="Rechercher..." style={{height: '17px'}} />
                                                <Button>
                                                    <SearchIcon/>
                                                </Button>
                                            </form>
                                        </div>
                                    </div>
                                    {
                                        discussions.length > 0 ? discussions.map(elt => {
                                            return (
                                                <div className={`chat-wrapper-inner-container-menu-item ${elt.id === currentDiscussion.id ? 'active' : ''}`} onClick={() => this.handleChangeDiscussion(elt)}>
                                                    <div className="chat-wrapper-inner-container-menu-item-image">
                                                        {
                                                           Array.isArray(elt.advert?.images) && elt.advert.images.length && elt.advert?.images[0] !== undefined ? (
                                                                <img src={adUrl + elt.advert.images[0].filename} alt="icon"/>
                                                            ) : (
                                                                <img src={adUrl + 'no-image'} alt="icon"/>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="chat-wrapper-inner-container-menu-item-name">
                                                        <h4>{elt.title}</h4>
                                                        <p>{elt.updated_at}</p>
                                                        { elt.deleted_by_other == 1 && <p className="delete-discuss-message">Cette discussion a été supprimer par l'autre partie; il ne pourra plus recevoir vos nouveaux messages.</p> }
                                                    </div>
                                                    <div className="chat-wrapper-inner-container-menu-item-delete">
                                                        <IconButton color="primary" aria-label="delete" component="span" onClick={this.handleDeleteChange}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                        <Dialog
                                                            className="dialog"
                                                            open={openDeleteDialog}
                                                            onClose={this.handleDeleteChange}
                                                            aria-labelledby="alert-dialog-title"
                                                            aria-describedby="alert-dialog-description">
                                                            <IconButton aria-label="close" className="closeButton" onClick={this.handleDeleteChange}>
                                                                <MdClose />
                                                            </IconButton>
                                                            <div className="change-container">
                                                                <DialogTitle id="alert-dialog-title">{"Suppression de discussion"}</DialogTitle>
                                                                <DialogContent>
                                                                    <div className="dialog-container">
                                                                        <p>Voulez-vous vraiment suppprimer cette discussion ?</p>
                                                                    </div>
                                                                </DialogContent>
                                                                <DialogActions className="confirmation-button">
                                                                    <Button onClick={this.handleDeleteChange} color="primary">
                                                                        Annuler
                                                                    </Button>
                                                                    <Button onClick={this.deleteDiscussion} color="primary" autoFocus>
                                                                        Oui
                                                                    </Button>
                                                                </DialogActions>
                                                            </div>
                                                        </Dialog>
                                                    </div>
                                                    {
                                                        (elt.sender_id === this.props.currentUser.id && elt?.nb_sender_unread_messages > 0) &&
                                                        <div className="chat-wrapper-inner-container-menu-item-badge">
                                                            <span>{elt?.nb_sender_unread_messages}</span>
                                                        </div>
                                                    }
                                                    {
                                                        (elt.sender_id !== this.props.currentUser.id && elt?.nb_receiver_unread_messages > 0) &&
                                                        <div className="chat-wrapper-inner-container-menu-item-badge">
                                                            <span>{elt.nb_receiver_unread_messages}</span>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        }) : ('')
                                    }
                                </div>
                                {
                                    currentDiscussion && (
                                        <div className="chat-wrapper-inner-container-content">
                                            <div className="chat-wrapper-inner-container-content-user">
                                                <div className="chat-wrapper-inner-container-content-user-image">
                                                    {
                                                        currentDiscussion.sender.user_image[0] !== undefined ? (
                                                            <img src={userUrl + currentDiscussion.sender.user_image[0].filename} alt="icon"/>
                                                        ) : (
                                                            <img src={userUrl + 'no-image'} alt="icon"/>
                                                        )
                                                    }
                                                </div>
                                                <div className="chat-wrapper-inner-container-content-user-infos">
                                                    <ul>
                                                        <li>{currentDiscussion.sender.first_name} {currentDiscussion.sender.last_name}</li>
                                                    </ul>
                                                </div>
                                                <div className="chat-wrapper-inner-container-content-user-menu">
                                                    {/* <IconButton color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
                                            <MoreVertIcon />
                                        </IconButton> */}
                                                    <Menu
                                                        id="simple-menu"
                                                        anchorEl={this.state.anchorEl}
                                                        keepMounted
                                                        open={Boolean(this.state.anchorEl)}
                                                        onClose={this.handleClose}
                                                    >
                                                        <MenuItem onClick={this.handleClose}>Bloquer cet utilisateur</MenuItem>
                                                        <MenuItem onClick={this.handleClose}>Supprimer la conversation</MenuItem>
                                                    </Menu>
                                                </div>
                                            </div>
                                            <div className="chat-wrapper-inner-container-content-messages">
                                                {
                                                    currentDiscussion.messages.length > 0 ? currentDiscussion.messages.map((message)=> {
                                                        return (
                                                               /*  <div className="load-more-button">
                                                        <Button><ArrowUpwardIcon/> Les plus anciens</Button>
                                                    </div> */
                                                                <div ref={this.messagesEndRef} key={message.id} className={"chat-wrapper-inner-container-content-messages-item " + ( message.sender_id !== this.props.currentUser.id ? 'left' : 'right')}>
                                                                    <div className="chat-wrapper-inner-container-content-messages-item-inner">
                                                                        <div className="chat-wrapper-inner-container-content-messages-item-inner-name">
                                                                            <ul>
                                                                                {/* <li>
                                                                        {
                                                                            (
                                                                                message.messagetype === 'outgoing' ?
                                                                                    (message.sender) : (message.receiver)
                                                                            )
                                                                        }
                                                                    </li> */}
                                                                                <li>{message.created_at}</li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="chat-wrapper-inner-container-content-messages-item-inner-message">
                                                                            {message.content}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        )
                                                    }) : <div>Aucun message</div>
                                                }
                                            </div>
                                            <div className="chat-wrapper-inner-container-content-poster">
                                                <textarea placeholder="Taper votre message" value={this.state.content} onChange={(e) => this.handleContentChange(e)}></textarea>
                                                <Button onClick={() => this.newMessage()}>
                                                    <SendIcon/>
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        ) : (
                            <div className="no-chat">Aucune discussion</div>
                        )
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.user.currentUser,
        currentCountry: state.country.currentCountry,
        advertToChat: state.message.advertToChat,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setMessagesCount: (data) => {
            dispatch(setMessagesCount({ count : data}));
          },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyMessages);
