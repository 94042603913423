import React, {useState, useRef} from 'react';
import './ask.scss';
import Header from '../../../containers/Header';
import Footer from '../../Footer';
import { useFormik } from 'formik';
import {useDispatch, useSelector} from 'react-redux';

import * as Yup from 'yup';
import CircularProgress from "@material-ui/core/CircularProgress";
import {sendAskEmailToUser, sendAskEmailToTaari} from "./../../../services/emailjs";
import {setBackColor, setMessage, setOpen} from "../../../reducers/snackbarReducer";



import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const Ask = () => {
    const dispatch = useDispatch();


    const hiddenFileInput = useRef(null);
    let [fileSelected, setFileSelected] = useState('Ajouter une fichier');

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleChange = event => {
        /*const files = event.target.files;
        const filesArr = Array.prototype.slice.call(files);
        setFileSelected(fileSelected = filesArr);
        console.log(fileSelected);*/
        const fileUploaded = event.target.files[0].name;
        setFileSelected(fileUploaded);
    };


    const formik = useFormik({
        initialValues: {
            email: '',
            prenom: '',
            telephone: '',
            sujet: '',
            message: '',



        },
        validationSchema: Yup.object({
         
            email: Yup.string().email('Votre email est invalide')
                .required('Ce champ est requis'),
            prenom: Yup.string()
                .required('Champ requis'),
            message: Yup.string()
                .required('Champ requis')
        }),
        onSubmit: (values, actions) => {
            let data = {...values};
            sendAskEmailToUser(data)
            .then((response)=>{
                formik.setSubmitting(false)
                formik.resetForm()
                console.log('RESPONSE TO USER', response)
                dispatch(setMessage({message : 'Demande envoyée avec succès'}));
            })
            .catch((error)=>{
                formik.setSubmitting(false)
                dispatch(setMessage({message : "Echec de l'envoi, veuillez réessayer"}));
                dispatch(setBackColor({background: 'red'}));
                console.log('error to user', error)
            })

            // Taari
            sendAskEmailToTaari(data)
            .then((response)=>{
                console.log('RESPONSE TO TAARI', response)

                formik.setSubmitting(false)
                formik.resetForm()
            })
            .catch((error)=>{
                formik.setSubmitting(false)
                console.log('error to taari', error)

            })

           
          
        },
    });


    return (
        <div>
            <Header/>
            <div className="ask-container">
                <div className="footer-breadcrumb">
                    <ul>
                        <li>
                            <NavLink to="/">Accueil</NavLink>
                        </li>
                        <li className="separator">/</li>
                        <li>
                            <NavLink to="/aide-et-support">Aide et support</NavLink>
                        </li>
                        <li className="separator">/</li>
                        <li>Envoyer une demande</li>
                    </ul>
                </div>
                <form className="ask-container-form" onSubmit={formik.handleSubmit}>
                    <h2 className="ask-container-form-title">Envoyer une demande</h2>
                    <div className="ask-container-form-fields">
                        <div className="ask-container-form-fields-item">
                            <TextField
                                name="email"
                                id="email"
                                label="Email"
                                type="text"
                                variant="outlined"
                                size="small"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            />
                        </div>
                        <div className="ask-container-form-fields-item">
                            <TextField
                                name="prenom"
                                id="prenom"
                                label="Prénom"
                                type="text"
                                variant="outlined"
                                size="small"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.prenom}
                            />
                        </div>
                        <div className="ask-container-form-fields-item">
                            <TextField
                                name="telephone"
                                id="telephone"
                                label="Téléphone"
                                type="tel"
                                variant="outlined"
                                size="small"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.telephone}
                            />
                        </div>
                        <div className="ask-container-form-fields-item">
                            <FormControl variant="outlined" className="select-town">
                                <InputLabel id="brand-label">Sujet de votre question</InputLabel>
                                <Select
                                    labelId="brand-label"
                                    id="subject-select"
                                    name='sujet'
                                    label="Sujet de votre question"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.sujet}>
                                    <MenuItem value="deposer une annonce">Déposer une annonce</MenuItem>
                                    <MenuItem value="gerer une annonce">Gérer une annonce</MenuItem>
                                    <MenuItem value="consulter une annonce">Consulter une annonce</MenuItem>
                                    <MenuItem value="messagerie interne">Messagerie interne (Chat sécurisée)</MenuItem>
                                    <MenuItem value="securite sur le site">Sécurite sur le site</MenuItem>
                                    <MenuItem value="mon compte personnel">Mon compte personnel</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="ask-container-form-fields-item">
                            <TextField
                                id="outlined-multiline-static"
                                label="Votre message"
                                name="message"
                                multiline
                                rows={4}
                                variant="outlined"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.message}
                            />
                        </div>
                 {/*        <div className="ask-container-form-fields-item file">
                            <p className="file-title">Pièce jointe</p>
                            <Button onClick={handleClick}>
                                {fileSelected}
                            </Button>
                            <input ref={hiddenFileInput} onChange={handleChange} style={{display:'none'}} type="file" accept=".doc,.docx,.pdf,.png,.jpg,.jpeg" />
                        </div> */}
                        <div className="ask-container-form-fields-item submit">
                            <Button type="submit"  disabled={formik.isSubmitting} >Envoyer


    
                                {
                                    formik.isSubmitting ?
                                        (<CircularProgress />) : ('')
                                }
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
            <Footer/>
        </div>
    )
}

export default Ask;
