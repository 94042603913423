import React from 'react';
import './removedAdd.scss';
import removedAd from "../../imgs/removed.svg";
import Header from "../../containers/Header";
import Footer from "../Footer";
import {NavLink} from "react-router-dom";

const RemovedAd = () => {
    return (
        <div>
            <Header/>
            <div className="removed-add-wrapper">
                <div className="removed-add-wrapper-inner">
                    <h2>Cette annonce n'est malheureusement plus disponible</h2>
                    <div className="removed-add-wrapper-inner-body">
                        <div className="removed-add-wrapper-inner-body-left">
                            <h3>Titre de l'annonce</h3>
                            <p>Description de l'annonce</p>
                        </div>
                        <div className="removed-add-wrapper-body-right">
                            <img src={removedAd} alt="image"/>
                        </div>
                        <NavLink to="/search">Démarer une nouvelle recherche</NavLink>
                    </div>
                    <div className="removed-add-wrapper-inner-items">
                        <h2>Les utilisateurs ayant regardé cette annonce ont également regardé ses annonces</h2>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default RemovedAd;
