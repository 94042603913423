export const adverts = [
    {
        id: 1,
        countryId: 1,
        cityId: 1,
        districtId: 1,
        shopId: 1,
        title: 'Vente de maison R+1',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [],
        status: 'urgent',
        category: 'Immobilier',
        advertType: 'Offres',
        location : 'Ablogamé',
        nbrView: 20,
        publicationDate: '10 janvier',
        publicationHour: '11:30',
        price: '120000000',
        nbrRoom: 5,
        nbrBathroom: 5,
        area: 1200,
        houseType: 'Appartements',
        sellerName: 'AGBAVITO',
        sellerPhone: '98989898',
        sellerWhatsapp: '98989898'
    },
    {
        id: 2,
        countryId: 1,
        cityId: 2,
        districtId: 2,
        shopId: 2,
        title: 'Vente de sac Guicci',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://images.unsplash.com/photo-1548036328-c9fa89d128fa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1049&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1548036328-c9fa89d128fa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1049&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1590739225287-bd31519780c3?ixlib=rb-1.2.1&auto=format&fit=crop&w=611&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1590739225287-bd31519780c3?ixlib=rb-1.2.1&auto=format&fit=crop&w=611&q=80',
            },
        ],
        status: '',
        category: 'Habillement et bien-êtres',
        advertType: 'Offres',
        location: 'Attiégou',
        nbrView: 10,
        publicationDate: '20 fevrier',
        publicationHour: '10:40',
        price: 21000,
        sellerName: 'AGBELENKO',
        sellerPhone: '99999999',
        sellerWhatsapp: '99999999'
    },
    {
        id: 3,
        countryId: 1,
        cityId: 3,
        districtId: 3,
        shopId: 3,
        title: 'Gros camion pour le transport de marchandises',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://images.unsplash.com/photo-1561157437-415893bd7149?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=865&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1561157437-415893bd7149?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=865&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1506306460327-3164753b74c7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1506306460327-3164753b74c7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1555907188-f9fd038c95d4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1555907188-f9fd038c95d4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1583559121633-63421224a8e1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1915&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1583559121633-63421224a8e1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1915&q=80',
            }
        ],
        status: '',
        category: 'Autos et motos',
        advertType: 'Offres',
        location: 'Tokoin Hopital',
        nbrView: 5,
        publicationDate: '17 mars',
        publicationHour: '16:40',
        price: '',
        brand: 'Benz',
        model: 'Acros',
        condition: 'Nouveau',
        buildYear: 2017,
        kilometers: 0,
        fuel: 'Gasoil',
        sellerName: 'AGBEPLETU',
        sellerPhone: '88888888',
        sellerWhatsapp: '88888888'
    },
    {
        id: 4,
        countryId: 2,
        cityId: 4,
        districtId: 4,
        shopId: 4,
        title: 'Moto de course KTM',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://images.unsplash.com/photo-1525013066836-c6090f0ad9d8?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1525013066836-c6090f0ad9d8?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1449426468159-d96dbf08f19f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1449426468159-d96dbf08f19f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
            }
        ],
        status: 'urgent',
        category: 'Autos et motos',
        advertType: 'Offres',
        location: 'Adakpamé',
        nbrView: 1000,
        publicationDate: '17 mars',
        publicationHour: '16:40',
        price: 3000000,
        brand: 'KTM',
        model: 'R-20',
        condition: 'Nouveau',
        buildYear: 2019,
        kilometers: 0,
        fuel: 'Essence',
        sellerName: 'AGBEKLANKO',
        sellerPhone: '77777777',
        sellerWhatsapp: '77777777'
    },
    {
        id: 5,
        countryId: 2,
        cityId: 5,
        districtId: 5,
        shopId: 5,
        title: 'Mercedes class G (G-WAGEN)',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://images.unsplash.com/photo-1559038465-e0ca2910a5b1?ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1559038465-e0ca2910a5b1?ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80',
            },
        ],
        status: '',
        category: 'Autos et motos',
        advertType: 'Offres',
        location: 'Agoè',
        nbrView: 90,
        publicationDate: '17 mars',
        publicationHour: '16:40',
        price: 80000000,
        brand: 'Benz',
        model: 'class G',
        condition: 'Utilisé',
        buildYear: 2018,
        kilometers: 1000,
        fuel: 'Essence',
        sellerName: 'AGBEKEKE',
        sellerPhone: '66666666',
        sellerWhatsapp: '66666666'
    },
    {
        id: 6,
        countryId: 2,
        cityId: 6,
        districtId: 6,
        shopId: 6,
        title: 'Chaussures Nike sport',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://images.unsplash.com/photo-1542291026-7eec264c27ff?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1542291026-7eec264c27ff?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1560769629-975ec94e6a86?ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1560769629-975ec94e6a86?ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1525966222134-fcfa99b8ae77?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=643&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1525966222134-fcfa99b8ae77?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=643&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1460353581641-37baddab0fa2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1460353581641-37baddab0fa2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80',
            }
        ],
        status: '',
        category: 'Habillement et bien-êtres',
        advertType: 'Offres',
        location: 'Djagblé',
        nbrView: 20,
        publicationDate: '17 mars',
        publicationHour: '16:40',
        price: 12000,
        sellerName: 'AGBEBAVI',
        sellerPhone: '55555555',
        sellerWhatsapp: '55555555'
    },
    {
        id: 7,
        countryId: 3,
        cityId: 7,
        districtId: 7,
        shopId: 7,
        title: 'Vente de terrain sur lot',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://images.unsplash.com/photo-1583699998579-5872a2117151?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1583699998579-5872a2117151?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
            },
        ],
        status: 'urgent',
        category: 'Immobilier',
        advertType: 'Offres',
        location: 'Lomé',
        nbrView: 80,
        publicationDate: '17 mars',
        publicationHour: '16:40',
        price: 120000000,
        sellerName: 'AGBEKOKO',
        sellerPhone: '44444444',
        sellerWhatsapp: '44444444'
    },
    {
        id: 8,
        countryId: 3,
        cityId: 8,
        districtId: 8,
        shopId: 8,
        title: 'T-shirt original',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://images.unsplash.com/photo-1521572163474-6864f9cf17ab?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1521572163474-6864f9cf17ab?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1583743814966-8936f5b7be1a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1583743814966-8936f5b7be1a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1571455786673-9d9d6c194f90?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1571455786673-9d9d6c194f90?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1489987707025-afc232f7ea0f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1489987707025-afc232f7ea0f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1576566588028-4147f3842f27?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1576566588028-4147f3842f27?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80',
            }
        ],
        status: '',
        category: 'Habillement et bien-êtres',
        advertType: 'Offres',
        location: 'Kara',
        nbrView: 1,
        publicationDate: '17 mars',
        publicationHour: '16:40',
        price: 4000,
        sellerName: 'AGBEFOFO',
        sellerPhone: '33333333',
        sellerWhatsapp: '33333333'
    },
    {
        id: 9,
        countryId: 3,
        cityId: 9,
        districtId: 9,
        shopId: 9,
        title: 'Maison a louer',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=967&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=967&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1564013799919-ab600027ffc6?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1564013799919-ab600027ffc6?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1572120360610-d971b9d7767c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1572120360610-d971b9d7767c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
            }
        ],
        status: '',
        category: 'Immobilier',
        advertType: 'Offres',
        location: 'Sokodé',
        nbrView: 100,
        publicationDate: '17 mars',
        publicationHour: '16:40',
        price: 60000,
        nbrRoom: 3,
        nbrBathroom: 3,
        area: 700,
        houseType: 'Villas',
        sellerName: 'AGBEKOKO',
        sellerPhone: '32323232',
        sellerWhatsapp: '32323232'
    },
    {
        id: 10,
        countryId: 4,
        cityId: 10,
        districtId: 10,
        shopId: 10,
        title: 'Ordinateur portable MSI new',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://dyw7ncnq1en5l.cloudfront.net/test/17/17255/test-msi-gf63-5.jpg',
                thumbnailUrl: 'https://dyw7ncnq1en5l.cloudfront.net/test/17/17255/test-msi-gf63-5.jpg',
            },
            {
                originalUrl: 'https://dyw7ncnq1en5l.cloudfront.net/test/17/17255/test-msi-gf63-4.jpg',
                thumbnailUrl: 'https://dyw7ncnq1en5l.cloudfront.net/test/17/17255/test-msi-gf63-4.jpg',
            },
            {
                originalUrl: 'https://dyw7ncnq1en5l.cloudfront.net/test/17/17255/test-msi-gf63-8.jpg',
                thumbnailUrl: 'https://dyw7ncnq1en5l.cloudfront.net/test/17/17255/test-msi-gf63-8.jpg',
            },
            {
                originalUrl: 'https://dyw7ncnq1en5l.cloudfront.net/test/17/17255/test-msi-gf63-3.jpg',
                thumbnailUrl: 'https://dyw7ncnq1en5l.cloudfront.net/test/17/17255/test-msi-gf63-3.jpg',
            }
        ],
        status: 'urgent',
        category: 'Informatique et multimédia',
        advertType: 'Offres',
        location: 'Aného',
        nbrView: 50,
        publicationDate: '17 mars',
        publicationHour: '16:40',
        price: 120000000,
        brand: 'MSI',
        model: 'GF 63 8RC',
        condition: 'Nouveau',
        sellerName: 'AGBEKEKELI',
        sellerPhone: '22222222',
        sellerWhatsapp: '22222222'
    },
    {
        id: 11,
        countryId: 4,
        cityId: 11,
        districtId: 11,
        shopId: 11,
        title: 'Téléphone portable Tecno spark 5',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://assets.afrikmag.com/uploads/2020/05/Spark2-780x405.png',
                thumbnailUrl: 'https://assets.afrikmag.com/uploads/2020/05/Spark2-780x405.png',
            },
        ],
        status: '',
        category: 'Informatique et multimédia',
        advertType: 'Offres',
        location: 'Aného',
        nbrView: 200,
        publicationDate: '17 mars',
        publicationHour: '16:40',
        price: 75000,
        brand: 'Tecno',
        model: 'Spark 5',
        condition: 'Nouveau',
        sellerName: 'AGBETROTRO',
        sellerPhone: '23232323',
        sellerWhatsapp: '23232323'
    },
    {
        id: 12,
        countryId: 4,
        cityId: 12,
        districtId: 12,
        shopId: 12,
        title: 'Maison a louer vers Bé kpota',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://images.unsplash.com/photo-1582268611958-ebfd161ef9cf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1582268611958-ebfd161ef9cf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
            },
        ],
        status: '',
        category: 'Immobilier',
        advertType: 'Offres',
        location: 'Lomé',
        nbrView: 100,
        publicationDate: '17 mars',
        publicationHour: '11:04',
        price: 1200000,
        nbrRoom: 7,
        nbrBathroom: 7,
        area: 1000,
        houseType: 'Villas',
        sellerName: 'AGBEKOKOKO',
        sellerPhone: '12121212',
        sellerWhatsapp: '12121212'
    },
    {
        id: 13,
        countryId: 5,
        cityId: 13,
        districtId: 13,
        shopId: 13,
        title: 'Toyota corolla dernier modèle',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://images.unsplash.com/photo-1594930197904-716c3369ae1a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1594930197904-716c3369ae1a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1595585022670-33cc4a47eac2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1454&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1595585022670-33cc4a47eac2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1454&q=80',
            },
        ],
        status: '',
        category: 'Autos et motos',
        advertType: 'Offres',
        location: 'Kovié',
        nbrView: 1000,
        publicationDate: '17 janvier',
        publicationHour: '10:40',
        price: 30000000,
        brand: 'Toyota',
        model: 'Land cruiser',
        condition: 'Nouveau',
        buildYear: 2020,
        kilometers: 0,
        fuel: 'Essence',
        sellerName: 'AGBEKEKEKE',
        sellerPhone: '62626262',
        sellerWhatsapp: '62626262'
    },
    {
        id: 14,
        countryId: 5,
        cityId: 14,
        districtId: 14,
        shopId: 14,
        title: 'Nike air force',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://images.unsplash.com/photo-1579338559194-a162d19bf842?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1579338559194-a162d19bf842?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1512374382149-233c42b6a83b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=675&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1512374382149-233c42b6a83b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=675&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1595950653106-6c9ebd614d3a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1595950653106-6c9ebd614d3a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1579338908476-3a3a1d71a706?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1579338908476-3a3a1d71a706?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
            },
        ],
        status: 'urgent',
        category: 'Habillement et bien-êtres',
        advertType: 'Offres',
        location: 'Blitta',
        nbrView: 5,
        publicationDate: '05 février',
        publicationHour: '20:40',
        price: 20000,
        sellerName: 'AGBEFOFOFO',
        sellerPhone: '31313131',
        sellerWhatsapp: '31313131'
    },
    {
        id: 15,
        countryId: 5,
        cityId: 15,
        districtId: 15,
        shopId: 15,
        title: 'Huawei P30 Pro',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://www.presse-citron.net/wordpress_prod/wp-content/uploads/2019/12/huaweip30pro.jpg',
                thumbnailUrl: 'https://www.presse-citron.net/wordpress_prod/wp-content/uploads/2019/12/huaweip30pro.jpg',
            },
        ],
        category: 'Informatique et multimédia',
        advertType: 'Offres',
        location: 'Nukafu',
        nbrView: 500,
        publicationDate: '02 Décembre',
        publicationHour: '02:40',
        price: 500000,
        brand: 'Huawei',
        model: 'P30 Pro',
        condition: 'Nouveau',
        sellerName: 'AGBETROTROKU',
        sellerPhone: '23232323',
        sellerWhatsapp: '23232323'
    },
    {
        id: 16,
        countryId: 6,
        cityId: 16,
        districtId: 16,
        shopId: 16,
        title: 'Iphone 11 pro',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://images.unsplash.com/photo-1581003989510-ba20094d343c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=672&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1581003989510-ba20094d343c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=672&q=80',
            },
        ],
        status: '',
        category: 'Informatique et multimédia',
        advertType: 'Offres',
        location: 'Nukafu',
        nbrView: 500,
        publicationDate: '02 Décembre',
        publicationHour: '02:40',
        price: 800000,
        brand: 'Iphone',
        model: '11 Pro',
        condition: 'Nouveau',
        sellerName: 'AGBETROTROKUKUI',
        sellerPhone: '23232320',
        sellerWhatsapp: '23232320'
    },
    {
        id: 17,
        countryId: 6,
        cityId: 17,
        districtId: 17,
        shopId: 1,
        title: 'Harley Davidson',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://images.unsplash.com/photo-1534752558854-d09c14294801?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1534752558854-d09c14294801?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1515777315835-281b94c9589f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1987&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1515777315835-281b94c9589f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1987&q=80',
            },
        ],
        status: '',
        category: 'Autos et motos',
        advertType: 'Offres',
        location: 'Dapaong',
        nbrView: 1000,
        publicationDate: '17 janvier',
        publicationHour: '10:40',
        price: 30000000,
        brand: 'Harley Davidson',
        model: 'Ranger',
        condition: 'Nouveau',
        buildYear: 2020,
        kilometers: 0,
        fuel: 'Essence',
        sellerName: 'AGBEVE',
        sellerPhone: '62623263',
        sellerWhatsapp: '62623263'
    },
    {
        id: 18,
        countryId: 6,
        cityId: 18,
        districtId: 18,
        shopId: 2,
        title: 'Ordinateur portable MSI new',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://dyw7ncnq1en5l.cloudfront.net/test/17/17255/test-msi-gf63-5.jpg',
                thumbnailUrl: 'https://dyw7ncnq1en5l.cloudfront.net/test/17/17255/test-msi-gf63-5.jpg',
            },
            {
                originalUrl: 'https://dyw7ncnq1en5l.cloudfront.net/test/17/17255/test-msi-gf63-4.jpg',
                thumbnailUrl: 'https://dyw7ncnq1en5l.cloudfront.net/test/17/17255/test-msi-gf63-4.jpg',
            },
            {
                originalUrl: 'https://dyw7ncnq1en5l.cloudfront.net/test/17/17255/test-msi-gf63-8.jpg',
                thumbnailUrl: 'https://dyw7ncnq1en5l.cloudfront.net/test/17/17255/test-msi-gf63-8.jpg',
            },
            {
                originalUrl: 'https://dyw7ncnq1en5l.cloudfront.net/test/17/17255/test-msi-gf63-3.jpg',
                thumbnailUrl: 'https://dyw7ncnq1en5l.cloudfront.net/test/17/17255/test-msi-gf63-3.jpg',
            }
        ],
        status: '',
        category: 'Informatique et multimédia',
        advertType: 'Offres',
        location: 'Aného',
        nbrView: 50,
        publicationDate: '17 mars',
        publicationHour: '16:40',
        price: 120000000,
        brand: 'MSI',
        model: 'GF 63 8RC',
        condition: 'Nouveau',
        sellerName: 'AGBEKEKELI',
        sellerPhone: '22222222',
        sellerWhatsapp: '22222222'
    },
    {
        id: 19,
        countryId: 7,
        cityId: 19,
        districtId: 19,
        shopId: 3,
        title: 'T-shirt original',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://images.unsplash.com/photo-1521572163474-6864f9cf17ab?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1521572163474-6864f9cf17ab?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1583743814966-8936f5b7be1a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1583743814966-8936f5b7be1a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1571455786673-9d9d6c194f90?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1571455786673-9d9d6c194f90?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1489987707025-afc232f7ea0f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1489987707025-afc232f7ea0f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1576566588028-4147f3842f27?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1576566588028-4147f3842f27?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80',
            }
        ],
        status: 'urgent',
        category: 'Habillement et bien-êtres',
        advertType: 'Offres',
        location: 'Kara',
        nbrView: 1,
        publicationDate: '17 mars',
        publicationHour: '16:40',
        price: 4000,
        sellerName: 'AGBEFOFO',
        sellerPhone: '33333333',
        sellerWhatsapp: '33333333'
    },
    {
        id: 20,
        countryId: 7,
        cityId: 20,
        districtId: 20,
        shopId: 4,
        title: 'Vente de terrain sur lot',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [],
        status: '',
        category: 'Immobilier',
        advertType: 'Offres',
        location: 'Lomé',
        nbrView: 800,
        publicationDate: '04 juin',
        publicationHour: '16:59',
        price: 200000000,
        sellerName: 'LASCO',
        sellerPhone: '44444444',
        sellerWhatsapp: '44444444'
    },
    {
        id: 21,
        countryId: 7,
        cityId: 21,
        districtId: 21,
        shopId: 4,
        title: 'Vente de sac Guicci',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://images.unsplash.com/photo-1548036328-c9fa89d128fa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1049&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1548036328-c9fa89d128fa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1049&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1590739225287-bd31519780c3?ixlib=rb-1.2.1&auto=format&fit=crop&w=611&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1590739225287-bd31519780c3?ixlib=rb-1.2.1&auto=format&fit=crop&w=611&q=80',
            },
        ],
        status: '',
        category: 'Habillement et bien-êtres',
        advertType: 'Offres',
        location: 'Attiégou',
        nbrView: 10,
        publicationDate: '20 fevrier',
        publicationHour: '10:40',
        price: 21000,
        sellerName: 'AGBELENKO',
        sellerPhone: '99999999',
        sellerWhatsapp: '99999999'
    },
    {
        id: 22,
        countryId: 8,
        cityId: 22,
        districtId: 22,
        shopId: 5,
        title: 'Téléphone portable Tecno spark 5',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://assets.afrikmag.com/uploads/2020/05/Spark2-780x405.png',
                thumbnailUrl: 'https://assets.afrikmag.com/uploads/2020/05/Spark2-780x405.png',
            },
        ],
        status: '',
        category: 'Informatique et multimédia',
        advertType: 'Offres',
        location: 'Atakpamé',
        nbrView: 30,
        publicationDate: '17 Novembre',
        publicationHour: '11:00',
        price: 75000,
        brand: 'Tecno',
        model: 'Spark 5',
        condition: 'Nouveau',
        sellerName: 'AGBETROTRO',
        sellerPhone: '23232323',
        sellerWhatsapp: '23232323'
    },
    {
        id: 23,
        countryId: 8,
        cityId: 23,
        districtId: 23,
        shopId: 6,
        title: 'Gros camion pour le transport de marchandises',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://images.unsplash.com/photo-1561157437-415893bd7149?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=865&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1561157437-415893bd7149?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=865&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1506306460327-3164753b74c7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1506306460327-3164753b74c7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1555907188-f9fd038c95d4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1555907188-f9fd038c95d4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1583559121633-63421224a8e1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1915&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1583559121633-63421224a8e1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1915&q=80',
            }
        ],
        status: '',
        category: 'Autos et motos',
        advertType: 'Offres',
        location: 'Tokoin Hopital',
        nbrView: 5,
        publicationDate: '17 mars',
        publicationHour: '16:40',
        price: '',
        brand: 'Benz',
        model: 'Acros',
        condition: 'Nouveau',
        buildYear: 2017,
        kilometers: 0,
        fuel: 'Gasoil',
        sellerName: 'AGBEPLETU',
        sellerPhone: '88888888',
        sellerWhatsapp: '88888888'
    },
    {
        id: 24,
        countryId: 8,
        cityId: 24,
        districtId: 24,
        shopId: 7,
        title: 'Moto de course KTM',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://images.unsplash.com/photo-1525013066836-c6090f0ad9d8?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1525013066836-c6090f0ad9d8?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1449426468159-d96dbf08f19f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1449426468159-d96dbf08f19f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
            }
        ],
        status: 'urgent',
        category: 'Autos et motos',
        advertType: 'Offres',
        location: 'Adakpamé',
        nbrView: 1000,
        publicationDate: '17 mars',
        publicationHour: '16:40',
        price: 3000000,
        brand: 'KTM',
        model: 'R-20',
        condition: 'Nouveau',
        buildYear: 2019,
        kilometers: 0,
        fuel: 'Essence',
        sellerName: 'AGBEKLANKO',
        sellerPhone: '77777777',
        sellerWhatsapp: '77777777'
    },
    {
        id: 25,
        countryId: 1,
        cityId: 2,
        districtId: 2,
        shopId: 2,
        title: 'Moto de course KTM',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://images.unsplash.com/photo-1525013066836-c6090f0ad9d8?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1525013066836-c6090f0ad9d8?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1449426468159-d96dbf08f19f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1449426468159-d96dbf08f19f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
            }
        ],
        status: '',
        category: 'Autos et motos',
        advertType: 'Offres',
        location: 'Adakpamé',
        nbrView: 1000,
        publicationDate: '17 mars',
        publicationHour: '16:40',
        price: 3000000,
        brand: 'KTM',
        model: 'R-20',
        condition: 'Nouveau',
        buildYear: 2019,
        kilometers: 0,
        fuel: 'Essence',
        sellerName: 'AGBEKLANKO',
        sellerPhone: '77777777',
        sellerWhatsapp: '77777777'
    },
    {
        id: 26,
        countryId: 1,
        cityId: 3,
        districtId: 3,
        shopId: 3,
        title: 'Gros camion pour le transport de marchandises',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://images.unsplash.com/photo-1561157437-415893bd7149?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=865&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1561157437-415893bd7149?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=865&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1506306460327-3164753b74c7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1506306460327-3164753b74c7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1555907188-f9fd038c95d4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1555907188-f9fd038c95d4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
            },
            {
                originalUrl: 'https://images.unsplash.com/photo-1583559121633-63421224a8e1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1915&q=80',
                thumbnailUrl: 'https://images.unsplash.com/photo-1583559121633-63421224a8e1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1915&q=80',
            }
        ],
        status: '',
        category: 'Autos et motos',
        advertType: 'Offres',
        location: 'Tokoin Hopital',
        nbrView: 5,
        publicationDate: '17 mars',
        publicationHour: '16:40',
        price: '',
        brand: 'Benz',
        model: 'Acros',
        condition: 'Nouveau',
        buildYear: 2017,
        kilometers: 0,
        fuel: 'Gasoil',
        sellerName: 'AGBEPLETU',
        sellerPhone: '88888888',
        sellerWhatsapp: '88888888'
    },
    {
        id: 27,
        countryId: 1,
        cityId: 3,
        districtId: 3,
        shopId: 3,
        title: 'Téléphone portable Tecno spark 5',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
        images: [
            {
                originalUrl: 'https://assets.afrikmag.com/uploads/2020/05/Spark2-780x405.png',
                thumbnailUrl: 'https://assets.afrikmag.com/uploads/2020/05/Spark2-780x405.png',
            },
        ],
        status: '',
        category: 'Informatique et multimédia',
        advertType: 'Offres',
        location: 'Atakpamé',
        nbrView: 30,
        publicationDate: '17 Novembre',
        publicationHour: '11:00',
        price: 75000,
        brand: 'Tecno',
        model: 'Spark 5',
        condition: 'Nouveau',
        sellerName: 'AGBETROTRO',
        sellerPhone: '23232323',
        sellerWhatsapp: '23232323'
    },
]

export const getAllAdvertisements = () => adverts;
export const getAdvertisementsByCountryId = (countryId) => adverts.filter((advertisement) => advertisement.countryId == countryId);
export const getAdvertisementsByCityId = (cityId) => adverts.filter((advertisement) => advertisement.cityId == cityId);
export const getAdvertisementsByDistrictId = (districtId) => adverts.filter((advertisement) => advertisement.districtId == districtId);
export const filterAdvertisements = (queryParams) =>
{
    let countryId = queryParams.countryId;
    let cityId = queryParams.cityId;
    let districtId = queryParams.districtId;
    let limit = queryParams.limit ? +queryParams.limit : 3;
    let offset = queryParams.offset ? +queryParams.offset : 0;
    let start = limit * offset;
    let end = (limit*offset)+(limit)

    let result = adverts;
    if(countryId)
        result = result.filter((advertisement) => advertisement.countryId == countryId);

    if(cityId)
        result = result.filter((advertisement) => advertisement.cityId == cityId);

    if(districtId)
        result = result.filter((advertisement) => advertisement.districtId == districtId);


    return { advertisements: result.slice(start, end), total : result.length };

};

export default {
    getAllAdvertisements,
    getAdvertisementsByCountryId,
    getAdvertisementsByCityId,
    getAdvertisementsByDistrictId,
    filterAdvertisements
};
