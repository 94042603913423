import { connect } from 'react-redux';
import SignupProForm from '../components/Signup/SignupProForm';

const mapStateToProps = state => ({
    categories: state.category.categories,
    currentCountry : state.country.currentCountry
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupProForm);