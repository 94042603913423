/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { uuid } from 'uuidv4';

import {
  MdChevronRight,
  MdClose,
} from 'react-icons/md';

import Loading from '../Loading';
import {staticApiUrl} from "../../utils/axios";

const CountryPopup = ({
  getAllCountries,
  countries,
  selected,
  countrySelected,
  closeCountryPopup,
  countriesLoaded
}) => {
  useEffect(() => {
    getAllCountries();
  }, []);

  let sortedCountries = countries.slice().sort((a, b) => a.order > b.order);
  console.warn('sortedCountries: ', sortedCountries);
  if (selected) {
    sortedCountries = sortedCountries.filter((country) => country.id !== selected.id);
  }

  const manageCloseCountryPopup = ({ target }) => {
    if (target.classList.contains('homepage-popup-container')) {
      closeCountryPopup();
    }
  };

  let url = `${staticApiUrl}/countryimages/`;

  // let filename = "no-image";
  // if (country && country.images) {
  //     filename = country.images[0].filename;
  // }

  const changeCountry = (country) => {
    countrySelected(country);
    closeCountryPopup();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div className="homepage-popup-container" onClick={manageCloseCountryPopup}>
      <div className="homepage-popup">
        <strong className="homepage-popup-header">
          Veuillez choisir un pays pour commencer
          <MdClose className="homepage-popup-close" onClick={closeCountryPopup} />
        </strong>
        <ul className="homepage-popup-list">
          <span className="homepage-popup-list-inner">
            { (selected) && (
                <li className="homepage-popup-list-inner-item">
                  <img src={selected.image} alt={selected.name} />
                  {selected.name}
                </li>
            ) }
            { (!countriesLoaded) && (
                <li className="homepage-popup-list-inner-item homepage-popup-list-inner-item-loading">
                  <Loading word="Chargement" />
                </li>
            ) }
            {
              sortedCountries.map((country) => (
                  <li
                      key={uuid()}
                      className="homepage-popup-list-inner-item"
                      onClick={() => changeCountry(country)}
                  >
                    {
                      country.images[0] !== undefined && (
                          <img src={url + country.images[0].filename} alt={country.name} />
                      )
                    }
                    {country.name}
                    <MdChevronRight className="homepage-popup-list-inner-item-chevron" />
                  </li>
              ))
            }
          </span>
        </ul>
      </div>
    </div>
  );
};

export default CountryPopup;
