

export function citiesToDialog(cities){
    let data = [];
    cities.forEach(city => {
            let ville = {
                id : city.id,
                title : city.name,
                items : [],
                type : "CITY"
            };
            city.zones.forEach(district => {
                ville.items.push({
                    id : district.id,
                    title : district.name,
                    type : "DISTRICT"
                })
            })

            data.push(ville);
        });

    return data;
}
