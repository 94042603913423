import React from 'react';

import quote from '../../imgs/quote.png';

const Testimonial = ({ testimonial }) => {
  const {
    review,
    name,
    country,
    image,
  } = testimonial;

  return (
    <article className="testimonial">
      <img src={quote} alt="" />
      <p>{review}</p>

      <div className="homepage-testimonials-user">
        <img src={image} alt={name} />
        <p>
          <strong className="bold">{name}</strong>
          <span className="country">{country}</span>
        </p>
      </div>
    </article>
  );
};

export default Testimonial;
