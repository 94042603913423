import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import Breadcrumbs from "../Breadcrumbs";
import { Redirect, useParams } from "react-router-dom";
import { FormControl, MenuItem, Select } from "@material-ui/core";

const OrderBy = ({
  counts,
  searchResult,
  isSearching,
  searchResultNumber,
  sortFunction,
  currentCategory,
  currentCity,
}) => {
  const currentCountry = useSelector((state) => state.country.currentCountry);
  const { countrySlug } = useParams();
  const [tri, setTri] = useState(2);
  const homeUrl = () => {
    if (currentCountry) {
      return `/${currentCountry.slug}/search`;
    }
    return "/search";
  };

  const handleChange = (event) => {
    setTri(event.target.value);
    sortFunction(event.target.value, searchResult);
  };
  const handleChange2 = (event) => {
    //console.log('SORT', event)
    setTri(event.target.value);
    sortFunction(event.target.value, searchResult);
  };

  if (currentCountry) {
    if (countrySlug) {
      if (countrySlug !== currentCountry.slug) {
        return <Redirect to={homeUrl()} />;
      }
    } else {
      return <Redirect to={homeUrl()} />;
    }
  }

  return (
    <section className="advertisements-orderby">
      <div className="advertisements-orderby-left">
        <div className="advertisements-orderby-left-breadcrumbs-wrapper">
          <Breadcrumbs
            past={[
              {
                url: `${homeUrl()}/search`,
                name: "Accueil",
              },
            ]}
            actual={`Tout "${currentCountry.name}"`}
          />
        </div>
        <div className="advertisements-orderby-left-results">
          <p className="advertisements-results">
            (
            {isSearching == 2
              ? searchResultNumber
                ? searchResultNumber
                : 0
              : counts
              ? counts.total_adverts
              : 0}
            ) Annonces dans{" "}
            <span style={{ fontWeight: "bold" }}>
              {currentCategory
                ? currentCategory.title || currentCategory.title_fr
                : "toutes les catégories"}
            </span>
            , Tout
            {currentCity ? (
              <span style={{ fontWeight: "bold" }}>" {currentCity.title}"</span>
            ) : (
              <span style={{ fontWeight: "bold" }}>
                " {currentCountry.name}"
              </span>
            )}
          </p>
        </div>
      </div>
      <div className="advertisements-orderby-right">
        <div className="advertisements-orderby-right-inner">
          <label htmlFor="orderby">
            <strong className="advertisements-orderby-right-text">Tri :</strong>
          </label>
          <FormControl>
            <Select
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              onChange={handleChange2}
              value={tri}
            >
              <MenuItem value="2">Plus récentes</MenuItem>
              <MenuItem value="1">Moins récentes</MenuItem>
              <MenuItem value="3">Moins cher</MenuItem>
              <MenuItem value="4">Plus cher</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </section>
  );
};

export default OrderBy;
