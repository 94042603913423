/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import {
  IoMdChatboxes,
  IoMdHeart,
} from 'react-icons/io';
import {
  MdPhone,
  MdWarning,
  MdShare,
  MdClose,
} from 'react-icons/md';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import {
  FaUserCircle,
} from 'react-icons/fa';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Header from '../../containers/Header';
import Breadcrumbs from '../Breadcrumbs';
import Footer from '../Footer';
import './Advertisement.scss';
import phillisonCo from '../../imgs/phillison-co.png';
import Button from "@material-ui/core/Button";
import ImageGallery from 'react-image-gallery';
import ReactWhatsapp from 'react-whatsapp';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from  "react-share";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import Advert from "../Advert/advert2";
import {othersAdvertisements, signalAdvert, advertViewIncrement, getAdvertById} from '../../services/advertisement';
import {setSavedAdvert, deleteUserSavedAdvert, getUserSavedAdvert} from '../../services/user_adverts';
import {IoMdHeartEmpty} from "react-icons/all";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {othersProAdverts} from "../../services/other-adverts";
import {Formik, Form} from "formik";
import * as Yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Redirect } from 'react-router-dom';
import Tooltip from "@material-ui/core/Tooltip";
import {staticApiUrl} from "../../utils/axios";
import LinkIcon from '@material-ui/icons/Link';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import getPathName from '../../utils/getPathName';
import {sendPostReportEmailToUser, sendPostReportEmailToTaari} from "../../services/emailjs";


moment.locale('fr');

let userBaseUrl = `${staticApiUrl}/userimages/`;
let adBaseUrl = `${staticApiUrl}/adimages/`;

let subj = '';
export class Advertisement extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = { past: [{
        url: '/', name: 'Accueil',},
        {
          url: '/search', name: 'Recherche',
        }],
      advertisement : {},
      showScroll : false,
      showSellerNumber : true,
      showShare: false,
      openReportDialog: false,
      openSnackBar: false,
      data: [],
      images: [],
      otherProAdverts: [],
      showChat: false,
      like: false,
      copied: false,
    };
    this.setMessage = props.setMessage;
    this.setAdvertToChat = props.setAdvertToChat;
    this.checkScroll = this.checkScroll.bind(this);
    this.handleOpenReport = this.handleOpenReport.bind(this);
    this.handleCloseReport = this.handleCloseReport.bind(this);
    this.copy = this.copy.bind(this);
    this.getAvert = this.getAvert.bind(this);
    window.addEventListener('scroll', this.checkScroll);
  }

  componentDidMount() {
    var id = getPathName();
    var data = {
      "advert_id": parseInt(id),
    };
    if (this.props?.currentCountry) {
      this.getOthersAdvertisements();
      this.getOthersProAdverts();
      this.loadSavedAdverts();
      advertViewIncrement(data);
    }
    this.getAvert(data)
  }

  /* Get All pro others */
  getOthersProAdverts = async () => {
    const proItems = {
      "user_id" : this.props?.advert?.user_id,
      "country_id" : this.props?.currentCountry.id
    }
    let res = await othersProAdverts(proItems);
    this.setState({otherProAdverts: res.data.RESPONSEDATA});
  }

  getOthersAdvertisements = async () => {
    var images = [];
    this.props.advert.images && this.props.advert.images.map((element)=>{
      images.push({
        original : adBaseUrl + element.filename,
        thumbnail : adBaseUrl + element.filename,
        thumbnailClass : "carouselThumnail",
      });
    });
    var obj = {
      'advert_id': this.props?.advert?.advert_id,
      'category_id': this.props?.advert?.category_id,
      'country_id': this.props?.currentCountry.id
    }
    let tab = await othersAdvertisements(obj);
    this.setState({data: tab.data.RESPONSEDATA, images: images});
    othersAdvertisements(obj).then(({data}) => {
      if (data.status === true) {
        this.setState({data: data.RESPONSEDATA, images: images});
      }
    }).catch(error => {
      console.warn(error);
    });
  }

  checkScroll () {
    if (! this.state.showScroll && window.pageYOffset > 450) {
      this.setState((state) => ({ showScroll : true }));
    } else if (this.state.showScroll && window.pageYOffset <= 450) {
      this.setState((state) => ({ showScroll : false }));
    }
  }

  handleOpenReport = () => {
    this.setState((state) => ({ openReportDialog : true }));
  };

  handleCloseReport = () => {
    this.setState((state) => ({ openReportDialog : false }));
  };

  handleAdvertChange = () => {
    window.location.reload();
    this.getOthersAdvertisements();
  };

  goToChat = () => {
    this.setAdvertToChat(this.props?.advert);
    this.setState({showChat: true});
  };

  signaler = (data) => {
    var body = {
      'advert_title': this.props?.advert?.title,
      'advert_category': this.props?.advert?.category_id,
      'motif': data.subject,
      'advert_user': this.props?.advert?.pro_rs || this.props?.advert?.first_name + ' ' + this.props?.advert?.last_name,
      'advert_id': this.props?.advert?.advert_id,
      'advert_user_id': this.props?.advert?.user_id,
      'advert_user_phone': this.props?.advert?.phone_indicatif + '' + this.props?.advert?.phone,
      'advert_user_email': this.props?.advert?.email ? this.props?.advert?.email : '',
      'sender_name': data.name,
      'sender_email': data.email,
      'sender_phone': data.phone,
      'message': data.message,
      'country_id': this.props?.currentCountry.id
    };

    //console.log('DATA', data)
    // SEND REPORT INFOS TO TAARI
    sendPostReportEmailToTaari({
      item_title: this.state.advertisement?.title,
      subject: data.subject,
      message: data.message,
      item_link: `https://taari.co/tg/advertisements/${this.state.advertisement?.title?.replaceAll(' ', '-')}/${this.props?.advert?.advert_id}`,

      first_name: data.name,
      email: data.email,
      phone_number: data.phone
    })
    .then((response)=>{
      this.props.setMessage("Signalement effectué avec succès!")
      this.setState((state) => ({ openReportDialog : false }));
    })
    .catch((error)=>{
      //this.props.setMessage("Signalement effectué avec succès!")
      this.setState((state) => ({ openReportDialog : false }));
    })

    // SEND REPORT INFOS TO USER
    sendPostReportEmailToUser({
      to_email: data.email,
      first_name: data.name,
      item_title: this.state.advertisement?.title,
      subject: data.subject
    })
    .then((response)=>{console.log('SEND EMAIL USER OK', response)})
    .catch((error)=>{console.log('SEND EMAIL USER ERROR', error)})



    signalAdvert(body).then(({data}) => {
        //console.log('DATA', data)
        // send email
   
  
      if (data.RESPONSECODE == 200) {
      
        //this.props.setMessage("Signalement effectué avec succès!");
        //this.setState((state) => ({ openReportDialog : false }));
      } else {
        this.setState((state) => ({ openReportDialog : false }));
        //this.props.setMessage("Signalement échoué!");
        //console.log('ERROR',data)
        //this.setState((state) => ({ openReportDialog : false }));
      }
    }).catch(error => {
      //this.props.setMessage("Signalement échoué!", error);
      //this.setState((state) => ({ openReportDialog : false }));
      //console.error(error);
    });
  };

  handleChangeSubject = (event) => {
    Formik.setFieldValue('subject', event.target.value, true);
    // setModels(filterModels.models);
  };

  handleFavoriteChange = (e) => {
    const { currentTarget } = e;
    const { checked } = currentTarget;
    if(this.props?.currentUser) {
      if (checked) {
        this.setState({like: true});
        var body = {
          'user_id': this.props?.currentUser.id,
          'advert_id': this.props?.advert?.advert_id,
          'country_id': this.props?.currentCountry.id,
        };
        setSavedAdvert(body).then(({data}) => {
          const {RESPONSECODE, RESPONSEMESSAGE} = data
          if (RESPONSECODE == 200) {
            this.setMessage(RESPONSEMESSAGE);
          }
          // else if (RESPONSECODE == 204) {
          //     this.setMessage(RESPONSEMESSAGE);
          // }
        }).catch(error => {
          console.warn(error);
        });
      } else {
        this.setState({like: false});
        let savedAdvert = this.props?.savedAdverts.find((elm) => {
          return  (elm.advert_id == this.props?.advert?.advert_id);
        });
        if (savedAdvert) {
          var body = {
            'saved_advert_id': savedAdvert.saved_id,
          };
          deleteUserSavedAdvert(body).then(({data}) => {
            const {RESPONSECODE, RESPONSEMESSAGE} = data
            if (RESPONSECODE == 200) {
              this.setMessage(RESPONSEMESSAGE);
            }
          }).catch(error => {
            console.warn(error);
          });
        }
      }
      this.loadSavedAdverts();
    } else {
      this.setState({like: false});
      this.setMessage('Connecter vous pour sauvegarder cette annonce');
    }
  };

  loadSavedAdverts = () => {
    if(this.props?.currentUser) {
      var body = {
        'user_id': this.props?.currentUser.id,
        'offset': 0,
        'limit': 15,
        'country_id': this.props?.currentCountry.id,
      };
      getUserSavedAdvert(body).then(({data}) => {
        const {RESPONSECODE, RESPONSEDATA} = data
        if (RESPONSECODE == 200) {
          let savedAdvert = RESPONSEDATA.find((elm) => {
            return  (elm.advert_id == this.props?.advert?.advert_id);
          });
          if (savedAdvert) {
            this.setState({like: true});
          }
          this.props.setSavedAdvertisements(RESPONSEDATA);
        } else if (RESPONSECODE == 204) {
          this.props.setSavedAdvertisements([]);
        }
      }).catch(error => {
        console.warn(error);
      }).finally(()=>{
      });
    }
  };

  copy = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.setState({copied: true});
    this.setMessage('Lien copié');
  }

  getAvert = (data) => {
    let images = []
    getAdvertById(data).then(({data}) => {
      console.log(data);
      data.images && data.images.map((element)=>{
        images.push({
          original : adBaseUrl + element.filename,
          thumbnail : adBaseUrl + element.filename,
          thumbnailClass : "carouselThumnail",
        });
      });
      this.setState({advertisement: data, images: images})
    }).catch(error => {
      console.error(error);
    })
  }

  render() {
    const slugify = () => {
      const regexSpaces = /(\s+|-+|_+)/gm;
      const regexDashes = /-+/gm;
      let name = this.state.advertisement.first_name.toLowerCase();
      name = name.replace(regexSpaces, '-');
      name = name.replace(regexDashes, '-');
      return `${name}`;
    };

    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 769 },
        items: 3,
        partialVisibilityGutter: 10,
        slidesToSlide: 1 // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 2,
        partialVisibilityGutter: 10,
        slidesToSlide: 1 // optional, default to 1.
      }
    };

    let filename = "no-image";
    if (this.state.advertisement?.images && this.state.advertisement?.images?.length !== 0) {
      filename = this.state.advertisement?.images[0].filename;
    }

    const REGEX_GROUPS = /(\d)(?=(\d\d\d)+(?!\d))/g
    function formatting(int, delimiter = '\u202f') {
      return int?.toString()?.replace(REGEX_GROUPS, `$1${delimiter}`)
    }

    const validationSchema = Yup.object().shape({
      email: Yup.string()
          .email('Courriel invalide')
          .required('Ce champ est requis'),
      message: Yup.string()
          .required('Ce champ est requis'),
      subject: Yup.string()
          .required('Ce champ est requis'),
      name: Yup.string()
          .required('Ce champ est requis'),
      phone: Yup.string()
          .required('Ce champ est requis'),
    });

    /* Read more script */
    const ReadMore = ({ children }) => {
      const text = children;
      const [isReadMore, setIsReadMore] = useState(true);
      const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
      };
      return (
          <div className="read-more-text">
            {isReadMore ? text.slice(0, 250) : text}
            {
              text.length >= 250 ? (
                  <span onClick={toggleReadMore} className="read-or-hide"> {
                    isReadMore ? (
                        <span className="read-more">...Voir plus<ArrowDownwardIcon/></span>
                    ) : (<span className="read-less">Voir moins <ArrowUpwardIcon/></span>)
                  }
                  </span>
              ) : ('')
            }
          </div>
      );
    };




    //console.log('log of detail', this.state.advertisement)
    return (
        <div className="advert-details-page">
          <Header />
          {this.state.showChat && <Redirect to="/dashboard/messages" />}
          <div className={"details-page-scroll-header " + ( this.state.showScroll ? "show" : "")}>
            <div className="details-page-scroll-header-left">
              <div className="details-page-scroll-header-left-image">
                { this.state.advertisement.images ?
                    (
                        <img src={adBaseUrl + this.state.advertisement.images[0].filename} alt={this.state.advertisement?.title} />
                    ) : (
                        <img src={adBaseUrl + 'default_image.png'} alt="" />
                    )
                }
              </div>
              <div className="details-page-scroll-header-left-text">
                <div className="details-page-scroll-header-left-text-title">
                  <h3>{this.state.advertisement?.title}</h3>
                </div>
                <div className="details-page-scroll-header-left-text-price">
                  {
                    (this.state.advertisement?.price == null || this.state.advertisement?.price == "0") ? (
                        <p>Me contacter</p>
                    ) : (
                        <p>
                          {formatting(this.state.advertisement?.price)} {this.props?.currentCountry?.currency[0].slug}
                        </p>
                    )
                  }
                  {
                    ((this.state.advertisement?.price !== null || this.state.advertisement?.price !== "0") && (this.state.advertisement?.negotiable === "1")) ? (
                        <span className="negociable">Négociable</span>
                    ) : ('')
                  }
                </div>
              </div>
            </div>
            <div className="details-page-scroll-header-right">
              {
                (this.props?.currentUser && this.props?.currentUser.id) !== this.state.advertisement?.user_id && (
                    <div className="details-page-scroll-header-right-inner">
                      {
                        (this.state.advertisement?.hidden_phone === "0")  ?
                            (
                                <a href={`tel:` + '+' + this.state.advertisement?.phone_indicatif + this.state.advertisement?.phone} className="details-page-scroll-header-right-inner-button upper orange">
                                  <MdPhone />
                                  {"+" + this.state.advertisement?.phone_indicatif + this.state.advertisement?.phone}
                                </a>
                            ) : (
                                <Tooltip className="hidden-message" title="Ce utilisateur ne souhaite pas afficher son numéro" arrow placement="top">
                                  <Button className="hidden-phone details-page-scroll-header-right-inner-button upper orange">
                                    <MdPhone /> Afficher le numéro
                                  </Button>
                                </Tooltip>
                            )
                      }
                      <Button className="details-page-scroll-header-right-inner-button upper outline" onClick={() => this.goToChat()}>
                        <IoMdChatboxes />Chat
                      </Button>
                      <ReactWhatsapp className="details-page-scroll-header-right-inner-button upper green" number={"+" + this.state.advertisement?.phone_indicatif + this.state.advertisement?.phone} message={`Bonjour ${this.state.advertisement?.usertype == 1 ? this.state.advertisement?.first_name : this.state.advertisement?.pro_rs}, l'article ${this.state.advertisement?.title} est-il toujours disponible?`}>
                        Contacter sur Whatsapp
                      </ReactWhatsapp>
                    </div>
                )
              }
            </div>
          </div>
          <section className="advertisement">
            <div className="container">
              <Breadcrumbs
                  past={this.state.past}
                  actual={this.state.advertisement?.title}
              />
              <div className="advertisement-flexbox">
                <div className="advertisement-primary">
                  <div className="advertisement-primary-carousel">
                    {
                      this.state.advertisement?.images?.length === 0 ?
                          (
                              <img className="default-image" src={adBaseUrl + "/default_image.png"} alt="" />
                          ) :
                          (
                              <div className="advertisement-primary-carousel-container-inner">
                                <ImageGallery showPlayButton={false} items={this.state.images} showIndex={true} useBrowserFullscreen={false} />
                              </div>
                          )
                    }
                  </div>
                  <div className="advertisement-primary-details">
                    <strong className="advertisement-primary-details-title upper bold">
                      {this.state.advertisement?.title}
                    </strong>
                    <ul className="mobile-price">
                      <li>
                        {
                          this.state.advertisement?.price !== null ?
                              (
                                  <span>
                                    {formatting(this.state.advertisement?.price)} {this.props?.currentCountry?.currency[0].slug}
                                  </span>
                              ) :
                              (
                                  <span>Me contacter</span>
                              )
                        }
                      </li>
                      {/* <li>
                        {this.state.advertisement?.publicationDate} à {this.state.advertisement?.publicationHour}
                      </li>*/}
                    </ul>
                    <div className="shadow">
                      <ul className="advertisement-primary-details-table">
                        <li className="advertisement-primary-details-table-item price">
                          <small className="advertisement-primary-details-table-item-left">Prix</small>
                          <em className="advertisement-primary-details-table-item-left orange bold price-wrapper">
                            {
                              (this.state.advertisement?.price == null || this.state.advertisement?.price == "0") ?
                                  (
                                      <span>Me contacter</span>
                                  ) :
                                  (
                                      <span>
                                        {formatting(this.state.advertisement?.price)} {this.props?.currentCountry?.currency[0].slug}
                                      </span>
                                  )
                            }
                            {
                              ((this.state.advertisement?.price !== null || this.state.advertisement?.price !== "0") && (this.state.advertisement?.negotiable === "1")) ? (
                                  <span className="negociable">Négociable</span>
                              ) : ('')
                            }
                          </em>
                        </li>
                        <li className="advertisement-primary-details-table-item">
                          <small className="advertisement-primary-details-table-item-left">Type</small>
                          <em className="advertisement-primary-details-table-item-left">
                            {this.state.advertisement?.type_advert_name}
                          </em>
                        </li>
                        <li className="advertisement-primary-details-table-item">
                          <small className="advertisement-primary-details-table-item-left">Catégorie</small>
                          <em className="advertisement-primary-details-table-item-left">
                            {this.state.advertisement?.category}
                          </em>
                        </li>
                        <li className="advertisement-primary-details-table-item">
                          <small className="advertisement-primary-details-table-item-left">Ville</small>
                          <em className="advertisement-primary-details-table-item-left">
                            {this.state.advertisement?.city_name} {this.state.advertisement?.zone_name?.name !== '' ? ' / ' + this.state.advertisement?.zone_name?.name : ''}
                          </em>
                        </li>
                        <li className="advertisement-primary-details-table-item">
                          <small className="advertisement-primary-details-table-item-left">Publiée le</small>
                          <em className="advertisement-primary-details-table-item-left">
                            <span>{moment.utc(this.state.advertisement?.created_at).format('DD-MM-YYYY HH:MM')} </span>
                          </em>
                        </li>
                        <li className="advertisement-primary-details-table-item">
                          <small className="advertisement-primary-details-table-item-left">Vues</small>
                          <em className="advertisement-primary-details-table-item-left">
                            {this.state.advertisement?.views}
                          </em>
                        </li>
                        {/*Brand and model */}
                        {
                          ["57", "79", "80", "81", "82", "141", "142", "143", "144"].includes(this.state.advertisement?.category_id) ? (
                              <li className="advertisement-primary-details-table-item">
                                <small className="advertisement-primary-details-table-item-left">Marque</small>
                                <em className="advertisement-primary-details-table-item-left">
                                  {
                                    this.state.advertisement?.brand_name.name !== null ? (
                                        this.state.advertisement?.brand_name.name
                                    ) : ('-')
                                  }
                                </em>
                              </li>
                          ) : ('')
                        }
                        {
                          ["57", "79", "80", "81", "82", "141", "142", "143", "144"].includes(this.state.advertisement?.category_id) ? (
                              <li className="advertisement-primary-details-table-item">
                                <small className="advertisement-primary-details-table-item-left">Modèle</small>
                                <em className="advertisement-primary-details-table-item-left">
                                  {
                                    this.state.advertisement?.model_name.name !== "" ? (
                                        this.state.advertisement?.model_name.name
                                    ) : ('-')
                                  }
                                </em>
                              </li>
                          ) : ('')
                        }
                        {/*Autos motos*/}
                        {
                          ["69", "70", "71", "72", "74", "203"].includes(this.state.advertisement?.category_id) ? (
                              <li className="advertisement-primary-details-table-item">
                                <small className="advertisement-primary-details-table-item-left">Année de sortie</small>
                                <em className="advertisement-primary-details-table-item-left">
                                  {
                                    this.state.advertisement?.year !== null ? (
                                        this.state.advertisement?.year
                                    ) : ('-')
                                  }
                                </em>
                              </li>
                          ) : ('')
                        }
                        {
                          ["69", "70", "71", "72", "74", "203"].includes(this.state.advertisement?.category_id) ? (
                              <li className="advertisement-primary-details-table-item">
                                <small className="advertisement-primary-details-table-item-left">Kilomètrage</small>
                                <em className="advertisement-primary-details-table-item-left">
                                  {
                                    (this.state.advertisement?.mileage !== null || 0) ? (
                                        this.state.advertisement?.mileage + ' Km'
                                    ) : ('-')
                                  }
                                </em>
                              </li>
                          ) : ('')
                        }
                        {
                          (
                              this.state.advertisement?.category_id == "55" ||
                              this.state.advertisement?.category_id == "69" ||
                              this.state.advertisement?.category_id == "70" ||
                              this.state.advertisement?.category_id == "71" ||
                              this.state.advertisement?.category_id == "73" ||
                              this.state.advertisement?.category_id == "74" ||
                              this.state.advertisement?.category_id == "146" ||
                              this.state.advertisement?.category_id == "147" ||
                              this.state.advertisement?.category_id == "157" ||
                              this.state.advertisement?.category_id == "203"
                          ) ? (
                              <li className="advertisement-primary-details-table-item">
                                <small className="advertisement-primary-details-table-item-left">Carburant</small>
                                <em className="advertisement-primary-details-table-item-left">
                                  {
                                    this.state.advertisement?.fuel_type && this.state.advertisement?.fuel_type.length !== 0 ? (
                                        this.state.advertisement?.fuel_type[0].name
                                    ) : ('-')
                                  }
                                </em>
                              </li>
                          ) : ('')
                        }
                        {
                          ["69", "70", "71", "72", "74", "203"].includes(this.state.advertisement?.category_id) ? (
                              <li className="advertisement-primary-details-table-item">
                                <small className="advertisement-primary-details-table-item-left">Nombre de portes</small>
                                <em className="advertisement-primary-details-table-item-left">
                                  {
                                    this.state.advertisement?.nb_door !== null ? (
                                        this.state.advertisement?.nb_door
                                    ) : ('-')
                                  }
                                </em>
                              </li>
                          ) : ('')
                        }
                        {
                          ["69", "70", "71", "72", "74", "203"].includes(this.state.advertisement?.category_id) ? (
                              <li className="advertisement-primary-details-table-item">
                                <small className="advertisement-primary-details-table-item-left">Nombre de places</small>
                                <em className="advertisement-primary-details-table-item-left">
                                  {
                                    this.state.advertisement?.nb_seat !== null ? (
                                        this.state.advertisement?.nb_seat
                                    ) : ('-')
                                  }
                                </em>
                              </li>
                          ) : ('')
                        }
                        {/*Estate*/}
                        {
                          (
                              this.state.advertisement?.category_id == "13" ||
                              this.state.advertisement?.category_id == "65" ||
                              this.state.advertisement?.category_id == "216" ||
                              this.state.advertisement?.category_id == "217" ||
                              this.state.advertisement?.category_id == "218" ||
                              this.state.advertisement?.category_id == "219" ||
                              this.state.advertisement?.category_id == "66"||
                              this.state.advertisement?.category_id == "67" ||
                              this.state.advertisement?.category_id == "148" ||
                              this.state.advertisement?.category_id == "149" ||
                              this.state.advertisement?.category_id == "150" ||
                              this.state.advertisement?.category_id == "151"
                          ) ? (
                              <li className="advertisement-primary-details-table-item">
                                <small className="advertisement-primary-details-table-item-left">Surface</small>
                                <em className="advertisement-primary-details-table-item-left">
                                  {
                                    this.state.advertisement?.area !== null ? (
                                        this.state.advertisement?.area + 'm²'
                                    ) : ('-')
                                  }
                                </em>
                              </li>
                          ) : ('')
                        }
                        {
                          (
                              this.state.advertisement?.category_id == "13" ||
                              this.state.advertisement?.category_id == "65" ||
                              this.state.advertisement?.category_id == "216" ||
                              this.state.advertisement?.category_id == "217" ||
                              this.state.advertisement?.category_id == "218" ||
                              this.state.advertisement?.category_id == "219" ||
                              this.state.advertisement?.category_id == "66"||
                              this.state.advertisement?.category_id == "67" ||
                              this.state.advertisement?.category_id == "148" ||
                              this.state.advertisement?.category_id == "149" ||
                              this.state.advertisement?.category_id == "150" ||
                              this.state.advertisement?.category_id == "151"
                          ) ? (
                              <li className="advertisement-primary-details-table-item">
                                <small className="advertisement-primary-details-table-item-left">Nombre de chambres</small>
                                <em className="advertisement-primary-details-table-item-left">
                                  {
                                    this.state.advertisement?.nb_rooms !== null ? (
                                        this.state.advertisement?.nb_rooms
                                    ) : ('-')
                                  }
                                </em>
                              </li>
                          ) : ('')
                        }
                        {
                          (
                              this.state.advertisement?.category_id == "13" ||
                              this.state.advertisement?.category_id == "65" ||
                              this.state.advertisement?.category_id == "66"||
                              this.state.advertisement?.category_id == "67" ||
                              this.state.advertisement?.category_id == "148" ||
                              this.state.advertisement?.category_id == "149" ||
                              this.state.advertisement?.category_id == "150" ||
                              this.state.advertisement?.category_id == "151" ||
                              this.state.advertisement?.category_id == "105" ||
                              this.state.advertisement?.category_id == "106" ||
                              this.state.advertisement?.category_id == "107" ||
                              this.state.advertisement?.category_id == "108" ||
                              this.state.advertisement?.category_id == "109" ||
                              this.state.advertisement?.category_id == "174" ||
                              this.state.advertisement?.category_id == "110" ||
                              this.state.advertisement?.category_id == "111" ||
                              this.state.advertisement?.category_id == "112" ||
                              this.state.advertisement?.category_id == "113" ||
                              this.state.advertisement?.category_id == "114" ||
                              this.state.advertisement?.category_id == "115" ||
                              this.state.advertisement?.category_id == "116" ||
                              this.state.advertisement?.category_id == "216" ||
                              this.state.advertisement?.category_id == "217" ||
                              this.state.advertisement?.category_id == "218" ||
                              this.state.advertisement?.category_id == "219" ||
                              this.state.advertisement?.category_id == "162" ||
                              this.state.advertisement?.category_id == "163" ||
                              this.state.advertisement?.category_id == "164" ||
                              this.state.advertisement?.category_id == "165" ||
                              this.state.advertisement?.category_id == "166" ||
                              this.state.advertisement?.category_id == "167" ||
                              this.state.advertisement?.category_id == "168" ||
                              this.state.advertisement?.category_id == "169" ||
                              this.state.advertisement?.category_id == "170" ||
                              this.state.advertisement?.category_id == "172" ||
                              this.state.advertisement?.category_id == "173" ||
                              this.state.advertisement?.category_id == "175" ||
                              this.state.advertisement?.category_id == "176"
                          ) ? (
                              (this.state.advertisement?.pricing_type && this.state.advertisement?.pricing_type.length !== 0) && <li className="advertisement-primary-details-table-item">
                                <small className="advertisement-primary-details-table-item-left">Type de tarification</small>
                                <em className="advertisement-primary-details-table-item-left">
                                  {this.state.advertisement?.pricing_type[0].name}
                                </em>
                              </li>
                          ) : ('')
                        }
                        {/*State*/}
                        {
                          (this.state.advertisement?.categoryId == null) ? (
                              <li className="advertisement-primary-details-table-item">
                                <small className="advertisement-primary-details-table-item-left">Etat du produit</small>
                                {
                                  this.state.advertisement?.state !== '0' ? (
                                      <em className="advertisement-primary-details-table-item-left">
                                        {
                                          this.state.advertisement?.state
                                        }
                                      </em>
                                  ) : ('-')
                                }
                              </li>
                          ) : ('')
                        }
                        {/* Phones, laptops, desktops, tablets*/}
                        {
                          ["79", "80", "81", "82"].includes(this.state.advertisement?.category_id) ? (
                              <li className="advertisement-primary-details-table-item">
                                <small className="advertisement-primary-details-table-item-left">Capacité de Stockage</small>
                                <em className="advertisement-primary-details-table-item-left">
                                  {
                                    this.state.advertisement?.storage !== null ? (
                                        this.state.advertisement?.storage + ' Go'
                                    ) : ('')
                                  }
                                </em>
                              </li>
                          ) : ('')
                        }
                        {
                          ["79", "80", "81", "82"].includes(this.state.advertisement?.category_id) ? (
                              <li className="advertisement-primary-details-table-item">
                                <small className="advertisement-primary-details-table-item-left">Mémoire RAM</small>
                                <em className="advertisement-primary-details-table-item-left">
                                  {
                                    this.state.advertisement?.memory !== null ? (
                                        this.state.advertisement?.memory + ' Go'
                                    ) : ('-')
                                  }
                                </em>
                              </li>
                          ) : ('')
                        }
                        {/*Colors*/}
                        {
                          (
                              this.state.advertisement?.category_id == "55" ||
                              this.state.advertisement?.category_id == "69" ||
                              this.state.advertisement?.category_id == "70" ||
                              this.state.advertisement?.category_id == "71" ||
                              this.state.advertisement?.category_id == "72" ||
                              this.state.advertisement?.category_id == "73" ||
                              this.state.advertisement?.category_id == "74" ||
                              this.state.advertisement?.category_id == "75" ||
                              this.state.advertisement?.category_id == "76" ||
                              this.state.advertisement?.category_id == "78" ||
                              this.state.advertisement?.category_id == "146" ||
                              this.state.advertisement?.category_id == "147" ||
                              this.state.advertisement?.category_id == "152" ||
                              this.state.advertisement?.category_id == "157" ||
                              this.state.advertisement?.category_id == "191" ||
                              this.state.advertisement?.category_id == "203"
                          ) ? (
                              this.state.advertisement?.advert_colors !== null ? (
                                  <li className="advertisement-primary-details-table-item colors">
                                    <small className="advertisement-primary-details-table-item-left">Couleur(s) disponible(s) pour ce produit :</small>
                                    <div className="advertisement-primary-details-table-item-left">
                                      <ul>
                                        {
                                          this.state.advertisement?.advert_colors && this.state.advertisement?.advert_colors.map((color) => {
                                            return <li key={color.id}>{color.name}</li>
                                          })
                                        }
                                      </ul>
                                    </div>
                                  </li>
                              ) : ('')
                          ) : ('')
                        }
                      </ul>
                      <div className="advertisement-primary-details-description">
                        <h4 className="advertisement-primary-details-description-title">Description du produit</h4>
                        <div className="description-text">
                          {/* <ReadMore>{this.state.advertisement?.description}</ReadMore> */}
                          {/* { ReactHtmlParser(this.state.advertisement?.description) } */}
                        </div>
                        <div className="description-text" dangerouslySetInnerHTML={{__html: this.state.advertisement?.description}} />
   
                      </div>
                      <div className="advertisement-primary-details-links">
                        <div className="advertisement-primary-details-links-button">
                          <Button onClick={this.handleOpenReport}>
                            <MdWarning />
                            Signaler
                          </Button>
                          <Dialog
                              className="dialog"
                              scroll="body"
                              open={this.state.openReportDialog}
                              onClose={this.handleCloseReport}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description">
                            <IconButton aria-label="close" className="closeButton" onClick={this.handleCloseReport}>
                              <MdClose />
                            </IconButton>
                            <div className="change-container alert-add-modal">
                              <DialogTitle id="alert-dialog-title">{"Signaler une annonce"}</DialogTitle>
                              <Formik
                                  initialValues={{ name: "", email: "", subject: "", message: "", phone: "" }}
                                  validationSchema={validationSchema}
                                  onSubmit={(fields) => {
                                    this.signaler(fields)
                                  }}
                              >
                                {({values,errors, touched, handleChange,
                                    handleBlur, handleSubmit, isSubmitting,}) => (
                                    <Form onSubmit={handleSubmit}>
                                      <DialogContent>
                                        <div className="dialog-container">
                                          <div className="dialog-container-item">
                                            <FormControl variant="outlined" className="select-pattern">
                                              <InputLabel id="brand-label">Choisir un motif</InputLabel>
                                              <Select
                                                  name="subject"
                                                  labelId="brand-label"
                                                  id="subject-select"
                                                  label="Sujet de votre question"
                                                  onBlur={handleBlur}
                                                  onChange={(e) => {
                                                    subj = e.target.value;
                                                    handleChange(e);
                                                  }}
                                                  value={values.subject}
                                              >
                                                <MenuItem value="">Aucun sujet</MenuItem>
                                                <MenuItem value="Fraude">Fraude</MenuItem>
                                                <MenuItem value="Doublon">Doublon</MenuItem>
                                                <MenuItem value="Mauvaise catégorie">Mauvaise catégorie</MenuItem>
                                                <MenuItem value="Mauvaise Photo">Mauvaise Photo</MenuItem>
                                                <MenuItem value="Faux numéro">Faux numéro</MenuItem>
                                                <MenuItem value="Article déjà vu">Article déjà vu</MenuItem>
                                                <MenuItem value="Mauvais prix">Mauvais prix</MenuItem>
                                              </Select>
                                            </FormControl>
                                            {errors.subject && touched.subject &&
                                            <p className="errorMessage">
                                              {errors.subject}
                                            </p>}
                                          </div>
                                          <div className="dialog-container-item">
                                            <TextField
                                                name="name"
                                                id="name"
                                                label="Votre nom"
                                                type="text"
                                                variant="outlined"
                                                size="small"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.name}
                                            />
                                            {errors.name && touched.name &&
                                            <p className="errorMessage">
                                              {errors.name}
                                            </p>}
                                          </div>
                                          <div className="dialog-container-item">
                                            <TextField
                                                name="email"
                                                id="email"
                                                label="Votre email"
                                                type="text"
                                                variant="outlined"
                                                size="small"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.email}
                                            />
                                            {errors.email && touched.email &&
                                            <p className="errorMessage">
                                              {errors.email}
                                            </p>}
                                          </div>
                                          <div className="dialog-container-item">
                                            <TextField
                                                name="phone"
                                                id="phone"
                                                label="Votre téléphone"
                                                type="text"
                                                variant="outlined"
                                                size="small"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.phone}
                                            />
                                            {errors.phone && touched.phone &&
                                            <p className="errorMessage">
                                              {errors.phone}
                                            </p>}
                                          </div>
                                          <div className="dialog-container-item">
                                            <TextField
                                                name="message"
                                                id="outlined-multiline-static"
                                                label="Votre message"
                                                multiline
                                                rows={3}
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.message}
                                            />
                                            {errors.message && touched.message &&
                                            <p className="errorMessage">
                                              {errors.message}
                                            </p>}
                                          </div>
                                        </div>
                                      </DialogContent>
                                      <DialogActions className="confirmation-button">
                                        <Button onClick={this.handleCloseReport} color="primary">
                                          Annuler
                                        </Button>
                                        <Button type="submit" disabled={isSubmitting} color="primary">
                                          Envoyer
                                          {
                                            isSubmitting ?
                                                (<CircularProgress />) : ('')
                                          }
                                        </Button>
                                      </DialogActions>
                                    </Form>
                                )}
                              </Formik>
                            </div>
                          </Dialog>
                        </div>
                        <div className="advertisement-primary-details-links-button">
                          <input type="checkbox" checked={this.state.like} id={`advert--${this.state.advertisement?.advert_id}`} onChange={this.handleFavoriteChange} />
                          <label htmlFor={`advert--${this.state.advertisement?.advert_id}`}>
                            <Button>
                              <IoMdHeartEmpty className="switch-off" />
                              <IoMdHeart className="switch-on" />
                              Sauvegarder
                            </Button>
                          </label>
                        </div>
                        <div className="share-wrapper">
                          <Button className="share-button" onClick={ () => this.setState({showShare: !this.state.showShare}) }>
                            <MdShare />
                            Partager
                          </Button>
                          <div className={"share-buttons-content " + ( this.state.showShare ? "show" : "")}>
                            <ul>
                              <li>
                                <FacebookShareButton
                                    url={window.location.href}
                                    quote={this.state.advertisement?.title}
                                    /*imageURL={this.state.advertisement?.images[0].originalUrl}*/
                                    /*hashtag="#camperstribe"*/>
                                  <FacebookIcon size={32} round={true} />
                                </FacebookShareButton>
                              </li>
                              <li>
                                <TwitterShareButton
                                    url={window.location.href}
                                    title={this.state.advertisement?.title}>
                                  <TwitterIcon size={32} round={true} />
                                </TwitterShareButton>
                              </li>
                              <li>
                                <WhatsappShareButton
                                    url={window.location.href}
                                    title={`Bonjour! Voici une annonce intéressante que je viens de trouver sur Taari... `}
                                >
                                  <WhatsappIcon  size={32} round={true} />
                                </WhatsappShareButton>
                              </li>
                              <li>
                                <EmailShareButton
                                    url={window.location.href}
                                    quote={this.state.advertisement?.title}>
                                  <EmailIcon size={32} round={true} />
                                </EmailShareButton>
                              </li>
                              <li>
                                <Button onClick={this.copy} className="copy-link"><LinkIcon /></Button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    this.state.advertisement?.usertype == 2 ? (
                        <div className="advertisement-primary-pro-adverts">
                          <div className="advertisement-primary-pro-adverts-title">
                            <h2>Autre annonces de ce pro</h2>
                            <Link to={{
                              pathname: `/boutiques/${slugify()}/${this.state.advertisement?.user_id}`,
                              state: this.state.advertisement,
                            }}
                            >Voir la boutique <ArrowForwardIosIcon/></Link>
                          </div>
                          <div className="advertisement-primary-pro-adverts-container">
                            {
                              this.state.otherProAdverts && this.state.otherProAdverts.map((advert) => {
                                return (
                                    <Advert key={advert.advert_id} object={advert} handleAdvertChange={() => this.handleAdvertChange()} />
                                )
                              })
                            }
                          </div>
                        </div>
                    ) : ('')
                  }
                  {
                    this.state.data && this.state.data.length !== 0 && (
                        <div className="advertisement-primary-others">
                          <h2>D'autres annonces qui pourraient vous interesser</h2>
                          <div className="advertisement-others-content">
                            <Carousel
                                swipeable={false}
                                draggable={false}
                                showDots={false}
                                responsive={responsive}
                                ssr={true} // means to render carousel on server-side.
                                infinite={false}
                                autoPlay={false}
                                keyBoardControl={true}
                                transitionDuration={1000}
                                containerClass="carousel-container"
                                deviceType={this.props?.deviceType}
                                itemClass="carousel-item">
                              {
                                this.state.data && this.state.data.map((advert)=> {
                                  return (
                                      <Advert key={advert.advert_id} object={advert} handleAdvertChange={() => this.handleAdvertChange()} />
                                  )
                                }).slice(0, 6)
                              }
                            </Carousel>
                          </div>
                        </div>
                    )
                  }
                </div>
                <aside className="advertisement-secondary">
                  <div className="advertisement-secondary-seller">

                    {this.state.advertisement?.usertype == 1 && (
                        <div className="particular">
                          <Link to={{
                            pathname: `/profile/${this.state.advertisement?.user_id}`,
                            state: this.state.advertisement,
                          }}>
                            <span className="advertisement-secondary-seller-header upper bold">
                              <span className="advertisement-secondary-seller-header-left">

                                <div className="entreprise-name-left">
                                  {
                                    this.state.advertisement?.user_image && this.state.advertisement?.user_image.length === 0 ? (
                                        <FaUserCircle />
                                    ) : (
                                        <img src={userBaseUrl+this.state.advertisement?.user_image[0].filename}  alt="App Store"/>
                                    )
                                  }
                                </div>
                                <p style={{marginLeft: 10}}>{this.state.advertisement?.first_name}</p>
                              </span>
                                  <span className="advertisement-secondary-seller-header-right">
                                <ArrowForwardIosIcon />
                             </span>
                            </span>
                          </Link>
                        </div>
                    )}

                    {this.state.advertisement?.usertype == 2 && (
                        <div className="entreprise">
                          <div className="back-space"></div>
                          <div className="entreprise-name">
                            <div className="entreprise-name-left">
                              {
                                this.state.advertisement?.user_image && this.state.advertisement?.user_image.length === 0 ? (
                                    <img src={phillisonCo} alt="App Store" />
                                ) : (
                                    <img src={userBaseUrl+this.state.advertisement?.user_image[0].filename}  alt="App Store"/>
                                )
                              }
                            </div>
                            <div className="entreprise-name-right">
                              <h4>{this.state.advertisement?.pro_rs}</h4>
                            </div>
                          </div>
                          {/* <div className="entreprise-activity-title">
                        <h5>Le specialiste du haut de gamme</h5>
                      </div> */}
                          <div className="entreprise-store-view">
                            <Link to={{
                              pathname: `/boutiques/${slugify()}/${this.state.advertisement?.user_id}`,
                              state: this.state.advertisement,
                            }}>Voir la boutique</Link>
                          </div>
                        </div>
                    )}
                    {
                      (this.props?.currentUser && this.props?.currentUser.id) !== this.state.advertisement?.user_id && (
                          <div className="advertisement-secondary-seller-buttons">
                            <div className="advertisement-secondary-seller-buttons-inner">
                              {
                                (this.state.advertisement?.hidden_phone === "0")  ?
                                    (
                                        <a href={`tel:` + '+' + this.state.advertisement?.phone_indicatif + this.state.advertisement?.phone} className="advertisement-secondary-seller-buttons-inner-button upper orange">
                                          <MdPhone />
                                          {"+" + this.state.advertisement?.phone_indicatif + this.state.advertisement?.phone}
                                        </a>
                                    ) : (
                                        <Tooltip className="hidden-message" title="Ce utilisateur ne souhaite pas afficher son numéro" arrow placement="top">
                                          <Button className="hidden-phone advertisement-secondary-seller-buttons-inner-button upper orange">
                                            <MdPhone /> Afficher le numéro
                                          </Button>
                                        </Tooltip>
                                    )
                              }
                              <Button className="advertisement-secondary-seller-buttons-inner-button upper outline" onClick={() => this.goToChat()}>
                                <IoMdChatboxes />Chat
                              </Button>
                              <ReactWhatsapp className="advertisement-secondary-seller-buttons-inner-button upper green" number={"+" + this.state.advertisement?.phone_indicatif + this.state.advertisement?.phone} message={`Bonjour ${this.state.advertisement?.usertype == 1 ? this.state.advertisement?.first_name : this.state.advertisement?.pro_rs}, l'article ${this.state.advertisement?.title} est-il toujours disponible?`}>
                                Contacter sur Whatsapp
                              </ReactWhatsapp>
                            </div>
                          </div>
                      )
                    }
                  </div>
                </aside>
              </div>
            </div>
          </section>
          {
            (this.props?.currentUser && this.props?.currentUser.id) !== this.state.advertisement?.user_id && (
                <div className="mobile-fixed-buttons">
                  <div className="mobile-fixed-buttons-item">
                    {
                      this.state.advertisement?.hidden_phone === "0"  ?
                          (
                              <a href={`tel:` + '+' + this.state.advertisement?.phone_indicatif + this.state.advertisement?.phone} onClick={this.showNumber} className="advertisement-secondary-seller-buttons-button upper orange">
                                <MdPhone />
                                <span>Contacter</span>
                              </a>
                          ) : (
                              <Tooltip title="Ce utilisateur ne souhaite pas afficher son numéro" arrow placement="top">
                                <button className="hidden-message advertisement-secondary-seller-buttons-button upper orange">
                                  <MdPhone />
                                  <span>Contacter</span>
                                </button>
                              </Tooltip>
                          )
                    }
                  </div>
                  <div className="mobile-fixed-buttons-item">
                    {
                      (this.props?.currentUser && this.props?.currentUser.id) !== this.state.advertisement?.user_id ? (
                          <Button className="advertisement-secondary-seller-buttons-button upper orange" onClick={() => this.goToChat()}>
                            <IoMdChatboxes />Chat
                          </Button>
                      ) : ('')
                    }
                  </div>
                  <div className="mobile-fixed-buttons-item">
                    <ReactWhatsapp className="whatsapp" number={"+" + this.state.advertisement?.phone_indicatif + this.state.advertisement?.phone} message={`Bonjour ${this.state.advertisement?.usertype == 1 ? this.state.advertisement?.first_name : this.state.advertisement?.pro_rs}, l'article ${this.state.advertisement?.title} est-il toujours disponible?`}>
                      <WhatsAppIcon/>
                      <span>Whatsapp</span>
                    </ReactWhatsapp>
                  </div>
                </div>
            )
          }
          <Footer />
        </div>
    );
  }
}

export default Advertisement;
