import React from "react";
import { MdLocationOn} from "react-icons/md";
// import Link from "@material-ui/core/Link";
import {Link} from "react-router-dom";
import {staticApiUrl} from "../../utils/axios";

let baseUrl = `${staticApiUrl}/userimages/`;

export class ShopItem extends React.Component {
    constructor(props) {
        super(props);
        console.log(props.object);
        this.state = { shop : props.object};
    }

    render() {
        const slugify = () => {
            const regexSpaces = /(\s+|-+|_+)/gm;
            const regexDashes = /-+/gm;
            let name = this.props.object.pro_rs.toLowerCase();
            name = name.replace(regexSpaces, '-');
            name = name.replace(regexDashes, '-');

            return `${name}`;
        };
        return (
            <div className="shop-container-items-item">
                <Link to={{
                    pathname: `/boutiques/${slugify()}/${this.props.object.id}`,
                    state: this.props.object,
                    }}>
                    <div className="shop-container-items-item-inner">
                        <div className="shop-container-items-item-inner-image">
                            {
                                this.props.object.image && this.props.object.image.length === 0 ? (
                                    <img src={baseUrl + 'default_image.png'} alt={this.props.object.pro_rs}/>
                                ) : (
                                    <img src={baseUrl+this.props.object.image[0].filename}  alt={this.props.object.pro_rs}/>
                                )
                            }
                        </div>
                        <div className="shop-container-items-item-inner-text">
                                <div className="shop-container-items-item-inner-text-title">
                                    <h2>{this.props.object.pro_rs}</h2>
                                </div>
                                <div className="shop-container-items-item-inner-text-description">
                                    <p>{this.props.object.activity_title}</p>
                                </div>
                           
                                <div className="shop-container-items-item-inner-text-category">
                                    <p>{this.props.object.category}</p>
                                </div> 
                            <div className="shop-container-items-item-inner-text-location">
                                <ul>
                                    <li><MdLocationOn />{this.props.object.city}</li>
                                    <li><span>{this.props.object.advert_num}</span>annonces</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        );
    }
}

export default ShopItem;
