import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import './MyAdverts.scss'
import {
    Button, CircularProgress
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import DashboardAdView from "../Dashboard Ad View/DashboardAdView";
import SearchIcon from '@material-ui/icons/Search';
import {
    getDeletedAdverts,
    getDraftAdverts,
    getInProgressAdverts,
    getOnlineAdverts,
    getRejectedAdverts
} from "../../../services/user_adverts";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={5}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const MyAdverts = ({currentUser, currentCountry}) => {
    const [value, setValue] = React.useState(0);
    const [limit, setLimit] = React.useState(15);
    const [offset, setOffset] = React.useState(0);
    const [onlineAdverts, setOnlineAdverts] = React.useState([]);
    const [inProgressAdverts, setInProgressAdverts] = React.useState([]);
    const [rejectedAdverts, setRejectedAdverts] = React.useState([]);
    const [draftsAdverts, setDraftsAdverts] = React.useState([]);
    const [deletedAdverts, setDeletedAdverts] = React.useState([]);
    const [advertsLoading, setAdvertsLoading] = React.useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const loadShop = (userId) => {
        setAdvertsLoading(true);
        /* Online */
        getOnlineAdverts({ user_id: userId, country_id: currentCountry.id, limit: limit, offset: offset })
            .then(({data}) => {
                let { RESPONSEDATA } = data;
                let res = onlineAdverts.slice();
                RESPONSEDATA && RESPONSEDATA.forEach((advert) => res.push(advert));
                setOnlineAdverts(res);
                setOffset(15);
            })
            .catch(e=> {
                console.error(e);
            }).finally(()=>{
            setAdvertsLoading(false);
        });
        /* Rejected */
        getRejectedAdverts({ user_id: userId, country_id: currentCountry.id, limit: limit, offset: offset })
            .then(({data}) => {
                let { RESPONSEDATA } = data;
                let res = rejectedAdverts.slice();
                RESPONSEDATA && RESPONSEDATA.forEach((advert) => res.push(advert));
                setRejectedAdverts(res);
            })
            .catch(e=> {
                console.error(e);
            }).finally(()=>{
            setAdvertsLoading(false);
        });
        /* Drafts */
        getDraftAdverts({ user_id: userId, country_id: currentCountry.id, limit: limit, offset: offset })
            .then(({data}) => {
                let { RESPONSEDATA } = data;
                let res = draftsAdverts.slice();
                RESPONSEDATA && RESPONSEDATA.forEach((advert) => res.push(advert));
                setDraftsAdverts(res);
            })
            .catch(e=> {
                console.error(e);
            }).finally(()=>{
            setAdvertsLoading(false);
        });
        /* In Progress */
        getInProgressAdverts({ user_id: userId, country_id: currentCountry.id, limit: limit, offset: offset })
            .then(({data}) => {
                let { RESPONSEDATA } = data;
                let res = inProgressAdverts.slice();
                RESPONSEDATA && RESPONSEDATA.forEach((advert) => res.push(advert));
                setInProgressAdverts(res);
            })
            .catch(e=> {
                console.error(e);
            }).finally(()=>{
            setAdvertsLoading(false);
        });
        /* Deleted */
        getDeletedAdverts({ user_id: userId, country_id: currentCountry.id, limit: limit, offset: offset })
            .then(({data}) => {
                let { RESPONSEDATA } = data;
                let res = deletedAdverts.slice();
                RESPONSEDATA && RESPONSEDATA.forEach((advert) => res.push(advert));
                setDeletedAdverts(res);
            })
            .catch(e=> {
                console.error(e);
            }).finally(()=>{
            setAdvertsLoading(false);
        });
    }

    useEffect(() => {
        loadShop(currentUser.id);
    }, []);

    const loadMore = (type) => {
        setAdvertsLoading(true);
        if (type == 1) {
            getOnlineAdverts({ user_id: currentUser.id, country_id: currentCountry.id, limit: limit, offset: offset })
            .then(({data}) => {
                const {RESPONSECODE, RESPONSEMESSAGE, RESPONSEDATA} = data;
                if (RESPONSECODE == 200) {
                    let { RESPONSEDATA } = data;
                    let res = [...onlineAdverts, ...RESPONSEDATA];
                    setOnlineAdverts(res);
                    setOffset(offset + 15);
                }
            })
            .catch(e=> {
                console.error(e);
            }).finally(()=>{
            setAdvertsLoading(false);
        });
        } else if (type == 2) {
            getInProgressAdverts({ user_id: currentUser.id, country_id: currentCountry.id, limit: limit, offset: offset })
            .then(({data}) => {
                const {RESPONSECODE, RESPONSEMESSAGE, RESPONSEDATA} = data;
                if (RESPONSECODE == 200) {
                    let { RESPONSEDATA } = data;
                    let res = [...onlineAdverts, ...RESPONSEDATA];
                    setInProgressAdverts(res);
                    setOffset(offset + 15);
                }
            })
            .catch(e=> {
                console.error(e);
            }).finally(()=>{
            setAdvertsLoading(false);
        });
        } else if (type == 3) {
            getRejectedAdverts({ user_id: currentUser.id, country_id: currentCountry.id, limit: limit, offset: offset })
            .then(({data}) => {
                const {RESPONSECODE, RESPONSEMESSAGE, RESPONSEDATA} = data;
                if (RESPONSECODE == 200) {
                    let { RESPONSEDATA } = data;
                    let res = [...rejectedAdverts, ...RESPONSEDATA];
                    setRejectedAdverts(res);
                    setOffset(offset + 15);
                }
            })
            .catch(e=> {
                console.error(e);
            }).finally(()=>{
            setAdvertsLoading(false);
        });
        } else if (type == 4) {
            getDeletedAdverts({ user_id: currentUser.id, country_id: currentCountry.id, limit: limit, offset: offset })
            .then(({data}) => {
                const {RESPONSECODE, RESPONSEMESSAGE, RESPONSEDATA} = data;
                if (RESPONSECODE == 200) {
                    let { RESPONSEDATA } = data;
                    let res = [...deletedAdverts, ...RESPONSEDATA];
                    setDeletedAdverts(res);
                    setOffset(offset + 15);
                }
            })
            .catch(e=> {
                console.error(e);
            }).finally(()=>{
            setAdvertsLoading(false);
        });
        } else if (type == 5) {
            getDraftAdverts({ user_id: currentUser.id, country_id: currentCountry.id, limit: limit, offset: offset })
            .then(({data}) => {
                const {RESPONSECODE, RESPONSEMESSAGE, RESPONSEDATA} = data;
                if (RESPONSECODE == 200) {
                    let { RESPONSEDATA } = data;
                    let res = [...draftsAdverts, ...RESPONSEDATA];
                    setDraftsAdverts(res);
                    setOffset(offset + 15);
                }
            })
            .catch(e=> {
                console.error(e);
            }).finally(()=>{
            setAdvertsLoading(false);
        });
        }
    }

    return (
        <div>
            <div className="my-adverts-search">
                <div className="my-adverts-search-form">
                    <form action="">
                        <input type="text" placeholder="Rechercher..."/>
                        <Button>
                            <SearchIcon/>
                        </Button>
                    </form>
                </div>
                {/*<div className="my-adverts-search-add">
                    <Link href="/advertisements/create"><AddIcon/> Ajouter une annonce</Link>
                </div>*/}
            </div>
            <div className="my-adverts">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label={`En ligne (${onlineAdverts.length})`} {...a11yProps(0)} />
                    <Tab label={`Brouillons (${draftsAdverts.length})`} {...a11yProps(1)} />
                    <Tab label={`Annonces rejetées (${rejectedAdverts.length})`} {...a11yProps(2)} />
                    {/*<Tab label={`Annonces supprimées (${deletedAdverts.length})`} {...a11yProps(3)} />*/}
                    <Tab label={`En cours d'acceptation (${inProgressAdverts.length})`} {...a11yProps(3)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    {
                        onlineAdverts.length !== 0 ? (
                            <div className="content">
                                {
                                    onlineAdverts.map((advert)=> {
                                        return (
                                            <DashboardAdView key={advert.advert_id} object={advert} footer={true} edited={true} saved={false} />
                                        )
                                    })
                                }
                            </div>
                        ) : (
                            <span className="content no-data">
                                Aucune annonce trouvée!
                            </span>
                        )
                    }
                    {
                        onlineAdverts.length !== 0 ? (
                            <div className="content-more-load">
                                <Button onClick={() => loadMore(1)}>
                                    Plus d'annonces
                                    {advertsLoading && <CircularProgress /> }
                                </Button>
                            </div>
                        ) : ('')
                    }
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {
                        draftsAdverts.length !== 0 ? (
                            <div className="content">
                                {
                                    draftsAdverts.map((advert)=> {
                                        return (
                                            <DashboardAdView key={advert.advert_id} object={advert} footer={true} edited={true} saved={false} />
                                        )
                                    })
                                }
                            </div>
                        ) : (
                            <span className="content no-data">
                                Aucune annonce trouvée!
                            </span>
                        )
                    }
                    {
                        draftsAdverts.length !== 0 ? (
                            <div className="content-more-load">
                                <Button onClick={() => loadMore(5)}>
                                    Plus d'annonces
                                    {advertsLoading && <CircularProgress /> }
                                </Button>
                            </div>
                        ) : ('')
                    }
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {
                        rejectedAdverts.length !== 0 ? (
                            <div className="content">
                                {
                                    rejectedAdverts.map((advert)=> {
                                        return (
                                            <DashboardAdView key={advert.advert_id} object={advert} footer={true} edited={true} saved={false} />
                                        )
                                    })
                                }
                            </div>
                        ) : (
                            <span className="content no-data">
                                Aucune annonce trouvée!
                            </span>
                        )
                    }
                    {
                        rejectedAdverts.length !== 0 ? (
                            <div className="content-more-load">
                                <Button onClick={() => loadMore(3)}>
                                    Plus d'annonces
                                    {advertsLoading && <CircularProgress /> }
                                </Button>
                            </div>
                        ) : ('')
                    }
                </TabPanel>
                {/*<TabPanel value={value} index={3}>
                    {
                        deletedAdverts.length !== 0 ? (
                            <div className="content">
                                {
                                    deletedAdverts.map((advert)=> {
                                        return (
                                            <DashboardAdView key={advert.advert_id} object={advert} footer={false} edited={true} saved={false} />
                                        )
                                    })
                                }
                            </div>
                        ) : (
                            <span className="content no-data">
                                Aucune annonce trouvée!
                            </span>
                        )
                    }
                    {
                        deletedAdverts.length !== 0 ? (
                            <div className="content-more-load">
                                <Button onClick={() => loadMore(4)}>
                                    Plus d'annonces
                                    {advertsLoading && <CircularProgress /> }
                                </Button>
                            </div>
                        ) : ('')
                    }
                </TabPanel>*/}
                <TabPanel value={value} index={3}>
                {
                        inProgressAdverts.length !== 0 ? (
                            <div className="content">
                                {
                                    inProgressAdverts.map((advert)=> {
                                        return (
                                            <DashboardAdView key={advert.advert_id} object={advert} footer={true} edited={true} saved={false} />
                                        )
                                    })
                                }
                            </div>
                        ) : (
                            <span className="content no-data">
                                Aucune annonce trouvée!
                            </span>
                        )
                    }
                    {
                        inProgressAdverts.length !== 0 ? (
                            <div className="content-more-load">
                                <Button onClick={() => loadMore(2)}>
                                    Plus d'annonces
                                    {advertsLoading && <CircularProgress /> }
                                </Button>
                            </div>
                        ) : ('')
                    }
                </TabPanel>
            </div>
        </div>
    )
}

export default MyAdverts;
