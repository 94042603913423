import React, {useEffect} from 'react';
import {
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import GuardedRoute from '../../security/GuardedRoute';

import Homepage from '../../containers/Homepage';
import Search from '../../containers/Search';
import Advertisement from '../../containers/Advertisement';
import Help from '../Help/index';
import Faq from '../Help/faq/faq';
import AdviceSecurity from '../Help/conseils-securite/conseils-securite';
import Tutorials from "../Help/tutos/tutos";
import Login from '../../containers/Login';
import Signup from '../Signup';
import CreateAdvertisement from '../../containers/CreateAdvertisement';
import EditAdvertisement from '../../containers/EditAdvertisement';
import NotFound from '../NotFound';
/*import { About } from "../About/About";*/
import JoinUs from "../Join-us/JoinUs";
import  Shops from "../Shops/shops";
import { Chat } from "../Chat/chat";
import ShopDetails from "../ShopDetails/shopDetails";


import './App.scss';
import Profile from "../Profile/profile";
/*import {CookiesPage} from "../Cookies/CookiesPage";*/
import Dashboard from "../User/Dashboard/Dashboard";
import MySavedAdverts from "../User/My Saved Adverts/MySavedAdverts";
import NumberConfirmation from "../Signup/Number Confirmation/NumberConfirmation";
import NumberValidation from "../Signup/Number Validation/NumberValidation";
import NewPassword from "../Signup/New Password/NewPassword";
import ConditionOfUse from "../ConditionOfUse/ConditionOfUse";
import PrivacyData from "../PrivacyData/PrivacyData";
import DiffusionRules from "../DiffusionRules/DiffusionRules";
import RestrictedRoute from '../../security/RestrictedRoute';
import CreationSuccessMessage from "../Signup/Creation Succes Message/CreationSuccesMessage";
import searchResults from "../searchResults/searchResults";
import DiscoverTaari from "../DiscoverTaari/DiscoverTaari";
import ConfirmationPage from "../CreateAdvertisement/confirmation-page";
import Ask from "../Help/ask/ask";
import RemovedAd from "../RemovedAd/removedAd";
import { initializeTagManager } from "../../gmt";
import { useHistory } from "react-router-dom";
import TagManager from "react-gtm-module";
import {sendEmailToTaari} from "./../../services/emailjs";
//import emailjs from "@emailjs/browser";



const App = ({ currentCountry, isAuthenticated, setSnackBarMessage }) => {
    const history =  useHistory()
    useEffect(() => {
        // window.OneSignal = window.OneSignal || [];
        // const OneSignal = window.OneSignal;
        // OneSignal.push(function() {
        //     OneSignal.on('notificationDisplay', function(event) {
        //     console.warn('OneSignal notification displayed:', event);
        //     });
        // });
        // OneSignal.push(function() {
        //     OneSignal.getUserId(function(userId) {
        //         console.warn('userId: ', userId);
        //     });
        // });
        // INITIALIZE GOOGLE TAG MANAGER
        initializeTagManager()
    }, []);

    //useEffect(() => emailjs.init("Sz-iLrRnig6Ez-Git"), []);
    //useEffect(() => sendEmailToTaari({message:'MESKDF'}), []);




    useEffect(() => {
    const unlisten = history.listen((location) => {
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
          page: location.pathname + location.search,
        },
      });
    });
    // Trigger the first pageview manually
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        page: window.location.pathname + window.location.search,
      },
    });
    return () => {
      unlisten();
    };
    }, [history]);
    
    return (
        <Switch>
            {/* Homepage */}
            <Route exact path="/" component={Homepage} />

            {/* Search */}
            <Route path="/:countrySlug/search">
                { (currentCountry) && (
                    <Search />
                ) }
                { (!currentCountry) && (
                    <Redirect to="/" />
                ) }
            </Route>
            <Route path="/search">
                <Redirect to="/" />
            </Route>

            {/* Help */}
            <Route path="/aide-et-support" component={Help} />


            {/* Help Faq*/}
            <Route path="/faq" component={Faq} />

            {/* Help Conseils Securite */}
            <Route path="/conseils-securite" component={AdviceSecurity} />

            {/* Help Tutoriels */}
            <Route path="/tutoriels" component={Tutorials} />

            {/* Help Tutoriels */}
            <Route path="/envoyer-une-demande" component={Ask} />

            {/* About
            <Route path="/about" component={About} />*/}

            {/* Join us */}
            <Route path="/nous-rejoindre" component={JoinUs} />

            {/* Cookies
            <Route path="/cookies" component={CookiesPage} />*/}

            {/* Condition of use */}
            <Route path="/conditions-generales" component={ConditionOfUse} />

            {/* Privacy Data Page */}
            <Route path="/donnees-privees" component={PrivacyData} />

            {/* Discover Page */}
            <Route path="/decouvrir-taari" component={DiscoverTaari} />

            {/* Diffusion Rules Page */}
            <Route path="/regles-de-diffusion" component={DiffusionRules} />

            {/* NotFound */}
            <Route path="/not-found" component={NotFound} />

            {/* Removed ad */}
            <Route path="/annonce-inaccessible" component={RemovedAd} />

            {/* ****Restrcited routes******* */}

            {/* Login */}
            <RestrictedRoute path="/login" component={Login} currentCountry={currentCountry} setSnackBarMessage={setSnackBarMessage} />

            {/* Signup */}
            <RestrictedRoute path="/signup/:signupPage" component={Signup}  currentCountry={currentCountry} setSnackBarMessage={setSnackBarMessage} />

            {/* Shops */}
            <RestrictedRoute path="/shops" component={Shops}  currentCountry={currentCountry} setSnackBarMessage={setSnackBarMessage}  />

            {/* Shops details */}
            <RestrictedRoute path="/boutiques/:shopName/:shopId" component={ShopDetails}  currentCountry={currentCountry} setSnackBarMessage={setSnackBarMessage} />

            {/* Search Results */}
            <RestrictedRoute path="/search-results" component={searchResults} currentCountry={currentCountry} setSnackBarMessage={setSnackBarMessage} />

            {/* Profile */}
            <RestrictedRoute path="/profile/:userId" component={Profile}  currentCountry={currentCountry} setSnackBarMessage={setSnackBarMessage}  />

            {/* Advertisement */}
            <Route path="/:countrySlug/advertisements/:advertName/:advertId" component={Advertisement}  currentCountry={currentCountry} setSnackBarMessage={setSnackBarMessage} />

            {/* Number Confirmation */}
            <RestrictedRoute path="/number-confirmation" component={NumberConfirmation}  currentCountry={currentCountry} setSnackBarMessage={setSnackBarMessage}  />

            {/* Number Validation */}
            <RestrictedRoute path="/number-validation" component={NumberValidation}  currentCountry={currentCountry} setSnackBarMessage={setSnackBarMessage} />

            <RestrictedRoute path="/new-password" component={NewPassword}  currentCountry={currentCountry} setSnackBarMessage={setSnackBarMessage} />

            {/* Creation Success */}
            <RestrictedRoute path="/account-success-message" component={CreationSuccessMessage}  currentCountry={currentCountry} setSnackBarMessage={setSnackBarMessage} />

            {/* Create Advertisement */}
            <GuardedRoute path="/advertisements/create" component={CreateAdvertisement} auth={isAuthenticated} />
            
            {/* Edit Advertisement */}
            <GuardedRoute path="/advertisements/edit" component={EditAdvertisement} auth={isAuthenticated} />

            {/* Confirmation Page */}
            <GuardedRoute path="/confirmation-page" component={ConfirmationPage} auth={isAuthenticated} />

            {/* User/Dashboard */}
            <GuardedRoute path="/dashboard" component={Dashboard} auth={isAuthenticated} />

            {/* Chat */}
            <GuardedRoute path="/chat" component={Chat} auth={isAuthenticated}  />

            {/* Favoris */}
            <GuardedRoute path="/favoris" component={MySavedAdverts} auth={isAuthenticated} />

            {/* Si la route n'existe pas ou n'est pas référencée */}
            <Route>
                <Redirect to="/not-found" />
            </Route>

            
        </Switch>
    );
}

export default App;
