import { connect } from 'react-redux';
import CreateAdvertisement from '../components/CreateAdvertisement';

const mapStateToProps = state => ({
    brands: state.advertisement.brands,
    categories: state.category.categories,
    currentCountry : state.country.currentCountry,
    currentUser : state.user.currentUser,
    isEditing: state.advertisement.isEditing,
    editObject: state.advertisement.editObject,
});

const mapDispatchToProps = dispatch => ({
  setSnackBarOpen: (open)=>{
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAdvertisement);