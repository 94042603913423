import React from "react";
import {NavLink} from "react-router-dom";
import './confirmation-page.scss';
import men from '../../imgs/men.svg';
import Header from "../../containers/Header";

const ConfirmationPage = () => {
    return (
        <div>
            <Header/>
            <div className="confirmation-page">
                <div className="confirmation-page-image">
                    <img src={men} alt="men"/>
                </div>
                <div className="confirmation-page-title">
                    <h2>Nous avons bien reçu votre annonce !</h2>
                    <p>Reçu 5 sur 5 :  Votre annonce va être  controlée dans les prochaines minutes.</p>
                    <p>Dès sa validation, vous pourrez la retrouver dans la section <span>"Mes annonces"</span> de votre compte.</p>
                </div>
                <div className="confirmation-page-buttons">
                    <NavLink to={`/dashboard/adverts`}>Voir mes annonces</NavLink>
                    <NavLink to={`/advertisements/create`}>Déposer une nouvelle annonce</NavLink>
                </div>
            </div>
        </div>

    )
}

export default ConfirmationPage;