import React from "react";
import '../faq/faq.scss';
import Header from "../../../containers/Header";
import Footer from "../../Footer";
import faq from '../../../imgs/faq-colored.svg';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AddIcon from '@material-ui/icons/Add';
import { NavLink } from 'react-router-dom';

const Faq = () => {

    const [expandedDeposit, setExpandedDeposit] = React.useState(false);
    const [expandedManage, setExpandedManage] = React.useState(false);
    const [expandedConsult, setExpandedConsult] = React.useState(false);
    const [expandedChat, setExpandedChat] = React.useState(false);
    const [expandedSecurity, setExpandedSecurity] = React.useState(false);
    const [expandedAccount, setExpandedAccount] = React.useState(false);

    const handleChangeDeposit = (panelDeposit) => (event, isExpandedDeposit) => {
        setExpandedDeposit(isExpandedDeposit ? panelDeposit : false);
    };

    const handleChangeManage = (panelManage) => (event, isExpandedManage) => {
        setExpandedManage(isExpandedManage ? panelManage : false);
    };

    const handleChangeConsult = (panelConsult) => (event, isExpandedConsult) => {
        setExpandedConsult(isExpandedConsult ? panelConsult : false);
    };

    const handleChangeChat = (panelChat) => (event, isExpandedChat) => {
        setExpandedChat(isExpandedChat ? panelChat : false);
    };

    const handleChangeSecurity = (panelSecurity) => (event, isExpandedSecurity) => {
        setExpandedSecurity(isExpandedSecurity ? panelSecurity : false);
    };

    const handleChangeAccount = (panelAccount) => (event, isExpandedAccount) => {
        setExpandedAccount(isExpandedAccount ? panelAccount : false);
    };

    return (
        <div>
            <Header/>
            <div className="faq-container">
                <div className="footer-breadcrumb">
                    <ul>
                        <li>
                            <NavLink to="/">Accueil</NavLink>
                        </li>
                        <li className="separator">/</li>
                        <li>
                            <NavLink to="/aide-et-support">Aide et support</NavLink>
                        </li>
                        <li className="separator">/</li>
                        <li>Faq</li>
                    </ul>
                </div>
                <div className="faq-container-header">
                    <img src={faq} alt=""/>
                    <h1>Foire aux questions</h1>
                </div>
                <div className="faq-container-body">
                    <p>
                        Bienvenue sur la page d’aide du site Taari. Cette page permet de répondre à vos questions les plus courantes.
                    </p>
                    <p>
                        Si votre annonce a été refusée ou que vous ne savez pas comment la rédiger, nous vous invitons à consulter les <NavLink to="/regles-de-diffusion">règles de diffusion des annonces</NavLink> sur le site.
                    </p>
                    <div className="faq-container-body-item">
                        <h2>DEPOSER UNE ANNONCE</h2>
                        <div className="faq-container-body-item-accordions">
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedDeposit === 'panelDeposit1'} onChange={handleChangeDeposit('panelDeposit1')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelDeposit1-content" id="panelDeposit1-header">
                                        <p>Règle de diffusion</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Pour connaître nos règles de diffusion, les produits prohibés ainsi que les informations obligatoires à fournir, <NavLink to="/regles-de-diffusion">cliquer ici</NavLink></p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedDeposit === 'panelDeposit2'} onChange={handleChangeDeposit('panelDeposit2')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelDeposit2-content" id="panelDeposit2-header">
                                        <p>Comment publier votre annonce</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Pour publier une annonce, rien de plus facile. Cliquez sur « déposer une annonce », puis renseignez le questionnaire en respectant bien les informations qui y sont mentionnées. La ville ou le quartier à choisir est celle dans laquelle votre bien est à vendre.</p>
                                        <p>D'autre part, choisissez avec soin le titre et le descriptif de votre annonce pour ainsi optimiser votre vente. Pour terminer, une annonce avec photos est 7 fois plus consultée qu'une annonce sans photo, alors n'hésitez pas à rendre votre annonce attrayante.</p>
                                        <p>Vous devez être membre de la communauté pour pouvoir déposer une annonce. Pour cela vous devez vous inscrire.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedDeposit === 'panelDeposit3'} onChange={handleChangeDeposit('panelDeposit3')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelDeposit3-content" id="panelDeposit3-header">
                                        <p>Combien coute le dépôt d’une annonce sur Taari</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Déposer une annonce sur Taari est TOTALEMENT GRATUIT.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedDeposit === 'panelDeposit4'} onChange={handleChangeDeposit('panelDeposit4')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelDeposit4-content" id="panelDeposit4-header">
                                        <p>Combien de temps mon annonce reste en ligne ? </p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Votre annonce restera pour une durée illimitée, période durant laquelle vous pouvez vous-même la supprimer.</p>
                                        <p>La souscription à une ou plusieurs option(s) payante(s) durant la vie de l'annonce ne prolonge aucunement la durée de vie initiale de celle-ci.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedDeposit === 'panelDeposit5'} onChange={handleChangeDeposit('panelDeposit5')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelDeposit5-content" id="panelDeposit5-header">
                                        <p>Dans quelle ville/quartier publier mon annonce ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Vous devez diffuser votre annonce dans la ville ou quartier dans laquelle votre bien est à vendre.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedDeposit === 'panelDeposit6'} onChange={handleChangeDeposit('panelDeposit6')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelDeposit6-content" id="panelDeposit6-header"><p>Dans quelle ville/quartier publier mon annonce ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Vous devez diffuser votre annonce dans la ville ou quartier dans laquelle votre bien est à vendre.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedDeposit === 'panelDeposit7'} onChange={handleChangeDeposit('panelDeposit7')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelDeposit7-content" id="panelDeposit7-header">
                                        <p>Que mettre dans le texte de mon annonce ? </p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Dans le texte de votre annonce, décrivez votre produit du mieux possible, afin que l'internaute ait un maximum de détails. Pour que votre annonce ne soit pas refusée, respectez bien les règles de diffusion de chaque catégorie de produit.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedDeposit === 'panelDeposit8'} onChange={handleChangeDeposit('panelDeposit8')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelDeposit8-content" id="panelDeposit8-header">
                                        <p>Dois-je faire figurer mon adresse e-mail dans le texte de mon annonce ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Pour vous éviter d'être l'objet de spam (emails et publicités indésirables), nous ne faisons pas apparaître votre adresse email en clair sur le site et nous vous conseillons de ne pas la faire figurer dans le texte de votre annonce. Pour vous contacter, les utilisateurs du site peuvent utiliser le formulaire de réponse que nous mettons à leur disposition, dans lequel votre adresse n'est pas visible.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedDeposit === 'panelDeposit9'} onChange={handleChangeDeposit('panelDeposit9')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelDeposit9-content" id="panelDeposit9-header">
                                        <p>Comment insérer des photos dans mon annonce ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            À l'aide du bouton « Choisir le fichier » qui se trouve dans le formulaire de dépôt d'annonce, vous pouvez ajouter jusqu'à 10 photos. Les photos doivent être au format GIF, BMP, PNG ou JPEG.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedDeposit === 'panelDeposit10'} onChange={handleChangeDeposit('panelDeposit10')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelDeposit10-content" id="panelDeposit10-header">
                                        <p>Puis-je utiliser du code ou des étiquettes html dans mon annonce ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Non, il est impossible d'utiliser du code HTML. Celui-ci sera automatiquement effacé. Cependant, si vous souhaitez ajouter des photos, il vous suffit d'utiliser les champs appropriés dans le formulaire.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedDeposit === 'panelDeposit11'} onChange={handleChangeDeposit('panelDeposit11')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelDeposit11-content" id="panelDeposit11-header">
                                        <p>Quand je clique sur « précèdent » dans mon navigateur pour modifier mon annonce, le formulaire est vide, pourquoi ? </p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Cela se produit parfois avec certains navigateurs. Si vous cliquez à nouveau sur « Précédent » puis sur « Suivant », votre texte va sans doute réapparaître dans le formulaire.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedDeposit === 'panelDeposit12'} onChange={handleChangeDeposit('panelDeposit12')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelDeposit12-content" id="panelDeposit12-header">
                                        <p>Pourquoi dois-je donner mon numéro de téléphone alors que je ne veux pas qu’il apparaisse dans l’annonce ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Même si vous choisissez de ne pas afficher votre numéro de téléphone, nous vous le demandons au cas où nous aurions besoin de vous contacter, par exemple, si vous perdez votre mot de passe ou si votre adresse email ne fonctionne pas.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedDeposit === 'panelDeposit13'} onChange={handleChangeDeposit('panelDeposit13')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelDeposit13-content" id="panelDeposit13-header">
                                        <p>Dois-je m’inscrire pour utiliser Taari ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>La validation d'une annonce déposée par un particulier entraine automatiquement la création d'un compte personnel. Celui-ci vous permettra de retrouver toutes vos annonces au même endroit en vous connectant sur notre site, grâce à l'e-mail et au mot de passe renseignés lors de votre dépôt.</p>
                                        <p>Aucune information supplémentaire ne vous sera demandée pour créer ce compte. Seuls vos noms, adresse email et numéro de téléphone restent nécessaires pour le dépôt d'une annonce.</p>
                                        <p>Veillez à ne pas faire d'erreur.</p>
                                        <p>Les professionnels doivent également impérativement créer un Compte Pro au préalable pour pouvoir déposer des annonces. Ils doivent également disposer d’une adresse e-mail.</p>
                                        <p>Peuvent être assimilées à une activité professionnelle les activités suivantes :</p>
                                        <ul>
                                            <li>Revendre des objets achetés à cette fin et non pour un usage personnel,</li>
                                            <li>Vendre des objets créés par l'Annonceur,</li>
                                            <li>Vendre régulièrement un volume important d'objets,</li>
                                            <li>Les ventes permettant de générer des bénéfices et de dégager un revenu substantiel.</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                        {/* <div className="faq-container-body-item-read-more">
                            <button>
                                <span>Lire plus</span>
                                <AddIcon/>
                            </button>
                        </div>*/}
                    </div>
                    <div className="faq-container-body-item">
                        <h2>GERER UNE ANNONCE</h2>
                        <div className="faq-container-body-item-accordions">
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedManage === 'panelManage1'} onChange={handleChangeManage('panelManage1')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelManage1-content" id="panelManage1-header">
                                        <p>Comment retrouver mon annonce ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Pour retrouver votre annonce, cliquez sur le lien contenu dans l'email que vous avez reçu lors de la mise en ligne de votre annonce.</p>
                                        <p>Vous pouvez également retrouver toutes vos annonces en vous connectant à votre compte personnel. Pour cela utiliser l'adresse email et le mot de passe renseignés lors de votre dépôt.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedManage === 'panelManage2'} onChange={handleChangeManage('panelManage2')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelManage2-content" id="panelManage2-header">
                                        <p>Comment modifier mon annonce ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Pour modifier votre annonce, cliquez sur le lien « Modifier » situé au regard de l’annonce se trouvant dans votre compte et dans la rubrique « Mes annonces »</p>
                                        <p>Vous pouvez également souscrire aux options payantes « Remonter en tête de liste », « bandeau » et « Premium » au cours de la modification de votre annonce. La modification de votre annonce n'invalide pas vos options.</p>
                                        <p>Pour retrouver votre annonce, connectez-vous sur votre compte personnel. Vous pourrez alors modifier vos annonces depuis votre espace.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedManage === 'panelManage3'} onChange={handleChangeManage('panelManage3')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelManage3-content" id="panelManage3-header">
                                        <p>Comment supprimer mon annonce ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Pour supprimer une annonce il vous suffit de cliquer sur le lien « Supprimer » situé au regard de l’annonce se trouvant dans votre compte et dans la rubrique « Mes annonces »</p>
                                        <p>Pour retrouver votre annonce, connectez-vous sur votre compte personnel. Vous pourrez alors supprimer vos annonces depuis votre espace personnelle. Dans tous les cas, votre annonce sera automatiquement supprimée au bout de deux mois.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedManage === 'panelManage4'} onChange={handleChangeManage('panelManage4')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelManage4-content" id="panelManage4-header">
                                        <p>Comment ajouter un « bandeau » ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>L'option « Bandeau » est une option indiquant les produits à vendre de manière urgente. Les annonces urgentes sont signalées par un bandeau autour de la colonne de l’annonce dans la liste de résultats. Une recherche ciblée uniquement sur ces annonces est également proposée.</p>
                                        <p>Vous pouvez ajouter un « Bandeau » au moment du dépôt de votre annonce ou au cours de la modification de votre annonce.</p>
                                        <p>Vous pouvez également ajouter un Bandeau depuis la page de votre annonce en cliquant sur "Modifier" et en vous connectant sur votre compte.</p>
                                        <p>Cette option est payable par carte bancaire, par PayPal, par Mobil Money ou par crédits pour les détenteurs d'un compte personnel ou professionnel.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedManage === 'panelManage5'} onChange={handleChangeManage('panelManage5')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelManage5-content" id="panelManage5-header">
                                        <p>Comment remonter mon annonce en tête de liste ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Il existe 4 possibilités pour remonter votre annonce en tête de liste :</p>
                                        <p>L'option « Immédiatement » vous permet de remonter automatiquement et immédiatement votre annonce en haut de la liste de résultats comme si elle venait d'être publiée. Pour remonter une annonce immédiatement en tête de liste, rendez-vous sur la page de modification de votre annonce et cliquez sur « Remonter en tête de liste » en vous connectant sur votre compte.</p>
                                        <ul>
                                            <li>
                                                Vous pouvez payer par carte bancaire, par PayPal, par Mobil Money ou par crédits. Votre annonce sera alors remontée en tête de liste lors de la prochaine mise à jour du site.
                                            </li>
                                            <li>
                                                Pour que votre annonce soit automatiquement remontée en tête de résultats chaque jour pendant 7 jours, vous pouvez acheter l'option « Chaque jour pendant 7 jours » au moment du dépôt de votre annonce, lors d'une modification de celle-ci ou lorsque votre annonce est déjà en ligne et en cliquant sur le lien « Mettre en avant » à partir de la page de modification de votre annonce. <br/>
                                                L'option est payable par carte bancaire, par PayPal, par Mobil Money ou par crédits.
                                            </li>
                                            <li>
                                                Pour que votre annonce soit automatiquement remontée en tête de résultats chaque jour pendant 30 jours, vous pouvez acheter l'option « Chaque jour pendant 30 jours » au moment du dépôt de votre annonce, lors d'une modification de celle-ci ou lorsque votre annonce est déjà en ligne et en cliquant sur le lien « Mettre en avant » à partir de la page de modification de votre annonce. <br/>
                                                L'option est payable par carte bancaire, par PayPal, par Mobil Money ou par crédits
                                            </li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedManage === 'panelManage6'} onChange={handleChangeManage('panelManage6')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelManage6-content" id="panelManage6-header">
                                        <p>Comment mettre mon annonce « premium » ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>L'option « Premium » met en avant votre annonce sur toutes les pages de résultats dans un emplacement privilégié en haut des listes d'annonces, pendant 7 ou 30 jours.</p>
                                        <p>L'affichage de votre annonce dans cet emplacement est aléatoire et fonction du volume d'autres annonces bénéficiant de cette même option.</p>
                                        <p>Vous pouvez acheter l'option « Premium » au moment du dépôt de votre annonce ou en cliquant sur le lien « Mettre en annonce Premium » sur la page de modification de votre annonce. Connectez-vous et cochez ensuite « Mettre en annonce Premium » puis cliquez sur Continuer.</p>
                                        <p>N'oubliez pas qu'il est obligatoire d'avoir au moins une photo sur votre annonce pour pouvoir bénéficier de l'option « Premium ».</p>
                                        <p>Cette option est payable par carte bancaire, par PayPal, par Mobil Money ou par crédits pour les détenteurs d'un compte personnel ou professionnel.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedManage === 'panelManage7'} onChange={handleChangeManage('panelManage7')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelManage7-content" id="panelManage7-header">
                                        <p>Comment souscrire à la « boutique » ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>La « Boutique » est un service payant, réservé uniquement aux professionnels ayant créé un compte pro sur Taari.</p>
                                        <p>Professionnels, avec la « Boutique » vous bénéficiez sur Taari d'une page « vitrine » dédiée à votre société/votre marque. Cet espace vous permet :</p>
                                        <ul>
                                            <li>De présenter votre société grâce à un logo, un slogan et un texte descriptif de votre activité</li>
                                            <li>D'augmenter votre visibilité en affichant toutes vos annonces sur une seule page.</li>
                                        </ul>
                                        <p>Sur la page de vos annonces, les utilisateurs retrouveront un encart aux couleurs de votre société, pointant vers votre « Boutique » et vos annonces.</p>
                                        <p>La durée maximale de souscription à la « Boutique » est de 12 mois.</p>
                                        <p>Si vous n'avez pas de compte pro, vous pouvez en créer un sur <a href="#" target="_blank">la page de création de Compte Pro.</a></p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedManage === 'panelManage8'} onChange={handleChangeManage('panelManage8')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelManage8-content" id="panelManage8-header">
                                        <p>Comment souscrire à plusieurs options en même temps sur la même annonce ? </p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Vous pouvez à tout moment pendant la diffusion de votre annonce souscrire à plusieurs options en même temps. Pour cela, rendez-vous sur votre annonce et cliquez sur le lien « Modifier votre annonce ». Connectez-vous ensuite en saisissant votre adresse mail et votre mot de passe.</p>
                                        <p>Vous arriverez sur la page de gestion de votre annonce. Il ne vous reste plus qu'à sélectionner les options auxquelles vous voulez souscrire (« Remonter mon annonce en tête de liste », « Ajouter un bandeau » et « Mettre mon annonce en Premium »).</p>
                                        <p>Rappel : seules les options qui ne sont pas déjà actives sur votre annonce seront affichées sur la page de gestion de votre annonce.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedManage === 'panelManage9'} onChange={handleChangeManage('panelManage9')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelManage9-content" id="panelManage9-header">
                                        <p>Que faire si j’ai oublié mon mot de passe ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Sur la page de connexion à votre compte, cliquez sur le lien « mot de passe oublié » et suivez les instructions qui s’afficherons sur l’écran.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedManage === 'panelManage10'} onChange={handleChangeManage('panelManage10')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelManage10-content" id="panelManage10-header">
                                        <p>Pourquoi mon annonce est-elle toujours visible alors que je l’ai supprimée ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Quelques minutes sont nécessaires entre le moment où vous demandez la suppression de votre annonce et où celle-ci est effective. Si vous continuez malgré tout à voir votre annonce, il est probable que la page de votre annonce soit conservée dans le cache de votre navigateur.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedManage === 'panelManage11'} onChange={handleChangeManage('panelManage11')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelManage11-content" id="panelManage11-header">
                                        <p>Prix</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Pour consulter le prix d'une option de mise en avant, veuillez choisir la catégorie dans laquelle vous avez déposé votre annonce :
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                        {/*<div className="faq-container-body-item-read-more">
                            <button>
                                <span>Lire plus</span>
                                <AddIcon/>
                            </button>
                        </div>*/}
                    </div>
                    <div className="faq-container-body-item">
                        <h2>CONSULTER UNE ANNONCE</h2>
                        <div className="faq-container-body-item-accordions">
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedConsult === 'panelConsult1'} onChange={handleChangeConsult('panelConsult1')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelConsult1-content" id="panelConsult1-header">
                                        <p>Comment chercher une annonce ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Pour chercher une annonce, rien de plus simple. Il suffit de cliquer sur la région dans laquelle vous souhaitez effectuer une recherche. Vous pouvez limiter votre recherche à un type spécifique d'annonces en sélectionnant une catégorie et/ou à une zone géographique précise (votre ville, votre quartier ou tout le pays).</p>
                                        <p>Vous pouvez également affiner votre recherche en précisant un ou plusieurs mots-clés. Par défaut cette recherche porte sur le titre et le texte de l'annonce.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedConsult === 'panelConsult2'} onChange={handleChangeConsult('panelConsult2')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelConsult2-content" id="panelConsult2-header">
                                        <p>Ou puis-je consulter les annonces que j’ai sauvegardées ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Les annonces que vous avez sauvegardées sont stockées sur la page « Mes annonces sauvegardées », dans le menu. Vous pourrez les retrouver lors d'une prochaine visite. Si l'annonce n'est plus active sur le site, elle disparaîtra automatiquement de votre sélection.</p>
                                        <p>Attention : si vous supprimez vos cookies de votre ordinateur, vous perdrez toutes vos annonces sauvegardées.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedConsult === 'panelConsult3'} onChange={handleChangeConsult('panelConsult3')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelConsult3-content" id="panelConsult3-header">
                                        <p>Qu’est-ce qu’un cookie et à quoi sert-il ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Taari utilise des cookies, qui sont de petits fichiers installés sur votre ordinateur. Les cookies permettent à notre système de se souvenir des annonces que vous avez sauvegardées et des recherches automatiques que vous avez enregistrées.</p>
                                        <p>Les cookies sont entièrement anonymes et ne contiennent aucune information à caractère personnel. Si vous le désirez, vous pouvez désactiver la fonction cookies en modifiant les paramètres de sécurité de votre navigateur web. Cependant, si vous désactivez cette fonction, certains services proposés sur le site ne seront pas actifs.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedConsult === 'panelConsult4'} onChange={handleChangeConsult('panelConsult4')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelConsult4-content" id="panelConsult4-header">
                                        <p>Comment puis-je accéder à la version mobile Taari depuis mon téléphone portable ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Vous pouvez consulter les annonces déposées sur notre site web depuis votre mobile en utilisant la connexion internet de votre téléphone portable. Le site web mobile.taari.co a été spécialement optimisé pour votre téléphone portable. N'hésitez pas à l'utiliser pour retrouver et consulter toutes les annonces du site en vous connectant à l'adresse //mobile.taari.co</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                        {/*<div className="faq-container-body-item-read-more">
                            <button>
                                <span>Lire plus</span>
                                <AddIcon/>
                            </button>
                        </div>*/}
                    </div>
                    <div className="faq-container-body-item">
                        <h2>MESSAGERIE INTERNE (CHAT SECURISEE)</h2>
                        <div className="faq-container-body-item-accordions">
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedChat === 'panelChat1'} onChange={handleChangeChat('panelChat1')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelChat1-content" id="panelChat1-header">
                                        <p>Comment contacter un annonceur ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Cliquez sur le bouton de « chat » en bas de l’annonce, écrivez votre message et cliquez sur « Envoyer ». Le fournisseur de l’annonce reçoit alors votre demande directement dans ses messages sur Taari.co et sur son Appli et une copie lui est également envoyée par e-mail.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedChat === 'panelChat2'} onChange={handleChangeChat('panelChat2')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelChat2-content" id="panelChat2-header">
                                        <p>Où puis-je trouver mes messages ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Vous trouverez tous les messages envoyés et reçus sous « Messages » sur le site web Taari.co et sur nos Appli. </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedChat === 'panelChat3'} onChange={handleChangeChat('panelChat3')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelChat3-content" id="panelChat3-header">
                                        <p>Comment répondre à un message ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Vous pouvez répondre aux messages que vous avez reçus directement sur Taari.co ou l’Appli par message direct sous « Messages ».
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedChat === 'panelChat4'} onChange={handleChangeChat('panelChat4')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelChat4-content" id="panelChat4-header">
                                        <p>Comment bloquer ou débloquer un utilisateur ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Vous trouverez toutes vos conversations sous « Messages ». Pour bloquer un utilisateur, cliquez sur la conversation puis en haut à droite sur les trois points formant une barre verticale et enfin sur « Bloquer l’utilisateur ».
                                            Notez que vous ne pourrez plus envoyer de messages ou de demandes de contact à cet utilisateur concernant d’autres de ses annonces tant qu’il reste bloqué.
                                            Vous pouvez débloquer à tout moment l’utilisateur dans vos messages et donc lever le blocage. Pour cela, cliquez sur la conversation en question puis en haut à droite sur les trois points formant une barre verticale et enfin sur « Débloquer l’utilisateur ».
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedChat === 'panelChat5'} onChange={handleChangeChat('panelChat5')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelChat5-content" id="panelChat5-header">
                                        <p>Comment supprimer un message ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Vous pouvez à tout moment supprimer les conversations avec d’autres utilisateurs de vos propres messages si vous n’en avez plus besoin. Pour cela, cliquez sur la conversation en question puis en haut à droite sur les trois points formant une barre verticale et enfin sur « Supprimer la conversation ».</p>
                                        <p>Cette option supprimera la copie de la conversation. Cependant, elle sera toujours visible pour l’autre utilisateur. Si vous supprimez une conversation et que l’autre personne vous écrit encore par la suite, l’intégralité de la conversation sera à nouveau affichée sur votre écran. Veuillez noter que la suppression d’une conversation est irréversible.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedChat === 'panelChat6'} onChange={handleChangeChat('panelChat6')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelChat6-content" id="panelChat6-header">
                                        <p>Comment signaler un message inapproprié ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Veuillez signaler directement tout message inapproprié à notre service client. Nous vous conseillons également de bloquer l’utilisateur.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedChat === 'panelChat7'} onChange={handleChangeChat('panelChat7')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelChat7-content" id="panelChat7-header">
                                        <p>L’annonce n’est plus active. Puis-je tout de même envoyer un message à l’annonceur ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Oui, vous pouvez encore communiquer avec l'annonceur même si l’annonce est désactivée. Utilisez pour cela tout simplement nos messages directs sur Taari.co ou sur l’Appli.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedChat === 'panelChat8'} onChange={handleChangeChat('panelChat8')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelChat8-content" id="panelChat8-header">
                                        <p>Pourquoi est-il impossible d’envoyer une demande de contact ou un message à un annonceur ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Si vous avez bloqué l’utilisateur concerné, vous devez d’abord le débloquer pour lui envoyer un message. Si l’utilisateur concerné vous a bloqué, vous ne pouvez malheureusement pas lui envoyer de message.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedChat === 'panelChat9'} onChange={handleChangeChat('panelChat9')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelChat9-content" id="panelChat9-header">
                                        <p>Comment suis-je informé des messages entrants ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Dès que vous recevez un nouveau message, une notification apparaît sous le menu « Messages » sur le site web. Si vous utilisez l’Appli Taari, une notification s’affichera sur l’écran de votre téléphone.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                        {/*<div className="faq-container-body-item-read-more">
                            <button>
                                <span>Lire plus</span>
                                <AddIcon/>
                            </button>
                        </div>*/}
                    </div>
                    <div className="faq-container-body-item">
                        <h2>SECURITE SUR LE SITE</h2>
                        <div className="faq-container-body-item-accordions">
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedSecurity === 'panelSecurity1'} onChange={handleChangeSecurity('panelSecurity1')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelSecurity1-content" id="panelSecurity1-header">
                                        <p>Une annonce me parait douteuse</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Méfiez-vous si le prix de l’annonce vous paraît trop faible.</p>
                                        <p>Si un annonceur vous recontacte en :</p>
                                        <ul>
                                            <li>Vous demandant l’ensemble de vos coordonnées</li>
                                            <li>Vous proposant un paiement par Mandat, Paypal ou Western Union par exemple</li>
                                            <li>Vous signalant qu’il est momentanément à l’étranger</li>
                                        </ul>
                                        <p>Dans chacun de ces cas, nous vous invitons à être vigilant.</p>
                                        <p>Si une annonce sur le site vous paraît douteuse, ou qu’elle ne respecte pas les règles de diffusion, n’y répondez-pas. Vous pouvez nous la signaler. Pour cela, cliquez sur le bouton ‘signaler l’annonce’ en bas de la page de l’annonce en question. Cette démarche est anonyme.</p>
                                        <p>L’annonce sera alors relue par nos équipes, puis supprimée s’il s’agit effectivement d’une annonce frauduleuse ou non conforme à nos règles de diffusion. Pensez bien à préciser le motif de votre signalement dans votre message afin que nos équipes puissent vous répondre au mieux.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedSecurity === 'panelSecurity2'} onChange={handleChangeSecurity('panelSecurity2')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelSecurity2-content" id="panelSecurity2-header">
                                        <p>J’ai reçu un email ou sms douteux en réponse à mon annonce.</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p><strong>Demande de contacter un numéro surtaxe (888…)</strong></p>
                                        <p>Ce message ne provient pas de nos services. Taari ne vous demandera jamais de contacter un numéro surtaxé pour être mis en relation avec un annonceur.
                                            Si vous recevez un SMS ou un mail vous demandant d'appeler un numéro surtaxé ne donnez pas suite à cette demande. Il s'agit d'une tentative d'escroquerie.</p>
                                        <p><strong>Proposition d’option à tarif réduit</strong></p>
                                        <p>Si vous recevez un message vous proposant de souscrire à un pack d’option à un tarif très avantageux, soyez méfiants. Vérifiez l’adresse email de l’expéditeur du message et méfiez-vous des emails écrits en mauvais français.
                                            Si vous avez un doute sur la provenance du message, n’hésitez pas à transmettre l’email à notre équipe en incluant le lien qui vous redirige vers une page de paiement.</p>
                                        <p><strong>Demande d’informations personnelles</strong></p>
                                        <p>Si vous avez reçu un email vous informant que votre compte a été suspendu pour raisons de sécurité, méfiez-vous.
                                            L’email ne provient pas de nos services, il s’agit d’une tentative de phishing. Taari ne vous demandera jamais vos données personnelles par email ou SMS (identifiant, mot de passe, coordonnées bancaires …). Nous vous invitons à nous contacter afin de nous fournir le lien du site frauduleux sur lequel vous êtes redirigé.</p>
                                        <p><strong>Demande de participation à un sondage</strong></p>
                                        <p>Si on vous propose de participer à une enquête avec un cadeau à la clé, nous vous invitons à rester vigilant.
                                            Ces sondages ne sont pas effectués par nos équipes. Nous vous invitons à ne pas communiquer vos coordonnées bancaires. Nous vous invitons à <a href="mailto:contact@taari.co">nous contacter</a> afin de nous fournir le lien du site frauduleux sur lequel vous êtes redirigé.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedSecurity === 'panelSecurity3'} onChange={handleChangeSecurity('panelSecurity3')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelSecurity3-content" id="panelSecurity3-header">
                                        <p>J’ai reçu un email ou sms douteux en réponse à mon annonce.</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p><strong>Si un acheteur vous contacte en :</strong></p>
                                        <ul>
                                            <li>Vous demandant l’ensemble de vos coordonnées</li>
                                            <li>Vous proposant un paiement par Mandat, Paypal ou Western Union par exemple</li>
                                            <li>Vous signalant qu’il est momentanément à l’étranger</li>
                                        </ul>
                                        <p>Dans chacun de ces cas, nous vous invitons à être vigilant.</p>
                                        <p>Privilégiez toujours la remise en mains propres, cela évite les mauvaises surprises, aussi bien pour l’acheteur que pour le vendeur. Si cela est possible, pensez toujours à vérifier les coordonnées de votre interlocuteur avant d’accepter une transaction à distance. Cela vous permettra d’avoir un contact en cas de litige.</p>
                                        <p><strong>Que faire en cas message douteux ?</strong></p>
                                        <p>
                                            Si vous recevez un sms douteux d'un utilisateur qui vous demande de le recontacter par mail : nous vous invitons à ne pas donner suite à cette proposition. Il s'agit probablement d'une tentative de fraude, vous pouvez supprimer ce sms.
                                            Les appels téléphoniques et sms ne passant pas par notre site, il nous est impossible de les filtrer.
                                        </p>
                                        <p><strong>Demande d’informations personnelles</strong></p>
                                        <p>
                                            Si vous avez reçu un email vous informant que votre compte a été suspendu pour raisons de sécurité, méfiez-vous.
                                            L’email ne provient pas de nos services, il s’agit d’une tentative de phishing. Taari ne vous demandera jamais vos données personnelles par email ou SMS (identifiant, mot de passe, coordonnées bancaires …). Nous vous invitons à nous contacter afin de nous fournir le lien du site frauduleux sur lequel vous êtes redirigé.
                                        </p>
                                        <p>
                                            Si vous avez reçu un email en mauvais français, d’un utilisateur se prétendant à l’étranger ou vous proposant un règlement via Paypal, Western Union, etc, nous vous invitons à ne pas donner suite à cette proposition. Il s’agit probablement d’une tentative de fraude. N’hésitez pas à envoyer une copie du message au support client afin que nous puissions prendre les mesures nécessaires.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedSecurity === 'panelSecurity4'} onChange={handleChangeSecurity('panelSecurity4')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelSecurity4-content" id="panelSecurity4-header">
                                        <p>J’ai reçu un message ou une publicité douteuse de la part de Taari.</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p><strong>Demande de contacter un numéro surtaxe (888…)</strong></p>
                                        <p>
                                            Ce message ne provient pas de nos services. Taari ne vous demandera jamais de contacter un numéro surtaxé pour être mis en relation avec un annonceur.
                                            Si vous recevez un SMS ou un mail vous demandant d'appeler un numéro surtaxé ne donnez pas suite à cette demande. Il s'agit d'une tentative d'escroquerie.
                                        </p>
                                        <p><strong>Proposition d’option à tarif réduit</strong></p>
                                        <p>
                                            Si vous recevez un message vous proposant de souscrire à un pack d’option à un tarif très avantageux, soyez méfiants. Vérifiez l’adresse email de l’expéditeur du message et méfiez-vous des emails écrits en mauvais français.
                                            Si vous avez un doute sur la provenance du message, n’hésitez pas à transmettre l’email à <a href="#">notre équipe</a> en incluant le lien qui vous redirige vers une page de paiement.
                                        </p>
                                        <p><strong>Demande d’informations personnelles</strong></p>
                                        <p>
                                            Si vous avez reçu un email vous informant que votre compte a été suspendu pour raisons de sécurité, méfiez-vous.
                                            L’email ne provient pas de nos services, il s’agit d’une tentative de phishing. Taari ne vous demandera jamais vos données personnelles par email ou SMS (identifiant, mot de passe, coordonnées bancaires …). Nous vous invitons à nous contacter afin de nous fournir le lien du site frauduleux sur lequel vous êtes redirigé.
                                        </p>
                                        <p><strong>Demande de participation à un sondage</strong></p>
                                        <p>
                                            Si on vous propose de participer à une enquête avec un cadeau à la clé, nous vous invitons à rester vigilant.
                                            Ces sondages ne sont pas effectués par nos équipes. Nous vous invitons à ne pas communiquer vos coordonnées bancaires. Nous vous invitons à <a href="#">nous contacter</a> afin de nous fournir le lien du site frauduleux sur lequel vous êtes redirigé.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedSecurity === 'panelSecurity5'} onChange={handleChangeSecurity('panelSecurity5')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelSecurity5-content" id="panelSecurity5-header">
                                        <p>Quelqu’un me demande de déposer une annonce pour lui.</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Si quelqu’un vous demande de déposer une annonce pour lui via un réseau social, méfiez-vous ! Le compte de cette personne a peut-être été piraté. Il s’agit probablement d’une annonce frauduleuse, n’y donnez pas suite.
                                        </p>
                                        <p>
                                            Si l’annonce a déjà été confirmée, nous vous invitons à nous la signaler via le bouton ‘signaler l’annonce’ en bas de la page de l’annonce concernée.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedSecurity === 'panelSecurity6'} onChange={handleChangeSecurity('panelSecurity6')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelSecurity6-content" id="panelSecurity6-header">
                                        <p>Usurpation de mes coordonnées..</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p><strong>Appels, messages ou mails reçus pour des annonces que vous n’avez pas déposées.</strong></p>
                                        <p>
                                            Si vous recevez des appels, mails ou SMS pour des annonces que vous n’avez pas déposées sur le site, nous vous invitons à prendre <a href="#">contact avec nous</a>. Nous vous recommandons de nous transmettre les coordonnées utilisées (numéro de téléphone, adresse mail,) afin de pouvoir agir rapidement sur les annonces concernées
                                        </p>
                                        <p><strong>Piratage de votre compte ou d’une annonce.</strong></p>
                                        <p>
                                            Si vous recevez un mail de confirmation de changement d’identifiant sur votre compte et que vous n’êtes pas à l’origine de cette modification, nous vous invitons à prendre rapidement <a href="#">contact avec nous</a> afin d’agir sur votre compte le plus vite possible.
                                            Il est possible que vous ayez répondu à un email frauduleux vous demandant de renseigner les coordonnées de votre compte. Cet email ne provenait pas de nos équipes. En règle générale, pensez à toujours vérifier l’adresse du site sur lequel on vous demande ce type d’information.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedSecurity === 'panelSecurity7'} onChange={handleChangeSecurity('panelSecurity7')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelSecurity7-content" id="panelSecurity7-header">
                                        <p>Une société me propose des potentiels acheteurs pour mes annonces déposées sur le site Taari.</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Si vous recevez un jour un appel ou un sms d’une entreprise vous proposant des acheteurs potentiels concernant des annonces déposées sur notre site, nous vous invitons à la plus grande prudence.</p>
                                        <p>Ce type de démarchage n’est pas de notre fait, ces sociétés ne sont pas partenaires de notre site. Nous vous invitons à contacter notre équipe afin de nous transmettre le message que vous avez reçu.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedSecurity === 'panelSecurity8'} onChange={handleChangeSecurity('panelSecurity8')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelSecurity8-content" id="panelSecurity8-header">
                                        <p>Taari peut-il se porter garant des transactions effectuées sur le site ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Taari est un site d'annonces de proximité. Nous n’intervenons pas dans les transactions, elles s’effectuent directement entre acheteur et vendeur.</p>
                                        <p>Privilégiez toujours la remise en main propre afin de pouvoir vous assurer que la transaction conviendra à tout le monde. Cela évite les mauvaises surprises !</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                        {/*<div className="faq-container-body-item-read-more">
                            <button>
                                <span>Lire plus</span>
                                <AddIcon/>
                            </button>
                        </div>*/}
                    </div>
                    <div className="faq-container-body-item">
                        <h2>MON COMPTE PERSONNEL</h2>
                        <div className="faq-container-body-item-accordions">
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedAccount === 'panelAccount1'} onChange={handleChangeAccount('panelAccount1')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelAccount1-content" id="panelAccount1-header">
                                        <p>Créer mon compte</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>La création de compte personnel est gratuite et obligatoire pour pouvoir déposer une annonce.</p>
                                        <p>La création du compte peut se faire depuis l'onglet Mon Compte en choisissant si on est un particulier ou un professionnel.</p>
                                        <p>Toutes les informations renseignées lors de la création d'un compte (coordonnées, email, numéro de téléphone etc..) se retrouvent dans l'onglet « Mon profil »</p>
                                        <p>Lors de la création du compte, vous devez valider celui-ci en validant votre numéro de téléphone avec un code qui vous sera envoyé par sms.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedAccount === 'panelAccount2'} onChange={handleChangeAccount('panelAccount2')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelAccount2-content" id="panelAccount2-header">
                                        <p>Mes données personnelles</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Depuis l'onglet Mes données personnelles il est possible de :</p>
                                        <ul>
                                            <li>Changer les informations liées à son compte. Tout est modifiable, coordonnées, e-mail et mot de passe.</li>
                                            <li>Supprimer son compte personnel : Attention si le compte est supprimé, cela supprime également les annonces liées au compte. Cette opération est irréversible. Un e-mail de validation de suppression de compte est envoyé. Si le lien présent dans cet e-mail n'est pas cliqué. Le compte n'est alors pas supprimé.</li>
                                            <li>Compléter son profil</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedAccount === 'panelAccount3'} onChange={handleChangeAccount('panelAccount3')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelAccount3-content" id="panelAccount3-header">
                                        <p>Mes achats</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>L'onglet Mes achats vous donne la possibilité de vérifier l'historique de vos achats d'options de mise en avant de vos annonces.</p>
                                        <p>Il est également possible depuis cet onglet d'imprimer un justificatif d'achat d'option.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedAccount === 'panelAccount4'} onChange={handleChangeAccount('panelAccount4')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelAccount4-content" id="panelAccount4-header">
                                        <p>Entrer dans mon compte</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            L'identification au compte peut se faire depuis la zone d'authentification en haut à droite de chaque page ou en rentrant votre mot de passe de compte sur la seconde page du formulaire de dépôt d'annonce. Une fois authentifiée (e), vous avez la possibilité de vous déconnecter de votre compte toujours dans ce même espace en haut à droite.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedAccount === 'panelAccount5'} onChange={handleChangeAccount('panelAccount5')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelAccount5-content" id="panelAccount5-header">
                                        <p>Mon espace annonce</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Cet espace intègre toutes les annonces de votre compte. Vous pouvez, depuis cet espace, gérer vos annonces en offres ou en demandes en souscrivant aux options de mise en avant proposées, mais également en supprimant vos annonces.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedAccount === 'panelAccount6'} onChange={handleChangeAccount('panelAccount6')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelAccount6-content" id="panelAccount6-header">
                                        <p>Gérer plusieurs annonces en même temps</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            Vous avez la possibilité, depuis votre Espace annonce, de souscrire à des options de mise en avant sur plusieurs annonces en même temps. Pour cela, il vous suffit de cocher les annonces concernées (coches à gauche de chaque annonce) et de cliquer sur l'option souhaitée.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="faq-container-body-item-accordions-item">
                                <Accordion expanded={expandedAccount === 'panelAccount7'} onChange={handleChangeAccount('panelAccount7')}>
                                    <AccordionSummary expandIcon={<AddIcon/>} aria-controls="panelAccount7-content" id="panelAccount7-header">
                                        <p>Comment acheter et dépenser des crédits ?</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>Lorsque vous disposez d’un Compte Personnel, vous pouvez payer chacun de nos services disponibles par crédits.</p>
                                        <p>Pour acheter des crédits, vous devez être connecté sur votre Compte Personnel et vous rendre sur l’onglet « Mes achats ». Sélectionnez le montant de crédits souhaités et cliquez sur « Valider ». Vous arriverez ensuite sur une page de paiement sécurisé. Une fois votre paiement validé, le nombre de crédits achetés sera automatiquement ajouté à votre solde de crédits disponibles. L’achat de crédits est accessible uniquement depuis notre site internet.</p>
                                        <p>Vous pouvez dépenser vos crédits sur le site Web et sur les applications iPhone et Android pour souscrire à l’ensemble de nos services en suivant les parcours habituels. Au moment du règlement de la transaction, le montant sera décompté de votre solde de crédits.</p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                        {/*<div className="faq-container-body-item-read-more">
                            <button>
                                <span>Lire plus</span>
                                <AddIcon/>
                            </button>
                        </div>*/}
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Faq;
