import React from "react";
import {Redirect} from "react-router-dom";
import './MySavedSearch.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import {MdClose} from "react-icons/md";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Chip from '@material-ui/core/Chip';
import {connect} from "react-redux";
import {getUserSavedSearch, deleteUserSavedSearch} from '../../../services/user_adverts';
import {setMessage} from "../../../reducers/snackbarReducer";
import {
    setIsSearching,
    setSearchObject,
} from '../../../reducers/advertisementReducer';
class MySavedSearch extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            openDeleteDialog: false,
            openModifiedDialog: false,
            search: [],
            currentId: '',
            goToSearch: null,
        };

        this.handleOpenDeleteChange = this.handleOpenDeleteChange.bind(this);
        this.handleCloseDeleteChange = this.handleCloseDeleteChange.bind(this);
        this.handleOpenModifiedChange = this.handleOpenModifiedChange.bind(this);
        this.handleCloseModifiedChange = this.handleCloseModifiedChange.bind(this);
    }

    componentDidMount(){
        this.loadSavedSearch();
    }

    loadSavedSearch = () => {
        // this.setState((state)=> ({ shopLoading : true }));
        var body = {
            'user_id': this.props.currentUser.id,
            'offset': 0,
            'limit': 15,
            'country_id': this.props.currentCountry.id,
        };
        getUserSavedSearch(body).then(({data}) => {
            const {RESPONSECODE, RESPONSEMESSAGE, RESPONSEDATA} = data;
            console.warn(data);
            if (RESPONSECODE == 200) {
                this.setState({search: RESPONSEDATA});
            }
        }).catch(error => {
            console.warn(error);
        }).finally(()=>{
            // this.setState((state)=> ({ shopLoading : false }));
        });
    }

    handleOpenDeleteChange = (e, id) => {
        this.setState({currentId: id});
        this.setState((state) => ({ openDeleteDialog : true }));
    };
    handleCloseDeleteChange () {
        var body = {
            'saved_search_id': this.state.currentId,
        };
        deleteUserSavedSearch(body).then(({data}) => {
            const {RESPONSECODE, RESPONSEMESSAGE} = data;
            if (RESPONSECODE == 200) {
                this.props.setMessage(RESPONSEMESSAGE);
                this.setState((state) => ({ openDeleteDialog : false }));
                this.loadSavedSearch();
                // window.location.reload();
            }
        }).catch(error => {
            console.warn(error);
        }).finally(()=>{
            // this.setState((state)=> ({ shopLoading : false }));
        });
    };

    handleOpenModifiedChange = () => {
        this.setState((state) => ({ openModifiedDialog : true }));
    };
    handleCloseModifiedChange () {
        this.setState((state) => ({ openModifiedDialog : false }));
    };

    handleGoToSearch = (e, criteria) => {
        delete criteria?.country_name;
        delete criteria?.city_name;
        delete criteria?.zone_name;
        delete criteria?.state_name;
        delete criteria?.category_name;
        delete criteria?.gear_box_name;
        delete criteria?.fuel_name;
        delete criteria?.brand_name;
        delete criteria?.model_name;
        this.props.setIsSearching(2);
        this.props.setSearchObject(criteria);
        this.setState({goToSearch: '/search'});
    };

    render() {
        return (
            <div className="my-saved-search">
                { this.state.goToSearch && <Redirect to={this.state.goToSearch} />}
                <h1>Mes recherches sauvegardées</h1>
                <div className="my-saved-search-content">
                    {
                        this.state.search.length !== 0 ? (
                            <div className="content">
                                {
                                    this.state.search.map((advert)=> {
                                        var criteria = JSON.parse(JSON.parse(advert.criteria))[0];
                                        return (
                                            <div className="my-saved-search-content-item">
                                                <div className="my-saved-search-content-item-title">
                                                    <h4>{advert.title}</h4>
                                                </div>
                                                <div className="my-saved-search-content-item-criteria">
                                                    <ul>
                                                        {
                                                            criteria?.brand_name && (
                                                                <li>
                                                                    <Chip label={<p>Marque : {criteria?.brand_name}</p>} />
                                                                </li>
                                                            )
                                                        }
                                                        {
                                                            criteria?.category_name && (
                                                                <li>
                                                                    <Chip label={<p>Catégorie : {criteria.category_name}</p>} />
                                                                </li>
                                                            )
                                                        }
                                                        {
                                                            criteria?.city_name && (
                                                                <li>
                                                                    <Chip label={<p>Ville : {criteria.city_name}</p>} />
                                                                </li>
                                                            )
                                                        }
                                                        {
                                                            criteria?.country_name && (
                                                                <li>
                                                                    <Chip label={<p>Pays : {criteria.country_name}</p>} />
                                                                </li>
                                                            )
                                                        }
                                                        {
                                                            criteria?.fuel_name && (
                                                                <li>
                                                                    <Chip label={<p>Carburant : {criteria.fuel_name}</p>} />
                                                                </li>
                                                            )
                                                        }
                                                        {
                                                            criteria?.gear_box_name && (
                                                                <li>
                                                                    <Chip label={<p>Boite de vitesse : {criteria.gear_box_name}</p>} />
                                                                </li>
                                                            )
                                                        }
                                                        {
                                                            criteria?.model_name && (
                                                                <li>
                                                                    <Chip label={<p>Modele : {criteria.model_name}</p>} />
                                                                </li>
                                                            )
                                                        }
                                                        {
                                                            criteria?.nb_door && (
                                                                <li>
                                                                    <Chip label={<p>Nombre de portes : {criteria.nb_door}</p>} />
                                                                </li>
                                                            )
                                                        }
                                                        {
                                                            criteria?.memory && (
                                                                <li>
                                                                    <Chip label={<p>Mémoire : {criteria.memory}</p>} />
                                                                </li>
                                                            )
                                                        }
                                                        {
                                                            criteria?.mileage && (
                                                                <li>
                                                                    <Chip label={<p>Kilométrage : {criteria.mileage}</p>} />
                                                                </li>
                                                            )
                                                        }
                                                        {
                                                            criteria?.nb_rooms && (
                                                                <li>
                                                                    <Chip label={<p>Nombre de chambre : {criteria.nb_rooms}</p>} />
                                                                </li>
                                                            )
                                                        },
                                                        {
                                                            criteria?.nb_seat && (
                                                                <li>
                                                                    <Chip label={<p>Nombre de siège : {criteria.nb_seat}</p>} />
                                                                </li>
                                                            )
                                                        },
                                                        {
                                                            criteria?.negociable && (
                                                                <li>
                                                                    <Chip label={<p>Négociable : {criteria.negociable}</p>} />
                                                                </li>
                                                            )
                                                        },
                                                        {
                                                            criteria?.price_max && (
                                                                <li>
                                                                    <Chip label={<p>Prix Max : {criteria.price_max}</p>} />
                                                                </li>
                                                            )
                                                        },
                                                        {
                                                            criteria?.price_min && (
                                                                <li>
                                                                    <Chip label={<p>Prix Min : {criteria.price_min}</p>} />
                                                                </li>
                                                            )
                                                        },
                                                        {
                                                            criteria?.state_name && (
                                                                <li>
                                                                    <Chip label={<p>État : {criteria.state_name}</p>} />
                                                                </li>
                                                            )
                                                        },
                                                        {
                                                            criteria?.storage && (
                                                                <li>
                                                                    <Chip label={<p>Stockage : {criteria.storage}</p>} />
                                                                </li>
                                                            )
                                                        },
                                                        {
                                                            criteria?.year && (
                                                                <li>
                                                                    <Chip label={<p>Année : {criteria.year}</p>} />
                                                                </li>
                                                            )
                                                        },
                                                        {
                                                            criteria?.zone_name && (
                                                                <li>
                                                                    <Chip label={<p>Quartier : {criteria.zone_name}</p>} />
                                                                </li>
                                                            )
                                                        }

                                                    </ul>
                                                </div>
                                                <div className="my-saved-search-content-item-footer">
                                                    <div className="my-saved-search-content-item-footer-item date">
                                                        <p>Sauvegardé le {advert.created_at}</p>
                                                    </div>
                                                    <div className="my-saved-search-content-item-footer-item actions">
                                                        <Button onClick={(e) => this.handleOpenDeleteChange(e, advert.id)}><DeleteIcon/>Supprimer</Button>
                                                        <Dialog
                                                            className="dialog"
                                                            open={this.state.openDeleteDialog}
                                                            onClose={this.handleOpenDeleteChange}
                                                            aria-labelledby="alert-dialog-title"
                                                            aria-describedby="alert-dialog-description">
                                                            <IconButton aria-label="close" className="closeButton" onClick={this.handleCloseDeleteChange}>
                                                                <MdClose />
                                                            </IconButton>
                                                            <div className="change-container">
                                                                <DialogTitle id="alert-dialog-title">{"Suppression de recherche"}</DialogTitle>
                                                                <DialogContent>
                                                                    <div className="dialog-container">
                                                                        <p>Voulez-vous vraiment suppprimer cette recherche ?</p>
                                                                    </div>
                                                                </DialogContent>
                                                                <DialogActions className="confirmation-button">
                                                                    <Button onClick={this.handleCloseDeleteChange} color="primary">
                                                                        Annuler
                                                                    </Button>
                                                                    <Button onClick={this.handleCloseDeleteChange} color="primary" autoFocus>
                                                                        Oui
                                                                    </Button>
                                                                </DialogActions>
                                                            </div>
                                                        </Dialog>
                                                    </div>
                                                    <div className="my-saved-search-content-item-footer-item search-button">
                                                        {/* <Link href="/search">Faire une recherche</Link> */}
                                                        <Button onClick={(e) => this.handleGoToSearch(e, criteria)}>Faire une recherche</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) : (
                            <span className="content">
                                Aucune recherche sauvegardée!
                            </span>
                        )
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentCountry: state.country.currentCountry,
        currentUser: state.user.currentUser,
    }};

const mapDispatchToProps = dispatch => {
    return {
        setMessage: (message) => {
            dispatch(setMessage({ message : message}));
        },
        setIsSearching: (data) => {
            dispatch(setIsSearching({ isSearching : data}));
        },
        setSearchObject: (data) => {
            dispatch(setSearchObject({ searchObject : data}));
        },
    }};

export default connect(mapStateToProps, mapDispatchToProps)(MySavedSearch);
