import React from 'react';
import { uuid } from 'uuidv4';
import Item from './Item';
import {getAdvertsCat} from "../../services/advertisement";

const Category = ({
  category,
  openCountryPopup,
  categorySelected,
  currentCountry,
  setSearchResult,
  setIsSearching,
  setSearchObject,
  setSearchResultNumber
}) => {
  const handleClick = (cate) => {
    // e.preventDefault();
    getAdverts(cate.id);
    categorySelected(cate);
    if (!currentCountry) {
      openCountryPopup();
    }
  };

  const getAdverts = async (id) => {
    var body = {
        limit: 15,
        offset: 0,
        country_id: currentCountry.id,
        category_id: id,
    }
    var {data} = await getAdvertsCat(body);
    setSearchResultNumber(data.RESPONSEDATA ? data.RESPONSEDATA.length : 0);
    setSearchResult(data.RESPONSEDATA ? data.RESPONSEDATA : []);
    setIsSearching(2);
    body.offset = 15;
    setSearchObject(body);
  } ;

  return (
    <div className="footer-categories-item">
      <span className="semi upper">{category.title}</span>
      <ul>
        {category.items.map((item) => <Item key={uuid()} callback={() => handleClick(item)} item={item} />)}
      </ul>
    </div>
  );
}

export default Category;
