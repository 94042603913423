import { connect } from 'react-redux';
import Results from '../components/Results';
import {
  getAdvertisements,
  saveAdvertisements,
  setIsSearching,
  setSearchResult,
  setSearchObject,
  setSavedAdvertisements
} from '../reducers/advertisementReducer';
import {setMessage, setOpen} from "../reducers/snackbarReducer";

const mapStateToProps = state => ({
  currentCountry: state.country.currentCountry,
  advertisements: state.advertisement.advertisements,
  advertisementLoaded: state.advertisement.advertisementsLoaded,
  searchResult: state.advertisement.searchResult,
  isSearching: state.advertisement.isSearching,
  searchObject: state.advertisement.searchObject,
  searchObjectName: state.advertisement.searchObjectName,
  searchResultNumber: state.advertisement.searchResultNumber,
});

const mapDispatchToProps = dispatch => ({
  getAdvertisements: () => {
    dispatch(getAdvertisements());
  },
  saveAdvertisements: (adverts) => {
    dispatch(saveAdvertisements({ advertisements : adverts.advertisements}));
  },
  setSavedAdvertisements: (data) => {
    dispatch(setSavedAdvertisements({ savedAdverts : data}));
  },
  setSearchResult: (data) => {
    dispatch(setSearchResult({ searchResult : data}));
  },
  setIsSearching: (data) => {
    dispatch(setIsSearching({ isSearching : data}));
  },
  setSearchObject: (data) => {
    dispatch(setSearchObject({ searchObject : data}));
  },
  setOpen: (open) => {
    dispatch(setOpen({ open : open}));
  },
  setMessage: (message) => {
      dispatch(setMessage({ message : message}));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Results);