import benin from '../imgs/benin.png';
import burkina_faso from '../imgs/burkina_faso.png';
import cote_divoire from '../imgs/cote_divoire.png';
import mali from '../imgs/mali.png';
import niger from '../imgs/niger.png';
import senegal from '../imgs/senegal.png';
import togo from '../imgs/togo.png';
import rdc from '../imgs/rdc.jpg';

const countries = [
  {
    id: 1,
    name: 'Togo',
    slug: 'tg',
    image: togo,
    link: '',
    order: 1,
  },
  {
    id: 2,
    name: 'Benin',
    slug: 'bj',
    image: benin,
    link: '',
    order: 2,
  },
  {
    id: 3,
    name: 'Cote d\'Ivoire',
    slug: 'ci',
    image: cote_divoire,
    link: '',
    order: 3,
  },
  {
    id: 4,
    name: 'Senegal',
    slug: 'sn',
    image: senegal,
    link: '',
    order: 4,
  },
  {
    id: 5,
    name: 'Burkina Faso',
    slug: 'bf',
    image: burkina_faso,
    link: '',
    order: 5,
  },
  {
    id: 6,
    name: 'Niger',
    slug: 'ng',
    image: niger,
    link: '',
    order: 6,
  },
  {
    id: 7,
    name: 'Mali',
    slug: 'ml',
    image: mali,
    link: '',
    order: 7,
  },
  {
    id: 8,
    name: 'RDC',
    slug: 'rdc',
    image: rdc,
    link: '',
    order: 8,
  },
];

const cities = [
  {
    id: 1,
    name: 'Lomé',
    order: 1,
    countryId: 1,
  },
  {
    id: 2,
    name: 'Kpalimé',
    order: 2,
    countryId: 1,
  },
  {
    id: 3,
    name: 'KARA',
    order: 3,
    countryId: 1,
  },
  {
    id: 4,
    name: 'Cotonou',
    order: 1,
    countryId: 2,
  },
  {
    id: 5,
    name: 'Abomey-Calavi',
    order: 2,
    countryId: 2,
  },
  {
    id: 6,
    name: 'Porto-Novo',
    order: 3,
    countryId: 2,
  },
  {
    id: 7,
    name: 'Ouagadougou',
    order: 1,
    countryId: 3,
  },
  {
    id: 8,
    name: 'Banfora',
    order: 2,
    countryId: 3,
  },
  {
    id: 9,
    name: 'Bobo-Dioulasso',
    order: 3,
    countryId: 3,
  },
  {
    id: 10,
    name: 'Bamako',
    order: 1,
    countryId: 4,
  },
  {
    id: 11,
    name: 'Araouane',
    order: 2,
    countryId: 4,
  },
  {
    id: 12,
    name: 'Bafoulabé',
    order: 3,
    countryId: 4,
  },
  {
    id: 13,
    name: 'Niamey',
    order: 1,
    countryId: 5,
  },
  {
    id: 14,
    name: 'Agadez',
    order: 2,
    countryId: 5,
  },
  {
    id: 15,
    name: 'Arlit',
    order: 3,
    countryId: 5,
  },
  {
    id: 16,
    name: 'Abidjan',
    order: 1,
    countryId: 6,
  },
  {
    id: 17,
    name: 'Bouake',
    order: 2,
    countryId: 6,
  },
  {
    id: 18,
    name: 'Daloa',
    order: 3,
    countryId: 6,
  },
  {
    id: 19,
    name: 'Dakar',
    order: 1,
    countryId: 7,
  },
  {
    id: 20,
    name: 'Pikine',
    order: 2,
    countryId: 7,
  },
  {
    id: 21,
    name: 'Guédiawaye',
    order: 3,
    countryId: 7,
  },
  {
    id: 22,
    name: 'Kinshasa',
    order: 1,
    countryId: 8,
  },
  {
    id: 23,
    name: 'Bandundu',
    order: 2,
    countryId: 8,
  },
  {
    id: 24,
    name: 'Baraka',
    order: 3,
    countryId: 8,
  },
];

export const districts = [
  {
    id: 1,
    name: 'Abobokomé',
    order: 1,
    cityId: 1,
  },
  {
    id: 2,
    name: 'Adawlato',
    order: 2,
    cityId: 1,
  },
  {
    id: 3,
    name: 'Adoboukomé',
    order: 3,
    cityId: 1,
  },
  {
    id: 4,
    name: 'Numétukodzi',
    order: 1,
    cityId: 2,
  },
  {
    id: 5,
    name: 'Atakpamékodzi',
    order: 2,
    cityId: 2,
  },
  {
    id: 6,
    name: 'Zomayi',
    order: 3,
    cityId: 2,
  },
  {
    id: 7,
    name: 'Chaminade',
    order: 1,
    cityId: 3,
  },
  {
    id: 8,
    name: 'Adabawere',
    order: 2,
    cityId: 3,
  },
  {
    id: 9,
    name: 'Dongoyo',
    order: 3,
    cityId: 3,
  },
  {
    id: 10,
    name: 'Aïdjèdo',
    order: 1,
    cityId: 4,
  },
  {
    id: 11,
    name: 'Agla',
    order: 2,
    cityId: 4,
  },
  {
    id: 12,
    name: 'Ahouansori',
    order: 3,
    cityId: 4,
  },
  {
    id: 13,
    name: 'Calavi',
    order: 1,
    cityId: 5,
  },
  {
    id: 14,
    name: 'Akassato',
    order: 2,
    cityId: 5,
  },
  {
    id: 15,
    name: 'Godomey',
    order: 3,
    cityId: 5,
  },
  {
    id: 16,
    name: 'Houèzoumè',
    order: 1,
    cityId: 6,
  },
  {
    id: 17,
    name: 'Attakê',
    order: 2,
    cityId: 6,
  },
  {
    id: 18,
    name: 'Djassin',
    order: 3,
    cityId: 6,
  },
  {
    id: 19,
    name: 'Bilbalogo',
    order: 1,
    cityId: 7,
  },
  {
    id: 20,
    name: 'Saint Léon',
    order: 2,
    cityId: 7,
  },
  {
    id: 21,
    name: 'Zangouettin',
    order: 3,
    cityId: 7,
  },
  {
    id: 22,
    name: 'Banconi',
    order: 1,
    cityId: 10,
  },
  {
    id: 23,
    name: 'Boulkassombougou',
    order: 2,
    cityId: 10,
  },
  {
    id: 24,
    name: 'Djélibougou',
    order: 3,
    cityId: 10,
  },
  {
    id: 25,
    name: 'Anikwara',
    order: 1,
    cityId: 13,
  },
  {
    id: 26,
    name: 'Balafon',
    order: 2,
    cityId: 13,
  },
  {
    id: 27,
    name: 'Banifandou',
    order: 3,
    cityId: 13,
  },
];

export const getCountryById = (countryId) => countries.find((country) => country.id == countryId);
export const getCityById = (cityId) => cities.find((city) => city.id == cityId);
export const getDistrictById = (districtId) => districts.find((district) => district.id == districtId);
export const getAllCitiesByCountryId = (countryId) => cities.filter((city) => city.countryId == countryId);
export const getAllDistrictsByCityId = (cityId) => districts.filter((district) => district.cityId == cityId);
export const getAllCountries = () => {
  let villes = cities.slice();
  villes.forEach((ville)=> {
    let dists = getAllDistrictsByCityId(ville.id);
    ville["districts"] = dists.slice();
  });
  let payss = countries.slice();
  payss.forEach((pays)=> {
    let villes = getAllCitiesByCountryId(pays.id);
    pays["cities"] = villes.slice();
  });

  return payss;
}

export default {
  getAllCountries,
  getAllCitiesByCountryId,
  getAllDistrictsByCityId,
  getCountryById, getCityById,
  getDistrictById
};
