import React, { useState, useEffect} from 'react';
import {
    useParams,
    Redirect,
} from 'react-router-dom';

import Header from '../../containers/Header';
import Filters from './Filters';
import Advertisements from '../../containers/Advertisements';
import Footer from '../Footer';
import { getAllCategories } from '../../services/category';
import carouselImage2 from '../../imgs/Immobilier.jpg';
import searchIllustration from '../../imgs/research-ilustration.svg';
import {getBrands, searchAdvertisements, searchAdvertisementsNumber} from '../../services/advertisement';
import Slider from "react-slick";
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import './Search.scss';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { MdClose } from 'react-icons/md';
import {staticApiUrl} from "../../utils/axios";

const Search = ({
    currentCountry,
    brands,
    saveBrands,
    saveCategories,
    setSearchResult,
    setIsSearching,
    searchObject,
    isSearching,
    searchResult,
    setSearchObject,
    setSearchResultNumber,
    searchResultNumber,
    categorySelected,
    citySelected,
    setSearchObjectName,
    showSearchDialog,
    setShowSearchDialog
}) => {
    const { countrySlug } = useParams();
    const first = {
        title : "Toutes les categories", items : [], icon : 'https://api.iconify.design/mdi:backburger.svg'
    };
    const [data, setData] = React.useState([first]);
    const [playing, setPlaying] = React.useState(true);
    const [search, setSearch] = useState(false);
    const [searching, setSearching] = useState(false);
    const [isOpen, setIsOpen] = useState("false");
    let sliderRef = null;
    // const [ brands, setBrands ] = useState(null);

    const resetSearch = () => {
        //console.log('before reset ', searchResult, searchResultNumber)
        setSearchResult([]);
        setIsSearching(1);
        setSearchObject({});
        setSearchObjectName({});
        setSearchResultNumber(null);
        //console.log('reset search function in search index')
        //console.log('after reset', searchResult, searchResultNumber)

    }
    
    useEffect(() => {
        //console.log('useEffect call of Search')
        //console.warn(searchObject);
        if (isSearching == 2 && Object.keys(searchObject).length !== 0 && searchObject.constructor === Object) {
            console.log('INIT SEARCH')
            filterAd(searchObject);
            searchAd(searchObject, null);
        }
        setSearchResult([]);
        setIsSearching(1);
        setSearchObject({});
        setSearchObjectName({});
        setSearchResultNumber(null);
        getAllCategories().then(({ data }) => {
            let { RESPONSEDATA } = data;
            // RESPONSEDATA.unshift(first);
            let tab = [];
            for (let i = 0; i < RESPONSEDATA.length; i++) {
                var item = RESPONSEDATA[i];
                var obj = {};
                obj.id = item.id;
                let url = `${staticApiUrl}/iconimages/`;
                let filename = "no-image";
                if (item.icon[0] !== undefined) {
                    filename = url + item.icon[0].filename;
                }
                obj.image = filename;
                obj.title = item.title_fr;
                let tab2 = [];
                item.sub.map(elt2 => {
                    tab2.push({
                        id: elt2.id,
                        title: elt2.title_fr,
                        parentId: item.id
                    })
                })
                obj.items = tab2;
                tab.push(obj);
            }
            setData(tab);
            // saveCategories(tab);
            //    setPlay();
        }).catch((e)=>{
            console.error(e);
        });
        fetchBrands();
    }, [])

    const homeUrl = () => {
        if (currentCountry) {
            return `/${currentCountry.slug}/search`;
        }

        return '/search';
    };

    if (currentCountry) {
        if (countrySlug) {
            if (countrySlug !== currentCountry.slug) {
                return (
                    <Redirect to={homeUrl()} />
                );
            }
        }
        else {
            return (
                <Redirect to={homeUrl()} />
            );
        }
    }

    const fetchBrands = ()=>{
        getBrands()
            .then(({data}) => {
                let { RESPONSEDATA } = data;
                saveBrands({brands : RESPONSEDATA});
                // setBrands(RESPONSEDATA);
            })
            .catch(e=> {
                console.error(e);
                // setBrands([]);
            }).finally(()=>{
        });
    }

    const carouselImages = [
        {
            key: 1,
            url: searchIllustration
        },
        {
            key: 2,
            url: carouselImage2
        },
        /*{
            key: 3,
            url: carouselImage3
        },
        {
            key: 4,
            url: carouselImage4
        },
        {
            key: 5,
            url: carouselImage5
        },
        {
            key: 6,
            url: carouselImage6
        },
        {
            key: 7,
            url: carouselImage7
        },
        {
            key: 8,
            url: carouselImage8
        },
        {
            key: 9,
            url: carouselImage9
        },
        {
            key: 10,
            url: carouselImage10
        },
        {
            key: 11,
            url: carouselImage11
        }*/
    ]

    const settings = {
        dots: false,
        infinite: true,
        speed: 700,
        adaptiveHeight: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 30000,
        slidesToShow: 1,
        slidesToScroll: 1
    }

    const togglePlay = () => {
        if (!playing) {
            sliderRef.slickPlay();
        } else {
            sliderRef.slickPause();
        }
        setPlaying(!playing);
    }

    const filterAd = async (body) => {
        console.log('filterAd---------------', body)
        setIsSearching(1);
        var myBody = Object.assign({}, body);
        myBody.offset = 0;
        myBody.limit = 15;
        var res = await searchAdvertisementsNumber(myBody);
        console.log('filterAd---------------', res.data.number_total_result)

        setSearchResultNumber(res.data.number_total_result);
    } ;

    const searchAd = async (body, body2) => {
        console.log('SEARCH component, searchAds called')
        var myBody = Object.assign({}, body);
        myBody.offset = 0;
        myBody.limit = 15;
        setSearching(true);
        searchAdvertisements(myBody)
            .then(({data}) => {
                console.warn('data', data);
                let { RESPONSEDATA, STATUS } = data;
                if (STATUS) {
                    myBody.offset = 15;
                    setIsSearching(2);
                    setSearchResult(RESPONSEDATA);
                    setSearchObject(myBody);
                    if (body2) {
                        setSearchObjectName(body2)
                    }
                    // window.location.reload();
                    // setSearch(true);
                } else {
                    setIsSearching(2);
                    setSearchResult([]);
                    setSearchObject(myBody);
                    if (body2) {
                        setSearchObjectName(body2)
                    }
                    // window.location.reload();
                }
            })
            .catch(e=> {
                setIsSearching(1);
                setSearchResult([]);
                setSearchObject({});
                setSearchObjectName({});
                console.error(e);
                // window.location.reload();
            }).finally(()=>{
            setSearching(false);
        });
    } ;

    /*const handleToggle = () => {
        setIsOpen(!isOpen);
    };*/




    //console.log('RENDER SEARCH COMP')
    if(search) return <Redirect to="/search-results" />

    return (
        <div className="search-wrapper">
            <Header />
            <main id="search" className="search">
                <div className="container">
                    <div className="container-carousel">
                        <div className="slider-pause">
                            <Button onClick={togglePlay}>
                                { playing ? <PauseIcon/> : <PlayArrowIcon/> }
                            </Button>
                        </div>
                        <Slider ref={slider => (sliderRef = slider)} {...settings}>
                            {
                                carouselImages.map((carouselImage) => <img src={carouselImage.url} key={carouselImage.key} />)
                            }
                        </Slider>

                        <Filters
                            categories={data}
                            cities={currentCountry.cities}
                            brands={brands} simple={false}
                            searchAd={searchAd}
                            filterAd={filterAd}
                            isSearching={isSearching}
                            searchResult={searchResult}
                            resetSearch={resetSearch}
                            searchResultNumber={searchResultNumber}
                            categorySelected={categorySelected}
                            citySelected={citySelected}
                            searching={searching}
                        />
                    </div>

                    <Advertisements />
                </div>
            </main>
            {/*<button onClick={handleToggle}>popup</button>*/}
            <div className={"mobile-filter" + ( !showSearchDialog ? "" : " open")} >

                <button className="close-button">
                    <IconButton aria-label="close" onClick={() => setShowSearchDialog(false)}>
                        <MdClose className="filter-close"/>
                    </IconButton>
                </button>
                <Filters
                    categories={data}
                    cities={currentCountry.cities}
                    brands={brands} simple={false}
                    searchAd={searchAd}
                    filterAd={filterAd}
                    isSearching={isSearching}
                    searchResult={searchResult}
                    resetSearch={resetSearch}
                    searchResultNumber={searchResultNumber}
                    categorySelected={categorySelected}
                    citySelected={citySelected}
                    searching={searching}
                />
            </div>
            <Footer />
        </div>
    );
};

export default Search;
