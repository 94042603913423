import React from "react";
import Header from "../../containers/Header";
import Footer from "../Footer";
import './PrivacyData.scss';
import privacy from "../../imgs/chat.svg";
import {NavLink} from "react-router-dom";

const PrivacyData = () => {
    return (
        <div>
            <Header />
            <div className="privacy-data-container">
                <div className="footer-breadcrumb">
                    <ul>
                        <li>
                            <NavLink to="/">Accueil</NavLink>
                        </li>
                        <li className="separator">/</li>
                        <li>Données privées</li>
                    </ul>
                </div>
                <div className="privacy-data-container-inner">
                    <div className="privacy-data-container-inner-header">
                        <div className="privacy-data-container-inner-header-image">
                            <img src={privacy} alt="privacy"/>
                        </div>
                        <div className="privacy-data-container-inner-header-text">
                            <h1>VIE PRIVEE ET COOKIES</h1>
                            <p>Mis à jour Septembre 2021</p>
                        </div>
                    </div>
                    <div className="privacy-data-container-inner-body">
                        <div className="privacy-data-container-inner-body-item">
                            <h2>Vie privée</h2>
                            <p>
                                Lorsque vous utilisez nos services, vous acceptez que notre société collecte certaines de vos informations personnelles. <br/>
                                Cette page a pour but de vous indiquer quelles informations nous collectons, pour quelle raison et comment nous les utilisons.
                            </p>
                            <h3>
                                Quels types de données collectons-nous ?
                            </h3>
                            <p>
                                Les données que vous renseignez :
                            </p>
                            <p>
                                Si vous êtes un particulier :
                            </p>
                            <p>Les données que vous renseignez :</p>
                            <p>
                                Vous êtes amenés à nous communiquer certaines informations, lorsque vous déposez votre annonce, lorsque vous créez ou vous connectez à votre Compte Personnel.
                                Lorsque vous déposez une annonce, vous devez nous communiquer :
                            </p>
                            <ul>
                                <li>Les données personnelles suivantes : adresse email, numéro de téléphone.</li>
                                <li>D’autres données : code postal ou ville où est situé le bien objet de votre annonce, ou adresse postale le cas échéant.</li>
                            </ul>
                            <p>
                                Vous pouvez également choisir de nous communiquer vos coordonnées de géolocalisation via la fonctionnalité de géolocalisation des annonces de notre site.
                            </p>
                            <p>
                                Lorsque vous vous créez ou vous connectez à votre Compte Personnel, vous devez nous communiquer :
                            </p>
                            <ul>
                                <li>Les données personnelles suivantes : nom, prénom, adresse email</li>
                                <li>D’autres données : pseudonyme.</li>
                            </ul>
                            <p>
                                Vous pouvez également choisir de nous communiquer les informations suivantes :
                            </p>
                            <ul>
                                <li>Civilité</li>
                                <li>Date de naissance</li>
                                <li>Catégorie socioprofessionnelle</li>
                                <li>Centres d’intérêts</li>
                            </ul>
                            <p>
                                Si vous êtes un professionnel : <br/>
                                Vous êtes amenés à nous communiquer certaines informations, lorsque vous créez un Compte Professionnel :
                            </p>
                            <ul>
                                <li>
                                    Les données personnelles suivantes : nom, prénom, adresse email, numéro de téléphone
                                </li>
                                <li>
                                    D’autres données : nom de la société, numéro RCCM, activité professionnelle, adresse
                                </li>
                            </ul>
                            <p>
                                Les données que nous collectons automatiquement par l’utilisation de nos services :
                            </p>
                            <p>
                                Nous recueillons les informations suivantes relatives aux services que vous utilisez et à l’usage que vous en faîtes :
                            </p>
                            <ul>
                                <li>
                                    Des données techniques : adresse IP, connexion internet, type de navigateur, informations concernant votre ordinateur ;
                                </li>
                                <li>
                                    Des données recueillies à l’aide de cookies : pour de plus amples informations, nous vous invitons à vous rendre sur la rubrique COOKIES ;
                                </li>
                            </ul>
                            <h3>
                                Comment utilisons-nous les données collectées ?
                            </h3>
                            <p>
                                Nous utilisons les données collectées, afin de :
                            </p>
                            <p>
                                Fournir nos services : <br/>
                                Vous assurez l’accès à nos services et leur utilisation, et notamment :
                            </p>
                            <ul>
                                <li>Assurer la publication de votre annonce ;</li>
                                <li>Assurer la gestion de votre compte ;</li>
                                <li>Géolocaliser votre bien ;</li>
                                <li>Répondre à vos questions.</li>
                            </ul>
                            <p>
                                Faire des analyses marketing :
                            </p>
                            <ul>
                                <li>Comprendre comment vous utilisez nos services ;</li>
                                <li>Vous proposez des services améliorés et personnalisés en recueillant notamment votre opinion à l’aide de questionnaires ;</li>
                                <li>Mener des enquêtes et des études.</li>
                            </ul>
                            <p>
                                Personnaliser nos communications publicitaires et commerciales :
                            </p>
                            <ul>
                                <li>Personnaliser nos publicités ;</li>
                                <li>Vous envoyer des propositions commerciales et/ou promotionnelles, émanant uniquement de Taari et concernant exclusivement le service Taari.co ;</li>
                                <li>Vous envoyer des propositions commerciales et/ou promotionnelles, émanant de sociétés tierces en relation avec Taari! et ne concernant pas exclusivement le service Taari.co, étant entendu que cet envoi sera effectuée exclusivement par Taari.</li>
                            </ul>
                            <p>
                                Aucune vente de base de données à des sociétés tiers n'est faite Taari.
                            </p>
                            <p>
                                En outre, cet envoi de propositions commerciales et/ou promotionnelles ne sera effectué qu'après avoir reçu le consentement exprès des personnes concernées,
                            </p>
                            <h3>
                                Qui sont les destinataires des données personnelles collectées ?
                            </h3>
                            <p>
                                Conformément à la loi, Taari s'engage à conserver toutes les données personnelles recueillies via le service Taari.co et à ne les transmettre à aucun tiers.
                            </p>
                            <p>
                                Par dérogation, l'Utilisateur et l'Annonceur sont informés que Taari peut être amenée à communiquer les données personnelles collectées via le service Taari.co :
                            </p>
                            <ul>
                                <li>
                                    A la société mère et aux sociétés-sœurs Taari;
                                </li>
                                <li>
                                    Aux autorités administratives et judiciaires autorisées, uniquement sur réquisition judiciaire ;
                                </li>
                                <li>
                                    Aux prestataires techniques de service qui nous aident à fournir et améliorer le service.
                                </li>
                            </ul>
                            <p>
                                Par ailleurs, nous faisons appel au prestataire de service HERE, qui, agissant en qualité de responsable de traitement de vos données, est susceptible de récupérer les données suivantes : codes postaux, adresses postales (le cas échéant) et adresses IP. Ces données sont soumises à un processus d’anonymisation conforme à la règlementation en vigueur en matière de données à caractère personnel.
                            </p>
                            <h3>
                                Comment puis-je gérer ou supprimer les données personnelles collectées à mon sujet ?
                            </h3>
                            <p>
                                Accès à vos données personnelles depuis votre compte
                            </p>
                            <p>
                                Vous pouvez, à tout moment, modifier les données personnelles de votre Compte Personnel et/ou Compte Professionnel. Il vous suffit de vous rendre dans votre Compte Personnel et/ou Compte Professionnel et de cliquer sur l’onglet « Mon profil ».
                            </p>
                            <p>
                                Exercer vos droits auprès de notre société
                            </p>
                            <p>
                                Conformément à la loi toute personne physique dispose à tout moment d'un droit d'accès, de rectification, de suppression ainsi que d'opposition au traitement des données le concernant.
                            </p>
                            <p>
                                L'Utilisateur et l'Annonceur Particulier peuvent exercer ce droit en contactant Le Taari  via la rubrique "Découvrir Taari", présente sur le Site Internet, l'Appli Universelle iPhone/iPad, ou l'Appli Android.
                            </p>
                            <ul>
                                <li>
                                    Droit d’opposition au traitement des données personnelles <br/>
                                    Il s’agit de la possibilité de s’opposer à ce que vos données soient collectées, enregistrées, diffusées, communiquées ou conservées dans : <br/>
                                    - Notre base de données clients : cette demande est gratuite <br/>
                                    - Hors de notre base de données clients : cette demande doit être faite pour un motif légitime mais est gratuite
                                </li>
                                <li>
                                    Droit d’accès à vos données personnelles <br/>
                                    Vous êtes susceptibles de devoir payer des frais de reproduction.
                                </li>
                                <li>
                                    Droit de rectification et d’effacement de vos données personnes <br/>
                                    Il s’agit de la possibilité de demander à ce que soient rectifiées, complétées, mises à jour, verrouillées ou effacées les données personnelles qui sont inexactes, incomplètes, équivoques, périmées, ou dont la collecte, l'utilisation, la communication ou la conservation est interdite.
                                </li>
                            </ul>
                            <p>
                                Ce droit fait suite à l’exercice du droit d’accès par un utilisateur, et son exercice est gratuit.
                            </p>
                            <h3>Durée de conservation de vos données personnelles auprès de Taari :</h3>
                            <p>
                                La durée de conservation de vos données personnelles varie en fonction de la finalité de leurs collectes :
                            </p>
                            <ul>
                                <li>
                                    Les données client (identité, coordonnées électroniques, historique d’utilisation des services) sont conservées 3 ans à compter de la fin de la relation commerciale ;
                                </li>
                                <li>
                                    Les données relatives à la gestion de votre compte sont conservées pour une durée de 5 ans à compter de sa suppression et ce, exclusivement à des fins de preuve ;
                                </li>
                                <li>
                                    Les documents et pièces comptables sont conservés 10 ans, à titre de preuve comptable ;
                                </li>
                                <li>
                                    Les données relatives aux transactions effectuées et moyens de paiement utilisés pour une durée d’un an à compter de la date d’émission de la facture ou du paiement ;
                                </li>
                                <li>
                                    Les données susceptibles de faire l’objet d’une réquisition judiciaire (données de connexion, identité, coordonnées de contact) sont conservées 12 mois à compter de leur collecte.
                                </li>
                            </ul>
                            <h3>Comment assurons-nous la sécurité des données ?</h3>
                            <p>
                                Nous protégeons vos informations à l’aide de mesures de sécurité physiques, électroniques et administratives. Nos mesures de protection incluent notamment des pare-feu, des contrôles d’accès physiques à nos centres de données et des contrôles d’autorisation d’accès aux informations.
                            </p>
                            <h3>Responsable de traitement, Déclaration, Contact</h3>
                            <p>
                                Le Groupe Techno’Sens, maison mère de Taari, immatriculée au RCS de LOME sous le numéro TG-LOM 2017 B 1192, ayant son siège social au 35 rue de la patience
                                Cassablanca Lomé - Togo représentée par monsieur Zuh Kwaku en sa qualité de Directeur Général, est responsable du traitement des données qu’elle collecte sur le Service Taari.
                            </p>
                            <p>
                                Nous vous invitons à nous contacter pour toute question ou requête relative à vos données personnelles et au respect de votre vie privée, en suivant ce <a href="mailto:contact@taari.co">lien</a>.
                            </p>
                        </div>
                        <div className="privacy-data-container-inner-body-item">
                            <h2>Cookies</h2>
                            <p>
                                Taari souhaite vous informer de manière claire et transparente sur l'usage des cookies lors de votre navigation sur son site web, et via ses applications iPhone, iPad et Android.
                            </p>
                            <h3>Qu’est-ce qu’un cookie ?</h3>
                            <p>
                                Un cookie est un fichier texte stocké sur le disque dur de votre terminal (ordinateur, tablette, mobile) par le biais de votre logiciel de navigation à l'occasion de la consultation d'un service en ligne.
                            </p>
                            <p>
                                Cela signifie qu’un cookie Taari nous est envoyé à chaque fois que vous ouvrez une page sur notre site à partir de votre ordinateur ou de votre appareil mobile. Les cookies enregistrés par nous ou par des tiers lorsque vous visitez notre site ou nos applications ne vous reconnaissent pas personnellement en tant qu'individu, mais reconnaissent seulement l'appareil que vous êtes en train d'utiliser. Les cookies ne stockent aucune donnée personnelle sensible mais donnent simplement une information sur votre navigation de façon à ce que votre terminal puisse être reconnu plus tard.
                            </p>
                            <p>
                                Les cookies ne causent aucun dommage à votre appareil, mais permettent plus facilement par exemple de retrouver vos préférences, de pré-remplir certains champs et d'adapter le contenu de nos services.
                            </p>
                            <p>
                                Vous seul(e) choisissez si vous souhaitez avoir des cookies enregistrés sur votre appareil et vous pouvez facilement contrôler l'enregistrement des cookies. Pour une information spécifique sur la gestion et le contrôle des cookies, veuillez-vous référer à la rubrique « Vos choix concernant les cookies ».
                            </p>
                            <h3>Quels sont les cookies présents sur notre site et nos applications ?</h3>
                            <p>
                                Les cookies fonctionnels et d’identification que nous déposons :
                            </p>
                            <p>
                                Les cookies fonctionnels que nous émettons nous permettent :
                            </p>
                            <ul>
                                <li>de vous identifier lorsque vous vous connectez sur notre site ;</li>
                                <li>d'adapter la présentation de nos sites, applications et espaces publicitaires aux préférences d'affichage de votre terminal (langue utilisée, résolution</li>
                                <li>l'affichage, (système d'exploitation utilisé, etc), selon les matériels et les logiciels de visualisation ou de lecture que votre terminal comporte ;</li>
                                <li>de faciliter vos recherches en conservant vos recherches automatiques et vos annonces sauvegardées ;</li>
                                <li>d'améliorer votre expérience utilisateur en pré-remplissant certains champs de nos formulaires.</li>
                            </ul>
                            <p>
                                Les cookies analytiques que nous déposons :
                            </p>
                            <p>
                                Les cookies analytiques que nous émettons nous permettent de mieux connaître nos sites et applications et d'améliorer nos services en établissant des statistiques et volumes de fréquentation et d'utilisation (rubriques et contenus visités, parcours...).
                            </p>
                            <p>
                                Les cookies de publicité ciblée que nous déposons :
                            </p>
                            <p>
                                La publicité ciblée consiste à personnaliser les contenus publicitaires d’un site en fonction du comportement des internautes et de l'identification de leurs centres d'intérêt.
                            </p>
                            <p>
                                A l’occasion de vos visites sur Taari, nous sommes susceptibles de placer des cookies sur votre terminal afin de créer des profils d'utilisateurs et de vous faire bénéficier de contenus publicitaires ciblés pertinents pour vous et vos centres d’intérêts.
                            </p>
                            <p>
                                Ces contenus s’affichent à vous en fonction d’informations liées uniquement à votre utilisation du site et des applications leboncoin.fr et notamment :
                            </p>
                            <ul>
                                <li>des catégories d’annonces que vous visitez ;</li>
                                <li>de la fréquence de vos visites ;</li>
                                <li>des mots-clés que vous saisissez.</li>
                            </ul>
                            <p>
                                Les cookies de publicité ciblée sont aussi utilisés pour limiter le nombre de fois où vous voyez une publicité et pour aider à mesurer l'efficacité d'une campagne publicitaire.
                            </p>
                            <p>
                                La durée de vie de nos cookies de publicité ciblée est de 60 jours.
                            </p>
                            <p>
                                Les cookies de publicité ciblée déposés par nos partenaires :
                            </p>
                            <p>
                                Des cookies de publicité ciblée peuvent être déposés soit par notre propre régie publicitaire, soit par nos partenaires. <br/>
                                Taari accepte en effet que, lorsque vous visitez nos sites et nos applications, certains cookies ne soient pas enregistrés par nous mais par des tiers partenaires pour la diffusion de publicité ciblée. <br/>
                                C’est le cas notamment à l’occasion de l’intégration sur notre site de certains modules et fonctionnalités, comme les boutons sociaux. <br/>
                                Leboncoin.fr ne gère pas les cookies de ces sociétés partenaires et n'a aucun contrôle sur ces cookies. <br/>
                                A cet égard, nous vous conseillons de consulter la propre politique de ces partenaires liée à ces cookies et en particulier la procédure d'opposition à ces cookies prévue par ces derniers (rubrique Vos choix concernant les cookies).
                            </p>
                            <h3>Quels sont vos choix par rapport aux cookies ?</h3>
                            <p>
                                Vous pouvez à tout moment exprimer ou modifier vos souhaits en matière de cookies, par les moyens décrits ci-dessous :
                            </p>
                            <p>
                                Vos choix concernant les cookies de manière générale
                            </p>
                            <p>
                                Vous pouvez à tout moment configurer votre logiciel de navigation de manière à ce que des cookies soient enregistrés dans votre terminal (opt-in) ou, au contraire, qu'ils soient rejetés (opt-out). Certains navigateurs vous permettent de définir des règles pour gérer les cookies site par site.
                            </p>
                            <p>
                                Pour la gestion des cookies, la configuration de chaque navigateur est différente. Elle est décrite dans le menu d'aide de votre navigateur, qui vous permettra de savoir de quelle manière modifier vos souhaits :
                            </p>
                            <ul>
                                <li>Edge</li>
                                <li>Safari</li>
                                <li>Chrome</li>
                                <li>Firefox</li>
                                <li>Opera</li>
                            </ul>
                            <p>
                                Vos choix concernant la publicité ciblée
                            </p>
                            <ul>
                                <li>
                                    Sur votre navigateur <br/>
                                    Vous pouvez à tout moment paramétrer votre navigateur de façon à refuser le cookie de publicité ciblée Taari en cliquant ici. <br/>
                                    Les bandeaux publicitaires qui seront affichés sur notre site seront alors des bandeaux de publicité standardisée. <br/>
                                    En cliquant sur ce lien, nous vous informons qu’un cookie sera déposé sur votre navigateur nous indiquant que vous ne souhaitez plus voir de publicité ciblée sur notre site Taari.co. Dès lors, si vous décidez ultérieurement de supprimer vos cookies, ce cookie sera également effacé et nous n’aurons plus aucun moyen de connaître vos choix en matière de publicité ciblée. Nous serons alors susceptibles d’afficher de nouveau du contenu publicitaire ciblé, à moins que vous ne le refusiez à nouveau via le lien ci-dessus.
                                </li>
                                <li>
                                    Sur votre téléphone ou votre tablette <br/>
                                    La procédure pour désactiver le ciblage publicitaire de vos appareils mobiles est la suivante : <br/>
                                    - sur iOS, rendez-vous sur les réglages de confidentialité, accédez à l’option « publicité » et activez le "suivi publicitaire limité" ; <br/>
                                    - sur Android, rendez-vous dans l’application "paramètres Google", activez l’option « désactiver les annonces par centre d’intérêt » et "réinitialiser l’identifiant publicitaire".
                                </li>
                            </ul>
                            <p>
                                Vos choix concernant la publicité délivrée par nos partenaires
                            </p>
                            <p>
                                Vous avez également la possibilité de paramétrer votre navigateur de façon à refuser les cookies de publicité ciblée émis par nos partenaires. <br/>
                                Nous vous conseillons de consulter les pages suivantes relatives aux procédures d’opposition à ces cookies prévues par ces derniers :
                            </p>
                            <ul>
                                <li>Google (Adsense, DoubleClick Adexchange)</li>
                                <li>Critéo</li>
                                <li>Real Media</li>
                                <li>Taboola</li>
                                <li>Quantum</li>
                                <li>Weborama</li>
                                <li>Rubicon</li>
                                <li>Outbrain</li>
                                <li>Appnexus</li>
                                <li>Tealium</li>
                                <li>Twitter</li>
                                <li>Facebook</li>
                                <li>Sublimskinz</li>
                                <li>AB Tasty</li>
                                <li>ADventori</li>
                            </ul>
                            <p>
                                Nous vous invitons également à nous contacter pour toute question ou requête relative aux cookies déposés sur notre site et/ou à la publicité ciblée, en suivant ce <a href="mailto:contact@taari.co">lien</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default PrivacyData;
