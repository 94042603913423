import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated : false,
  userLoadState : "NOT LOADED",
  currentUser : null,
  preLoginData : {},
  operation: 0,
  playerId: null,
};

const userSlice = createSlice({
  name : 'user',
  initialState: initialState,
  reducers : {
      saveUser : (state, action) => {
        state.currentUser = action.payload.user
        state.isAuthenticated = true
      },

      setPreLoginData : (state, action) => {
        state.preLoginData = action.payload.user
      },

      disconnectUser : (state) => {
        state.currentUser = null
        state.isAuthenticated = false
      },

      updateUserLoadState : (state, action) => {
        state.userLoadState = action.payload.userLoadState;
      },

      changeOperation : (state, action) => {
        state.operation = action.payload.operation;
      },

      setPlayerId : (state, action) => {
        state.playerId = action.payload.playerId;
      }
  }

});

export const { saveUser, disconnectUser, updateUserLoadState, setPreLoginData, changeOperation, setPlayerId} = userSlice.actions;
export default userSlice.reducer;
