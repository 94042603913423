import React, {useEffect, useState} from 'react';
import {Button, Checkbox, FormControl, InputLabel, MenuItem, Select} from '@material-ui/core';
import {
    MdLocationOn,
    MdSearch
} from 'react-icons/md';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Slider from "@material-ui/core/Slider";
import {useDispatch, useSelector} from 'react-redux';
import {SimpleDialog} from "../SimpleDialog/simpleDialog";
import {citiesToDialog} from "../../services/normalizers";
import {resetAdvertisements, updateAdvertisementsSearchFilter} from "../../reducers/advertisementReducer";
import {getAllCategories} from "../../services/category";
import {getBrands, countAdverts} from "../../services/advertisement";
import list3 from '../../imgs/list-3.svg';
import Popover from "@material-ui/core/Popover";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import {useFormik} from 'formik';
import {getAllColors} from "../../services/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import List from "@material-ui/core/List";
import CircularProgress from "@material-ui/core/CircularProgress";
import { setShowSearchDialog } from "../../reducers/advertisementReducer";
import {staticApiUrl} from "../../utils/axios";
import { resetCountry } from '../../reducers/countryReducer';
import { setSearchResultNumber } from '../../reducers/advertisementReducer';

var search_text = '';
var city_id = null;
var zone_id = null;
var category_id = null;
var price_min = null;
var price_max = null;
var type_advert = null;
var state = null;
var type_price = null;
var year = null;
var mileage = null;
var color = null;
var gear_box = null;
var fuel = null;
var brand_id = null;
var model_id = null;
var nb_door = null;
var nb_seat = null;
var type_immo = null;
var area = null;
var negociable = null;
var storage = null;
var memory = null;
var nb_rooms = null

const Filters = (props) => {
    const dispatch = useDispatch();
    const currentCountry = useSelector(state => state.country.currentCountry);
    const [searchString, setSearchString] = useState("");
    const [openCity, setOpenCity] = useState(false);
    const [openCategory, setOpenCategory] = useState(false);
    const [elementCity, setElementCity] = useState(null);
    // Sauvegarde la categorie recherchée
    const [elementCategory, setElementCategory] = useState(null);
    // Sauvegarder le parent de la categorie
    const [elementCategoryParent, setElementCategoryParent] = useState(null);
    // Si l'élément sélectionné est un child category
    const [isChild, setIsChild] = useState(false);
    const [categories, setCategories] = useState([]);
    const [colors, setColors] = useState([]);
    const [brands, setBrands] = useState([]);
    const [cityId, setCityId] = useState('');
    const [zoneId, setZoneId] = useState('');

    const cities = citiesToDialog(currentCountry.cities);

    const handleBrandsChange = (type) => {
        getBrands({'type': type})
            .then(({data}) => {
                let { RESPONSEDATA } = data;
                setBrands(RESPONSEDATA);
            })
            .catch(e=> {
                console.error(e);
                // setBrands([]);
            }).finally(()=>{
        });
    };

    const handleClickOpenCity = ()=> {
        setOpenCity(!openCity);
        setOpenCategory(false);
    }

    const handleClickOpenCategory = ()=> {
        setOpenCategory(!openCategory);
        setOpenCity(false);
    }

    const handleCloseCity = (element, city) => {
        setOpenCity(false);
        if (city) {
            setElementCity(element ? element : city);
            setCityId(city.id);
            console.log(city)
            city_id = city.id;
            zone_id = element ? element.id : null;
        } else {
            setElementCity(null);
            setCityId(null);
            city_id = null;
            zone_id = null;
        }
        if(element){
            setZoneId(element.id);
            filterAdvertisements(element);
        }
        goToSearch(false);
    };

    const loadCategories = () => {
        getAllCategories().then(({ data }) => {
            let { RESPONSEDATA } = data;
            let tab = [];
            for (let i = 0; i < RESPONSEDATA.length; i++) {
                var item = RESPONSEDATA[i];
                var obj = {};
                obj.id = item.id;
                let url = `${staticApiUrl}/iconimages/`;
                let filename = "no-image";
                if (item.icon[0] !== undefined) {
                    filename = url + item.icon[0].filename;
                }
                obj.image = filename;
                obj.title = item.title_fr;
                let tab2 = [];
                item.sub.map(elt2 => {
                    tab2.push({
                        id: elt2.id,
                        title: elt2.title_fr,
                        parentId: item.id
                    })
                })
                obj.items = tab2;
                tab.push(obj);
            }
            setCategories(tab);
        })
    }

    const filterAdvertisements = (element) => {
        if(element.type == 'CITY'){
            dispatch(updateAdvertisementsSearchFilter({ cityId : element.id}));
        }else if (element.type == 'DISTRICT'){
            dispatch(updateAdvertisementsSearchFilter({ districtId : element.id}))
        }
    }

    const searchStringChanged = (e)=> {
        setSearchString(e.target.value);
        search_text = e.target.value
        setTimeout(() => {
            goToSearch(false);
        }, 500);
    }

    let currentYear = new Date().getFullYear();

    const [mileageValue, setMileageValue] = React.useState({ values : [0, 250000], marks : [{value: 0,label: '0 km'}, {value: 250000,label: '250000 + km'}]});
    const [yearValue, setYearValue] = React.useState({ values : [1980, currentYear], marks : [{value: 1980,label: 1980}, {value: currentYear,label: currentYear}]});
    const [estateAreaValue, setEstateAreaValue] = React.useState({ values : [0, 300], marks : [ { value: 0, label: '0 m\u00b2'}, { value: 300, label: '300 + m\u00b2'}]});
    const [marque, setMarque] = React.useState('Choisir une marque');
    const [models, setModels] = React.useState([]);

    function mileageValueText(mileageValue) {
        return `${mileageValue.values}`;
    }

    function estateAreaValueText(estateAreaValue) {
        return `${estateAreaValue.values}`;
    }

    function yearValueText(yearValue) {
        return `${yearValue.values}`;
    }

    const handleMileageChange = (event, newMileageValue) => {
        setMileageFilter(`${newMileageValue[0]} - ${newMileageValue[1]}`);
        formik.setFieldValue("mileage", `${newMileageValue[0]}-${newMileageValue[1]}`);
        setMileageValue({ values : newMileageValue,
            marks : [{value : newMileageValue[0], label : newMileageValue[0]+" km"},
                {value : newMileageValue[1], label : newMileageValue[1]+" km"}]});
        mileage = `${newMileageValue[0]}-${newMileageValue[1]}`;
        goToSearch(false);
    };

    const handleEstateAreaChange = (event, newEstateAreaValue) => {
        setAreaFilter(`${newEstateAreaValue[0]} - ${newEstateAreaValue[1]}`);
        formik.setFieldValue("area", `${newEstateAreaValue[0]}-${newEstateAreaValue[1]}`);
        setEstateAreaValue({ values : newEstateAreaValue,
            marks : [{value : newEstateAreaValue[0], label : newEstateAreaValue[0]+" m\u00b2"},
                {value : newEstateAreaValue[1], label : newEstateAreaValue[1]+" m\u00b2"}]});
        area = `${newEstateAreaValue[0]} - ${newEstateAreaValue[1]}`;
        goToSearch(false);
    };

    const handleYearChange = (event, newYearValue) => {
        setYearFilter(`${newYearValue[0]} - ${newYearValue[1]}`);
        formik.setFieldValue("year", `${newYearValue[0]}-${newYearValue[1]}`);
        setYearValue({ values : newYearValue,
            marks : [{value : newYearValue[0], label : newYearValue[0]},
                {value : newYearValue[1], label : newYearValue[1]}]});
        year = `${newYearValue[0]} - ${newYearValue[1]}`;
        goToSearch(false);
    };

    useEffect(() => {
        // getAllCount();
        loadCategories();
        getAllColors().then(({data})=> {
            let res = data;
            setColors(res);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
        })
    }, []);

    const [anchorElPrice, setAnchorElPrice] = useState(null);
    const [priceMin, setPriceMin] = useState("Prix");
    const [priceMax, setPriceMax] = useState("");
    const [anchorElBrand, setAnchorElBrand] = useState(null);
    const [brandFilter, setBrandFilter] = useState('Marque');
    const [anchorElModel, setAnchorElModel] = useState(null);
    const [modelFilter, setModelFilter] = useState('Modèle');
    const [anchorElFuel, setAnchorElFuel] = useState(null);
    const [fuelFilter, setFuelFilter] = useState('Carburant');
    const [anchorElYear, setAnchorElYear] = useState(null);
    const [yearFilter, setYearFilter] = useState('Année');
    const [anchorElMileage, setAnchorElMileage] = useState(null);
    const [mileageFilter, setMileageFilter] = useState('Kilométrage');
    const [anchorElGearBox, setAnchorElGearBox] = useState(null);
    const [gearBoxFilter, setGearBoxFilter] = useState('Boite à vitesse');
    const [anchorElDoors, setAnchorElDoors] = useState(null);
    const [doorsFilter, setDoorsFilter] = useState('Nombre de portes');
    const [anchorElArea, setAnchorElArea] = useState(null);
    const [areaFilter, setAreaFilter] = useState('Surface');
    const [anchorElRooms, setAnchorElRooms] = useState(null);
    const [roomsFilter, setRoomsFilter] = useState('Pièces');
    const [anchorElColor, setAnchorElColor] = useState(null);
    const [colorFilter, setColorFilter] = useState('Couleur');
    const [anchorElPlace, setAnchorElPlace] = useState(null);
    const [placeFilter, setPlaceFilter] = useState('Nombre de places');
    const [anchorElState, setAnchorElState] = useState(null);
    const [stateFilter, setStateFilter] = useState('État');
    const [anchorElStorage, setAnchorElStorage] = useState(null);
    const [storageFilter, setStorageFilter] = useState('Capacité de stockage');
    const [anchorElMemory, setAnchorElMemory] = useState(null);
    const [memoryFilter, setMemoryFilter] = useState('Mémoire RAM');

    const formik = useFormik({
        initialValues : {
            priceMin: '',
            priceMax: '',
            brand: '',
            model: '',
            fuel: '',
            year: '',
            mileage: '',
            gearBox: '',
            doors: '',
            area: '',
            rooms: '',
            color: '',
            state: '',
            storage: '',
            memory: ''
        }
    })

    const goToSearch = (type) => {
        var obj = {
            "search_text" : search_text,
            "limit": 15,
            "offset": 0,
            "country_id": currentCountry.id,
            "city_id": city_id,
            "zone_id": zone_id,
            "category_id": category_id ? category_id : null,
            "price_min": price_min,
            "price_max": price_max,
            "type_advert": type_advert,
            "state": state,
            "type_price": type_price,
            "year": year,
            "mileage": mileage,
            "color": color,
            "gear_box": gear_box,
            "fuel": fuel,
            "brand_id": brand_id,
            "model_id": model_id,
            "nb_door": nb_door,
            "nb_seat": nb_seat,
            "type_immo": type_immo,
            "area": area,
            "negociable": negociable,
            "storage": storage,
            "memory": memory,
            "nb_rooms": nb_rooms
        }
        var objName = {
            "country_name": currentCountry.name,
            "city_name": elementCity ? elementCity.title : '',
            "zone_name": elementCity ? elementCity.title : '',
            "category_name": elementCategory ? elementCategory.title : 'Aucune',
            "state_name": stateFilter !== 'État' ? stateFilter : '',
            "gear_box_name": gearBoxFilter !== 'Boite à vitesse' ? gearBoxFilter : '',
            "fuel_name": fuelFilter !== 'Carburant' ? fuelFilter : '',
            "brand_name": brandFilter !== 'Marque' ? brandFilter : '',
            "model_name": modelFilter !== 'Modèle' ? modelFilter : '',
        }
        //console.log('goToSearch', type)
        if (type !== false) {
            //console.log('yes', elementCity, elementCategory,obj, objName)
            props.citySelected(elementCity);
            props.categorySelected(elementCategory);
            props.searchAd(obj, objName);
            dispatch(setShowSearchDialog({setShowSearchDialog: false}));
        } 
        else {
            //console.log('no')

            props.filterAd(obj);
        }
    }
    const goToSearch2 = (type) => {
        var obj = {
            "search_text" : search_text,
            "limit": 15,
            "offset": 0,
            "country_id": currentCountry.id,
            "city_id": city_id,
            "zone_id": zone_id,
            "category_id": null,
            "price_min": price_min,
            "price_max": price_max,
            "type_advert": type_advert,
            "state": state,
            "type_price": type_price,
            "year": year,
            "mileage": mileage,
            "color": color,
            "gear_box": gear_box,
            "fuel": fuel,
            "brand_id": brand_id,
            "model_id": model_id,
            "nb_door": nb_door,
            "nb_seat": nb_seat,
            "type_immo": type_immo,
            "area": area,
            "negociable": negociable,
            "storage": storage,
            "memory": memory,
            "nb_rooms": nb_rooms
        }
        var objName = {
            "country_name": currentCountry.name,
            "city_name": elementCity ? elementCity.title : '',
            "zone_name": elementCity ? elementCity.title : '',
            "category_name": elementCategory ? elementCategory.title : 'Aucune',
            "state_name": stateFilter !== 'État' ? stateFilter : '',
            "gear_box_name": gearBoxFilter !== 'Boite à vitesse' ? gearBoxFilter : '',
            "fuel_name": fuelFilter !== 'Carburant' ? fuelFilter : '',
            "brand_name": brandFilter !== 'Marque' ? brandFilter : '',
            "model_name": modelFilter !== 'Modèle' ? modelFilter : '',
        }
      
            props.searchAd(obj, objName);
            props.filterAd(obj);
            dispatch(setShowSearchDialog({setShowSearchDialog: false}));
       
    }

    /*Price*/
    const handleClickPrice = (event) => {
        setAnchorElPrice(event.currentTarget);
        document.body.style.position = "fixed";
    };

    const handleClosePrice = () => {
        setAnchorElPrice(null);
        document.body.style.position = "relative";
    };

    const handleChangePriceMin = (e) => {
        formik.setFieldValue("priceMin", e.target.value, true);
        setPriceMin(e.target.value);
        price_min = e.target.value;
        goToSearch(false);
    }

    const handleChangePriceMax = (e) => {
        formik.setFieldValue("priceMax", e.target.value, true);
        setPriceMax(e.target.value);
        price_max = e.target.value;
        goToSearch(false);
    }

    const resetPriceFields = () => {
        setPriceMin('Prix');
        setPriceMax("");
        formik.setFieldValue("priceMin", "");
        formik.setFieldValue("priceMax", "");
        price_min = null;
        price_max = null;
        handleClosePrice();
        goToSearch(false);
    }

    const resetPriceFields2 = () => {
        setPriceMin('Prix');
        setPriceMax("");
        formik.setFieldValue("priceMin", "");
        formik.setFieldValue("priceMax", "");
        price_min = null;
        price_max = null;
        handleClosePrice();
    }

    /*Brand*/
    const handleClickBrand = (event) => {
        setAnchorElBrand(event.currentTarget);
        document.body.style.position = "fixed";
    };

    const handleCloseBrand = () => {
        setAnchorElBrand(null);
        document.body.style.position = "relative";
    };

    const resetBrand = () => {
        setBrandFilter("Marque");
        formik.setFieldValue("brand", "");
        brand_id = null;
        handleCloseBrand();
        goToSearch(false);
    }

    const resetBrand2 = () => {
        setBrandFilter("Marque");
        formik.setFieldValue("brand", "");
        brand_id = null;
        handleCloseBrand();
    }

    /*Model*/
    const handleClickModel = (event) => {
        setAnchorElModel(event.currentTarget);
        document.body.style.position = "fixed";
    };

    const handleCloseModel = () => {
        setAnchorElModel(null);
        document.body.style.position = "relative";
    };

    const resetModel = () => {
        setModelFilter("Modèle");
        formik.setFieldValue("model", "");
        model_id = null;
        handleCloseModel();
        goToSearch(false);
    }

    const resetModel2 = () => {
        setModelFilter("Modèle");
        formik.setFieldValue("model", "");
        model_id = null;
        handleCloseModel();
    }
    /*Fuel*/
    const handleClickFuel = (event) => {
        setAnchorElFuel(event.currentTarget);
        document.body.style.position = "fixed";
    };

    const handleCloseFuel = () => {
        setAnchorElFuel(null);
        document.body.style.position = "relative";
    };

    const resetFuel = () => {
        setFuelFilter("Carburant");
        formik.setFieldValue("fuel", "");
        fuel = null;
        handleCloseFuel();
        goToSearch(false);
    }

    const resetFuel2 = () => {
        setFuelFilter("Carburant");
        formik.setFieldValue("fuel", "");
        fuel = null;
        handleCloseFuel();
    }

    /*Year*/
    const handleClickYear = (event) => {
        setAnchorElYear(event.currentTarget);
        document.body.style.position = "fixed";
    };

    const handleCloseYear = () => {
        setAnchorElYear(null);
        document.body.style.position = "relative";
    };

    const resetYear = () => {
        setYearFilter("Année");
        setYearValue({ values : [1980, currentYear], marks : [{value: 1980,label: 1980}, {value: currentYear,label: currentYear}]});
        formik.setFieldValue("year", "");
        year = null;
        handleCloseYear();
        //goToSearch(false);
    }
    const resetYear2 = () => {
        setYearFilter("Année");
        setYearValue({ values : [1980, currentYear], marks : [{value: 1980,label: 1980}, {value: currentYear,label: currentYear}]});
        formik.setFieldValue("year", "");
        year = null;
        handleCloseYear();
    }

    /*Mileage*/
    const handleClickMileage = (event) => {
        setAnchorElMileage(event.currentTarget);
        document.body.style.position = "fixed";
    };

    const handleCloseMileage = () => {
        setAnchorElMileage(null);
        document.body.style.position = "relative";
    };

    const resetMileage = () => {
        setMileageFilter("Kilométrage");
        setMileageValue({ values : [0, 250000], marks : [{value: 0,label: '0 km'}, {value: 250000,label: '250000 + km'}]});
        formik.setFieldValue("mileage", "");
        mileage = null;
        handleCloseMileage();
        goToSearch(false);
    }

    /*GearBox*/
    const handleClickGearBox = (event) => {
        setAnchorElGearBox(event.currentTarget);
        document.body.style.position = "fixed";
        goToSearch(false);
    };

    const handleCloseGearBox = () => {
        setAnchorElGearBox(null);
        document.body.style.position = "relative";
    };

    const resetGearBox = () => {
        setGearBoxFilter("Boite à vitesse");
        formik.setFieldValue("gearBox", "");
        gear_box = null;
        handleCloseGearBox();
        goToSearch(false);
    }

    const resetGearBox2 = () => {
        setGearBoxFilter("Boite à vitesse");
        formik.setFieldValue("gearBox", "");
        gear_box = null;
        handleCloseGearBox();
    }

    /*Doors*/
    const handleClickDoors = (event) => {
        setAnchorElDoors(event.currentTarget);
        document.body.style.position = "fixed";
    };

    const handleCloseDoors = () => {
        setAnchorElDoors(null);
        document.body.style.position = "relative";
    };

    const resetDoors = () => {
        setDoorsFilter("Nombre de portes");
        formik.setFieldValue("doors", "");
        nb_door = null;
        handleCloseDoors();
        goToSearch(false);
    }

    const resetDoors2 = () => {
        setDoorsFilter("Nombre de portes");
        formik.setFieldValue("doors", "");
        nb_door = null;
        handleCloseDoors();
    }

    /*Area*/
    const handleClickArea = (event) => {
        setAnchorElArea(event.currentTarget);
        document.body.style.position = "fixed";
    };

    const handleCloseArea = () => {
        setAnchorElArea(null);
        document.body.style.position = "relative";
    };

    const resetArea = () => {
        setAreaFilter("Surface");
        setEstateAreaValue({ values : [0, 300], marks : [ { value: 0, label: '0 m\u00b2'}, { value: 300, label: '300 + m\u00b2'}]});
        formik.setFieldValue("area", "");
        area = null;
        handleCloseArea();
        goToSearch(false);
    }

    const resetArea2 = () => {
        setAreaFilter("Surface");
        setEstateAreaValue({ values : [0, 300], marks : [ { value: 0, label: '0 m\u00b2'}, { value: 300, label: '300 + m\u00b2'}]});
        formik.setFieldValue("area", "");
        area = null;
        handleCloseArea();
    }

    /*Rooms*/
    const handleClickRooms = (event) => {
        setAnchorElRooms(event.currentTarget);
        document.body.style.position = "fixed";
    };

    const handleCloseRooms = () => {
        setAnchorElRooms(null);
        document.body.style.position = "relative";
    };

    const resetRooms = () => {
        setRoomsFilter("Pièces");
        formik.setFieldValue("rooms", "");
        nb_rooms = null;
        handleCloseRooms();
        goToSearch(false);
    }
    const resetRooms2 = () => {
        setRoomsFilter("Pièces");
        formik.setFieldValue("rooms", "");
        nb_rooms = null;
        handleCloseRooms();
    }

    /*Color*/
    const handleClickColor = (event) => {
        setAnchorElColor(event.currentTarget);
        document.body.style.position = "fixed";
    };

    const handleCloseColor = () => {
        setAnchorElColor(null);
        document.body.style.position = "relative";
    };

    /* Color select */
    const [checkedColor, setCheckedColor] = React.useState([]);
    const [checkedColorName, setCheckedColorName] = React.useState([]);
    const handleToggleColor = (value, name) => () => {
        const currentIndex = checkedColor.indexOf(value);
        const currentName = checkedColorName.indexOf(name);
        const newChecked = [...checkedColor];
        const newName = [...checkedColorName];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        if (currentName === -1) {
            newName.push(name);
        } else {
            newName.splice(currentName, 1);
        }
        if (colorFilter !== null) {
            setColorFilter("Couleur");
        }
        setCheckedColor(newChecked);
        setCheckedColorName(newName);
        formik.setFieldValue("color", newChecked);
        setColorFilter(newName + " ");
        goToSearch(false);
    };

    const resetColor = () => {
        setColorFilter("Couleur");
        formik.setFieldValue("color", "");
        color = null;
        setCheckedColor([]);
        setCheckedColorName([]);
        handleCloseColor();
        goToSearch(false);
    }

    const resetColor2 = () => {
        setColorFilter("Couleur");
        formik.setFieldValue("color", "");
        color = null;
        setCheckedColor([]);
        setCheckedColorName([]);
        handleCloseColor();
    }

    /*Places*/
    const handleClickPlace = (event) => {
        setAnchorElPlace(event.currentTarget);
        document.body.style.position = "fixed";
    };
    const handleClosePlace = () => {
        setAnchorElPlace(null);
        document.body.style.position = "relative";
    };
    const resetPlace = () => {
        setPlaceFilter("Nombre de places");
        formik.setFieldValue("place", "");
        nb_seat = null;
        handleClosePlace();
        goToSearch(false);
    }

    const resetPlace2 = () => {
        setPlaceFilter("Nombre de places");
        formik.setFieldValue("place", "");
        nb_seat = null;
        handleClosePlace();
    }

    /*State*/
    const handleClickState = (event) => {
        setAnchorElState(event.currentTarget);
        document.body.style.position = "fixed";
    };

    const handleCloseState = () => {
        setAnchorElState(null);
        document.body.style.position = "relative";
    };

    const resetState = () => {
        setStateFilter("État");
        formik.setFieldValue("state", "");
        state = null;
        handleCloseState();
        goToSearch(false);
    }

    const resetState2 = () => {
        setStateFilter("État");
        formik.setFieldValue("state", "");
        state = null;
        handleCloseState();
    }

    /*Storage*/
    const handleClickStorage = (event) => {
        setAnchorElStorage(event.currentTarget);
        document.body.style.position = "fixed";
    };

    const handleCloseStorage = () => {
        setAnchorElStorage(null);
        document.body.style.position = "relative";
    };

    const resetStorage = () => {
        setStorageFilter("Capacité de stockage");
        formik.setFieldValue("storage", "");
        storage = null;
        handleCloseStorage();
        goToSearch(false);
    }
    const resetStorage2 = () => {
        setStorageFilter("Capacité de stockage");
        formik.setFieldValue("storage", "");
        storage = null;
        handleCloseStorage();
    }
    


    /*Memory*/
    const handleClickMemory = (event) => {
        setAnchorElMemory(event.currentTarget);
        document.body.style.position = "fixed";
    };

    const handleCloseMemory = () => {
        setAnchorElMemory(null);
        document.body.style.position = "relative";
    };

    const resetMemory = () => {
        setMemoryFilter("Mémoire RAM");
        formik.setFieldValue("memory", "");
        memory = null;
        handleCloseMemory();
        goToSearch(false);
    }

    const resetMemory2 = () => {
        setMemoryFilter("Mémoire RAM");
        formik.setFieldValue("memory", "");
        memory = null;
        handleCloseMemory();
    }

    let memories = [];
    for (let start = 1; start <= 512; start += 1) {
        memories = [...memories, start];
    }

    /*Price*/
    const openPrice = Boolean(anchorElPrice);
    const idPrice = openPrice ? 'price-popover' : undefined;

    /*Brand*/
    const openBrand = Boolean(anchorElBrand);
    const idBrand = openBrand ? 'brand-popover' : undefined;

    /*Model*/
    const openModel = Boolean(anchorElModel);
    const idModel = openModel ? 'model-popover' : undefined;

    /*Fuel*/
    const openFuel = Boolean(anchorElFuel);
    const idFuel = openFuel ? 'fuel-popover' : undefined;

    /*Year*/
    const openYear = Boolean(anchorElYear);
    const idYear = openYear ? 'year-popover' : undefined;

    /*Mileage*/
    const openMileage = Boolean(anchorElMileage);
    const idMileage = openMileage ? 'mileage-popover' : undefined;

    /*GearBox*/
    const openGearBox = Boolean(anchorElGearBox);
    const idGearBox = openGearBox ? 'gearbox-popover' : undefined;

    /*Doors*/
    const openDoors = Boolean(anchorElDoors);
    const idDoors = openDoors ? 'doors-popover' : undefined;

    /*Area*/
    const openArea = Boolean(anchorElArea);
    const idArea = openArea ? 'area-popover' : undefined;

    /*Rooms*/
    const openRooms = Boolean(anchorElRooms);
    const idRooms = openRooms ? 'rooms-popover' : undefined;

    /*Color*/
    const openColor = Boolean(anchorElColor);
    const idColor = openColor ? 'color-popover' : undefined;

    /*Place*/
    const openPlace = Boolean(anchorElPlace);
    const idPlace = openPlace ? 'place-popover' : undefined;

    /*State*/
    const openState = Boolean(anchorElState);
    const idState = openState ? 'state-popover' : undefined;

    /*Storage*/
    const openStorage = Boolean(anchorElStorage);
    const idStorage = openStorage ? 'storage-popover' : undefined;

    /*Memory*/
    const openMemory = Boolean(anchorElMemory);
    const idMemory = openMemory ? 'memory-popover' : undefined;

    const handleCloseCategory = (element, category, isChild) => {
        if (element && element.id == '79' || element && element.id == '80') {
            handleBrandsChange(2);
        } else if (element && element.id == '81' || element && element.id == '82')  {
            handleBrandsChange(4);
        } else if (element && element.id == '71' || element && element.id == '69' || element && element.id == '72')  {
            handleBrandsChange(1);
        } else if (element && element.id == '70' || element && element.id == '203')  {
            handleBrandsChange(3);
        } else if (element && element.id == '74') {
            handleBrandsChange(5);
        }
        setBrandFilter('Marque');
        setModelFilter('Modèle');
        setOpenCategory(false);
        category_id = element !== undefined ? element?.id : category?.id;
        element ? setElementCategory(element) : setElementCategory(category)
        setElementCategoryParent(category);
        setIsChild(isChild);
        goToSearch(false);
    };

    const handleMarqueChange = (event) => {
        let filterModels = brands.find((elm) => {
            return  (elm.id == event.target.value);
        });
        setMarque(event.target.value);
        setBrandFilter(event.nativeEvent.target.innerText);
        setModels(filterModels.models);
        brand_id = event.target.value;
        goToSearch(false);
    };

    const handleChangeFuel = (event) => {
        if (event.target.value == '1') {
            setFuelFilter('Essence');
        } else if (event.target.value == '2') {
            setFuelFilter('Diesel');
        } else if (event.target.value == '3') {
            setFuelFilter('Hybride');
        } else if (event.target.value == '4') {
            setFuelFilter('Electrique');
        } else if (event.target.value == '5') {
            setFuelFilter('LPG');
        } else if (event.target.value == '6') {
            setFuelFilter('Autres');
        }
        fuel = event.target.value;
        goToSearch(false);
    };

    const handleChangeGearBox = (event) => {
        if (event.target.value == '1') {
            setGearBoxFilter('Manuelle');
        } else if (event.target.value == '2') {
            setGearBoxFilter('Automatique');
        }
        gear_box = event.target.value;
        goToSearch(false);
    };

    const handleChangeDoors = (event) => {
        setDoorsFilter(event.target.value);
        nb_door = event.target.value;
        goToSearch(false);
    };

    const handleChangeRooms = (event) => {
        setRoomsFilter(event.target.value);
        nb_rooms = event.target.value;
        goToSearch(false);
    };

    const handleChangeModel = (event) => {
        setModelFilter(event.nativeEvent.target.innerText);
        model_id = event.target.value;
        goToSearch(false);
    };

    const handleChangePlace = (event) => {
        setPlaceFilter(event.target.value);
        nb_seat = event.target.value;
        goToSearch(false);
    };

    const handleChangeState = (event) => {
        setStateFilter(event.target.value);
        state = event.target.value;
        goToSearch(false);
    };

    const handleChangeStorage = (event) => {
        setStorageFilter(event.target.value);
        setStorageFilter(event.nativeEvent.target.innerText);
        storage = event.target.value;
        goToSearch(false);
    };

    const handleChangeMemory = (event) => {
        setMemoryFilter(event.target.value);
        setMemoryFilter(event.nativeEvent.target.innerText);
        memory = event.target.value;
        goToSearch(false);
    };

    /* Reset search and filters */
    const handleResetFilters = () => {
        setSearchString("");
        search_text = '';
        setElementCategory(null);
        setElementCity('');
        resetPriceFields();
        resetState();
        resetArea();
        resetBrand();
        resetColor();
        resetDoors();
        resetModel();
        resetFuel();
        resetGearBox();
        resetMemory();
        resetPlace();
        resetRooms();
        resetStorage();
        resetYear();
        resetAdvertisements();
        resetCountry();
        //dispatch(setSearchResultNumber({ searchResultNumber : 0}));
        if(props.resetSearch){
            //console.log('reset search function exists')
            props.resetSearch()
        }
        //dispatch(setIsSearching({ setIsSearching : 1}));
    }

    const handleResetFilters2 = () => {
        setSearchString("");
        search_text = '';
        setElementCategory(null);
        setElementCity('');
        resetPriceFields2();
        resetState2();
        resetArea2();
        resetBrand2();
        resetColor2();
        resetDoors2();
        resetModel2();
        resetFuel2();
        resetGearBox2();
        resetMemory2();
        resetPlace2();
        resetStorage2();
        resetYear2();
        resetAdvertisements();
        resetCountry();
        //goToSearch2()

     if(props.resetSearch){
        //console.log('reset search function exists')
        props.resetSearch()
    }
}
    console.log('cat', elementCategory, elementCategoryParent)


    return (
        <section className="search-filters">
            <div className="search-filters-inner">
                <div className="search-filters-inner-row first">
                    <div className="search-filters-inner-row-wrapper">
                        <div className="search-filters-inner-row-wrapper-left">
                            <Button onClick={handleClickOpenCategory}>
                                <div className="icon-wrapper">
                                    <img src={list3} alt="icon"/>
                                </div>
                                <span>{elementCategory ? elementCategory.title : "Toutes les catégories"}</span>
                            </Button>
                            <SimpleDialog prompt={"Choisir une categorie"} data={categories} defaultSelection={"Toutes les catégories"} open={openCategory} hasImage={true} onClose={handleCloseCategory} onCancel={handleClickOpenCategory} />
                        </div>

                        <div className="search-filters-inner-row-wrapper-center">
                            <div className="input-wrapper">
                                <input id="item-name" placeholder="Que cherchez vous ?" value={search_text} onChange={searchStringChanged} />
                            </div>
                        </div>

                        <div className="search-filters-inner-row-wrapper-right">
                            <Button onClick={handleClickOpenCity}>
                                <div className="icon-wrapper">
                                    <MdLocationOn/>
                                </div>
                                <span>{elementCity ? elementCity.title : "Ville ou quartier"}</span>
                            </Button>
                            <SimpleDialog prompt={"Choisir une ville ou un quartier"} data={cities} defaultSelection={"Toutes les villes"} open={openCity} hasImage={false} onClose={handleCloseCity} onCancel={handleClickOpenCity} />
                        </div>
                    </div>
                </div>
                {
                    (elementCategory && elementCategory.title !== null || undefined) && (isChild) ? (
                        <div className="search-filters-inner-row">
                            <div className="search-filters-inner-row-filters">
                                <div className="search-filters-inner-row-filters-title">
                                    <h4>Filtres</h4>
                                </div>
                                <div className="search-filters-inner-row-filters-items">
                                    {/* Price & State */}
                                    <div className="items-wrapper">
                                        {/* Price */}
                                        {
                                            /*Immobilier*/
                                            (elementCategory && elementCategory.id === "13") || (elementCategoryParent && elementCategoryParent.id === "13") ||
                                            /*Location de vacances*/
                                            (elementCategory && elementCategory.id === "216") || (elementCategoryParent && elementCategoryParent.id === "216") ||

                                            /*Autos motos */
                                            (elementCategory && elementCategory.id === "55") || (elementCategoryParent && elementCategoryParent.id === "55") ||
                                            /*Habillement*/
                                            (elementCategory && elementCategory.id === "56") || (elementCategoryParent && elementCategoryParent.id === "56") ||
                                            /*Informatique*/
                                            (elementCategory && elementCategory.id === "57") || (elementCategoryParent && elementCategoryParent.id === "57") ||
                                            /*Loisirs*/
                                            (elementCategory && elementCategory.id === "58") || (elementCategoryParent && elementCategoryParent.id === "58") ||
                                            /*Maison*/
                                            (elementCategory && elementCategory.id === "61") || (elementCategoryParent && elementCategoryParent.id === "61") ||
                                            /*Services*/
                                            (elementCategory && elementCategory.id === "63") || (elementCategoryParent && elementCategoryParent.id === "63") ||
                                            /*Autres*/
                                            (elementCategory && elementCategory.id === "64") || (elementCategoryParent && elementCategoryParent.id === "64") ||
                                            /*Entreprise*/
                                            (elementCategory && elementCategory.id === "132") || (elementCategoryParent && elementCategoryParent.id === "132") ||
                                            /*Appel d'offres en ligne*/
                                            /*(elementCategory && elementCategory.id === "212") || (elementCategoryParent && elementCategoryParent.id === "212")*/
                                            /*Evenements et annonces locales*/
                                            (elementCategory && elementCategory.id === "206") || (elementCategoryParent && elementCategoryParent.id === "206") ||
                                            /*Fond de commerces*/
                                            (elementCategory && elementCategory.id === "209") || (elementCategoryParent && elementCategoryParent.id === "209")  ? (
                                                <div className="search-filters-inner-row-filters-items-item">
                                                    <Button className={(priceMin !== "Prix" || priceMax !== '') ? ' active' : ''} aria-describedby={idPrice} variant="contained" color="primary" onClick={handleClickPrice}>
                                                        {priceMin} {priceMax !== '' ? '-' : '' } {priceMax}
                                                    </Button>
                                                    <Popover
                                                        id={idPrice}
                                                        open={openPrice}
                                                        anchorEl={anchorElPrice}
                                                        onClose={handleClosePrice}
                                                        className="filterContent"
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}>
                                                        <h5 className="popper-title">Prix (FCFA)</h5>
                                                        <div className="price">
                                                            <div className="price-min">
                                                                <TextField
                                                                    id="priceMin"
                                                                    label="Prix min"
                                                                    type="number"
                                                                    name="priceMin"
                                                                    onChange={(e) => {
                                                                        //  formik.handleChange(e);
                                                                        handleChangePriceMin(e);
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.priceMin || ""}
                                                                />
                                                            </div>
                                                            <div className="price-max">
                                                                <TextField
                                                                    id="priceMax"
                                                                    label="Prix max"
                                                                    type="number"
                                                                    name="priceMax"
                                                                    onChange={(e) => {
                                                                        //formik.handleChange(e);
                                                                        handleChangePriceMax(e);
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.priceMax || ""}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="popper-footer">
                                                            <Button onClick={resetPriceFields}>Effacer</Button>
                                                            <Button onClick={handleClosePrice}>Valider</Button>
                                                        </div>
                                                    </Popover>
                                                </div>
                                            ) : ('')
                                        }
                                        {/* State */}
                                        {
                                            /*Immobilier*/
                                            (elementCategory && elementCategory.id === "13") || (elementCategoryParent && elementCategoryParent.id === "13") ||
                                            /*Location de vacances*/
                                            (elementCategory && elementCategory.id === "216") || (elementCategoryParent && elementCategoryParent.id === "216") ||
                                            /*Autos motos */
                                            (elementCategory && elementCategory.id === "55") || (elementCategoryParent && elementCategoryParent.id === "55") ||
                                            /*Habillement*/
                                            (elementCategory && elementCategory.id === "56") || (elementCategoryParent && elementCategoryParent.id === "56") ||
                                            /*Informatique*/
                                            (elementCategory && elementCategory.id === "57") || (elementCategoryParent && elementCategoryParent.id === "57") ||
                                            /*Loisirs*/
                                            (elementCategory && elementCategory.id === "58") || (elementCategoryParent && elementCategoryParent.id === "58") ||
                                            /*Maison*/
                                            (elementCategory && elementCategory.id === "61") || (elementCategoryParent && elementCategoryParent.id === "61") ? (
                                                <div className="items-wrapper">
                                                    <div className="search-filters-inner-row-filters-items-item">
                                                        <Button className={(stateFilter !== "État" ? ' active' : '')} aria-describedby={idState} variant="contained" color="primary" onClick={handleClickState}>
                                                            {stateFilter}
                                                        </Button>
                                                        <Popover
                                                            id={idState}
                                                            open={openState}
                                                            anchorEl={anchorElState}
                                                            onClose={handleCloseState}
                                                            className="filterContent"
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}>
                                                            <h5 className="popper-title">État</h5>
                                                            <div className="state">
                                                                <FormControl variant="outlined" className="select-state">
                                                                    <InputLabel id="place-label">État</InputLabel>
                                                                    {
                                                                        (elementCategory && elementCategory.id === "57") || (elementCategoryParent && elementCategoryParent.id === "57") ? (
                                                                            <Select
                                                                                name="state"
                                                                                labelId="state-label"
                                                                                id="state-select"
                                                                                label="État"
                                                                                onBlur={formik.handleBlur}
                                                                                onChange={(e) => {
                                                                                    formik.handleChange(e);
                                                                                    handleChangeState(e);
                                                                                }}
                                                                                value={formik.values.state || ""}>
                                                                                <MenuItem value="Neuf et sous blister">Neuf et sous blister</MenuItem>
                                                                                <MenuItem value="Neuf sans  blister">Neuf sans  blister</MenuItem>
                                                                                <MenuItem value="Très bon état">Très bon état</MenuItem>
                                                                                <MenuItem value="Bon état">Bon état</MenuItem>
                                                                                <MenuItem value="État satisfaisant">État satisfaisant</MenuItem>
                                                                            </Select>
                                                                        ) : ('')
                                                                    }
                                                                    {
                                                                        (elementCategory && elementCategory.id === "61") || (elementCategoryParent && elementCategoryParent.id === "61") ? (
                                                                            <Select
                                                                                name="state"
                                                                                labelId="state-label"
                                                                                id="state-select"
                                                                                label="État"
                                                                                onBlur={formik.handleBlur}
                                                                                onChange={(e) => {
                                                                                    formik.handleChange(e);
                                                                                    handleChangeState(e);
                                                                                }}
                                                                                value={formik.values.state || ""}>
                                                                                <MenuItem value="Neuf et jamais utilisé">Neuf et jamais utilisé</MenuItem>
                                                                                <MenuItem value="Neuf et peu servis">Neuf et peu servis</MenuItem>
                                                                                <MenuItem value="Très bon état">Très bon état</MenuItem>
                                                                                <MenuItem value="Bon état">Bon état</MenuItem>
                                                                                <MenuItem value="État satisfaisant">État satisfaisant</MenuItem>
                                                                            </Select>
                                                                        ) : ('')
                                                                    }
                                                                    {
                                                                        (elementCategory && elementCategory.id === "56") || (elementCategoryParent && elementCategoryParent.id === "56") ? (
                                                                            <Select
                                                                                name="state"
                                                                                labelId="state-label"
                                                                                id="state-select"
                                                                                label="État"
                                                                                onBlur={formik.handleBlur}
                                                                                onChange={(e) => {
                                                                                    formik.handleChange(e);
                                                                                    handleChangeState(e);
                                                                                }}
                                                                                value={formik.values.state || ""}>
                                                                                <MenuItem value="Neuf avec étiquette">Neuf avec étiquette</MenuItem>
                                                                                <MenuItem value="Neuf sans étiquette">Neuf sans étiquette</MenuItem>
                                                                                <MenuItem value="Très bon état">Très bon état</MenuItem>
                                                                                <MenuItem value="Bon état">Bon état</MenuItem>
                                                                                <MenuItem value="État satisfaisant">État satisfaisant</MenuItem>
                                                                            </Select>
                                                                        ) : ('')
                                                                    }
                                                                    {
                                                                        (elementCategory && elementCategory.id === "58") || (elementCategoryParent && elementCategoryParent.id === "58") ? (
                                                                            <Select
                                                                                name="state"
                                                                                labelId="state-label"
                                                                                id="state-select"
                                                                                label="État"
                                                                                onBlur={formik.handleBlur}
                                                                                onChange={(e) => {
                                                                                    formik.handleChange(e);
                                                                                    handleChangeState(e);
                                                                                }}
                                                                                value={formik.values.state || ""}>
                                                                                <MenuItem value="Neuf">Neuf</MenuItem>
                                                                                <MenuItem value="Très bon état">Très bon état</MenuItem>
                                                                                <MenuItem value="Bon état">Bon état</MenuItem>
                                                                                <MenuItem value="État satisfaisant">État satisfaisant</MenuItem>
                                                                            </Select>
                                                                        ) : ('')
                                                                    }
                                                                    {
                                                                        (elementCategory && elementCategory.id === "55") || (elementCategoryParent && elementCategoryParent.id === "55") ? (
                                                                            <Select
                                                                                name="state"
                                                                                labelId="state-label"
                                                                                id="state-select"
                                                                                label="État"
                                                                                onBlur={formik.handleBlur}
                                                                                onChange={(e) => {
                                                                                    formik.handleChange(e);
                                                                                    handleChangeState(e);
                                                                                }}
                                                                                value={formik.values.state || ""}>
                                                                                <MenuItem value="Neuf">Neuf</MenuItem>
                                                                                <MenuItem value="Occasion">Occasion</MenuItem>
                                                                            </Select>
                                                                        ) : ('')
                                                                    }
                                                                    {
                                                                        (elementCategory && elementCategory.id === "13") || (elementCategoryParent && elementCategoryParent.id === "13") ? (
                                                                            <Select
                                                                                name="state"
                                                                                labelId="state-label"
                                                                                id="state-select"
                                                                                label="État"
                                                                                onBlur={formik.handleBlur}
                                                                                onChange={(e) => {
                                                                                    formik.handleChange(e);
                                                                                    handleChangeState(e);
                                                                                }}
                                                                                value={formik.values.state || ""}>
                                                                                <MenuItem value="Jamais habité">Jamais habité</MenuItem>
                                                                                <MenuItem value="Déja habité">Déja habité</MenuItem>
                                                                            </Select>
                                                                        ) : ('')
                                                                    }
                                                                </FormControl>
                                                            </div>
                                                            <div className="popper-footer">
                                                                <Button onClick={resetState}>Effacer</Button>
                                                                <Button onClick={handleCloseState}>Valider</Button>
                                                            </div>
                                                        </Popover>
                                                    </div>
                                                </div>
                                            ): ('')
                                        }
                                    </div>

                                    {/* Autos & motos */}
                                    {
                                        ["69", "70", "71", "72", "74", "203"].includes(elementCategory && elementCategory.id) ? (
                                            <div className="items-wrapper">
                                                <div className="search-filters-inner-row-filters-items-item">
                                                    <Button className={brandFilter !== "Marque" ? 'active' : ''} aria-describedby={idBrand} variant="contained" color="primary" onClick={handleClickBrand}>
                                                        {brandFilter}
                                                    </Button>
                                                    <Popover
                                                        id={idBrand}
                                                        open={openBrand}
                                                        anchorEl={anchorElBrand}
                                                        onClose={handleCloseBrand}
                                                        className="filterContent"
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <h5 className="popper-title">Marque</h5>
                                                        <div className="brand">
                                                            <FormControl variant="outlined" className="select-brand">
                                                                <InputLabel id="brand-label">Marque</InputLabel>
                                                                <Select
                                                                    labelId="brand-label"
                                                                    id="brand-select"
                                                                    label="Marque"
                                                                    name="brand"
                                                                    onChange={(e) => {
                                                                        formik.handleChange(e);
                                                                        handleMarqueChange(e);
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.brand || ""}
                                                                >
                                                                    {
                                                                        brands.map(element => {
                                                                            return <MenuItem key={element.id} value={element.id}>{element.name}</MenuItem>
                                                                        })
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div className="popper-footer">
                                                            <Button onClick={resetBrand}>Effacer</Button>
                                                            <Button onClick={handleCloseBrand}>Valider</Button>
                                                        </div>
                                                    </Popover>
                                                </div>
                                                <div className="search-filters-inner-row-filters-items-item">
                                                    <Button className={"MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary model-button" + (brandFilter !== 'Marque' ? ' enabled' : '')  + (modelFilter !== "Modèle" ? ' active' : '')} aria-describedby={idModel} variant="contained" color="primary" onClick={handleClickModel}>
                                                        {modelFilter}
                                                    </Button>
                                                    <Popover
                                                        id={idModel}
                                                        open={openModel}
                                                        anchorEl={anchorElModel}
                                                        onClose={handleCloseModel}
                                                        className="filterContent"
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <h5 className="popper-title">Modèle</h5>
                                                        <div className="model">
                                                            <FormControl variant="outlined" className="select-model">
                                                                <InputLabel id="model-label">Modèle</InputLabel>
                                                                <Select
                                                                    labelId="model-label"
                                                                    id="model-select"
                                                                    label="Modèle"
                                                                    name="model"
                                                                    onChange={(e) => {
                                                                        formik.handleChange(e);
                                                                        handleChangeModel(e);
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.model || ""}
                                                                >
                                                                    {
                                                                        models && models.map(element => {
                                                                            return <MenuItem key={element.id} value={element.id}>{element.name}</MenuItem>
                                                                        })
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div className="popper-footer">
                                                            <Button onClick={resetModel}>Effacer</Button>
                                                            <Button onClick={handleCloseModel}>Valider</Button>
                                                        </div>
                                                    </Popover>
                                                </div>
                                                {
                                                    (elementCategory && elementCategory.id === "69") ||
                                                    (elementCategory && elementCategory.id === "70") ||
                                                    (elementCategory && elementCategory.id === "71") ||
                                                    (elementCategory && elementCategory.id === "73") ||
                                                    (elementCategory && elementCategory.id === "74") ||
                                                    (elementCategory && elementCategory.id === "146") ||
                                                    (elementCategory && elementCategory.id === "147") ||
                                                    (elementCategory && elementCategory.id === "203") ? (
                                                        <div className="search-filters-inner-row-filters-items-item">
                                                            <Button className={fuelFilter !== "Carburant" ? ' active' : ''} aria-describedby={idFuel} variant="contained" color="primary" onClick={handleClickFuel}>
                                                                {fuelFilter}
                                                            </Button>
                                                            <Popover
                                                                id={idFuel}
                                                                open={openFuel}
                                                                anchorEl={anchorElFuel}
                                                                onClose={handleCloseFuel}
                                                                className="filterContent"
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >
                                                                <h5 className="popper-title">Carburant</h5>
                                                                <div className="fuel">
                                                                    <RadioGroup
                                                                        aria-label="fuel"
                                                                        name="fuel"
                                                                        value={formik.values.fuel || ""}
                                                                        onChange={(e) => {
                                                                            formik.handleChange(e);
                                                                            handleChangeFuel(e);
                                                                        }}
                                                                        onBlur={formik.handleBlur}>
                                                                        <FormControlLabel value="1" control={<Radio />} label="Essence" />
                                                                        <FormControlLabel value="2" control={<Radio />} label="Diesel" />
                                                                        <FormControlLabel value="3" control={<Radio />} label="Hybride" />
                                                                        <FormControlLabel value="4" control={<Radio />} label="Éléctrique" />
                                                                        <FormControlLabel value="5" control={<Radio />} label="LPG" />
                                                                        <FormControlLabel value="6" control={<Radio />} label="Autre" />
                                                                    </RadioGroup>
                                                                </div>
                                                                <div className="popper-footer">
                                                                    <Button onClick={resetFuel}>Effacer</Button>
                                                                    <Button onClick={handleCloseFuel}>Valider</Button>
                                                                </div>
                                                            </Popover>
                                                        </div>
                                                    ) : ('')
                                                }

                                                <div className="search-filters-inner-row-filters-items-item">
                                                    <Button className={yearFilter !== "Année" ? ' active' : ''} aria-describedby={idYear} variant="contained" color="primary" onClick={handleClickYear}>
                                                        {yearFilter}
                                                    </Button>
                                                    <Popover
                                                        id={idYear}
                                                        open={openYear}
                                                        anchorEl={anchorElYear}
                                                        onClose={handleCloseYear}
                                                        className="filterContent"
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <h5 className="popper-title">Année</h5>
                                                        <div className="year">
                                                            <Slider
                                                                value={yearValue.values || ""}
                                                                min={1980}
                                                                step={1}
                                                                max={currentYear}
                                                                onChange={handleYearChange}
                                                                valueLabelDisplay="off"
                                                                aria-labelledby="range-slider"
                                                                getAriaValueText={yearValueText}
                                                                marks={yearValue.marks}
                                                            />
                                                        </div>
                                                        <div className="popper-footer">
                                                            <Button onClick={resetYear}>Effacer</Button>
                                                            <Button onClick={handleCloseYear}>Valider</Button>
                                                        </div>
                                                    </Popover>
                                                </div>
                                                {
                                                    (elementCategory && elementCategory.id === "69") ||
                                                    (elementCategory && elementCategory.id === "70") ||
                                                    (elementCategory && elementCategory.id === "71") ||
                                                    (elementCategory && elementCategory.id === "73") ||
                                                    (elementCategory && elementCategory.id === "74") ||
                                                    (elementCategory && elementCategory.id === "146") ||
                                                    (elementCategory && elementCategory.id === "147") ||
                                                    (elementCategory && elementCategory.id === "203") ? (
                                                        <div className="search-filters-inner-row-filters-items-item">
                                                            <Button className={mileageFilter !== "Kilométrage" ? ' active' : ''} aria-describedby={idMileage} variant="contained" color="primary" onClick={handleClickMileage}>
                                                                {mileageFilter}</Button>
                                                            <Popover
                                                                id={idMileage}
                                                                open={openMileage}
                                                                anchorEl={anchorElMileage}
                                                                onClose={handleCloseMileage}
                                                                className="filterContent"
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >
                                                                <h5 className="popper-title">Kilométrage</h5>
                                                                <div className="mileage">
                                                                    <Slider
                                                                        value={mileageValue.values || ""}
                                                                        min={0}
                                                                        step={10}
                                                                        max={250000}
                                                                        onChange={handleMileageChange}
                                                                        valueLabelDisplay="off"
                                                                        aria-labelledby="range-slider"
                                                                        getAriaValueText={mileageValueText}
                                                                        marks={mileageValue.marks}
                                                                    />
                                                                </div>
                                                                <div className="popper-footer">
                                                                    <Button onClick={resetMileage}>Effacer</Button>
                                                                    <Button onClick={handleCloseMileage}>Valider</Button>
                                                                </div>
                                                            </Popover>
                                                        </div>
                                                    ) : ('')
                                                }

                                                {
                                                    (elementCategory && elementCategory.id === "69") ||
                                                    (elementCategory && elementCategory.id === "70") ||
                                                    (elementCategory && elementCategory.id === "71") ||
                                                    (elementCategory && elementCategory.id === "73") ||
                                                    (elementCategory && elementCategory.id === "74") ||
                                                    (elementCategory && elementCategory.id === "146") ||
                                                    (elementCategory && elementCategory.id === "147") ||
                                                    (elementCategory && elementCategory.id === "203") ? (
                                                        <div className="search-filters-inner-row-filters-items-item">
                                                            <Button className={gearBoxFilter !== "Boite à vitesse" ? ' active' : ''} aria-describedby={idGearBox} variant="contained" color="primary" onClick={handleClickGearBox}>
                                                                {gearBoxFilter}
                                                            </Button>
                                                            <Popover
                                                                id={idGearBox}
                                                                open={openGearBox}
                                                                anchorEl={anchorElGearBox}
                                                                onClose={handleCloseGearBox}
                                                                className="filterContent"
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}>
                                                                <h5 className="popper-title">Boite de vitesse</h5>
                                                                <div className="gearbox">
                                                                    <RadioGroup
                                                                        aria-label="gearbox"
                                                                        name="gearbox"
                                                                        value={formik.values.gearbox || ""}
                                                                        onChange={(e) => {
                                                                            formik.handleChange(e);
                                                                            handleChangeGearBox(e);
                                                                        }}
                                                                        onBlur={formik.handleBlur}>
                                                                        <FormControlLabel value="2" control={<Radio />} label="Automatique" />
                                                                        <FormControlLabel value="1" control={<Radio />} label="Manuelle" />
                                                                    </RadioGroup>
                                                                </div>
                                                                <div className="popper-footer">
                                                                    <Button onClick={resetGearBox}>Effacer</Button>
                                                                    <Button onClick={handleCloseGearBox}>Valider</Button>
                                                                </div>
                                                            </Popover>
                                                        </div>
                                                    ) : ('')
                                                }

                                                {
                                                    (elementCategory && elementCategory.id === "69") ||
                                                    (elementCategory && elementCategory.id === "70") ||
                                                    (elementCategory && elementCategory.id === "71") ||
                                                    (elementCategory && elementCategory.id === "73") ||
                                                    (elementCategory && elementCategory.id === "74") ||
                                                    (elementCategory && elementCategory.id === "146") ||
                                                    (elementCategory && elementCategory.id === "147") ||
                                                    (elementCategory && elementCategory.id === "203") ? (
                                                        <div className="search-filters-inner-row-filters-items-item">
                                                            <Button className={(doorsFilter !== "Nombre de portes" ? ' active' : '')} aria-describedby={idDoors} variant="contained" color="primary" onClick={handleClickDoors}>
                                                                {doorsFilter}
                                                            </Button>
                                                            <Popover
                                                                id={idDoors}
                                                                open={openDoors}
                                                                anchorEl={anchorElDoors}
                                                                onClose={handleCloseDoors}
                                                                className="filterContent"
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}>
                                                                <h5 className="popper-title">Nombre de portes</h5>
                                                                <div className="doors">
                                                                    <FormControl variant="outlined" className="select-doors">
                                                                        <InputLabel id="doors-label">Nombre de portes</InputLabel>
                                                                        <Select
                                                                            labelId="doors-label"
                                                                            id="doors-select"
                                                                            label="Nombre de portes"
                                                                            name="doors"
                                                                            onChange={(e) => {
                                                                                formik.handleChange(e);
                                                                                handleChangeDoors(e);
                                                                            }}
                                                                            onBlur={formik.handleBlur}
                                                                            value={formik.values.doors || ""}>
                                                                            <MenuItem value="2">2</MenuItem>
                                                                            <MenuItem value="3">3</MenuItem>
                                                                            <MenuItem value="4">4</MenuItem>
                                                                            <MenuItem value="5">5+</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </div>
                                                                <div className="popper-footer">
                                                                    <Button onClick={resetDoors}>Effacer</Button>
                                                                    <Button onClick={handleCloseDoors}>Valider</Button>
                                                                </div>
                                                            </Popover>
                                                        </div>
                                                    ) : ('')
                                                }

                                                <div className="search-filters-inner-row-filters-items-item">
                                                    <Button className={(colorFilter !== "Couleur" ? ' active' : '')} aria-describedby={idColor} variant="contained" color="primary" onClick={handleClickColor}>
                                                        {colorFilter}
                                                    </Button>
                                                    <Popover
                                                        id={idColor}
                                                        open={openColor}
                                                        anchorEl={anchorElColor}
                                                        onClose={handleCloseColor}
                                                        className="filterContent"
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}>
                                                        <h5 className="popper-title">Couleur</h5>
                                                        <div className="color">
                                                            <FormControl variant="outlined" className="select-color">
                                                                <List className="color-list">
                                                                    {
                                                                        colors.map((color) => {
                                                                            const labelId = `checkbox-list-label-${color.id}`;
                                                                            return (
                                                                                <ListItem key={color.id} button>
                                                                                    <ListItemIcon>
                                                                                        <span style={{ backgroundColor: color.code }}></span>
                                                                                    </ListItemIcon>
                                                                                    <ListItemText id={labelId} primary={color.name} />
                                                                                    <ListItemSecondaryAction>
                                                                                        <Checkbox
                                                                                            edge="end"
                                                                                            onClick={handleToggleColor(color.id, color.name)}
                                                                                            checked={checkedColor.indexOf(color.id) !== -1 || checkedColorName.indexOf(color.name) !== -1 }
                                                                                            tabIndex={-1}
                                                                                            disableRipple
                                                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                                                            name="color"
                                                                                        />
                                                                                    </ListItemSecondaryAction>
                                                                                </ListItem>
                                                                            );
                                                                        })
                                                                    }
                                                                </List>
                                                            </FormControl>
                                                        </div>
                                                        <div className="popper-footer">
                                                            <Button onClick={resetColor}>Effacer</Button>
                                                            <Button onClick={handleCloseColor}>Valider</Button>
                                                        </div>
                                                    </Popover>
                                                </div>

                                                {
                                                    (elementCategory && elementCategory.id === "69") ||
                                                    (elementCategory && elementCategory.id === "70") ||
                                                    (elementCategory && elementCategory.id === "71") ||
                                                    (elementCategory && elementCategory.id === "73") ||
                                                    (elementCategory && elementCategory.id === "74") ||
                                                    (elementCategory && elementCategory.id === "146") ||
                                                    (elementCategory && elementCategory.id === "147") ||
                                                    (elementCategory && elementCategory.id === "203") ? (
                                                        <div className="search-filters-inner-row-filters-items-item">
                                                            <Button className={(placeFilter !== "Nombre de places" ? ' active' : '')} aria-describedby={idPlace} variant="contained" color="primary" onClick={handleClickPlace}>
                                                                {placeFilter}
                                                            </Button>
                                                            <Popover
                                                                id={idPlace}
                                                                open={openPlace}
                                                                anchorEl={anchorElPlace}
                                                                onClose={handleClosePlace}
                                                                className="filterContent"
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}>
                                                                <h5 className="popper-title">Nombre de places</h5>
                                                                <div className="place">
                                                                    <FormControl variant="outlined" className="select-place">
                                                                        <InputLabel id="place-label">Nombre de places</InputLabel>
                                                                        <Select
                                                                            labelId="place-label"
                                                                            id="color-select"
                                                                            label="Nombre de places"
                                                                            name="place"
                                                                            onChange={(e) => {
                                                                                formik.handleChange(e);
                                                                                handleChangePlace(e);
                                                                            }}
                                                                            onBlur={formik.handleBlur}
                                                                            value={formik.values.place || ""}>
                                                                            <MenuItem value="2">2</MenuItem>
                                                                            <MenuItem value="3">3</MenuItem>
                                                                            <MenuItem value="4">4</MenuItem>
                                                                            <MenuItem value="5">5</MenuItem>
                                                                            <MenuItem value="6">6</MenuItem>
                                                                            <MenuItem value="7">7</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </div>
                                                                <div className="popper-footer">
                                                                    <Button onClick={resetPlace}>Effacer</Button>
                                                                    <Button onClick={handleClosePlace}>Valider</Button>
                                                                </div>
                                                            </Popover>
                                                        </div>
                                                    ) : ('')
                                                }
                                            </div>
                                        ) : ('')
                                    }

                                    {/* Informatique && Multimedia */}
                                    {
                                        ["79", "80", "81", "82"].includes(elementCategory && elementCategory.id) ? (
                                            <div className="items-wrapper">
                                                <div className="search-filters-inner-row-filters-items-item">
                                                    <Button className={brandFilter !== "Marque" ? 'active' : ''} aria-describedby={idBrand} variant="contained" color="primary" onClick={handleClickBrand}>
                                                        {brandFilter}
                                                    </Button>
                                                    <Popover
                                                        id={idBrand}
                                                        open={openBrand}
                                                        anchorEl={anchorElBrand}
                                                        onClose={handleCloseBrand}
                                                        className="filterContent"
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <h5 className="popper-title">Marque</h5>
                                                        <div className="brand">
                                                            <FormControl variant="outlined" className="select-brand">
                                                                <InputLabel id="brand-label">Marque</InputLabel>
                                                                <Select
                                                                    labelId="brand-label"
                                                                    id="brand-select"
                                                                    label="Marque"
                                                                    name="brand"
                                                                    onChange={(e) => {
                                                                        formik.handleChange(e);
                                                                        handleMarqueChange(e);
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.brand || ""}
                                                                >
                                                                    {
                                                                        brands.map(element => {
                                                                            return <MenuItem value={element.id}>{element.name}</MenuItem>
                                                                        })
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div className="popper-footer">
                                                            <Button onClick={resetBrand}>Effacer</Button>
                                                            <Button onClick={handleCloseBrand}>Valider</Button>
                                                        </div>
                                                    </Popover>
                                                </div>
                                                <div className="search-filters-inner-row-filters-items-item">
                                                    <Button className={"MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary model-button" + (brandFilter !== 'Marque' ? ' enabled' : '')  + (modelFilter !== "Modèle" ? ' active' : '')} aria-describedby={idModel} variant="contained" color="primary" onClick={handleClickModel}>
                                                        {modelFilter}
                                                    </Button>
                                                    <Popover
                                                        id={idModel}
                                                        open={openModel}
                                                        anchorEl={anchorElModel}
                                                        onClose={handleCloseModel}
                                                        className="filterContent"
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <h5 className="popper-title">Modèle</h5>
                                                        <div className="model">
                                                            <FormControl variant="outlined" className="select-model">
                                                                <InputLabel id="model-label">Modèle</InputLabel>
                                                                <Select
                                                                    labelId="model-label"
                                                                    id="model-select"
                                                                    label="Modèle"
                                                                    name="model"
                                                                    onChange={(e) => {
                                                                        formik.handleChange(e);
                                                                        handleChangeModel(e);
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.model || ""}
                                                                >
                                                                    {
                                                                        models && models.map(element => {
                                                                            return <MenuItem value={element.id}>{element.name}</MenuItem>
                                                                        })
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div className="popper-footer">
                                                            <Button onClick={resetModel}>Effacer</Button>
                                                            <Button onClick={handleCloseModel}>Valider</Button>
                                                        </div>
                                                    </Popover>
                                                </div>
                                                {/* Phones and laptops category selected */}
                                                {
                                                    (elementCategory && elementCategory.id === "79") || (elementCategory && elementCategory.id === "80") || (elementCategory && elementCategory.id === "81") || (elementCategory && elementCategory.id === "82") ? (
                                                        <div className="items-wrapper">
                                                            <div className="search-filters-inner-row-filters-items-item">
                                                                <Button
                                                                    className={storageFilter !== "Capacité de stockage" ? 'active' : ''}
                                                                    aria-describedby={idStorage} variant="contained"
                                                                    color="primary" onClick={handleClickStorage}>
                                                                    {storageFilter}
                                                                </Button>
                                                                <Popover
                                                                    id={idStorage}
                                                                    open={openStorage}
                                                                    anchorEl={anchorElStorage}
                                                                    onClose={handleCloseStorage}
                                                                    className="filterContent"
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    }}>
                                                                    <h5 className="popper-title">Capacité de stockage</h5>
                                                                    <div className="storage">
                                                                        <FormControl variant="outlined"
                                                                                     className="select-storage">
                                                                            <InputLabel id="brand-label">Capacité de
                                                                                stockage</InputLabel>
                                                                            <Select
                                                                                labelId="storage-label"
                                                                                id="stoarge-select"
                                                                                label="Capacité de stockage"
                                                                                name="storage"
                                                                                onChange={(e) => {
                                                                                    formik.handleChange(e);
                                                                                    handleChangeStorage(e);
                                                                                }}
                                                                                value={formik.values.storage || ""}>
                                                                                <MenuItem value="4">2 Go</MenuItem>
                                                                                <MenuItem value="4">4 Go</MenuItem>
                                                                                <MenuItem value="8">8 Go</MenuItem>
                                                                                <MenuItem value="16">16 Go</MenuItem>
                                                                                <MenuItem value="32">32 Go</MenuItem>
                                                                                <MenuItem value="64">64 Go</MenuItem>
                                                                                <MenuItem value="128">128 Go</MenuItem>
                                                                                <MenuItem value="256">256 Go</MenuItem>
                                                                                <MenuItem value="512">512 Go</MenuItem>
                                                                                <MenuItem value="1024">1 To</MenuItem>
                                                                                <MenuItem value="2048">2 To</MenuItem>
                                                                                <MenuItem value="2048+">2 To et +</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </div>
                                                                    <div className="popper-footer">
                                                                        <Button onClick={resetStorage}>Effacer</Button>
                                                                        <Button onClick={handleCloseStorage}>Valider</Button>
                                                                    </div>
                                                                </Popover>
                                                            </div>
                                                            <div className="search-filters-inner-row-filters-items-item">
                                                                <Button
                                                                    className={memoryFilter !== "Mémoire RAM" ? 'active' : ''}
                                                                    aria-describedby={idMemory} variant="contained"
                                                                    color="primary" onClick={handleClickMemory}>
                                                                    {memoryFilter}
                                                                </Button>
                                                                <Popover
                                                                    id={idMemory}
                                                                    open={openMemory}
                                                                    anchorEl={anchorElMemory}
                                                                    onClose={handleCloseMemory}
                                                                    className="filterContent"
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    }}>
                                                                    <h5 className="popper-title">Mémoire RAM</h5>
                                                                    <div className="memory">
                                                                        <FormControl variant="outlined"
                                                                                     className="select-memory">
                                                                            <InputLabel id="memory-label">Mémoire
                                                                                RAM</InputLabel>
                                                                            <Select
                                                                                labelId="memory-label"
                                                                                id="memory-select"
                                                                                label="Mémoire RAM"
                                                                                name="memory"
                                                                                onChange={(e) => {
                                                                                    formik.handleChange(e);
                                                                                    handleChangeMemory(e);
                                                                                }}
                                                                                value={formik.values.memory || ""}>
                                                                                {
                                                                                    memories.map((memory) => (
                                                                                        <MenuItem value={memory}
                                                                                                  key={memory}>{memory} Go</MenuItem>)
                                                                                    )
                                                                                }
                                                                            </Select>
                                                                        </FormControl>
                                                                    </div>
                                                                    <div className="popper-footer">
                                                                        <Button onClick={resetMemory}>Effacer</Button>
                                                                        <Button onClick={handleCloseMemory}>Valider</Button>
                                                                    </div>
                                                                </Popover>
                                                            </div>
                                                        </div>
                                                    ) : ('')
                                                }
                                            </div>
                                        ) : ('')
                                    }
                                    {/* Estate */}
                                    {
                                        (
                                            (elementCategory && elementCategory.id === "13") || (elementCategoryParent && elementCategoryParent.id === "13") || 
                                            (elementCategory && elementCategory.id === "216") || (elementCategoryParent && elementCategoryParent.id === "216")
                                         ) ? (
                                            <div className="items-wrapper">
                                                <div className="search-filters-inner-row-filters-items-item">
                                                    <Button className={areaFilter !== "Surface" ? ' active' : ''} aria-describedby={idArea} variant="contained" color="primary" onClick={handleClickArea}>
                                                        {areaFilter}</Button>
                                                    <Popover
                                                        id={idArea}
                                                        open={openArea}
                                                        anchorEl={anchorElArea}
                                                        onClose={handleCloseArea}
                                                        className="filterContent"
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}>
                                                        <h5 className="popper-title">Surface</h5>
                                                        <div className="area">
                                                            <Slider
                                                                value={estateAreaValue.values || ""}
                                                                min={0}
                                                                step={1}
                                                                max={300}
                                                                onChange={handleEstateAreaChange}
                                                                valueLabelDisplay="off"
                                                                aria-labelledby="range-slider"
                                                                getAriaValueText={estateAreaValueText}
                                                                marks={estateAreaValue.marks}
                                                            />
                                                        </div>
                                                        <div className="popper-footer">
                                                            <Button onClick={resetArea}>Effacer</Button>
                                                            <Button onClick={handleCloseArea}>Valider</Button>
                                                        </div>
                                                    </Popover>
                                                </div>
                                                {
                                                    (elementCategory && elementCategory.id === "65") ||
                                                    (elementCategory && elementCategory.id === "217") ||
                                                    (elementCategory && elementCategory.id === "218") ||
                                                    (elementCategory && elementCategory.id === "219") ||
                                                    (elementCategory && elementCategory.id === "216") ||
                                                    (elementCategory && elementCategory.id === "66") ||
                                                    (elementCategory && elementCategory.id === "67") ||
                                                    (elementCategory && elementCategory.id === "68") ||
                                                    (elementCategory && elementCategory.id === "149") ||
                                                    (elementCategory && elementCategory.id === "150") ||
                                                    (elementCategory && elementCategory.id === "151")
                                                        ? (
                                                            <div className="search-filters-inner-row-filters-items-item">
                                                                <Button className={roomsFilter !== "Pièces" ? ' active' : ''} aria-describedby={idRooms} variant="contained" color="primary" onClick={handleClickRooms}>
                                                                    {roomsFilter}</Button>
                                                                <Popover
                                                                    id={idRooms}
                                                                    open={openRooms}
                                                                    anchorEl={anchorElRooms}
                                                                    onClose={handleCloseRooms}
                                                                    className="filterContent"
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    }}>
                                                                    <h5 className="popper-title">Pièces</h5>
                                                                    <div className="rooms">
                                                                        <FormControl variant="outlined" className="select-doors">
                                                                            <InputLabel id="rooms-label">Nombre de Pièces</InputLabel>
                                                                            <Select
                                                                                labelId="rooms-label"
                                                                                id="rooms-select"
                                                                                label="Nombre de pièces"
                                                                                name="rooms"
                                                                                onChange={(e) => {
                                                                                    formik.handleChange(e);
                                                                                    handleChangeRooms(e);
                                                                                }}
                                                                                onBlur={formik.handleBlur}
                                                                                value={formik.values.rooms || ""}>
                                                                                <MenuItem value="1">1</MenuItem>
                                                                                <MenuItem value="2">2</MenuItem>
                                                                                <MenuItem value="3">3</MenuItem>
                                                                                <MenuItem value="4">4</MenuItem>
                                                                                <MenuItem value="5">5</MenuItem>
                                                                                <MenuItem value="6">6</MenuItem>
                                                                                <MenuItem value="7">7</MenuItem>
                                                                                <MenuItem value="8+">8+</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </div>
                                                                    <div className="popper-footer">
                                                                        <Button onClick={resetRooms}>Effacer</Button>
                                                                        <Button onClick={handleCloseRooms}>Valider</Button>
                                                                    </div>
                                                                </Popover>
                                                            </div>
                                                        ) : ('')
                                                }
                                            </div>
                                        ) : ('')
                                    }
                                </div>
                            </div>
                        </div>
                    ) : ('')
                }
                <div className="search-filters-inner-row-button">
                    <Button
                        onClick={goToSearch}
                        variant="contained"
                        disableElevation>
                         {props.searchResultNumber !== null ? `Afficher ${props.searchResultNumber} résultat(s)` :  <span className="search-block"><MdSearch/>Recherche</span>}
                        {
                            props.searching ?
                                (<CircularProgress />) : ('')
                        }
                    </Button>
                    {
                        props.searchResultNumber !== null &&  <Button 
                        className="reset" 
                        onClick={handleResetFilters2}>
                            Réinitialiser
                        </Button>
                    }
                </div>
            </div>
        </section>
    );
};

export default Filters;
