import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  advertToChat: null,
  countMessages: 0,
};

const messageSlice = createSlice({
  name : 'message',
  initialState: initialState,
  reducers : {
    setAdvertToChat : (state, action) => {
        state.advertToChat = action.payload.advertToChat
    },
    setMessagesCount : (state, action) => {
        state.countMessages = action.payload.count
    },
  }

});

export const { setAdvertToChat, setMessagesCount } = messageSlice.actions;
export default messageSlice.reducer;
