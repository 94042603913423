import React from "react";
import './MyShop.scss';
import marketIcon from "../../../imgs/market.svg";
import {adverts} from "../../../data/advert";
import {Advert} from "../../Advert/advert";
import Button from "@material-ui/core/Button";
import DashboardAdView from "../Dashboard Ad View/DashboardAdView";
import {connect} from "react-redux";
import {getShopById, getAdvertByShop} from "../../../services/shop";

class MyShop extends React.Component {
    constructor(props) {
        super(props);
        this.state = { shop : props.location.state, shops: [], categories : [], category : {}, shopLoading : true, categoryLoading : true, advertsInitializing : true, limit: 15, offset: 0, total: 0 };
    }

    componentDidMount(){
        this.loadShop();
    }

    loadShop = async () => {
        this.setState((state)=> ({ shopLoading : true }));

        getAdvertByShop({ user_id: this.props.currentUser.id, country_id: this.props.currentCountry.id, limit: this.state.limit, offset: this.state.offset })
        .then(({data}) => {
                console.warn(data);
                let { RESPONSEDATA } = data;
                let res = this.state.shops.slice();
                RESPONSEDATA.forEach((shop) => res.push(shop));
                this.setState((state)=> ({ shops : res, total:  res.length }));
            })
            .catch(e=> {
                console.error(e);
            }).finally(()=>{
                this.setState((state)=> ({ shopLoading : false, advertsInitializing : false }));
            });
    }

    render() {
        return (
            <div className="my-shop">
                <h2>Ma boutique</h2>
                <div className="my-shop-content">
                    <div className="my-shop-content-header">
                        <div className="my-shop-content-header-shape"></div>
                        <div className="my-shop-content-header-inner">
                            <div className="my-shop-content-header-inner-image">
                                <img src={marketIcon} alt="icon"/>
                            </div>
                            <div className="my-shop-content-header-inner-infos">
                                <h3>{this.props.currentUser.pro_rs}</h3>
                                <ul>
                                    <li>Adresse</li>
                                    <li>{this.props.currentUser.address}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="my-shop-content-body">
                        {
                            this.state.shops.map((advert)=> {
                                return (
                                    <DashboardAdView key={advert.id} object={advert} />
                                )
                            })
                        }
                        {
                            this.state.shopLoading == false && this.state.shops.length === 0 && (
                                <div className="content">
                                Aucune annonce trouvée!
                            </div>
                            )
                        }
                    </div>
                    {/* <div className="content-more-load">
                        <Button>Plus d'annonces</Button>
                    </div> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
    currentCountry: state.country.currentCountry,
});

export default connect(mapStateToProps, null)(MyShop);