import React, { useEffect, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";

import Categories from "./Categories";
import OrderBy from "./OrderBy";
import TopCategories from "./TopCategories";
import Results from "../../containers/Results";

import "./Advertisements.scss";
import { NavLink } from "react-router-dom";
import { countAdverts, getAdvertsCat } from "../../services/advertisement";
import { getFeaturedAdverts } from "../../services/featuredAdvert";
import Advert from "../Advert/advert";

const Advertisements = ({
  currentCountry,
  searchResult,
  isSearching,
  searchResultNumber,
  setSearchResult,
  setIsSearching,
  setSearchObject,
  setSearchResultNumber,
  currentCategory,
  currentCity,
  categorySelected,
}) => {
  useEffect(() => {
    getAllCount();
  }, []);

  const calcCreateAdvertisementButton = () => {
    const button = document.getElementById("create-advertisement");
    const searchBlock = document.getElementById("search");
    const baseClass = "advertisements-separator-content-createadvertisement";

    if (searchBlock && button) {
      if (window.scrollY + window.innerHeight > searchBlock.clientHeight) {
        button.classList.add(`${baseClass}--absoluted`);
      } else {
        button.classList.remove(`${baseClass}--absoluted`);
      }
    }
  };

  const getAllCount = () => {
    var data = {
      country_id: currentCountry.id,
    };
    countAdverts(data)
      .then(({ data }) => {
        setCounts(data);
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const [counts, setCounts] = useState(null);

  window.addEventListener("scroll", calcCreateAdvertisementButton);
  calcCreateAdvertisementButton();

  const getAdverts = async (cat) => {
    console.log('getAdverts from Advertisements component')
    var body = {
      limit: 15,
      offset: 0,
      country_id: currentCountry.id,
      category_id: cat.id,
    };
    var category =
      counts &&
      counts.total_categories_adverts.find((element) => {
        return element.id == cat.id;
      });
    var { data } = await getAdvertsCat(body);

    categorySelected(cat);
    setSearchResultNumber(category.total_adverts);
    setSearchResult(data.RESPONSEDATA ? data.RESPONSEDATA : []);
    setIsSearching(2);
    body.offset = 15;
    setSearchObject(body);
  };

  const [featuredList, setFeaturedList] = useState([]);

  const sortFunction = (type, array) => {
    var tab = [];
    //let arr = [...array];
    let arr = [...array];

    if (type == 1) {
      tab = arr.sort(function (a, b) {
        return new Date(a.created_at) - new Date(b.created_at);
      });
    } else if (type == 2) {
      tab = arr.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
    } else if (type == 3) {
      tab = arr.sort(function (a, b) {
        return parseInt(a.price) - parseInt(b.price);
      });
    } else if (type == 4) {
      tab = arr.sort(function (a, b) {
        return parseInt(b.price) - parseInt(a.price);
      });
    }
    //console.log('SORT', array.length, tab.length)
    setSearchResult(tab);
    //console.log(searchResult);
    //setSearchResultNumber(tab.length);
    setIsSearching(3);
  };

  useEffect(() => {
    const country_id = {
      country_id: currentCountry.id,
    };
    getFeaturedAdverts(country_id)
      .then(({ data }) => {
        if (data.RESPONSECODE == 200) {
          let res = data.RESPONSEDATA;
          //console.log(data.RESPONSEDATA);
          setFeaturedList(res);
        } else {
          setFeaturedList([]);
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally((e) => {});
  }, []);

  //console.log('RESPONSE')
  //console.log(searchResult)

  return (
    <section className="advertisements">
      {/*<small className="advertisements-results">
          (10903) Annonces dans toutes les catégories
          { (currentCountry) && (
              <>
                , Tout "{currentCountry.name}"
              </>
          ) }
        </small>*/}
      <Categories counts={counts} />
      <section className="advertisements-mobile">
        <div className="advertisements-mobile-top">
          <div className="advertisements-mobile-top-left">
            <span className="advertisements-mobile-top-left-small">
              Toutes les catégories
            </span>
            <span className="advertisements-mobile-top-left-strong">
              {/*<FaMapMarkerAlt />*/}, tout{" "}
              <strong>"{currentCountry.name}"</strong> (
              {counts ? counts.total_adverts : 0})
            </span>
          </div>
          <OrderBy
            counts={counts}
            searchResult={searchResult}
            isSearching={isSearching}
            searchResultNumber={searchResultNumber}
            sortFunction={sortFunction}
            currentCategory={currentCategory}
            currentCity={currentCity}
            setSearchResult={setSearchResult}
            setIsSearching={setIsSearching}
          />
        </div>
        {/*<div className="advertisements-mobile-bottom">
            <NavLink className="button upper" to={`/${currentCountry.slug}/advanced-search`}>Recherche avancée</NavLink>
          </div>*/}
      </section>

      <div className="advertisements-separator">
        <div className="advertisements-separator-content">
          <TopCategories
            currentCountry={currentCountry}
            searchResult={searchResult}
            counts={counts}
            getAdverts={getAdverts}
            searchResultNumber={searchResultNumber}
            isSearching={isSearching}
            currentCategory={currentCategory}
            currentCity={currentCity}
            sortFunction={sortFunction}
          />
          <Results />
          <NavLink
            to="/advertisements/create"
            id="create-advertisement"
            className="advertisements-separator-content-createadvertisement"
          >
            <BsPencilSquare />
          </NavLink>
        </div>

        <div className="advertisements-separator-ads">
          <div className="advertisements-separator-ads-inner">
            <div className="advertisements-separator-ads-inner-item ads-adverts">
              {featuredList.map((advert) => {
                return (
                  <Advert
                    key={advert.advert_id}
                    object={advert}
                    featured={true}
                  />
                );
              })}
            </div>
            <div className="advertisements-separator-ads-inner-item">
              <div className="advertisements-separator-ads-inner-item-pub">
                <img src="https://picsum.photos/200?random=2" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Advertisements;
