import React from 'react';
import './app-dialog.scss';
import Dialog from "@material-ui/core/Dialog";
import {MdClose} from "react-icons/md";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";
import mobileAppPicture from "../../imgs/image-app-mobile.png";
import logoRound from "../../imgs/logo-round.png";
import GooglePlay from "../../imgs/google-play.svg";
import AppStore from "../../imgs/appstore.svg";
import qr from "../../imgs/qr-code.png";

export class AppDialog extends React.Component {

    constructor(props) {
        super(props);
        if (localStorage.getItem("appDialogState") !== undefined) {
            this.state = JSON.parse(localStorage.getItem("appDialogState"));
        } else {
            this.state = {acceptedDialog: false};
        }
        this.state = {
            isDialogOpen: false,
            acceptedDialog: false,
        }
        this.accept = this.accept.bind(this);
        this.handleDialogOpen = this.handleDialogOpen.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            if (!localStorage.getItem("appDialogState")) {
                console.log(localStorage.getItem("appDialogState"));
                this.setState({isDialogOpen: true});
            } else {
                this.setState({isDialogOpen: false})
            }
        }, 10000)
    }

    handleDialogOpen () {
        this.setState({
            isDialogOpen: true
        });
    }

    handleDialogClose() {
        this.setState({
            isDialogOpen: false
        });
    }

    accept() {
        this.setState((state) => ({ acceptedDialog : true }));
        localStorage.setItem('appDialogState', JSON.stringify({ acceptedDialog : true }));
        console.log(localStorage.getItem("appDialogState"));
    }

    render() {
        return (
            <Dialog className="dialog app-dialog" disableBackdropClick disableEscapeKeyDown open={this.state.isDialogOpen} onClose={this.handleDialogClose}>
                <IconButton aria-label="close" className="closeButton" onClick={() => {
                    this.accept(); this.handleDialogClose()
                }}>
                    <MdClose />
                </IconButton>
                <DialogContent>
                    <div className="app-dialog-container">
                        <div className="app-dialog-container-left">
                            <div className="app-dialog-container-left-header">
                                <div className="app-dialog-container-left-header-image">
                                    <img src={logoRound} alt="logo"/>
                                </div>
                                <div className="app-dialog-container-left-header-text">
                                    <h3>Téléchargez</h3>
                                    <p>Notre App Taari</p>
                                </div>
                            </div>
                            <div className="app-dialog-container-left-body">
                                <p>Scanner le QR code ci dessous avec votre appareil photo ou avec votre Application de lecteur QR code</p>
                                <div className="app-dialog-container-left-body-qr-image">
                                    <img src={qr} alt="code"/>
                                </div>
                                <div className="app-dialog-container-left-body-qr-text">
                                    <p>Télécharger Taari</p>
                                </div>
                            </div>
                            <div className="app-dialog-container-left-footer">
                                <ul>
                                    <li>
                                        <a href="https://play.google.com/store/apps/details?id=com.taari.app" target="_blank" onClick={this.accept}>
                                            <img src={GooglePlay} alt="logo"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" onClick={this.accept}>
                                            <img src={AppStore} alt="logo"/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="app-dialog-container-right">
                            <img src={mobileAppPicture} alt="image"/>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}
