import { connect } from 'react-redux';
import SignupForm from '../components/Signup/SignupForm';

const mapStateToProps = state => ({
    categories: state.category.categories,
    currentCountry : state.country.currentCountry
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);