import React from "react";
import './MyProfile.scss';
import Icon from "@iconify/react";
import cameraPlusOutline from "@iconify/icons-mdi/camera-plus-outline";
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import PhoneInput from "react-phone-input-2";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { connect } from 'react-redux';
import { MdClose } from "react-icons/md";
import IconButton from "@material-ui/core/IconButton";
import {MdClear} from "react-icons/all";
import {changePassword, deleteUserAccount, removelogUserOutData, userUpdate} from '../../../services/users';
import {setBackColor, setMessage, setOpen} from "../../../reducers/snackbarReducer";
import {saveUser, changeOperation, setPreLoginData} from '../../../reducers/userReducer';
import { getAllCategories } from '../../../services/category';
import { Redirect, } from 'react-router-dom';
import {Formik} from "formik";
import * as Yup from "yup";
import {staticApiUrl} from "../../../utils/axios";

const url = `${staticApiUrl}/userimages/`;

export class MyProfile extends React.Component {
    constructor(props){
        super(props);
        this.currentUser = props.currentUser;
        this.state = {
            openPasswordDialog : false,
            openNumberChangeDialog: false,
            openDeleteDialog: false,
            currentCityDistricts : [],
            picture : this.props.currentUser.image && this.props.currentUser.image[0] ? `${url}${this.props.currentUser.image[0].filename}` : undefined,
            oldPassword: '',
            newPassword: '',
            newPasswordconfirm: '',
            newNumber: '',
            ville: '',
            quartier: '',
            activity_description: '',
            activity_title: '',
            address: '',
            first_name: '',
            last_name: '',
            category_id: '',
            city_id: '',
            country_id: '',
            email: '',
            phone: '',
            phone_indicatif: '',
            whatsapp: '',
            whatsapp_indicatif: '',
            zone_id: '',
            pro_rs: '',
            pro_rc: '',
            horaires: '',
            number: `${this.currentUser.phone_indicatif}${this.currentUser.phone}`,
            categories: [],
            internet: '',
            validateNumber: false,
            confirmNumber: false,
            numero: '',
            updating: false,
            newImage: '',
        };

        this.setOpen = props.setOpen;
        this.setMessage = props.setMessage;
        this.setBackColor = props.setBackColor;
        this.saveUser = props.saveUser;
        this.changeOperation = props.changeOperation;
        this.setPreLoginData = props.setPreLoginData;

        /* File upload */
        this.fileInput = React.createRef();
        this.index = props.index;
        this.handleChange = this.handleChange.bind(this);
        this.deletePicture = this.deletePicture.bind(this);
        this.updatePicture = this.updatePicture.bind(this);

        this.handleOpenPassword = this.handleOpenPassword.bind(this);
        this.handleOpenNumberChange = this.handleOpenNumberChange.bind(this);
        this.handleOpenDeleteChange = this.handleOpenDeleteChange.bind(this);
        this.handleClosePassword = this.handleClosePassword.bind(this);
        this.handleCloseNumberChange = this.handleCloseNumberChange.bind(this);
        this.handleCloseDeleteChange = this.handleCloseDeleteChange.bind(this);

        this.handleSelectCityChange = this.handleSelectCityChange.bind(this);
        this.handleSelectDistrictChange = this.handleSelectDistrictChange.bind(this);
    }

    componentDidMount() {
        getAllCategories().then(({ data }) => {
            let { RESPONSEDATA } = data;
            let tab = [];
            for (let i = 0; i < RESPONSEDATA.length; i++) {
                var item = RESPONSEDATA[i];
                var obj = {};
                obj.id = item.id;
                obj.title = item.title_fr;
                let tab2 = [];
                item.sub.map(elt2 => {
                    tab2.push({
                        id: elt2.id,
                        title: elt2.title_fr,
                        parentId: item.id
                    })
                })
                obj.items = tab2;
                tab.push(obj);
            }
            this.setState({categories: tab});
        }).catch((e)=>{
            console.error(e);
        });
        if (this.props.currentUser.zone_id) {
            var obj = this.props.currentCountry.cities.find(element => {
                return element.id === this.props.currentUser.city_id;
            })
            this.setState((state) => ({currentCityDistricts : obj ? obj.zones : []}));
        }
        // this.handleSelectCityChange(this.props.currentUser.city_id);
    }

    handleOpenPassword = () => {
        this.changeOperation(1);
        this.setState((state) => ({ openPasswordDialog : true }));
    };

    handleOpenNumberChange = () => {
        this.changeOperation(2);
        this.setState((state) => ({ openNumberChangeDialog : true }));
    };

    handleOpenDeleteChange = () => {
        this.setState((state) => ({ openDeleteDialog : true }));
    };

    handleClosePassword () {
        this.setState((state) => ({ openPasswordDialog : false }));
    };

    handleCloseNumberChange () {
        this.setState((state) => ({ openNumberChangeDialog : false }));
    };

    handleCloseDeleteChange () {
        this.setState((state) => ({ openDeleteDialog : false }));
    };

    handleSubmitDeleteAccount (values) {
        //console.log('current user', this.currentUser, values)
        if(this.currentUser?.id){
            deleteUserAccount({user_id:this.currentUser.id, deletion_motive:values?.motif})
            .then((response)=>{
                //console.log('RESPONSE', response)
                removelogUserOutData()
                this.setMessage('votre compte taari a été supprimé avec succes');
                this.setOpen(true);
                this.setBackColor('green');

            })
            .catch((response)=>{
                this.setMessage('Erreur survenue lors de la suppression de votre compte, veuillez essayer plus tard');
                this.setOpen(true);
                this.setBackColor('red');
                
            })
        }
        //deleteUserAccount({})
        //this.setState((state) => ({ openDeleteDialog : false }));
    };

    handleSelectCityChange(e){
        let cityId = e.target.value ;
        var tab = this.props.currentCountry.cities.filter(element => {
            return element.id === cityId;
        });
        this.setState((state) => ({ city_id: cityId, currentCityId: cityId, currentCityDistricts : tab[0].zones }));
    }

    handleSelectDistrictChange(e){
        this.setState((state) => ({zone_id: e.target.value}));
    }

    handleCategorieChange = (e) => {
        this.setState((state) => ({category_id: e.target.value}));
    }

    handleChange(){
        let file = (this.fileInput.current.files[0]);
        if(file != undefined){
            let reader = new FileReader();
            let ctx = this;
            reader.onload = function(e) {
                ctx.updatePicture(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    }

    handlePassThingChange = (e, type) => {
        if (type == 'whatsapp' && (e === ''|| e.length < 11)) {
            this.setMessage("Veuillez Saisir un numero précedé de l'indicatif");
            this.setOpen(true);
        }
        this.setState({[type]: type == 'whatsapp' ? e : e.target.value});
    };

    handleNumberChange = (e) => {
        this.setState({numero: e});
    };

    deletePicture() {
        this.setState((state) => ({ picture: undefined }));
    }

    updatePicture(url){
        this.setState((state)=>({ picture : url, newImage: url}));
    }

    changeNumber = () => {
        const {numero} = this.state;
        var userData = {
            user_id: this.currentUser.id,
            phone_indicatif : numero.slice(0, 3),
            phone : numero.substring(3)
        }
        if (numero === ''|| numero.length < 11) {
            this.setMessage("Veuillez Saisir un numero précedé de l'indicatif");
            this.setOpen(true);
        } else {
            this.setPreLoginData(userData);
            this.setState((state) => ({ confirmNumber : true }));
            // changePassword(userData).then((data) => {
            //     this.setMessage('Successful update');
            //     this.setOpen(true);
            //     this.setState((state) => ({ openNumberChangeDialog : false }));
            // }).catch(error => {
            //     console.warn('changePassword error: ', error);
            //     // this.setMessage({message : data.RESPONSEMESSAGE});
            //     this.setOpen(true);
            // });
        }
    }

    changePassword = () => {
        const {oldPassword, newPassword, newPasswordconfirm} = this.state;
        var userData = {
            user_id: this.currentUser.id,
            old_password: oldPassword,
            new_password: newPassword,
        }
        if (oldPassword == '' || newPassword == '' || newPasswordconfirm == '') {
            this.setMessage('Tous les champs doivent être remplis');
            this.setOpen(true);
            this.setBackColor('red');
        } else {
            if (newPassword !== newPasswordconfirm) {
                this.setMessage('Les mots de passe ne sont pas identiques');
                this.setOpen(true);
                this.setBackColor('red');
            } else {
                changePassword(userData).then(({data}) => {
                    console.warn(data);
                    this.setMessage(data.RESPONSEMESSAGE);
                    this.setOpen(true);
                    this.setState((state) => ({ openPasswordDialog : false, oldPassword: '', newPassword: '', newPasswordconfirm: '' }));
                }).catch(error => {
                    // this.setMessage({message : data.RESPONSEMESSAGE});
                    this.setOpen(true);
                });
            }
        }
    }

    userUpdate = () => {
        const {
            first_name,
            last_name,
            activity_description,
            activity_title,
            address,
            category_id,
            city_id,
            country_id,
            email,
            phone,
            phone_indicatif,
            whatsapp,
            /*whatsapp_indicatif,*/
            zone_id,
            pro_rs,
            pro_rc,
            horaires,
            internet,
        } = this.state;
        var userData = {
            "first_name" : first_name ? first_name : this.currentUser.first_name,
            "last_name" : last_name ? last_name : this.currentUser.last_name,
            "phone": phone ? phone : this.currentUser.phone,
            "phone_indicatif": phone_indicatif ? phone_indicatif : this.currentUser.phone_indicatif,
            "whatsapp": whatsapp ? whatsapp.substring(3) : this.currentUser.whatsapp,
            "whatsapp_indicatif": whatsapp ? whatsapp.substring(0, 3) : this.currentUser.whatsapp_indicatif,
            "email": email ? email : this.currentUser.email,
            "address": address ? address : this.currentUser.address,
            "internet": internet ? internet : this.currentUser.internet,
            "zone_id": zone_id ? zone_id : this.currentUser.zone_id,
            "city_id": city_id ? city_id : this.currentUser.city_id,
            "country_id": country_id ? country_id : this.currentUser.country_id,
            "type": "USER",
            "pro_rs": pro_rs ? pro_rs : this.currentUser.pro_rs,
            "pro_rc": pro_rc ? pro_rc : this.currentUser.pro_rc,
            "horaires": horaires ? horaires : this.currentUser.horaires,
            "category_id": category_id ? category_id : this.currentUser.category_id,
            "activity_title": activity_title ? activity_title : this.currentUser.activity_title,
            "activity_description": activity_description ? activity_description : this.currentUser.activity_description,
            "user_id": this.currentUser.id,
            "images": this.state.newImage ?  [this.state.newImage] : []
        }
        console.warn(userData);
        this.setState({updating: true});
        userUpdate(userData).then(({data}) => {
            console.warn(data);
            if (data.RESPONSECODE == 200) {
                this.setMessage(data.RESPONSEMESSAGE);
                this.saveUser(data.RESPONSEDATA.user_data)
                this.setOpen(true);
                this.setState({updating: false});
            }
        }).catch(error => {
            // this.setMessage({message : data.RESPONSEMESSAGE});
            this.setOpen(true);
            this.setBackColor('red');
        });
    }

    render() {
        // On définit de manière dynamique le placeholder des inputs
        // des téléphones (la lib utilisée ne le permet pas...)
        setTimeout(() => {
            const phoneInputContainers = document.querySelectorAll(':scope .react-tel-input');
            phoneInputContainers.forEach((container) => {
                const phoneInput = container.querySelector('input');
                const { placeholder } = phoneInput;
                const inputLabel = container.querySelector('.flag-dropdown');
                inputLabel.dataset.placeholder = placeholder;
            });
        });
        console.log(this.state);
        const formSchema = Yup.object().shape({
            motif: Yup.string()
                .required('Ce champ est requis')
        });
        return (
            <div className="dashboard-profile">
                {this.state.validateNumber && <Redirect to="/number-validation" />}
                {this.state.confirmNumber && <Redirect to="/number-confirmation" />}
                <h2>Informations personnelles</h2>
                <div className="dashboard-profile-container">
                    <div className="dashboard-profile-container-picture">
                        <div className="dashboard-profile-container-picture-text">
                            <h3>Photo</h3>
                            {
                                (this.currentUser.type === '2') ? (
                                    <p>
                                        personnalisez votre boutique en y ajoutant une photo ou un logo
                                    </p>
                                ) : (this.currentUser.type === '1') && (
                                    <p>
                                        Ajouter un photo de profil
                                    </p>
                                )
                            }
                        </div>
                        {(this.state.picture === undefined) &&  <div className="dashboard-profile-container-picture-choose">
                            <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" ref={this.fileInput} onChange={this.handleChange}/>
                            <label htmlFor="imageUpload">
                                <Icon icon={cameraPlusOutline}/>
                                <span>Ajouter une photo</span>
                            </label>
                        </div> }
                        {(this.state.picture !== undefined) && <div className="dashboard-profile-container-picture-default">
                            <img src={this.state.picture}/>
                            <button type="button" onClick={this.deletePicture}><MdClear/></button>
                        </div>}
                    </div>
                    <div className="dashboard-profile-container-infos">
                        <h3>Vos informations</h3>
                        <div className="dashboard-profile-container-infos-one">
                            <div className="dashboard-profile-container-infos-item">
                                <div className="half-form-wrapper name">
                                    <TextField
                                        name="firstName"
                                        id="firstName"
                                        label="Prénom"
                                        type="text"
                                        variant="outlined"
                                        className="firstName"
                                        size="small"
                                        value={this.state.first_name !== '' ? this.state.first_name : this.currentUser.first_name}
                                        onChange={(e) => this.handlePassThingChange(e, 'first_name')}
                                    />
                                    <TextField
                                        name="lastName"
                                        id="LastName"
                                        label="Nom"
                                        type="text"
                                        variant="outlined"
                                        className="lastName"
                                        size="small"
                                        value={this.state.last_name !== '' ? this.state.last_name : this.currentUser.last_name}
                                        onChange={(e) => this.handlePassThingChange(e, 'last_name')}
                                    />
                                </div>
                                <div className="half-form-wrapper">
                                    <FormControl className="select-city">
                                        <InputLabel id="city-label">Ville</InputLabel>
                                        <Select
                                            onChange={this.handleSelectCityChange}
                                            name="town"
                                            id="town"
                                            labelId="city-label"
                                            variant="outlined"
                                            size="small"
                                            className="city"
                                            defaultValue={this.props.currentUser.city_id ? this.props.currentUser.city_id : ''}>
                                            { this.props.currentCountry.cities.map((city) => (
                                                <MenuItem key={city.id} value={city.id}>{city.name}</MenuItem>
                                            )) }

                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="dashboard-profile-container-infos-item">
                                <div className="half-form-wrapper">
                                    <FormControl className="select-district">
                                        <InputLabel id="district-label">Quartier</InputLabel>
                                        <Select
                                            onChange={this.handleSelectDistrictChange}
                                            name="district"
                                            id="district"
                                            labelId="district-label"
                                            variant="outlined"
                                            size="small"
                                            className="district"
                                            defaultValue={this.props.currentUser.zone_id ? this.props.currentUser.zone_id : ''}
                                        >
                                            { this.state.currentCityDistricts.map((district) => (
                                                <MenuItem value={district.id}>{district.name}</MenuItem>
                                            )) }
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="half-form-wrapper">
                                    <TextField
                                        name="address"
                                        id="address"
                                        label="Adresse"
                                        type="text"
                                        variant="outlined"
                                        className="address"
                                        size="small"
                                        value={this.state.address !== '' ? this.state.address : this.currentUser.address}
                                        onChange={(e) => this.handlePassThingChange(e, 'address')}
                                    />
                                </div>
                            </div>
                            <div className="dashboard-profile-container-infos-item">
                                {
                                    this.currentUser.type === '2' ?
                                        (
                                            <div className="half-form-wrapper">
                                                <FormControl className="select-category">
                                                    <InputLabel id="category-label">Catégorie de votre activité</InputLabel>
                                                    <Select
                                                        name="activityCategory"
                                                        id="activityCategory"
                                                        labelId="category-label"
                                                        variant="outlined"
                                                        size="small"
                                                        className="activityCategory"
                                                        onChange={this.handleCategorieChange}
                                                        defaultValue={this.currentUser.category_id}>
                                                        {
                                                            this.state.categories.map(element => {
                                                                return <MenuItem value={element.id}>{element.title}</MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        ) : ('')
                                }
                                {
                                    this.currentUser.type === '2' ?
                                        (
                                            <div className="half-form-wrapper">
                                                <TextField
                                                    name="societyName"
                                                    id="societyName"
                                                    label="Nom de la société"
                                                    type="text"
                                                    variant="outlined"
                                                    className="societyName"
                                                    size="small"
                                                    value={this.state.pro_rs !== '' ? this.state.pro_rs : this.currentUser.pro_rs}
                                                    onChange={(e) => this.handlePassThingChange(e, 'pro_rs')}
                                                />
                                            </div>
                                        ) : ('')
                                }
                            </div>
                            <div className="dashboard-profile-container-infos-item">
                                {
                                    this.currentUser.type === '2' ?
                                        (
                                            <div className="half-form-wrapper">
                                                <TextField
                                                    name="activityTitle"
                                                    id="activityTitle"
                                                    label="Titre de votre activité"
                                                    type="text"
                                                    variant="outlined"
                                                    className="activityTitle"
                                                    size="small"
                                                    value={this.state.activity_title !== '' ? this.state.activity_title : this.currentUser.activity_title}
                                                    onChange={(e) => this.handlePassThingChange(e, 'activity_title')}
                                                />
                                            </div>
                                        ) : ('')
                                }
                                {
                                    this.currentUser.type === '2' ?
                                        (
                                            <div className="half-form-wrapper">
                                                <TextField
                                                    name="activityDescription"
                                                    id="activityDescription"
                                                    label="Présentation de votre activité"
                                                    type="text"
                                                    variant="outlined"
                                                    className="presentation"
                                                    size="small"
                                                    value={this.state.activity_description !== '' ? this.state.activity_description : this.currentUser.activity_description}
                                                    onChange={(e) => this.handlePassThingChange(e, 'activity_description')}
                                                />
                                            </div>
                                        ) : ('')
                                }
                            </div>
                            <div className="dashboard-profile-container-infos-item">
                                {
                                    this.currentUser.type === '2' ?
                                        (
                                            <div className="half-form-wrapper">
                                                <TextField
                                                    name="website"
                                                    id="website"
                                                    label="Site internet"
                                                    type="text"
                                                    variant="outlined"
                                                    className="website"
                                                    size="small"
                                                    value={this.state.internet !== '' ? this.state.internet : this.currentUser.internet}
                                                    onChange={(e) => this.handlePassThingChange(e, 'internet')}
                                                />
                                            </div>
                                        ) : ('')
                                }
                                 {
                                    this.currentUser.type === '2' ?
                                        (
                                            <div className="half-form-wrapper">
                                                <TextField
                                                    name="rccmNumber"
                                                    id="rccmNumber"
                                                    label="Numéro RCCM"
                                                    type="text"
                                                    variant="outlined"
                                                    className="rccmNumber"
                                                    size="small"
                                                    value={this.state.pro_rc !== '' ? this.state.pro_rc : this.currentUser.pro_rc}
                                                    onChange={(e) => this.handlePassThingChange(e, 'pro_rc')}
                                                />
                                            </div>
                                        ) : ('')
                                }
                            </div>
                            <div className="dashboard-profile-container-infos-item">
                                {
                                    this.currentUser.type === '2' ?
                                        (
                                            <div className="full-form-wrapper">
                                                <TextField
                                                    name="times"
                                                    id="times"
                                                    label="Vos horaires"
                                                    multiline
                                                    rows={4}
                                                    variant="outlined"
                                                    value={this.state.horaires !== '' ? this.state.horaires : this.currentUser.horaires}
                                                    onChange={(e) => this.handlePassThingChange(e, 'horaires')}
                                                />
                                            </div>
                                        ) : ('')
                                }
                            </div>
                        </div>
                        <h3>Votre compte</h3>
                        <div className="dashboard-profile-container-infos-two">
                            <div className="dashboard-profile-container-infos-item">
                                <div className="half-form-wrapper">
                                    <TextField
                                        name="email"
                                        id="email"
                                        label="E-mail"
                                        variant="outlined"
                                        size="small"
                                        className="email"
                                        value={this.state.email !== '' ? this.state.email : this.currentUser.email}
                                        onChange={(e) => this.handlePassThingChange(e, 'email')}
                                    />
                                </div>
                                <div className="half-form-wrapper tel-input">
                                    <PhoneInput
                                        /*regions={'africa'}*/
                                        country={'tg'}
                                        value={`${this.currentUser.phone_indicatif}${this.currentUser.phone}`}
                                        containerClass='phone-input'
                                        placeholder={'Numéro de téléphone'}
                                        defaultMask={'.. .. .. .. .. ..'}
                                        preferredCountries={['tg', 'cd', 'ml', 'sn', 'bj', 'ne', 'bf', 'ci']}
                                        /*onlyCountries={['tg', 'cd', 'ml', 'sn', 'bj', 'ne', 'bf', 'ci']}*/
                                        enableSearch={true}
                                        disabled={true}
                                    />
                                    {/*<FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={this.handleChange}
                                                name="checkedPrice"
                                                color="primary"
                                            />
                                        }
                                        label="Cacher ce numéro sur l'annonce"
                                    />*/}
                                </div>
                            </div>
                            <div className="dashboard-profile-container-infos-item">
                                <div className="half-form-wrapper tel-input">
                                    <PhoneInput
                                        /*regions={'africa'}*/
                                        country={'tg'}
                                        value={this.state.whatsapp ? this.state.whatsapp : `${this.currentUser.whatsapp_indicatif}${this.currentUser.whatsapp}`}
                                        containerClass='whatsapp-input'
                                        placeholder={'Numéro de téléphone Whatsapp'}
                                        defaultMask={'.. .. .. .. .. ..'}
                                        preferredCountries={['tg', 'cd', 'ml', 'sn', 'bj', 'ne', 'bf', 'ci']}
                                        /*onlyCountries={['tg', 'cd', 'ml', 'sn', 'bj', 'ne', 'bf', 'ci']}*/
                                        enableSearch={true}
                                        disabled={false}
                                        onChange={(e) => this.handlePassThingChange(e, 'whatsapp')}
                                    />
                                </div>
                                <div className="half-form-wrapper">
                                    <Button onClick={this.handleOpenNumberChange}>
                                        <CreateIcon/>Modifier mon numéro de connexion
                                    </Button>
                                    <Dialog
                                        className="dialog"
                                        open={this.state.openNumberChangeDialog}
                                        onClose={this.handleOpenNumberChange}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description">
                                        <IconButton aria-label="close" className="closeButton" onClick={this.handleCloseNumberChange}>
                                            <MdClose />
                                        </IconButton>
                                        <div className="change-container connexion-number">
                                            <DialogTitle id="alert-dialog-title">{"Renseignez votre nouveau numéro de connexion"}</DialogTitle>
                                            <DialogContent>
                                                <div className="dialog-container">
                                                    <div className="tel-input">
                                                        <PhoneInput
                                                            /*regions={'africa'}*/
                                                            country={'tg'}
                                                            containerClass='whatsapp-input'
                                                            placeholder={'Numéro de téléphone'}
                                                            value={this.state.numero}
                                                            defaultMask={'.. .. .. .. .. ..'}
                                                            preferredCountries={['tg', 'cd', 'ml', 'sn', 'bj', 'ne', 'bf', 'ci']}
                                                            /*onlyCountries={['tg', 'cd', 'ml', 'sn', 'bj', 'ne', 'bf', 'ci']}*/
                                                            enableSearch={true}
                                                            onChange={this.handleNumberChange}
                                                        />
                                                    </div>
                                                </div>
                                            </DialogContent>
                                            <DialogActions className="confirmation-button">
                                                <Button onClick={this.handleCloseNumberChange} color="primary">
                                                    Annuler
                                                </Button>
                                                <Button onClick={this.changeNumber} color="primary" autoFocus>
                                                    Suivant
                                                </Button>
                                            </DialogActions>
                                        </div>
                                    </Dialog>
                                </div>
                            </div>
                            <div className="dashboard-profile-container-infos-item">
                                <div className="half-form-wrapper">
                                    <Button onClick={this.handleOpenDeleteChange}>
                                        <DeleteIcon />Supprimer mon compte
                                    </Button>
                                    <Dialog
                                        className="dialog"
                                        open={this.state.openDeleteDialog}
                                        onClose={this.handleOpenDeleteChange}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description">
                                        <IconButton aria-label="close" className="closeButton" onClick={this.handleCloseDeleteChange}>
                                            <MdClose />
                                        </IconButton>
                                        <div className="change-container account-deletion">
                                            <DialogTitle id="alert-dialog-title">{"Suppression de compte"}</DialogTitle>
                                            <Formik
                                                initialValues={{ motif: "" }}
                                                validationSchema={formSchema}
                                                onSubmit={(values, actions) => {
                                                    //console.log('on submit deletion')
                                                    this.handleSubmitDeleteAccount(values)

                                                }}>
                                                {({values,errors, touched, handleChange,
                                                      handleBlur, handleSubmit, isSubmitting}) => (
                                                    <form onSubmit={handleSubmit}>
                                                        <DialogContent>
                                                            <div className="dialog-container">
                                                                <div className="dialog-container-item">
                                                                <p>Voulez-vous vraiment suppprimer votre compte ?</p>
                                                                </div>
                                                                <div className="dialog-container-item">
                                                                    <FormControl className={"select-category" + (errors.motif && touched.motif ? ' is-invalid' : '')}>
                                                                        <InputLabel id="motif-label">Motif de la suppression</InputLabel>
                                                                        <Select
                                                                            name="motif"
                                                                            id="motif-label"
                                                                            labelId="category-label"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            value={values.motif}
                                                                        >
                                                                            <MenuItem value="">Veuillez sélectionner une raison</MenuItem>
                                                                            <MenuItem value="J'ai trouver un autre site plus interessant">J'ai trouver un autre site plus interessant</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                    {errors.motif && touched.motif &&
                                                                    <p className="errorMessage">
                                                                        {errors.motif}
                                                                    </p>}
                                                                </div>
                                                            </div>
                                                        </DialogContent>
                                                        <DialogActions className="confirmation-button">
                                                            <Button onClick={this.handleCloseDeleteChange} color="primary">
                                                                Annuler
                                                            </Button>
                                                            <Button type="submit"  color="primary" autoFocus>
                                                                Oui
                                                            </Button>
                                                        </DialogActions>
                                                    </form>
                                                )}
                                            </Formik>
                                        </div>
                                    </Dialog>
                                </div>
                                <div className="half-form-wrapper">
                                    <Button onClick={this.handleOpenPassword}>
                                        <CreateIcon/>Modifier mon mot de passe
                                    </Button>
                                    <Dialog
                                        className="dialog"
                                        open={this.state.openPasswordDialog}
                                        onClose={this.handleOpenPassword}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description">
                                        <IconButton aria-label="close" className="closeButton" onClick={this.handleClosePassword}>
                                            <MdClose />
                                        </IconButton>
                                        <div className="change-container password-change">
                                            <DialogTitle id="dialog-title">{"Changer mon mot de passe"}</DialogTitle>
                                            <DialogContent>
                                                <div className="dialog-container">
                                                    <div className="dialog-container-item">
                                                        <TextField
                                                            name="oldPassword"
                                                            id="oldPassword"
                                                            label="Ancien mot de passe"
                                                            type="text"
                                                            variant="outlined"
                                                            className="oldPassword"
                                                            size="small"
                                                            value={this.state.oldPassword}
                                                            onChange={(e) => this.handlePassThingChange(e, 'oldPassword')}
                                                        />
                                                    </div>
                                                    <div className="dialog-container-item">
                                                        <TextField
                                                            name="newPassword"
                                                            id="newPassword"
                                                            label="Nouveau mot de passe"
                                                            type="text"
                                                            variant="outlined"
                                                            className="oldPassword"
                                                            size="small"
                                                            value={this.state.newPassword}
                                                            onChange={(e) => this.handlePassThingChange(e, 'newPassword')}
                                                        />
                                                    </div>
                                                    <div className="dialog-container-item">
                                                        <TextField
                                                            name="confirmationPassword"
                                                            id="confirmationPassword"
                                                            label="Confirmer le mot de passe"
                                                            type="text"
                                                            variant="outlined"
                                                            className="confirmationPassword"
                                                            size="small"
                                                            value={this.state.newPasswordconfirm}
                                                            onChange={(e) => this.handlePassThingChange(e, 'newPasswordconfirm')}
                                                        />
                                                    </div>
                                                </div>
                                            </DialogContent>
                                            <DialogActions className="confirmation-button">
                                                <Button onClick={this.handleClosePassword} color="primary">
                                                    Annuler
                                                </Button>
                                                <Button onClick={this.changePassword} color="primary" autoFocus>
                                                    Modifier
                                                </Button>
                                            </DialogActions>
                                        </div>
                                    </Dialog>
                                </div>
                            </div>
                            <div className="dashboard-profile-container-infos-item submit">
                                <Button onClick={this.userUpdate}>
                                    Mettre à jour les informations
                                    {this.state.updating ? (<CircularProgress size={20} color="info" />) : ('')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.user.currentUser,
        currentCountry: state.country.currentCountry,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOpen: (open) => {
            dispatch(setOpen({ open : open}));
        },
        setMessage: (message) => {
            dispatch(setMessage({ message : message}));
        },
        setBackColor: (background) => {
            dispatch(setBackColor({background: background}));
        },
        saveUser: (user) => {
            dispatch(saveUser({ user : user}));
        },
        changeOperation: (operation) => {
            dispatch(changeOperation({ operation : operation}));
        },
        setPreLoginData: (user) => {
            dispatch(setPreLoginData({ user : user}));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
