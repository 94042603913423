
import emailjs from '@emailjs/browser';

const serviceId = "service_1lcwcs9";
const templateId = "default_template";
const askTemplateId = "ask_template";
const welcomeTemplateId = "welcome_template";
const storeWelcomeTemplateId = "store_welcome_template";

const reportTemplateId = "signalement_template";


const publicKey = "Sz-iLrRnig6Ez-Git";



export async function  sendEmailToTaari(data){

    const templateParams = {
        name: 'James',
        notes: 'Check this out!',
      };
      
      emailjs
        .send(serviceId, templateId, templateParams, 
           publicKey,
        )
        .then(
          (response) => {
            console.log('SUCCESS!', response.status, response.text);
            return response
          },
          (err) => {
            console.log('FAILED...', err);
            return err
          },
        );
}


function getCurrentFormattedDate(format = 'YYYY-MM-DD') { 
  const now = new Date(); 
  const year = now.getFullYear(); 
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1 
  const day = String(now.getDate()).padStart(2, '0'); 
  switch (format) { 
    case 'YYYY-MM-DD': return `${day}-${month}-${year}`; 
    case 'MM/DD/YYYY': return `${day}-${month}-${year}`; 
    case 'DD-MM-YYYY': return `${day}-${month}-${year}`; 
    default: 
    throw new Error('Invalid date format'); } 
  }

export async function  sendAskEmailToUser(data){

  const templateParams = {
      message: data?.message,
      subject: data?.sujet,
      prenom: data?.prenom,
      user_email: data?.email,
      date: getCurrentFormattedDate()
    };
    return new Promise((resolve, reject) => {
      emailjs
        .send(serviceId, askTemplateId, templateParams, 
          publicKey,
        )
        .then(
          (response) => {
            //console.log('SUCCESS!', response.status, response.text);
            resolve(response)
          },
          (error) => {
            //console.log('FAILED...', err);
            reject(error)
          },
        );

      })
}


export async function  sendAskEmailToTaari(data){

  const templateParams = {
       content: `
       Prénom: ${data?.prenom}
       Email: ${data?.email}
       Téléphone: ${data?.telephone}
       Sujet: ${data?.sujet}
       Message: ${data?.message}
       Date: ${getCurrentFormattedDate()}
        `,
      subjet: 'Demande de contact',
      to_mail: 'aide@taari.co',
      from_mail: 'hello@taari.co',
      from_name: data?.prenom
        };
    return new Promise((resolve, reject) => {
      emailjs
        .send(serviceId, templateId, templateParams, 
          publicKey,
        )
        .then(
          (response) => {
            //console.log('SUCCESS!', response.status, response.text);
            resolve(response)
          },
          (error) => {
            //console.log('FAILED...', err);
            reject(error)
          },
        );

      })
}

export async function  sendAskEmailToUserAfterRegister(data){

  const templateParams = {
      to_email:data?.to_email,
      to_name:data?.to_name 
    
    };
    return new Promise((resolve, reject) => {
      emailjs
        .send(serviceId, welcomeTemplateId, templateParams, 
          publicKey,
        )
        .then(
          (response) => {
            console.log('SUCCESS!', response.status, response.text);
            resolve(response)
          },
          (error) => {
            console.log('FAILED...', error);
            reject(error)
          },
        );

      })
}

export async function  sendWelcomeEmailToStoreAfterRegister(data){

  const templateParams = {
      to_email:data?.to_email,
      to_name:data?.to_name 
    
    };
    return new Promise((resolve, reject) => {
      emailjs
        .send(serviceId, storeWelcomeTemplateId, templateParams, 
          publicKey,
        )
        .then(
          (response) => {
            console.log('SUCCESS!', response.status, response.text);
            resolve(response)
          },
          (error) => {
            console.log('FAILED...', error);
            reject(error)
          },
        );

      })
}



export async function  sendPostReportEmailToUser(data){

  const templateParams = {
      to_email: data?.to_email,
      first_name: data?.first_name,
      item_title: data?.item_title,
      subject: data?.subject,
      date: getCurrentFormattedDate()
    };
    return new Promise((resolve, reject) => {
      emailjs
        .send(serviceId, reportTemplateId, templateParams, 
          publicKey,
        )
        .then(
          (response) => {
            //console.log('SUCCESS!', response.status, response.text);
            resolve(response)
          },
          (error) => {
            //console.log('FAILED...', err);
            reject(error)
          },
        );

      })
}

export async function  sendPostReportEmailToTaari(data){

  const templateParams = {
       content: `
       Annonce: ${data?.item_title}
       Sujet: ${data?.subject}
       Message: ${data?.message}
       Lien de l'annonce: ${data?.item_link}
       Date: ${getCurrentFormattedDate()}

       Utilisateur
       Prénom: ${data?.first_name}
       Email: ${data?.email}
       Téléphone: ${data?.phone_number}
        `,
      subjet: "Signalement d'annonce",
      to_mail: 'signalements@taari.co',
      from_mail: 'hello@taari.co'
    };
    return new Promise((resolve, reject) => {
      emailjs
        .send(serviceId, templateId, templateParams, 
          publicKey,
        )
        .then(
          (response) => {
            //console.log('SUCCESS!', response.status, response.text);
            resolve(response)
          },
          (error) => {
            //console.log('FAILED...', err);
            reject(error)
          },
        );

      })
}