import React from "react";
import './shops.scss';
import Header from "../../containers/Header";
import Footer from "../../components/Footer";
import shopBanner from "../../imgs/shopBanner.png";
import {
    Button, CircularProgress
} from "@material-ui/core";
import { ShopItem } from "./shop";
import { filterShops, searchShops, searchShopsNumber , countShops } from '../../services/shop';
import { getTopCategories } from '../../services/category'
import { ShopSkeleton } from "../skeletons/ShopSkeleton"
import {connect} from "react-redux";
import ShopFilter from "./ShopFilter/ShopFilter";
import {citySelected} from '../../reducers/countryReducer';

export class Shops extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shops : [],
            categories : [],
            shopsInitializing : true,
            limit : 50,
            offset : 0,
            shopsLoading : false,
            countryId : props.currentCountry.id,
            total : 0,
            loadedCount : 0,
            filterCount: null,
            searchParams : {},
            searching: false,
            cursor: {limit : 15, offset : 0, currentCount : 0},
            shopLoading: false,
            isSearching: 1,
            searchObject: {},
        }
        this.currentCountry = props.currentCountry;
        this.loadShops = this.loadShops.bind(this);
        this.loadTopCategories = this.loadTopCategories.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }

    componentDidMount(){
        //countShops({country_id: this.currentCountry.id}).then(result => {
            //console.log('count shops', result)
            //this.setState({total: result.data});
        //});
        //this.loadShops();
        this.searchAd(
            {
                'limit': 15,
                'offset': 0,
                'country_id': this.currentCountry.id,
                'city_id': '',
                'zone_id': '',
                'search_text': '',
                'category_id': ''
              }
        )
        this.loadTopCategories();
    }

 
    loadShops(search = {}){
        const {limit, offset} = this.state;
        this.setState((state)=> ({ shopsInitializing : true }));
        filterShops({ country_id: this.currentCountry.id, limit: limit, offset: offset })
            .then(({data}) => {
                let { RESPONSEDATA } = data;
                //console.log('filter response', data)
                if (RESPONSEDATA) {
                    this.setState((state)=> ({ shops : RESPONSEDATA, loadedCount: RESPONSEDATA.length, total: RESPONSEDATA.length, offset: this.state.offset + 15 }));
                }
            })
            .catch(e=> {
                console.error(e);
            }).finally(()=>{
            this.setState((state)=> ({ shopsInitializing : false }));
        });
    }

    loadMore(){
        const {limit, offset, shops, isSearching, loadedCount, searchObject} = this.state;
        this.setState((state)=> ({ shopsLoading: true }));
        console.log('load more', isSearching)
        if (isSearching == 1) {
            filterShops({ country_id: this.currentCountry.id, limit: limit, offset: offset })
            .then(({data}) => {
                console.log('response from load more', data)

                const { RESPONSEDATA, STATUS } = data;
                if (STATUS) {
                    let res = [...shops, ...RESPONSEDATA];
                    let currentCount = loadedCount + RESPONSEDATA.length;
                    this.setState((state)=> ({ shops : res, loadedCount: currentCount,  offset: offset + 5 }));
                }
            })
            .catch(e=> {
                console.error(e);
                console.log('error from load more', e)
            }).finally(()=>{
            this.setState((state)=> ({ shopsLoading: false }));
        });
        } else if (isSearching == 2){
            searchShops(searchObject)
            .then(({data}) => {
                const { RESPONSEDATA, STATUS } = data;
                if (STATUS) {
                    let res = [...shops, ...RESPONSEDATA];
                    let currentCount = loadedCount + RESPONSEDATA.length;
                    this.setState((state)=> ({ shops : res, loadedCount: currentCount, searchObject: {...searchObject, offset: offset + 15 }}));
                }
            })
            .catch(e=> {
                console.error(e);
            }).finally(()=>{
            this.setState((state)=> ({ shopsLoading: false }));
        });
        }
    }

    loadTopCategories(){
        getTopCategories().then(({data})=>{
            let { categories } = data;
            this.setState((state) => ({categories: categories}));
        }).catch((e)=>{
            console.error(e);
        })
    }

    filterAd = async (body) => {
      body.offset = 0;
      var res = await searchShopsNumber(body);
      this.setState({filterCount: res.data});
    };

    searchAd = async (body) => {
      body.offset = 0;
      body.limit = 15;
      this.setState({searching: true, shopsInitializing:true});
      console.log(body)
      searchShops(body)
          .then(({data}) => {
              const { RESPONSEDATA, STATUS } = data;
              //console.log('search shops response', RESPONSEDATA, STATUS)
              //this.setState({searching:false})
              if (STATUS) {
                  body.offset = 15;
                  this.setState({isSearching: 2, shops: RESPONSEDATA, searchObject: body, loadedCount: RESPONSEDATA.length});
                  // window.location.reload();
                  // setSearch(true);
              } else {
                  this.setState({isSearching: 2, searchObject: body, shops: [], loadedCount: 0});
                //   setSearchResult([]);
                  // window.location.reload();
              }
          })
          .catch(e=> {
            console.log('search shops error', e)

              this.setState({isSearching: 1, shops: [], searchObject: {}, loadedCount: 0});
              console.error(e);
              // window.location.reload();
          }).finally(()=>{
          this.setState({searching: false, shopsInitializing:false});
      });
    } ;

    reload = () => {
        window.location.reload();
    };

    render() {
        return (
            <div>
                <Header />
                <div className="page-content">
                    <div className="shop">
                        <div className="shop-banner">
                            <img src={shopBanner} alt="shop-banner"/>
                        </div>

                        <div className="shop-container">

                            <div className="shop-container-search">
                                <ShopFilter
                                  searchAd={this.searchAd}
                                  filterAd={this.filterAd}
                                  setSearchShopNumber={number => this.setState({filterCount:number})}
                                  isSearching={this.state.isSearching}
                                  searchResult={this.state.shops}
                                  searchShopNumber={this.state.filterCount}
                                  searching={this.state.searching}
                                    citySelected={this.props.citySelected}
                                    reload={this.reload}
                                />
                            </div>
                            <div className="shop-container-small-title">
                               {/*   <p>
                                    <span>({
                                    this.state.isSearching == 1 ? this.state.total : this.state.filterCount})</span>Boutiques trouvées dans tout { this.props.currentCity ? (
                            <span style={{fontWeight: 'bold'}}>
                                " {this.props.currentCity.title}"
                            </span>
                        ) : (
                            <span style={{fontWeight: 'bold'}}>
                                " {this.props.currentCountry.name}"
                            </span>
                                 )}</p> */}

<p>
                                    <span>({
                                    this.state.shops.length})</span>Boutiques trouvées dans tout { this.props.currentCity ? (
                            <span style={{fontWeight: 'bold'}}>
                                " {this.props.currentCity.title}"
                            </span>
                        ) : (
                            <span style={{fontWeight: 'bold'}}>
                                " {this.props.currentCountry.name}"
                            </span>
                                 )}</p>

                                {/* <p><span>({this.state.shops.length})</span>Boutiques dans tous les secteurs, Tout le <span>{this.currentCountry.name}</span></p> */}
                            </div>
                            { this.state.shopsInitializing ? <ShopSkeleton count={10} />  :
                                <div className="shop-container-items">
                                    {
                                        this.state.shops.map((shop) => {
                                            return (
                                                <ShopItem key={shop.id} object={shop} />
                                            )
                                        })
                                    }
                                </div>  }
                            { !this.state.shopsInitializing &&
                            <div className="load-more-content">
                                <p> Vous avez vu {this.state.loadedCount} sur {this.state.isSearching == 1 ? this.state.total : this.state.filterCount} boutique(s)</p>
                                <Button onClick={this.loadMore} disabled={((this.state.loadedCount < (this.state.isSearching == 1 ? this.state.total : this.state.filterCount)) && !this.state.shopsLoading) ? false : true }>
                                    Voir plus de boutique
                                    {this.state.shopsLoading && <CircularProgress /> }
                                </Button>
                            </div> }
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentCountry: state.country.currentCountry,
        currentCity: state.country.currentCity,
    }};

const mapDispatchToProps = dispatch => {
    return {
        citySelected: (data) => {
            dispatch(citySelected({ city : data}));
        },
    }};


export default connect(mapStateToProps, mapDispatchToProps)(Shops);
