import React from "react";
import './profile.scss';
// import { adverts } from "../../data/advert";
import ReactWhatsapp from 'react-whatsapp';
import { connect } from 'react-redux';
import Advert from "../Advert/advert";
import {FaUserCircle} from "react-icons/fa";
import Header from "../../containers/Header";
import Footer from "../Footer";
import {FormControl, MenuItem, Select} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import profileViewer from "../../imgs/profile-viewer.jpg";
import {getShopById, getAdvertByShop} from "../../services/shop";
import { MdPhone } from "react-icons/all";
import {IoMdChatboxes} from "react-icons/io";
import Loading from "../Loading";
import { Redirect } from 'react-router-dom';
import {staticApiUrl} from "../../utils/axios";

let baseUrl = `${staticApiUrl}/userimages/`;

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            limit: 15,
            offset: 0,
            adverts: [],
            total: 0,
            shopLoading: true,
            sort: 'none',
            showChat: false,
        }
    }

    componentDidMount(){
        this.loadAdvert(this.props.match.params.userId);
    }

    loadAdvert = (userId) => {
        this.setState((state)=> ({ shopLoading : true}));
        getShopById({user_id: userId}).then(({data}) => {
            let { RESPONSEDATA } = data;
            this.setState((state)=> ({ user : RESPONSEDATA[0]}));
            var obj = { user_id: userId, country_id: this.props.currentCountry.id, limit: this.state.limit, offset: this.state.offset };
            getAdvertByShop(obj)
            .then(({data}) => {
                    let { RESPONSEDATA } = data;
                    let res = this.state.adverts.slice();
                    if (RESPONSEDATA) {
                        RESPONSEDATA.forEach((shop) => res.push(shop));
                        this.setState((state)=> ({ adverts : res, total:  res.length }));
                    }
                })
                .catch(e=> {
                    console.error(e);
                }).finally(()=>{
                    this.setState((state)=> ({ shopLoading : false}));
                });
        }).catch(e=> {
            console.error(e);
        }).finally(()=>{
        });
    }
    
    sorting = (event) => {
        this.setState({ sort: event.target.value});
        this.sortFunction(event.target.value);
    }

    sortFunction = (type) => {
        var tab = [];
        let arr = [...this.state.adverts];

        if (type == 1) {
            tab = arr.sort(function(a,b){
                return new Date(a.created_at) - new Date(b.created_at);
            });
        } else if (type == 2) {
            tab = arr.sort(function(a,b){
                return new Date(b.created_at) - new Date(a.created_at);
            });
        }
        else if (type == 3) {
            tab = arr.sort(function(a,b){
                return parseInt(a.price) - parseInt(b.price);
            });
        } else if (type == 4) {
            tab = arr.sort(function(a,b){
                return parseInt(b.price) - parseInt(a.price);
            });
        }
        this.setState({adverts: tab});
    };

    goToChat = () => {
        this.setAdvertToChat(this.props.advert);
        this.setState({showChat: true});
    };

    render() {
        const data = this.state.adverts;
        console.warn(this.state.user);
        return (
            <div>
                <Header />
                {this.state.showChat && <Redirect to="/dashboard/messages" />}
                { this.state.shopLoading && <Loading />}
                {
                    this.state.adverts && (
                        <div className="profile-page">
                            <div className="profile-page-back">
                                <img src={profileViewer} alt="image"/>
                            </div>
                            <div className="profile-page-header">
                                <div className="profile-page-header-inner">
                                    <div className="profile-page-header-inner-image">
                                        {
                                            this.state.user.image && this.state.user.image.length !== 0 ? (
                                                <img src={baseUrl+this.state.user.image[0].filename}  alt="App Store"/>
                                            ) : (
                                                <FaUserCircle />
                                            )
                                        }
                                    </div>
                                    <div className="profile-page-header-inner-infos">
                                        <h4>{this.state.user.first_name} {this.state.user.last_name}</h4>
                                        <div className="profile-page-header-inner-infos-inner">
                                            <ul>
                                                <li>Lomé, <span>(Togo)</span></li>
                                                <li>Membre depuis <span>{this.state.user.created_at}</span></li>
                                            </ul>
                                            <div className="profile-page-header-inner-infos-inner-contact">
                                                <ReactWhatsapp className="buttons-button green" number={"+" + this.state.user.phone_indicatif + this.state.user.phone} message={`Bonjour ${this.state.user.first_name} ${this.state.user.last_name}`}>
                                                    Message Whatsapp
                                                </ReactWhatsapp>
                                                {/* <Button className="details-page-scroll-header-right-button upper outline" onClick={() => this.goToChat()}>
                                                    <IoMdChatboxes />Chat
                                                </Button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="profile-page-items">
                                <div className="profile-page-items-header">
                                    <div className="profile-page-items-header-left">
                                        <p><span>({this.state.total})</span> Annonces en ligne</p>
                                    </div>
                                    <div className="profile-page-items-header-right">
                                        <div className="profile-page-items-header-right-tri">
                                            <p>Tri :</p>
                                        </div>
                                        <FormControl>
                                            <Select
                                                displayEmpty
                                                inputProps={{'aria-label': 'Without label'}}
                                                onChange={this.sorting}
                                                value={this.state.sort}
                                            >
                                                <MenuItem value="none">Aucune selection</MenuItem>
                                                <MenuItem value="1">Moins récent</MenuItem>
                                                <MenuItem value="2">Plus récent</MenuItem>
                                                <MenuItem value="3">Moins cher</MenuItem>
                                                <MenuItem value="4">Plus cher</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="profile-page-items-content">
                                    {
                                        data.map((advert)=> {
                                            return (
                                                <Advert key={advert.id} object={advert} />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentCountry: state.country.currentCountry,
    }
};

export default connect(mapStateToProps, null)(Profile);
