import { connect } from 'react-redux';
import Advertisements from '../components/Advertisements';
import {
  setSearchResult,
  setIsSearching,
  setSearchResultNumber,
  setSearchObject
} from '../reducers/advertisementReducer';
import { categorySelected } from '../reducers/categoryReducer';

const mapStateToProps = state => ({
  currentCountry: state.country.currentCountry,
  currentCategory: state.category.currentCategory,
  currentCity: state.country.currentCity,
  searchResult: state.advertisement.searchResult,
  isSearching: state.advertisement.isSearching,
  searchResultNumber: state.advertisement.searchResultNumber,
});

const mapDispatchToProps = dispatch => ({
  setSearchResult: (data) => {
    dispatch(setSearchResult({ searchResult : data}));
  },
  setIsSearching: (data) => {
    dispatch(setIsSearching({ isSearching : data}));
  },
  setSearchObject: (data) => {
    dispatch(setSearchObject({ searchObject : data}));
  },
  setSearchResultNumber: (data) => {
    dispatch(setSearchResultNumber({ searchResultNumber : data}));
  },
  categorySelected: (data) => {
    dispatch(categorySelected({ category : data}));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Advertisements);