import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    open : false,
    message : "",
    background : "",
};

const snackBarSlice = createSlice({
    name : 'snackbar',
    initialState: initialState,
    reducers : {
        setOpen : (state, action) => {
            state.open = action.payload.open
        },

        setMessage : (state, action) => {
            state.message = action.payload.message;
            state.open = true;
        },

        setBackColor : (state, action) => {
            state.background = action.payload.background;
            state.open = true;
        }
    }
});

export const { setOpen, setMessage, setBackColor } = snackBarSlice.actions;
export default snackBarSlice.reducer;
