import { connect } from 'react-redux';
import FooterCategory from '../components/Footer/Category';

import { getAllCategories, categorySelected } from '../reducers/categoryReducer';
import { openCountryPopup } from '../reducers/countryReducer';
import {
  setSearchResult,
  setIsSearching,
  setSearchResultNumber,
  setSearchObject
} from '../reducers/advertisementReducer';

const mapStateToProps = state => ({
  categories: state.category.categories,
  currentCountry: state.country.currentCountry,
});

const mapDispatchToProps = dispatch => ({
  openCountryPopup: () => {
    dispatch(openCountryPopup());
  },
  getAllCategories: () => {
    dispatch(getAllCategories());
  },
  categorySelected: (data) => {
    dispatch(categorySelected({ category : data}));
  },
  setSearchResult: (data) => {
    dispatch(setSearchResult({ searchResult : data}));
  },
  setIsSearching: (data) => {
    dispatch(setIsSearching({ isSearching : data}));
  },
  setSearchObject: (data) => {
    dispatch(setSearchObject({ searchObject : data}));
  },
  setSearchResultNumber: (data) => {
    dispatch(setSearchResultNumber({ searchResultNumber : data}));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FooterCategory);