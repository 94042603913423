import { connect } from 'react-redux';
import Countries from '../components/Homepage/Countries';

import { getAllCountries, countrySelected } from '../reducers/countryReducer';

const mapStateToProps = state => ({
  countries: state.country.countries,
  countriesLoaded: state.country.countriesLoaded,
});

const mapDispatchToProps = dispatch => ({
  getAllCountries: () => {
    dispatch(getAllCountries());
  },
  countrySelected: (country, redirect) => {
    dispatch(countrySelected({ country : country, redirect : redirect}));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Countries);