import { connect } from 'react-redux';
import MyAdverts from '../components/User/My Adverts/MyAdverts';

const mapStateToProps = state => ({
  currentCountry: state.country.currentCountry,
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MyAdverts);