import React from 'react';
import cameraPlusOutline from '@iconify/icons-mdi/camera-plus-outline';
import Icon from "@iconify/react";
import { MdClear } from "react-icons/all";
// const imageThumbnail = require('image-thumbnail');

export class PictureInput extends React.Component {

    constructor(props){
        super(props);
        this.fileInput = React.createRef();
        this.index = props.index;
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(){
        // imageThumbnail('/9j/4AAQSkZJRgABAQEBLAEsAAD/4QEERXhpZgAA==')
        // .then(thumbnail => { console.log(thumbnail) })
        // .catch(err => console.error(err));
        let file = (this.fileInput.current.files[0]);
        if(file != undefined){
            let reader = new FileReader();
            let ctx = this;
            reader.onload = function(e) {
                ctx.props.updateInput(ctx.props.index, e.target.result);
            };
            reader.readAsDataURL(file);
        }
    }

    // Creates a thumbnail fitted insize the boundBox (w x h)
    generateThumbnail(file, boundBox){
        if (!boundBox || boundBox.length != 2){
        throw "You need to give the boundBox"
        }
        var scaleRatio = Math.min(...boundBox) / Math.max(file.width, file.height)
        var reader = new FileReader();
        var canvas = document.createElement("canvas")
        var ctx = canvas.getContext('2d');
    
        return new Promise((resolve, reject) => {
        reader.onload = function(event){
            var img = new Image();
            img.onload = function(){
                var scaleRatio = Math.min(...boundBox) / Math.max(img.width, img.height)
                let w = img.width*scaleRatio
                let h = img.height*scaleRatio
                canvas.width = w;
                canvas.height = h;
                ctx.drawImage(img, 0, 0, w, h);
                return resolve(canvas.toDataURL(file.type))
            }
            img.src = event.target.result;
        }
        reader.readAsDataURL(file);
        })
    }

    render (){
        return (
            <div className="image-picker-item">
                <div className="image-picker-item-inner">
                    <input disabled={this.props.enabledInput(this.props.index)} type='file' id={"imageUpload"+this.props.index} accept=".png, .jpg, .jpeg"
                           ref={this.fileInput}  onChange={this.handleChange}/>
                    <label htmlFor={"imageUpload"+this.props.index}>
                        <Icon icon={cameraPlusOutline}/>
                        {(this.props.index === 0) && <span>Photo <br/>principale</span>}
                        {(this.props.index > 0) && <span>Photo {this.props.index+1}</span>}
                    </label>
                </div>
                {(this.props.objet.file != undefined) && <div className="image-picker-item-image">
                    <img src={this.props.objet.file} />
                    <button type="button" onClick={()=>this.props.deleteInput(this.props.index)}><MdClear/></button>
                    {(this.props.index === 0) && <div className="hover-pricipale-indication">
                        <p>Photo principale</p>
                    </div>}
                </div>}
            </div>
        );
    }

}
