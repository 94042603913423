//export const apiUrl = `https://taaridev.esdev-volontariat.org/index.php/api`;
//export const staticApiUrl = `https://taaridev.esdev-volontariat.org/uploads`;
export const apiUrl = `https://api.esdev-volontariat.org/index.php/api`;
export const staticApiUrl = `https://api.esdev-volontariat.org/uploads`;



export default {
  apiUrl,
  staticApiUrl
};
