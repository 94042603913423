import React from "react";
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import './Dashboard.scss';
import Header from "../../../containers/Header";
import MyAdverts from "../../../containers/MyAdvert";
import MyMessages from "../My Messages/MyMessages";
import MyProfile from "../My Profile/MyProfile";
import MySavedAdverts from "../My Saved Adverts/MySavedAdverts";
import MyShop from "../My Shop/MyShop";
import MySavedSearch from "../My Saved Search/MySavedSearch";
import {AdvertsDetails} from "../Adverts Details/AdvertsDetails";
import { Icon } from '@iconify/react';
import baselineViewList from '@iconify/icons-ic/baseline-view-list';
import ForumIcon from '@material-ui/icons/Forum';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import appMenu from '@iconify/icons-fe/app-menu';
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SpeedDial from "@material-ui/lab/SpeedDial";
import {connect} from "react-redux";
import Badge from "@material-ui/core/Badge";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            OpenSpeedDial: false,
        }
        this.handleCloseSpeedDial = this.handleCloseSpeedDial.bind(this);
        this.handleOpenSpeedDial = this.handleOpenSpeedDial.bind(this);
    }

    handleCloseSpeedDial = () => {
        this.setState((state) => ({ OpenSpeedDial : false }));
    };

    handleOpenSpeedDial = () => {
        this.setState((state) => ({ OpenSpeedDial : true }));
    };

    render() {
        const actions = [
            { icon: <NavLink to={this.props.currentUser.type === '1' ? `/dashboard/adverts` : `/dashboard/shop`}><Icon icon={baselineViewList} /></NavLink>, name: 'Mes annonces' },
            { icon: <NavLink to={`/dashboard/saved-adverts`}><FavoriteIcon className="favorite"/></NavLink>, name: 'Annonces sauvegardées' },
            { icon: <NavLink to={`/dashboard/saved-search`}><FindInPageIcon className="search-saved"/></NavLink>, name: 'Mes recherches sauvegardées' },
            // { icon: <NavLink to={`/dashboard/shop`}><Icon icon={bxsStore} /></NavLink>, name: 'Ma boutique' },
            { icon: <NavLink to={`/dashboard/messages`}><ForumIcon className="message"/></NavLink>, name: 'Mes messages (4)'},
            { icon: <NavLink to={`/dashboard/profile`}><PermContactCalendarIcon className="profile" /></NavLink>, name: 'Mon profil' },
        ];
        return(
            <div>
                <Header />
                <div className="dashboard">
                    <div className="dashboard-sidebar">
                        <div className="dashboard-sidebar-menu">
                            <ul>
                                <li>
                                    <NavLink to={`/dashboard/adverts`}>
                                        <Icon icon={baselineViewList} />
                                        <span>Mes annonces</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/dashboard/saved-adverts`}>
                                        <FavoriteIcon className="favorite"/>
                                        <span>Annonces sauvegardées</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/dashboard/saved-search`}>
                                        <FindInPageIcon className="search-saved"/>
                                        <span>Mes recherches sauvegardées</span>
                                    </NavLink>
                                </li>
                                {/*{
                                    this.props.currentUser.type === '2' && (
                                        <li>
                                            <NavLink to={`/dashboard/shop`}>
                                                <Icon icon={bxsStore} />
                                                <span>Ma boutique</span>
                                            </NavLink>
                                        </li>
                                    )
                                }*/}
                                <li>
                                    <NavLink to={`/dashboard/messages`}>
                                        <ForumIcon className="message"/>
                                        <span>Mes messages</span>
                                        {this.props.countMessages > 0 && <span className="badge">{this.props.countMessages}</span>}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/dashboard/profile`}>
                                        <PermContactCalendarIcon className="profile" />
                                        <span>Mon profil</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="dashboard-content">
                        <Switch>
                            <Route exact path="/dashboard/adverts" component={MyAdverts} />
                            <Route path="/dashboard/saved-adverts" component={MySavedAdverts} />
                            <Route path="/dashboard/shop" component={MyShop} />
                            <Route path="/dashboard/messages" component={MyMessages} />
                            <Route path="/dashboard/profile" component={MyProfile} />
                            <Route path="/dashboard/saved-search" component={MySavedSearch} />
                            {/* Details advert */}
                            <Route path="/dashboard/details/:advertName/:advertId" component={AdvertsDetails} />
                            {/* Si la route n'existe pas ou n'est pas référencée */}
                            <Route>
                                <Redirect to="/not-found" />
                            </Route>
                        </Switch>
                    </div>
                    <SpeedDial
                        ariaLabel="SpeedDial menu"
                        /*hidden={hidden}*/
                        icon={<Icon icon={appMenu} />}
                        onClose={this.handleCloseSpeedDial}
                        onOpen={this.handleOpenSpeedDial}
                        open={this.state.OpenSpeedDial}>
                        {actions.map((action) => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                tooltipOpen
                                onClick={this.handleCloseSpeedDial}
                                title={action.name}/>
                        ))}
                    </SpeedDial>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
    countMessages: state.message.countMessages,
});

export default connect(mapStateToProps, null)(Dashboard);
