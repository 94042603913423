import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  advertisements: [],
  advertisement: null,
  advertisementsSearchFilter : null,
  advertisementsLoaded: false,
  advertisementLoaded: false,
  brands: [],
  models: [],
  createAdvertisement: {
    title: '',
    desc: '',
  },
  selectedAdvert: {},
  advert: {},
  searchResult: [],
  isSearching: 1,
  searchObject: {},
  searchObjectName: {},
  searchResultNumber: null,
  editObject: null,
  isEditing: false,
  showSearchDialog: false,
  savedAdverts: []
};

const advertisementSlice = createSlice({
  name : 'advertisement',
  initialState : initialState,
  reducers : {
    getAdvertisementById : (state, action)=> {

    },

    getAdvertisementByCountryId : (state, action)=> {

    },

    getAdvertisements : (state, action)=> {
      
    },

    getAllAdvertisements : (state, action)=> {

    },
    
    getAdvertisement : (state, action)=> {

    },
    
    resetAdvertisement : (state, action) => {
      state.advertisement = null
    },

    resetAdvertisements : (state, action) => {
      state.advertisements = []
    },

    advertisementsLoading : (state, action) => {
      state.advertisementsLoaded = action.payload.status
    },

    advertisementLoading : (state, action) => {
      state.advertisementLoaded = action.payload.status
    },

    saveAdvertisements : (state, action) => {
      state.advertisements =  [ ...action.payload.advertisements ];
    },

    saveAdvertisement : (state, action) => {
      state.advertisement = action.payload.advertisement
    },

    setAdvert : (state, action) => {
      state.advert = action.payload.advert
    },

    setSearchResult : (state, action) => {
      state.searchResult = action.payload.searchResult
    },

    setIsSearching : (state, action) => {
      state.isSearching = action.payload.isSearching
    },

    setSearchObject : (state, action) => {
      state.searchObject = action.payload.searchObject
    },

    setSearchObjectName : (state, action) => {
      state.searchObjectName = action.payload.searchObjectName
    },

    setSearchResultNumber : (state, action) => {
      console.log('INSIDE setSearchResultNumber', action.payload.searchResultNumber)
      state.searchResultNumber = action.payload.searchResultNumber

    },

    setSelectedAdvert : (state, action) => {
      state.selectedAdvert = action.payload.selectedAdvert
    },

    saveBrands : (state, action) => {
      state.brands = action.payload.brands
    },

    saveModels : (state, action) => {
      state.models = action.payload.models
    },

    updateAdvertisementsSearchFilter : (state, action) => {
      state.advertisementsSearchFilter = action.payload.advertisementsSearchFilter
    },

    setIsEditing : (state, action) => {
      state.isEditing = action.payload.isEditing
    },

    setEditObject : (state, action) => {
      state.editObject = action.payload.editObject
    },

    setShowSearchDialog : (state, action) => {
      state.showSearchDialog = action.payload.showSearchDialog
    },

    setSavedAdvertisements : (state, action) => {
      state.savedAdverts =  [ ...action.payload.savedAdverts ];
    },
  }
});

export const {advertisementsLoading, advertisementLoading, saveAdvertisement, saveAdvertisements,
                resetAdvertisements, resetAdvertisement, getAdvertisements, getAdvertisementById, getAllAdvertisements, 
                  getAdvertisement, getAdvertisementByCountryId, updateAdvertisementsSearchFilter, saveBrands, setSelectedAdvert, setAdvert, setSearchResult, setIsSearching, setSearchObject, setSearchObjectName, setSearchResultNumber, setIsEditing, setEditObject, setShowSearchDialog, setSavedAdvertisements } = advertisementSlice.actions;

export default advertisementSlice.reducer;
