import { connect } from 'react-redux';
import App from '../components/App';
import { setMessage, setOpen } from '../reducers/snackbarReducer';

const mapStateToProps = state => ({
  currentCountry: state.country.currentCountry,
  isAuthenticated : state.user.isAuthenticated,
});

const mapDispatchToProps = dispatch => ({
  setSnackBarMessage: (message) => {
    dispatch(setMessage({message : message}));
  },
  setSnackBarOpen: (open)=>{
    dispatch(setOpen({open : open}));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(App);