import React from 'react';
import fr from 'react-phone-input-2/lang/fr.json';
import {Button, Checkbox, FormControlLabel, TextField,} from '@material-ui/core';
import VisibilityIcon from "@material-ui/icons/Visibility";
import {NavLink, Redirect} from 'react-router-dom';
import 'react-phone-input-2/lib/material.css';
import PhoneInput from 'react-phone-input-2';
import {useFormik} from 'formik';
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {setBackColor, setMessage, setOpen} from "../../reducers/snackbarReducer";
import CircularProgress from "@material-ui/core/CircularProgress";
import {createUser} from "../../services/users";
import { useRef, useState } from 'react'

import {setPreLoginData, changeOperation} from "../../reducers/userReducer";
import ReCAPTCHA from 'react-google-recaptcha'


const SignupForm = ({currentCountry}) => {
    const recaptcha = useRef()


    const dispatch = useDispatch();

    let [redirect, setRedirect] = React.useState(null);

    let countries = useSelector(state => state.country.countries);

    let defaultCountrySlug = currentCountry.slug;

    // On définit de manière dynamique le placeholder des inputs
    // des téléphones (la lib utilisée ne le permet pas...)
    setTimeout(() => {
        const phoneInputContainers = document.querySelectorAll(':scope .react-tel-input');
        phoneInputContainers.forEach((container) => {
            const phoneInput = container.querySelector('input');
            const { placeholder } = phoneInput;
            const inputLabel = container.querySelector('.flag-dropdown');
            inputLabel.dataset.placeholder = placeholder;
        });
    });

    const togglePassword = ({ currentTarget }) => {
        currentTarget.classList.toggle('active');
        const passwordInput = document.getElementById('password');

        if (currentTarget.classList.contains('active')) {
            passwordInput.type = 'text';
        }
        else {
            passwordInput.type = 'password';
        }
    }



    const formik = useFormik({
        initialValues: {
            first_name: '',
            phone: '',
            phone_indicatif: '',
            whatsapp: '',
            whatsapp_indicatif: '',
            password: '',
            email: '',
            last_name: null,
            zone_id: currentCountry.cities[0].zones[0].id,
            city_id: currentCountry.cities[0].id,
            country_id: null,
            type: 1,
            pro_rs: null,
            po_rc: null,
            horaires: null,
            category_id: null,
            activity_title: null,
            activity_description: null,
            address: null,
            images: null,
            acceptTerms: false
        },
        validationSchema: Yup.object({
            first_name: Yup.string()
                .required('Ce champ est requis'),
            // email: Yup.string()
            //     .email('Adresse Email invalide')
            //     .required('Ce champ est requis'),
            phone: Yup.string()
                .min(8, "Au min 8 caractères").required("Ce champ est requis"),
            whatsapp: Yup.string()
                .min(8, "Au min 8 caractères"),
            password: Yup.string()
                .required('Ce champ est requis'),
            acceptTerms: Yup.bool()
                .oneOf([true], 'L\'acceptation des termes et conditions sont requises'),
        }),

        onSubmit: (values, actions) => {

         
                // make form submission
                let user = {...values};
                let p = values.phone_indicatif.length;
                user.phone = user.phone.substring(p - 0);

                let w = values.whatsapp_indicatif.length;
                user.whatsapp = user.whatsapp.substring(w - 0);
                user.country_id = currentCountry.id;
                delete user.acceptTerms;

                createUser(user).then(({data})=> {
                    if (data.RESPONSECODE == 200) {
                        // dispatch(setMessage({message : data.RESPONSEMESSAGE}));
                        dispatch(setPreLoginData({ user : {...user ,...data?.RESPONSEDATA} }));
                        dispatch(changeOperation({ operation : 0 }));
                        // dispatch(setOpen({open : true}));
                        // send email 
                        if(values?.email){
                            
                            //sendAskEmailToUserAfterRegister({email:values.email})
                        
                        }
                        formik.resetForm();
                        actions.setSubmitting(false);
                        setRedirect("/number-confirmation");

                    } else {
                        dispatch(setMessage({message : data.RESPONSEMESSAGE}));
                        dispatch(setOpen({open : true}));
                        actions.setSubmitting(false);
                        dispatch(setBackColor({background: "red"}));
                    }
                }).catch((e)=>{
                    dispatch(setMessage({message : "Erreur lors de la création"}));
                    dispatch(setOpen({open : true}));
                    console.error(e);
                }).finally((e)=> {
                })

            



        },
    });

    console.log('SITE', process.env)

    if(redirect)
        return <Redirect to={redirect}/>

    return (
        <form className="signup-form" onSubmit={formik.handleSubmit}>
            <div className="logo-font">
                <h3>Taari</h3>
            </div>
            <strong className="signup-form-title">Créer votre compte Taari</strong>
            <div className="signup-form-line first-line">
                <div className="half-form-wrapper">
                    <TextField
                        name="first_name"
                        id="firstName"
                        label="Prénom"
                        type="text"
                        variant="outlined"
                        className={"firstname" + (formik.errors.first_name && formik.touched.first_name ? ' is-invalid' : '')}
                        size="small"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.first_name}
                    />
                    {formik.touched.first_name && formik.errors.first_name ? (
                        <p className="errorMessage">{formik.errors.first_name}</p>
                    ) : null}
                </div>
                <div className="half-form-wrapper">
                    <TextField
                        name="email"
                        id="email"
                        label="Adresse e-mail (facultative)"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        variant="outlined"
                        className={"email" + (formik.errors.email && formik.touched.email ? ' is-invalid' : '')}
                        size="small"
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <p className="errorMessage">{formik.errors.email}</p>
                    ) : null}
                </div>
            </div>
            <div className="full-form-wrapper tel-input">
                <PhoneInput
                    inputProps={{id : "phoneNumber", name : "phone"}}
                    onChange={(phone, data) => {
                        formik.setFieldValue('phone', phone);
                        formik.setFieldValue('phone_indicatif', ""+data.dialCode);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    localization={fr}
                    /*regions={'africa'}*/
                    country={defaultCountrySlug}
                    searchPlaceholder={'Rechercher'}
                    countryCodeEditable={false}
                    containerClass={'phone-input' + (formik.touched.phone && formik.errors.phone ? ' is-invalid' : '')}
                    placeholder={'Numéro de téléphone'}
                    defaultMask={'.. .. .. .. .. ..'}
                    preferredCountries = {
                        countries.map((country) => (
                            country.slug
                        ))
                    }
                    /*onlyCountries={['tg', 'cd', 'ml', 'sn', 'bj', 'ne', 'bf', 'ci']}*/
                    enableSearch={true}
                />
                {formik.touched.phone && formik.errors.phone ? (
                    <p className="errorMessage">{formik.errors.phone}</p>
                ) : null}
            </div>
            <div className="full-form-wrapper tel-input">
                <PhoneInput
                    inputProps={{id : "whatsappPhoneNumber", name : "whatsapp"}}
                    onChange={(phone, data) => {
                        formik.setFieldValue('whatsapp_indicatif', ""+data.dialCode);
                        formik.setFieldValue('whatsapp', phone);

                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.whatsapp}
                    localization={fr}
                    /*regions={'africa'}*/
                    country={defaultCountrySlug}
                    searchPlaceholder={'Rechercher'}
                    countryCodeEditable={false}
                    containerClass={'whatsapp-input' + (formik.errors.whatsapp && formik.touched.whatsapp ? ' is-invalid' : '')}
                    placeholder={'Numéro de téléphone Whatsapp'}
                    defaultMask={'.. .. .. .. .. ..'}
                    preferredCountries = {
                        countries.map((country) => (
                            country.slug
                        ))
                    }
                    /*onlyCountries={['tg', 'cd', 'ml', 'sn', 'bj', 'ne', 'bf', 'ci']}*/
                    enableSearch={true}
                />
                {formik.touched.whatsapp && formik.errors.whatsapp ? (
                    <p className="errorMessage">{formik.errors.whatsapp}</p>
                ) : null}
            </div>
            <div className="signup-form-line second-line">
                <div className="full-form-wrapper">
                    <TextField
                        name="password"
                        id="password"
                        label="Mot de passe"
                        type="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        variant="outlined"
                        size="small"
                        className={(formik.errors.password && formik.touched.password ? ' is-invalid' : '')}
                    />
                    <span className="show-password" onClick={togglePassword}><VisibilityIcon /></span>
                    {formik.touched.password && formik.errors.password ? (
                        <p className="errorMessage">{formik.errors.password}</p>
                    ) : null}
                </div>
            </div>
            <div className="full-form-wrapper cgu">
                <FormControlLabel
                    control={
                        <Checkbox
                            id="acceptTerms"
                            name="acceptTerms"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.acceptTerms}
                            color="primary"
                            className={'cgu' + (formik.errors.acceptTerms && formik.touched.acceptTerms ? ' is-invalid' : '')}
                        />
                    }
                    label={<label htmlFor="acceptTerms">J'accepte les <NavLink to="/conditions-generales">Conditions Générales d'Utilisation</NavLink></label>} />
                {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                    <p className="errorMessage">{formik.errors.acceptTerms}</p>
                ) : null}
            </div>
            <div className="signup-form-line last-line">
                <div className="half-form-wrapper">
                    <NavLink to="/login">Me connecter</NavLink>
                </div>
{/*                 <ReCAPTCHA sitekey={process.env.RC_SITE_KEY} />
 */}                <div className="half-form-wrapper">
                    <Button type="submit" className="signup-form-button" disabled={formik.isSubmitting} variant="contained" color="primary">
                        Suivant
                        {
                            formik.isSubmitting ?
                                (<CircularProgress />) : ('')
                        }
                    </Button>
                </div>
            </div>
        </form>
    )
};

export default SignupForm;
