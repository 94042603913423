import { connect } from 'react-redux';
import FooterBanner from '../components/Footer/FooterBanner';

import {
  openCountryPopup,
} from '../reducers/countryReducer';

const mapStateToProps = state => ({
  country: state.country.currentCountry,
  popupOpened: state.country.popups.country.opened,
});

const mapDispatchToProps = dispatch => ({
  openCountryPopup: () => {
    dispatch(openCountryPopup());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FooterBanner);