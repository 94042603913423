import { connect } from 'react-redux';
import Login from '../components/Login/LoginForm';
import { changeOperation } from '../reducers/userReducer';

const mapStateToProps = state => ({
    currentCountry : state.country.currentCountry
});

const mapDispatchToProps = dispatch => ({
    changeOperation: () => {
        dispatch(changeOperation());
      },
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
