import axios from 'axios';

import {
  apiUrl,
} from '../utils/axios';

export function getAllCategories(){
    return axios.get(`${apiUrl}/get_categories_with_sub`);
}

export function getTopCategories(){
  return axios.get(`${apiUrl}/top_categories`);
}

export function getSubCategories(topCategoryId){
  return axios.get(`${apiUrl}/sub_categories/${topCategoryId}`);
}

export function getCategoryById(id){
  return axios.get(`${apiUrl}/categories/${id}`);
}

export function getCategorySubs(id){
    return axios.get(`${apiUrl}/get_categ_sub_category/${id}`);
}