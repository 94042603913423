import React from 'react';
import {
  NavLink,
} from 'react-router-dom';

import appstore from '../../imgs/appstore.svg';
import playstore from '../../imgs/google-play.svg';

import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaChevronDown,
} from 'react-icons/fa';

import CountryDropdown from '../../containers/HomepageCountryPopup';
import {staticApiUrl} from "../../utils/axios";

const FooterBanner = ({
  openCountryPopup,
  country,
  popupOpened,
}) => {
  const manageCountryPopup = (e) => {
    e.preventDefault();

    if (!popupOpened) {
      openCountryPopup();
    }
  };

  let url = `${staticApiUrl}/countryimages/`;

  let filename = "no-image";
  if (country && country.images[0] !== undefined) {
      filename = country.images[0].filename;
  }
  return (
    <div className="footer-banner">
      <div className="container">
        <ul className="footer-banner-nav no-mobile">
          <li>
            <NavLink className="hover-underline" to="/aide-et-support">Aide & support</NavLink>
          </li>
          <li>
            <NavLink className="hover-underline" to="/decouvrir-taari">Découvrir Taari</NavLink>
          </li>
          <li>
            <NavLink className="hover-underline" to="/regles-de-diffusion">Règles de diffusion</NavLink>
          </li>
          <li>
            <NavLink className="hover-underline" to="/conditions-generales">Conditions générales d'utilisation</NavLink>
          </li>
          <li>
            <NavLink className="hover-underline" to="/not-found">Média</NavLink>
          </li>
          <li>
            <NavLink className="hover-underline" to="/not-found">Nous rejoindre</NavLink>
          </li>
          <li>
            <NavLink className="hover-underline" to="/donnees-privees">Données privées</NavLink>
          </li>
          <li>
            <NavLink className="hover-underline" to="/not-found">Publicité</NavLink>
          </li>
        </ul>
        <hr className="short no-mobile" />
        <div className="footer-banner-countries mobile">
          <ul>
            <li
              onClick={manageCountryPopup}
              className="footer-banner-countries-country"
            >
              { (country && country.images[0] !== undefined) && (
                <>
                  <img src={url + filename} alt={country.name} />
                  {country.name}
                </>
              ) }
              { (!country) && (<span>Choisir un pays</span>) }
              <FaChevronDown />
              { (popupOpened) && (<CountryDropdown />) }
            </li>
          </ul>
        </div>
        <hr className="medium mobile" />
        <div className="footer-banner-social">
          <div className="footer-banner-social-left">
            <strong>Téléchargez l'app</strong>
            <a href="#a">
              <img src={appstore} alt="App Store" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.taari.app" target="_blank">
              <img src={playstore} alt="Google Play" />
            </a>
          </div>
          <hr className="mobile medium" />
          <div className="footer-banner-social-right">
            <strong className="no-mobile">Suivez-nous sur</strong>
            <a href="https://www.facebook.com/taaricommunity/" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
            <a href="https://twitter.com/taaricommunity" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            <a href="https://www.instagram.com/taaricommunity/" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.youtube.com/channel/UCPHkGD-KE1iyssKznVkwWPw/featured?view_as=subscriber" target="_blank" rel="noopener noreferrer">
              <FaYoutube />
            </a>
          </div>
          <hr className="medium mobile" />
        </div>
        <hr className="long no-mobile" />
        <div className="footer-banner-countries copyright">
          <strong className="copy upper">&copy; Taari 2017 - {(new Date().getFullYear())}</strong>
          <ul className="no-mobile">
            <li
              onClick={manageCountryPopup}
              className="footer-banner-countries-country"
            >
              { (country && country.images[0] !== undefined) && (
                <>
                  <img src={url + filename} alt={country.name} />
                  {country.name}
                </>
              ) }
              { (!country) && ('Choisir un pays') }
              <FaChevronDown />
              { (popupOpened) && (<CountryDropdown />) }
            </li>
          </ul>
        </div>
        <hr className="blank no-mobile" />
      </div>
    </div>
  );
};

export default FooterBanner;
